const userBranchesSearchInput = document.getElementById('user-branches-search');

function getBranchUsers() {
  const accountId = document.getElementById('branch_users').dataset.accountId;
  const branchId = document.getElementById('branch_users').dataset.branchId;

  window.$.ajax({
    url: `/accounts/${accountId}/branches/${branchId}/branch_users`,
    data: { filter: { user_full_name_or_user_email_cont: userBranchesSearchInput.value } },
    dataType: 'script',
    type: 'GET',
  });
}

userBranchesSearchInput?.addEventListener('keyup', window.debounce(() => {
  getBranchUsers();
  window.AddParamsToTailwindPagination({ 'filter[user_full_name_or_user_email_cont]': userBranchesSearchInput.value });
  if (userBranchesSearchInput.value !== '') {
    userBranchesSearchInput.classList.add('search_input_filled');
    document.getElementById('clean-user-branch-search').classList.remove('tw-hidden');
  } else {
    userBranchesSearchInput.classList.remove('search_input_filled');
    document.getElementById('clean-user-branch-search').classList.add('tw-hidden');
  }
}, 500));

function verifySelectAllBranchPermissions() {
  const permissions = ['run', 'edit', 'admin'];
  permissions.map((permission) => window.verifySelectAllStatus(`selectAll${permission}`, `[id*="controller-${permission}"]`));
}
window.verifySelectAllBranchPermissions = verifySelectAllBranchPermissions;
document.addEventListener('DOMContentLoaded', () => {
  const branchUsers = document.getElementById('branch_users-index');

  if (branchUsers) {
    verifySelectAllBranchPermissions();
    const urlValues = new URL(window.location.href);
    const suggested = urlValues.searchParams.get('filter[user_full_name_or_user_email_cont]');
    if (suggested) {
      document.getElementById('user-branches-search').value = suggested;
      userBranchesSearchInput.classList.add('search_input_filled');
      document.getElementById('clean-user-branch-search').classList.remove('tw-hidden');
    }
  }
});

function updateAllBranchUserPermission(permission, checkboxElement, userId = null) {
  const accountId = document.getElementById('branch_users').dataset.accountId;
  const branchId = document.getElementById('branch_users').dataset.branchId;
  const elementChecked = checkboxElement.checked;
  let userIds;
  if (userId === null) {
    const userInputs = document.querySelectorAll(`[id*='controller-${checkboxElement.id.split('selectAll')[1]}']`);
    userIds = [...userInputs].map((user) => user.id.split('-')[2]);
  } else {
    userIds = [userId];
  }
  window.$.ajax({
    url: `/accounts/${accountId}/branches/${branchId}/branch_users/update_permissions`,
    data: { user_ids: userIds, add_permission: elementChecked, permission },
    dataType: 'script',
    type: 'PUT',
  });
}
window.updateAllBranchUserPermission = updateAllBranchUserPermission;
function destroyBranchUser(branchUserId) {
  const accountId = document.getElementById('branch_users').dataset.accountId;
  const branchId = document.getElementById('branch_users').dataset.branchId;
  if (document.querySelector('#remove-branch-user-modal #loading-state').children.length === 0) {
    window.loadAnimationPending('#remove-branch-user-modal #loading-state');
  }
  document.querySelector('#remove-branch-user-modal .loading_component').classList.remove('tw-hidden');
  const button = document.querySelector('#remove-branch-user-modal .action');

  button.classList.remove('tw-btn-action-red');
  button.classList.add('tw-btn-disabled');

  window.$.ajax({
    url: `/accounts/${accountId}/branches/${branchId}/branch_users/${branchUserId}`,
    dataType: 'script',
    type: 'DELETE',
    error: () => {
      button.classList.add('tw-btn-action-red');
      button.classList.remove('tw-btn-disabled');
      document.querySelector('#remove-branch-user-modal .loading_component').classList.add('tw-hidden');
    },
  });
}

function showRemoveBranchUserModal(branchUserId, userName, branchName) {
  const warningModal = document.getElementById('remove-branch-user-modal');
  warningModal.querySelector('.tw-btn-action-red').onclick = () => destroyBranchUser(branchUserId);
  warningModal.querySelector('.description').innerHTML = window.I18n.branch.manage.users.remove_modal.description_html.replace('%{user_name}', userName).replace('%{branch_name}', branchName);
  warningModal.classList.remove('tw-hidden');
}
window.showRemoveBranchUserModal = showRemoveBranchUserModal;
