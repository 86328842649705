function updateInspectionsModalMenuButtons(modal, mainButtonClass, otherButtonClass) {
  modal.classList.remove('tw-hidden');
  modal.querySelector(`#${mainButtonClass}`).classList.remove('tw-hidden');
  modal.querySelector(`.${mainButtonClass}-btn`).classList.add('tw-btn-action-blue');
  modal.querySelector(`.${mainButtonClass}-btn`).classList.remove('tw-text-blue-500');
  modal.querySelector(`.${mainButtonClass}-btn p`).classList.remove('tw-hidden', 'md:tw-block');
  modal.querySelector(`.${mainButtonClass}-btn p`).classList.add('tw-block');
  modal.querySelector(`#${otherButtonClass}`).classList.add('tw-hidden');
  modal.querySelector(`.${otherButtonClass}-btn`).classList.remove('tw-btn-action-blue');
  modal.querySelector(`.${otherButtonClass}-btn`).classList.add('tw-text-blue-500');
  modal.querySelector(`.${otherButtonClass}-btn p`).classList.add('tw-hidden', 'md:tw-block');
  modal.querySelector(`.${otherButtonClass}-btn p`).classList.remove('tw-block');
}

function updateInspectionsScheduled(type, modal) {
  updateInspectionsModalMenuButtons(modal, 'scheduled_inspections', 'inspections_performed');
  window.loadAnimationPending(`#${type}_inspections_detail_modal #scheduled_inspections #loading-state`);
  window.$.ajax({
    url: '/insights/scheduled_inspections',
    data: { type, per_page: 5, branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'script',
    type: 'GET',
  });
}

function updateInspectionsPerformed(type, modal) {
  updateInspectionsModalMenuButtons(modal, 'inspections_performed', 'scheduled_inspections');
  window.loadAnimationPending(`#${type}_inspections_detail_modal #inspections_performed #loading-state`);
  window.$.ajax({
    url: '/insights/performed_inspections',
    data: { type, per_page: 5, branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'script',
    type: 'GET',
  });
}

function changeInspectionsCompletedTab(period, tabName) {
  const modal = document.getElementById(`${period}_inspections_detail_modal`);
  const makeCall = (modal.querySelector(`#${tabName} #inspections_detail > div`).id) === 'loading-data';
  if (tabName === 'inspections_performed') {
    if (makeCall) {
      updateInspectionsPerformed(period, modal);
    } else {
      updateInspectionsModalMenuButtons(modal, 'inspections_performed', 'scheduled_inspections');
    }
  } else if (makeCall) {
    updateInspectionsScheduled(period, modal);
  } else {
    updateInspectionsModalMenuButtons(modal, 'scheduled_inspections', 'inspections_performed');
  }
}
window.changeInspectionsCompletedTab = changeInspectionsCompletedTab;
