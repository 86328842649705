function updateFileSelection(files, sectionId) {
  const section = document.querySelector(`#${sectionId} .tw-info`);
  if (files.length > 0) {
    section.classList.remove('tw-hidden');
    const size = window.bytesToSize(files[0].size);
    document.querySelector(`#${sectionId} .choose`).classList.add('tw-hidden');
    document.querySelector(`#${sectionId} .cancel`).classList.remove('tw-hidden');
    document.querySelector(`#${sectionId} .upload`).classList.remove('tw-hidden');
    section.children[1].textContent = `${files[0].name} (${size})`;
  } else {
    section.classList.add('tw-hidden');
    document.querySelector(`#${sectionId} .choose`).classList.remove('tw-hidden');
    document.querySelector(`#${sectionId} .cancel`).classList.add('tw-hidden');
    document.querySelector(`#${sectionId} .upload`).classList.add('tw-hidden');
  }
}
window.updateFileSelection = updateFileSelection;

function submitFile(sectionId) {
  const file = document.querySelector(`#${sectionId} input[type="file"]`);
  const formData = new FormData();
  formData.append('file', file.files[0]);
  formData.append('renewal_type', sectionId);
  window.$.ajax({
    url: '/dashboard/admin/bulk_renewals',
    data: formData,
    type: 'POST',
    contentType: false,
    dataType: 'script',
    processData: false,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('dashboard-admin-bulk_renewals-new');
  if (body) {
    ['service', 'auto'].forEach((sectionId) => {
      const file = document.querySelector(`#${sectionId} input[type="file"]`);
      document.querySelector(`#${sectionId} .choose`).addEventListener('click', () => file.click());
      file.addEventListener('change', (event) => {
        updateFileSelection(event.target.files, sectionId);
      });
      document.querySelector(`#${sectionId} .remove_file`).addEventListener('click', () => {
        file.value = '';
        updateFileSelection([], sectionId);
      });
      document.querySelector(`#${sectionId} .submit`).addEventListener('click', () => submitFile(sectionId));
      document.querySelector(`#${sectionId} .cancel`).addEventListener('click', () => {
        file.value = '';
        updateFileSelection([], sectionId);
      });
    });
  }
});
