import Backbone from 'backbone';
import $ from 'jquery';

const InspectionEditView = Backbone.View.extend({
  el: 'body',
  events: {
    'ajax:success .first-inspection-form': 'hideFirstSettings',
    'ajax:error .first-inspection-form': 'saveError',
    'click #show-first-inspection': 'toggleFirstSettings',
  },
  initialize() {
    // console.log 'Inspection Edit Initalized'
    if (!$('#first-inspection').hasClass('hidden')) {
      $('#show-first-inspection').addClass('hidden');
    }
  },
  saveError(e, xhr) {
    alert(`There was a problem saving your settings.  Please check all your values are valid.  ${xhr.responseText}`);
  },
  toggleFirstSettings() {
    $('#first-inspection').removeClass('hidden');
    $('#show-first-inspection').addClass('hidden');
    return $('#first-inspection').slideDown();
  },
  hideFirstSettings() {
    $('#first-inspection').slideUp();
    $('#show-first-inspection').removeClass('hidden');
    $('#prior-equipment').text($('#inspection_prior_equipment').val());
    $('#prior-configuration-compliant').text($('#inspection_prior_configuration_compliant option:selected').text());
    $('#prior-configuration-rf-present').text($('#inspection_prior_configuration_rf_present option:selected').text());
    return $('#water-pressure').text(`${$('#inspection_water_pressure').val()} ${$('#inspection_water_pressure_unit option:selected').text()}`);
  },
});
export default InspectionEditView;
