function closeTransferSitesModal() {
  const modal = document.getElementById('transfer-sites-modal');
  modal.querySelector('input').value = '';
  const addSitesButton = document.querySelector('#transfer-sites-modal #add-sites-button');
  addSitesButton.classList.remove('tw-button-primary');
  addSitesButton.classList.add('tw-btn-disabled');
  modal.classList.add('tw-hidden');
}
window.closeTransferSitesModal = closeTransferSitesModal;
function findBranches(searchValue) {
  const dataset = document.getElementById('branches-sites').dataset;
  window.$.ajax({
    url: `/accounts/${dataset.accountId}/branches/${dataset.branchId}/branch_sites/find_branches`,
    data: { filter: { search: searchValue } },
    dataType: 'script',
    type: 'GET',
  });
}

const selectBranchToTransferSites = document.querySelector('#transfer-sites-modal input');

selectBranchToTransferSites?.addEventListener('keyup', window.debounce(() => {
  findBranches(selectBranchToTransferSites.value);
}, 500));

const branchToTransferSitesDropdownIcon = document.querySelector('#transfer-sites-modal #select-branch-icon');
branchToTransferSitesDropdownIcon?.addEventListener('click', () => findBranches(selectBranchToTransferSites.value));

function updateSelectedBranchInput(branchName, branchId) {
  const addSitesButton = document.querySelector('#transfer-sites-modal #add-sites-button');
  addSitesButton.classList.remove('tw-btn-disabled');
  addSitesButton.classList.add('tw-button-primary');
  document.getElementById('transfer_sites-branch_selected').innerHTML = '';
  selectBranchToTransferSites.value = branchName;
  selectBranchToTransferSites.dataset.branchId = branchId;
}
window.updateSelectedBranchInput = updateSelectedBranchInput;
document.addEventListener('mouseup', (e) => {
  if (!e.target.closest('#transfer_sites-branch_selected')) {
    const branchesDropdown = document.getElementById('transfer_sites-branch_selected');
    if (branchesDropdown) {
      branchesDropdown.innerHTML = '';
    }
  }
});

function transferSites() {
  const dataset = document.getElementById('branches-sites').dataset;
  const newBranchId = selectBranchToTransferSites.dataset.branchId;
  const selectedSites = document.querySelectorAll("[id*=\"sites-select\"][type='checkbox']:checked");
  const siteIds = [...selectedSites].map((site) => site.id.split('_')[1]);
  window.$.ajax({
    url: `/accounts/${dataset.accountId}/branches/${dataset.branchId}/branch_sites/bulk_transfer`,
    dataType: 'script',
    data: { site_ids: siteIds, new_branch_id: newBranchId },
    type: 'PUT',
  });
}
window.transferSites = transferSites;
