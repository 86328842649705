const newUserRoleModal = document.getElementById('new-user-role-modal');
newUserRoleModal?.addEventListener('click', (event) => {
  if (event.target.classList.contains('tw-btn-cancel')) newUserRoleModal.classList.add('tw-hidden');
});
function showNewUserRoleModal(url) {
  const modal = document.getElementById('new-user-role-modal');
  modal.classList.remove('tw-hidden');
  modal.dataset.url = url;
}
window.showNewUserRoleModal = showNewUserRoleModal;
function enableAssignRoleButton() {
  const button = document.querySelector('#new-user-role-modal .btn-action');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-btn-action-blue');
}
window.enableAssignRoleButton = enableAssignRoleButton;
function addNewUserWithRole() {
  const url = document.getElementById('new-user-role-modal').dataset.url;
  const userRole = document.querySelector('#new-user-role-modal .li-check').id.split('-')[1];
  window.$.ajax({
    url,
    dataType: 'script',
    data: { user_role_id: userRole },
    type: 'PATCH',
  });
}
window.addNewUserWithRole = addNewUserWithRole;
