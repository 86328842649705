function showSetUserGoalModal(userName, url, proposalsValue, proposalsAcceptedValue, proposalsAcceptanceRate, inspectionGoals) {
  const modal = document.getElementById('set-user-goal-modal');
  const userNameContainer = modal.querySelector('.user-name');
  userNameContainer.innerHTML = window.I18n.accounts.user_goals.set_user_goal.subtitle.replace('%{user_name}', userName);
  document.querySelector('input[name=proposals-value]').value = parseFloat(proposalsValue).toFixed(2);
  document.querySelector('input[name=proposals-accepted-value]').value = parseFloat(proposalsAcceptedValue).toFixed(2);
  document.querySelector('input[name=proposals-acceptance-rate]').value = proposalsAcceptanceRate;
  document.querySelector('input[name=inspection-goals]').value = inspectionGoals;
  if (inspectionGoals === 0) {
    window.toggleInspectionGoalsMinusButton('disable');
  } else {
    window.toggleInspectionGoalsMinusButton('enable');
  }
  modal.classList.remove('tw-hidden');
  modal.dataset.url = url;
}
window.showSetUserGoalModal = showSetUserGoalModal;

function hideSetUserGoalModal() {
  const modal = document.getElementById('set-user-goal-modal');
  const setGoalButton = document.querySelector('#set-user-goal-modal .set-goal-btn');
  modal.querySelector('#proposals').classList.add('tw-hidden');
  modal.querySelector('#inspections').classList.add('tw-hidden');
  modal.classList.add('tw-hidden');
  setGoalButton.classList.remove('tw-button-primary');
  setGoalButton.classList.add('tw-btn-disabled');
  window.resetDropdown('#set-user-goal-modal');
}
window.hideSetUserGoalModal = hideSetUserGoalModal;
