function updatePlaidPaymentButton() {
  document.querySelector('#plaid-accounts_modal .submit').classList.remove('tw-btn-disabled');
  document.querySelector('#plaid-accounts_modal .submit').classList.add('tw-button-primary');
}
window.updatePlaidPaymentButton = updatePlaidPaymentButton;

function renderPlaidAccounts(accounts) {
  document.getElementById('plaid-accounts_modal').classList.remove('tw-hidden');
  document.querySelector('#plaid-accounts_modal .submit').classList.add('tw-btn-disabled');
  document.querySelector('#plaid-accounts_modal .submit').classList.remove('tw-button-primary');
  document.querySelector('#plaid_accounts_dropdown button').firstChild.data = window.I18n.select;
  const container = document.querySelector('#plaid_accounts_dropdown ul');
  container.innerHTML = '';
  accounts.forEach((account) => {
    const html = `<li class="tw-list-none tw-font-normal tw-text-base tw-text-gray-600 tw-bg-white tw-p-2 tw-flex tw-items-center hover:tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white tw-gap-[10px] li-check"
      id="plaid_accounts_dropdown-${account.account_id}"
      onclick="selectChosenOption(this);updatePlaidPaymentButton()"
    >
      <svg
        class="tw-w-4 tw-h-4 heroicon heroicon-check tw-hidden"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
      </svg>
      ${account.name} - ${account.mask}
    </li>`;
    container.innerHTML += html;
  });
}

function createUserPlaidPaymentSource(token) {
  const plaidAccountId = document.querySelector('#plaid-accounts_modal .li-check').id.split('-')[1];
  window.$.ajax({
    type: 'POST',
    data: {
      token,
      type: 'plaid',
      plaid_account_id: plaidAccountId,
      alias: document.querySelector("#plaid-accounts_modal input[name='account-alias']").value,
      creator_type: document.querySelector("#plaid-accounts_modal input[name='plaid_owner_type']:checked").value,
      primary: document.querySelector("#plaid-accounts_modal input[name='plaid_primary_payment']").checked,
    },
    url: '/payment_sources',
    dataType: 'script',
  });
}

function getPlaidAccounts(token) {
  window.$.ajax({
    type: 'POST',
    data: { token },
    url: '/api/v2/cart/payment_sources/plaid_accounts',
    success: (data) => {
      renderPlaidAccounts(data.accounts);
      const button = document.querySelector('#plaid-accounts_modal .submit');
      button.onclick = () => { createUserPlaidPaymentSource(token); };
    },
    error: (jqXHR, textStatus) => {
      window.renderPaymentError(textStatus);
    },
  });
}

function openPlaidModal() {
  // TODO: Once the Plaid gem is update to the latest version, we should change the key and use token instead
  // https://plaid.com/docs/link/link-token-migration-guide/
  const handler = window.Plaid.create({
    env: window.SERVER_ENV === 'production' ? 'production' : 'sandbox',
    apiVersion: 'v2',
    clientName: 'SLN Payments',
    key: window.PLAID_KEY,
    product: ['auth'],
    onSuccess: (publickToken) => { getPlaidAccounts(publickToken); },
    onExit: window.plaidExited,
  });
  handler.open();
}
window.openPlaidModal = openPlaidModal;

function plaidExited(error) {
  if (error != null) {
    window.renderPaymentError('', `Error adding account: ${error.message} (${error.code})`);
  }
}
window.plaidExited = plaidExited;
