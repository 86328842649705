function invoicesSetEntriesParamValue() {
  const invoicesEntrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (invoicesEntrySelected) {
    const entries = document.getElementById(`accounts_invoices-entries-${invoicesEntrySelected}`);
    window.selectChosenOption(entries);
  }
}

const invoicesColumnsHeaders = {
  4: 'subscription_invoices.created_at',
  5: 'billing_user',
};

function invoicesSetSortParamValue() {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(invoicesColumnsHeaders);
    const values = Object.values(invoicesColumnsHeaders);

    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}

function getInvoices(searchValue, page, perPage, sort) {
  const accountId = window.location.href.split('/')[4];
  window.$.ajax({
    url: `/accounts/${accountId}/subscriptions/invoices`,
    data:
    {
      filter: { search: searchValue },
      page,
      per_page: perPage,
      sort,
    },
    dataType: 'script',
    type: 'GET',
  });
}

function invoicesSetSearchInputListenerAndValue() {
  const invoicesSearchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const invoicesSearchInput = document.getElementById('search-accounts_invoices');
  invoicesSearchInput.value = invoicesSearchInputParam;

  invoicesSearchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#accounts_invoices-entries .li-check').id.split('-')[2];
    const sortSelected = (new URLSearchParams(window.location.search)).get('sort');
    window.AddParamsToTailwindPagination({ 'filter[search]': invoicesSearchInput.value });
    getInvoices(invoicesSearchInput.value, 1, entrySelected, sortSelected);
  }, 500));
}

function executeInvoicesSort(event) {
  const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
  const classesList = event.target.classList;
  const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
  const sortStr = `${invoicesColumnsHeaders[index]} ${sort}`;

  const entries = document.querySelector('#accounts_invoices-entries .li-check').id.split('-')[2];
  const searchValue = document.getElementById('search-accounts_invoices').value;
  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}` });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  getInvoices(searchValue, 1, entries, sortStr);
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('account_subscriptions-invoices');
  if (body) {
    document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', executeInvoicesSort);
    });
    invoicesSetSortParamValue();
    invoicesSetEntriesParamValue();
    invoicesSetSearchInputListenerAndValue();
  }
});

function updateEntriesForAccountsInvoicesTable() {
  const entries = document.querySelector('#accounts_invoices-entries .li-check').id.split('-')[2];
  const searchValue = document.getElementById('search-accounts_invoices').value;
  const sortSelected = (new URLSearchParams(window.location.search)).get('sort');
  getInvoices(searchValue, 1, entries, sortSelected);
}
window.updateEntriesForAccountsInvoicesTable = updateEntriesForAccountsInvoicesTable;
function updateAccountsInvoicesUrlWithEntries() {
  const entries = document.querySelector('#accounts_invoices-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateAccountsInvoicesUrlWithEntries = updateAccountsInvoicesUrlWithEntries;
function getInvoicesIds() {
  const checkedInputs = document.querySelectorAll('table input[id*="select-invoice"]:checked');
  const ids = [];
  checkedInputs.forEach((input) => ids.push(input.id.split('-')[2]));
  return ids;
}

function getInvoicesIdsForUrl(invoicesIds) {
  let path = '';
  invoicesIds.forEach((id) => path += `invoices_ids[]=${id}&`);
  return path;
}

function exportInvoices() {
  const invoicesIds = getInvoicesIds();
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });

  if (invoicesIds.length >= 1) {
    const accountId = window.location.href.split('/')[4];
    const url = `/accounts/${accountId}/subscriptions/export_invoices?${getInvoicesIdsForUrl(invoicesIds)}`;
    let filename;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        const disposition = res.headers.get('Content-Disposition');
        filename = disposition.split(';')[2].split('=')[1];
        if (filename.toLowerCase().startsWith("utf-8''")) filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
        else filename = filename.replace(/['"]/g, '');
        return res.blob();
      })
      .then((blob) => {
        const urlObject = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlObject;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.cleanSnackbars();
        window.toggleSnackbar('success-snackbar', window.I18n.accounts.invoices.export.success);
      });
  } else {
    window.toggleSnackbar('warning-snackbar', window.I18n.accounts.invoices.export.error);
  }
}
window.exportInvoices = exportInvoices;
function disableGeneralCheckbox() {
  const checkbox = document.getElementById('select-all-invoices');
  checkbox.checked = false;
}
window.disableGeneralCheckbox = disableGeneralCheckbox;
