function selectChosenOption(optionElement, skipReplace = false) {
  const button = optionElement.parentElement.parentElement.parentElement.querySelector('.btn-dropdown');
  if (button.children[0]?.classList.contains('icon-for-option')) {
    button.children[0].remove();
  }
  if (!skipReplace) button.querySelector('.dropdown-content').textContent = optionElement.textContent;

  const dropdown = button.nextElementSibling;
  dropdown.classList.add('tw-hidden');

  const options = optionElement.parentElement.querySelectorAll('li');
  options.forEach((option) => {
    option.firstChild.nextElementSibling.classList.add('tw-hidden');
    option.classList.remove('li-check');
    option.classList.add('li-no-check');
  });

  const checkIconContainer = optionElement.querySelector('#check-icon-container');
  checkIconContainer.classList.remove('tw-hidden');
  checkIconContainer.parentElement.classList.remove('li-no-check');
  checkIconContainer.parentElement.classList.add('li-check');
}
window.selectChosenOption = selectChosenOption;
function resetDropdown(selector, selectMessage) {
  document.querySelector(`${selector} .btn-dropdown .dropdown-content`).textContent = selectMessage || window.I18n.select;
  const chosenOption = document.querySelector(`${selector} .li-check`);
  if (chosenOption) {
    chosenOption.firstElementChild.classList.add('tw-hidden');
    chosenOption.classList.remove('li-check');
    chosenOption.classList.add('li-no-check');
  }
}
window.resetDropdown = resetDropdown;
