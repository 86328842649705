function updateAccountName(nameInput) {
  const hiddenNameInput = document.getElementById('hidden-name-input');
  const button = document.querySelector('.ctn-name form .tw-button');

  hiddenNameInput.value = nameInput.value;
  if (window.isEmpty(nameInput.value) || nameInput.value === nameInput.dataset.original) {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
    nameInput.classList.remove('input-typed');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-button-primary');
    nameInput.classList.add('input-typed');
  }
}
window.updateAccountName = updateAccountName;
const logoFileInput = document.querySelector('#account-settings #logo-file-input');
logoFileInput?.addEventListener('change', (event) => {
  const logoPreview = document.querySelector('#account-settings .ctn-logo-preview');
  const filePreview = logoPreview.nextElementSibling;
  const button = document.querySelector('.ctn-logo form .tw-button');
  const fileAddress = event.target.value.split('\\');

  logoPreview.classList.add('tw-hidden');
  filePreview.classList.remove('tw-hidden');
  button.classList.add('tw-button-primary');
  button.classList.remove('tw-btn-disabled');
  filePreview.querySelector('p').textContent = fileAddress[fileAddress.length - 1];
});
function updateAccountSecuritySetting(optionElement) {
  const hiddenInput = document.getElementById('hidden-security-settings-input');
  const button = document.querySelector('.ctn-security-settings form .tw-button');

  hiddenInput.value = optionElement.id.split('-')[2];
  button.classList.add('tw-button-primary');
  button.classList.remove('tw-btn-disabled');
}
window.updateAccountSecuritySetting = updateAccountSecuritySetting;
