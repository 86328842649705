function toggleExportButton() {
  const sections = document.querySelectorAll("#insights-export_modal input[type='checkbox']");
  const exportButton = document.querySelector('#insights-export_modal .submit');
  let selectedSections = 0;
  sections.forEach((section) => {
    if (section.checked === true && section.disabled !== true) selectedSections += 1;
  });
  if (selectedSections > 0) {
    exportButton.classList.add('tw-btn-action-blue');
    exportButton.classList.remove('tw-btn-disabled');
  } else {
    exportButton.classList.remove('tw-btn-action-blue');
    exportButton.classList.add('tw-btn-disabled');
  }
}
window.toggleExportButton = toggleExportButton;
