import Backbone from 'backbone';

const ReportWeatherCollection = Backbone.Collection.extend({
  initialize(siteId) {
    this.siteId = siteId;
  },
  url() {
    return `/api/v2/sites/${this.siteId}/report/weather.json`;
  },
  parse(response) {
    if (Object.hasOwn(response, 'result') && Object.hasOwn(response.result, 'site')) {
      const { site } = response.result;
      if (Object.hasOwn(site, 'controllers') && site.controllers.length > 0) {
        const controller = site.controllers[0];
        if (Object.hasOwn(controller, 'zones') && controller.zones.length > 0) {
          return controller.zones[0].weather;
        }
        return controller.weather;
      }
      return site.weather;
    }
    return [];
  },
});

const ReportUsageCollection = Backbone.Collection.extend({
  initialize(siteId) {
    this.siteId = siteId;
  },
  url() {
    return `/api/v2/sites/${this.siteId}/report/usage.json`;
  },
  parse(response) {
    if (Object.hasOwn(response, 'result') && Object.hasOwn(response.result, 'site')) {
      const { site } = response.result;
      if (Object.hasOwn(site, 'controllers') && site.controllers.length > 0) {
        const controller = site.controllers[0];
        if (Object.hasOwn(controller, 'zones') && controller.zones.length > 0) {
          return controller.zones[0].usage;
        }
        return controller.usage;
      }
      return site.usage;
    }
    return [];
  },
});

const ReportDeficitCollection = Backbone.Collection.extend({
  initialize(siteId) {
    this.siteId = siteId;
  },
  url() {
    return `/api/v2/sites/${this.siteId}/report/deficit.json`;
  },
  parse(response) {
    if (Object.hasOwn(response, 'result') && Object.hasOwn(response.result, 'site')) {
      const { site } = response.result;
      if (Object.hasOwn(site, 'controllers') && site.controllers.length > 0) {
        const controller = site.controllers[0];
        if (Object.hasOwn(controller, 'zones') && controller.zones.length > 0) {
          return controller.zones[0].deficits;
        }
        return controller.deficits;
      }
      return site.deficits;
    }
    return [];
  },
});
export { ReportWeatherCollection, ReportUsageCollection, ReportDeficitCollection };
