function openExitModal() {
  const modal = document.getElementById('exit-modal-global-commands');
  modal.classList.remove('tw-hidden');
}

const globalCommandsExit = document.querySelector('.global-commands .ctn-exit');
globalCommandsExit?.addEventListener('click', openExitModal);

const exitModal = document.getElementById('exit-modal-global-commands');
exitModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('tw-btn-cancel')) exitModal.classList.add('tw-hidden');
});
