import Backbone from 'backbone';
import $ from 'jquery';
import addProposalCCEmailTemplate from '@assets/javascripts/backbone/templates/proposals/add_proposal_cc_email.ejs';

const InspectionsProposalsConfigureView = Backbone.View.extend({
  el: 'body',
  events: {
    'click .create-proposal-cc-email': 'createProposalCCEmail',
    'click .delete-proposal-cc-email': 'deleteProposalCCEmail',
  },
  initialize() {
    console.log('Inspections Proposals Configure Initalized');
    this.addProposalCCEmail = addProposalCCEmailTemplate;
  },
  createProposalCCEmail(e) {
    const self = this;
    e.preventDefault();
    const address = $('#create-address').val();
    const description = $('#create-description').val();
    const accountID = $('#account_id').attr('data-account-id');

    $.ajax({
      url: '/api/v2/proposal_carbon_copies',
      type: 'POST',
      data: ({ cc_email: { address, description, account_id: accountID } }),
      dataType: 'json',
      success(response) {
        if (response.errors) {
          alert(`Sorry, email not added: ${response.errors[0]}`);
        } else {
          $('#create-address').val('');
          $('#create-description').val('');
          $('#proposals-cc-list tr').eq(1).after(self.addProposalCCEmail({
            address: response.address,
            description: response.description,
            proposal_cc_email_id: response.id,
          }));
          $('#proposals-cc-list tr').eq(2).addClass('success');
          setTimeout((() => {
            $('#proposals-cc-list tr').eq(2).removeClass('success');
          }), 1000);
        }
      },
      error(response) {
        alert("We're sorry something went wrong.");
        console.log(response);
      },
    });
  },
  deleteProposalCCEmail(e) {
    e.preventDefault();
    const ccEmailID = $(e.currentTarget).attr('data-cc-email-id');
    const accountID = $('#account_id').attr('data-account-id');
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm('Are you sure you want to delete this email from the list?')) {
      $.ajax({
        url: `/api/v2/proposal_carbon_copies/${ccEmailID}`,
        type: 'DELETE',
        dataType: 'json',
        data: ({ cc_email: { account_id: accountID } }),
        success() {
          $(e.currentTarget).parent().parent().fadeOut();
        },
        error(response) {
          alert("We're sorry something went wrong");
          console.log(response);
        },
      });
    }
  },
});
export default InspectionsProposalsConfigureView;
