window.$('#show_custom_fields_button').on('click', () => {
  window.$('#site_custom_fields').toggleClass('tw-hidden');
});

const optionsContainer = (dropdownId, inner) => `
  <div id=${dropdownId} class="tw-text-base tw-text-gray-600 tw-mt-1 tw-bg-white tw-w-full tw-absolute tw-z-50 tw-rounded-xl tw-shadow-[0px_0px_8px_rgba(0,0,0,0.16)] colored_scroll" style="max-height: 200px;overflow-y: auto">
    ${inner}
  </div>
`;

const showSelectDropdownOptions = (element, dateSelect = false) => {
  const container = dateSelect ? window.$(element).parent() : window.$(element);
  if (container.parent().attr('disabled') !== 'disabled') {
    const dropdownId = `${container.find('select').attr('id')}_dropdown`;

    if (!container.parent().find(`#${dropdownId}`).length) {
      const options = container.find('select').html();

      // * If there is no options, remove required
      const parentContainer = dateSelect ? container : container.parent();
      if (window.$(options).length === 0) {
        parentContainer.append(optionsContainer(dropdownId, '<div>No option</div>'));
      }

      parentContainer.append(optionsContainer(dropdownId, options.replace(/option/g, 'div')));
    } else {
      window.$(`#${dropdownId}`).remove();
    }
    const dropdown = window.$(`#${dropdownId}`);
    dropdown
      .find('div')
      .addClass(
        'tw-h-fit tw-break-all tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-bg-blue-500 hover:tw-text-white',
      );

    // * Hide dropdown when click outside
    window.$(document).mouseup((e) => {
      e.stopPropagation();
      if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) dropdown.remove();
    });

    dropdown.find('div').each((i, el) => {
      window.$(el).one('click', (e) => {
        dropdown.remove();
        const select = container.find('select');
        select.val(window.$(e.target).attr('value'));
        select[0].dispatchEvent(new Event('change'));
      });
    });
  }
};

window.showSelectDropdownOptions = showSelectDropdownOptions;

function clearDropdownSelection(event) {
  event.stopPropagation();
  const selectElement = event.target.closest('.sln-select').querySelector('select');
  selectElement.value = '';
  selectElement.nextElementSibling.classList.add('tw-hidden');
  selectElement.classList.remove('tw-bg-none');
  selectElement.dispatchEvent(new Event('change'));
}

window.clearDropdownSelection = clearDropdownSelection;
