function getControllers({ searchValue, page, perPage }) {
  const sectionId = document.getElementsByTagName('section')[0].id;
  const siteId = sectionId.split('-')[1];

  window.$.ajax({
    url: `/sites/${siteId}/delete`,
    data:
    {
      filter: { search: searchValue },
      page,
      per_page: perPage,
    },
    dataType: 'script',
    type: 'GET',
  });
}

function controllersSetSearchInputListenerAndValue() {
  const controllerSearchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const controllerSearchInput = document.getElementById('controller-search-input');
  controllerSearchInput.value = controllerSearchInputParam;

  controllerSearchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#controller-entries-input .li-check').id.split('-')[3];
    window.AddParamsToTailwindPagination({ 'filter[search]': controllerSearchInput.value });
    getControllers({ searchValue: controllerSearchInput.value, page: 1, perPage: entrySelected });
  }, 500));
}

function controllersSetEntriesParamValue() {
  const controllersEntrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (controllersEntrySelected) {
    const entries = document.getElementById(`controller-entries-input-${controllersEntrySelected}`);
    window.selectChosenOption(entries);
  }
}

function updateSitesDeleteUrlWithEntries() {
  const entries = document.querySelector('#controller-entries-input .li-check').id.split('-')[3];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

function updateEntriesForDeleteControllerTable() {
  const entries = document.querySelector('#controller-entries-input .li-check').id.split('-')[3];
  const searchValue = document.getElementById('controller-search-input').value;
  updateSitesDeleteUrlWithEntries();
  getControllers({ searchValue, page: 1, perPage: entries });
}
window.updateEntriesForDeleteControllerTable = updateEntriesForDeleteControllerTable;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('sites-delete');
  if (body) {
    controllersSetEntriesParamValue();
    controllersSetSearchInputListenerAndValue();
  }
});

function deleteSite(siteId) {
  window.$.ajax({
    url: `/sites/${siteId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}

function showDeleteSiteModal(siteId) {
  const warningModal = document.getElementById('delete_site_modal');
  warningModal.querySelector('.tw-btn-action-red').onclick = () => deleteSite(siteId);
  warningModal.classList.remove('tw-hidden');
}
window.showDeleteSiteModal = showDeleteSiteModal;
