import Backbone from 'backbone';
import $ from 'jquery';
import ZoneModel from '@assets/javascripts/backbone/models/zone.js';
import InspectionZoneModel from '@assets/javascripts/backbone/models/inspections/inspections_zones.js';
import '@vendor/javascripts/jquery-plugins/jquery.dropdown-form.js.coffee';

const InspectionEditZonesView = Backbone.View.extend({
  el: '#inspection-table',
  controllerId: $('#inspection').data('controller-id'),
  inspectionId: $('#inspection').data('inspection-id'),
  events: {
    'click .editable.boolean': 'editBoolean',
    'click .editable.boolean2': 'editBoolean2',
    'click .zone-settings': 'editZoneSettings',
    'click .save-zone-settings': 'saveZoneSettings',
    'click .cancel-zone-settings': 'cancelZoneSettings',
    'click .percent-area': 'editPercentArea',
    'click .save-percent-area-setting': 'save_percent_area_setting',
    'click .cancel-percent-area-setting': 'cancelPercentArea',
    'change select.valve_size': 'selectValveSize',
  },
  initialize() {
    this.checkDropdownForm();
  },
  checkDropdownForm() {
    if (typeof $.fn.dropdownForm === 'function') {
      this.initializeDropdowns();
    } else {
      console.log('retrying in 100 ms');
      setTimeout(this.checkDropdownForm.bind(this), 100);
    }
  },
  initializeDropdowns() {
    $('.dropdown-form.integer').dropdownForm({ success: this.dropdownInteger.bind(this) });
    $('.dropdown-form.boolean-select').dropdownForm({ success: this.dropdownBoolean.bind(this) });
    $('.dropdown-form.estimated-cost').dropdownForm({ success: this.dropdownEstimatedCost.bind(this) });
    $('.dropdown-form.description').dropdownForm({ success: this.dropdownDescription.bind(this) }, { trigger: '.zone-description-trigger' });
  },
  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  },

  // ----------------------
  // Provide default GPM based on valve size selection
  // ----------------------
  selectValveSize(e) {
    let baseval;
    const pr = $(e.target).val();
    if (pr === '0.0') {
      baseval = 0;
    } else if (pr === '0.75') {
      baseval = 10;
    } else if ((pr === '1.0') || (pr === '1')) {
      baseval = 16;
    } else if (pr === '1.25') {
      baseval = 26;
    } else if (pr === '1.5') {
      baseval = 35;
    } else if (pr === '2.0') {
      baseval = 55;
    } else if (pr === '2.5') {
      baseval = 80;
    } else if (pr === '3.0') {
      baseval = 120;
    }

    $(e.target).closest('.row').find('input').val(String(baseval));
  },

  // ----------------------
  // GET ZONE ID
  // ----------------------
  getZoneId(e) {
    return $(e.target).parent().attr('inspections_zone_id');
  },

  // ----------------------
  // Expand the auto-adjust settings editor
  // ----------------------
  editZoneSettings(e) {
    e.preventDefault();
    const zoneId = $(e.currentTarget).attr('data-zone-id');
    $(`.zone_settings_panel[data-zone-id=${zoneId}]`).toggle();
  },

  // ----------------------
  // Save settings from auto-adjust editor
  // ----------------------
  saveZoneSettings(e) {
    e.preventDefault();
    const context = $(e.currentTarget).closest('tr'); // we only want stuff that's inside our currently selected panel, so instead of copying this every time below, make a variable
    const zoneId = context.attr('data-zone-id'); // the zone-id is set in our HTML tags, so let's pull it out for reference
    const zone = new ZoneModel(this.controllerId); // build a backbone model; see javascripts/backbone/models/zone.js
    zone.set({ id: zoneId });
    zone.set('sprinkler_type', context.find('select[name=sprinkler_type]').val()); // set the attribute on the model
    zone.set('plant_type', context.find('select[name=plant_type]').val());
    zone.set('soil_type', context.find('select[name=soil_type]').val());
    zone.set('soil_slope', context.find('select[name=soil_slope]').val());
    zone.set('adjustment', context.find('select[name=adjustment]').val());
    zone.set('valve_size', context.find('select[name=valve-size]').val());
    zone.set('gpm', context.find('input[name=gpm]').val());
    zone.save({}, {
      success() { // this does out PUT for us, no fancy AJAX needed...backbone handles it instead
      // on success, update the static text in the zone settings section, and hide our editor panel
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.sprinkler-type-text').text(context.find('select[name=sprinkler_type] option:selected').text());
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.plant-type-text').text(context.find('select[name=plant_type] option:selected').text());
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.soil-type-text').text(context.find('select[name=soil_type] option:selected').text());
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.soil-slope-text').text(context.find('select[name=soil_slope] option:selected').text());
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.adjustment-text').text(context.find('select[name=adjustment] option:selected').text());
        const valveSize = context.find('select[name=valve-size] option:selected').text();
        const gpm = context.find('input[name=gpm]').val();
        $(`.zone-settings[data-zone-id=${zoneId}]`).find('.valve-text').text(`${valveSize} (${gpm} GPM)`);
        $(`.zone_settings_panel[data-zone-id=${zoneId}]`).toggle();
      },
    });
  },

  cancelZoneSettings(e) {
    $(e.currentTarget).closest('tr').hide();
  },

  // ----------------------
  // Percent area setting editor
  // ----------------------

  editPercentArea(e) {
    e.preventDefault();
    const zoneId = $(e.currentTarget).attr('data-zone-id');
    $(`.percent_area_panel[data-zone-id=${zoneId}]`).toggle();
  },

  save_percent_area_setting(e) {
    e.preventDefault();
    const context = $(e.currentTarget).closest('tr');
    const zoneId = context.attr('data-zone-id');
    const zone = new ZoneModel(this.controllerId);
    zone.set({ percentage_of_landscape: context.find('select[name=percentage_of_landscape]').val(), id: zoneId });
    zone.save({}, {
      success() {
        $(`.percent-area[data-zone-id=${zoneId}]`).find('.percentage-of-landscape-text').text(context.find('select[name=percentage_of_landscape] option:selected').text());
        $(`.percent_area_panel[data-zone-id=${zoneId}]`).toggle();
      },
    });
  },

  cancelPercentArea(e) {
    $(e.currentTarget).closest('tr').hide();
  },

  // ----------------------
  // EDIT BOOLEAN CELL
  // Currently used for valve status only
  // ----------------------
  editBoolean(e) {
    const t = $(e.target);
    const zid = this.getZoneId(e);
    const zone = new InspectionZoneModel(this.controllerId, this.inspectionId);
    if ((t.data('value') === 'true') || (t.data('value') === true)) {
      zone.save({ id: zid, valve_status: false }, {
        success() {
          t.data('value', false).text('Fail').addClass('orange');
        },
      });
    } else {
      zone.save({ id: zid, valve_status: true }, {
        success() {
          t.data('value', true).text('Pass').removeClass('orange');
        },
      });
    }
  },

  // ----------------------
  // EDIT BOOLEAN + NULL CELL
  // Currenly used for In/Out Scope
  // ----------------------
  editBoolean2(e) {
    const t = $(e.target);
    const zid = this.getZoneId(e);
    const zone = new InspectionZoneModel(this.controllerId, this.inspectionId);
    if ((t.data('value') === 'true') || (t.data('value') === true)) {
      zone.save({ id: zid, scope: false }, {
        success() {
          t.data('value', false).text('Out');
        },
      });
    } else if ((t.data('value') === 'false') || (t.data('value') === false)) {
      zone.save({ id: zid, scope: null }, {
        success() {
          t.data('value', null).text('\xa0');
        },
      });
    } else {
      zone.save({ id: zid, scope: true }, {
        success() {
          t.data('value', true).text('In');
        },
      });
    }
  },

  // ----------------------
  // EDIT INTEGER 0-10
  // Most common method
  // ----------------------
  dropdownInteger(data, textStatus, jqXHR, dropdown) {
    const trigger = dropdown.find('.dropdown-trigger');
    const string = data[trigger.data('value')];
    if ((string === '') || (string === '0') || (string === 0)) {
      trigger.text('');
      dropdown.closest('.editable').removeClass('orange red');
      trigger.attr('data-current-value', 0);
    } else {
      trigger.attr('data-current-value', string);
      trigger.text(string);
      if ((trigger.data('value') === 'broken_lateral') || (trigger.data('value') === 'broken_main')) {
        dropdown.closest('.editable').addClass('red');
      }
      dropdown.closest('.editable').addClass('orange');
    }
  },

  // ----------------------
  // Dropdown Boolean
  // Show true / false (pass/fail) in dropdown instead of clicking, for proposals
  // ----------------------
  dropdownBoolean(data, textStatus, jqXHR, dropdown) {
    const t = dropdown.find('.dropdown-trigger');
    const string = data[t.data('value')];
    if ((string === 'true') || (string === true)) {
      t.attr('data-current-value', 'true');
      t.text('Pass').closest('.editable').removeClass('orange');
    } else {
      t.attr('data-current-value', 'false');
      t.text('Fail').closest('.editable').addClass('orange');
    }
  },

  // ----------------------
  // EDIT MONEY
  // Currently used for estimated cost
  // ----------------------
  // edit_money(e) {
  //   const zid = this.getZoneId(e);
  //   const zone = new InspectionZoneModel(this.controllerId, this.inspectionId);
  //   const value = prompt('Estimated Cost');
  //   if (value) {
  //     if (this.isNumber(value) && (value >= 0)) {
  //       zone.save({ id: zid, estimated_cost: value }, {
  //         success() {
  //           $(e.target).text(`$ ${value}`);
  //         },
  //       });
  //     } else {
  //       alert('Invalid Input');
  //     }
  //   }
  // },
  dropdownEstimatedCost(data, textStatus, jqXHR, dropdown) {
    const trigger = dropdown.find('.dropdown-trigger');
    const string = data.estimated_cost;
    if (string) {
      trigger.text(`$ ${string}`);
    } else {
      trigger.text('$ 0');
    }
  },

  dropdownDescription(data, textStatus, jqXHR, dropdown) {
    const trigger = dropdown.find('.zone-description-trigger');
    // console.log data
    const string = data.result.zone.description;
    if (string) {
      trigger.text(string);
    } else {
      trigger.text('');
    }
    trigger.data('value', string);
  },
});
export default InspectionEditZonesView;
