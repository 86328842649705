// includes bindings for fetching/fetched
import Backbone from 'backbone';
import $ from 'jquery';
import PaginationTemplate from '@assets/javascripts/backbone/templates/pagination.ejs';

const PaginatedCollection = Backbone.Collection.extend({
  initialize(options) {
    if (typeof (options) === 'undefined') {
      options = {};
    }
    if (typeof (this.modelCollectionName) === 'undefined') {
      throw new Error('Must define modelCollectionName for an object extending PaginatedCollection');
    }
    this.extraParams = options.extraParams || {};
    this.page = 1;
    if (options.per_page) {
      this.perPage = options.per_page;
    }
    if (options.sort_col) {
      this.sortCol = options.sort_col;
    }
    if (options.sort_dir) {
      this.sortDir = options.sort_dir;
    }
    this.perPage ??= 50;
    this.sortCol ??= 'name';
    this.sortDir ??= 1;
  },
  fetch(options) {
    options ??= {};
    this.trigger('fetching');
    const self = this;
    const success = options.success;
    options.success = (resp) => {
      self.trigger('fetched');
      if (success) {
        success(self, resp);
      }
    };
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  parse(resp) {
    if (resp.meta) {
      // API V2 PAGINATION FORMAT
      this.page = resp.meta.request.page;
      this.perPage = resp.meta.request.perPage;
      this.total = resp.meta.found;
      this.pages = Math.ceil(resp.meta.found / resp.meta.request.perPage);
      return resp.result[this.modelCollectionName];
    }
    if (resp.page_num) {
      // API V1 PAGINATION FORMAT
      this.page = resp.page_num;
      this.perPage = resp.per_page;
      this.total = resp.total;
      this.pages = resp.total_pages;
      return resp[this.modelCollectionName];
    }
    // NO PAGINATION FOUND
    return resp;
  },

  url() {
    return `${this.baseUrl}?${this.uri()}`;
  },

  uri() {
    let paramHash = {
      page: this.page, perPage: this.perPage, sortCol: this.sortCol, sortDir: this.sortDir,
    };
    if (this.extraParams) {
      paramHash = { ...paramHash, ...this.extraParams };
    }
    return $.param(paramHash);
  },

  pageInfo() {
    const info = {
      total: this.total,
      page: this.page,
      sortCol: this.sortCol,
      sortDir: this.sortDir,
      perPage: this.perPage,
      pages: Math.ceil(this.total / this.perPage),
      prev: false,
      next: false,
      modelName: this.modelCollectionName,
      span: 8,
    };

    let max = Math.min(this.total, this.page * this.perPage);

    if (this.total === this.pages * this.perPage) {
      max = this.total;
    }

    info.range = [(this.page - 1) * this.perPage + 1, max];

    if (this.page > 1) {
      info.prev = parseInt(this.page, 10) - 1;
    }

    if (this.page < info.pages) {
      info.next = parseInt(this.page, 10) + 1;
    }

    return info;
  },

  gotoPage(page) {
    if (page > this.pages) {
      return false;
    }
    this.page = parseInt(page, 10);
    return this.fetch();
  },

  nextPage() {
    if (!this.pageInfo().next) {
      return false;
    }
    this.page = parseInt(this.page, 10) + 1;
    return this.fetch();
  },

  previousPage() {
    if (!this.pageInfo().prev) {
      return false;
    }
    this.page = parseInt(this.page, 10) - 1;
    return this.fetch();
  },

  sort(sortCol) {
    this.sortCol = sortCol;
    this.sortDir = (this.sortDir || 1) * -1;
    return this.fetch();
  },
});

const PaginatedView = Backbone.View.extend({
  initialize() {
  },
  template: PaginationTemplate,
  events: {
    'click a.prev': 'previous',
    'click a.next': 'next',
    'click a.goto': 'goto',
  },
  render() {
    const content = this.template(this.collection.pageInfo());
    this.$el.html(content);
    this.delegateEvents(); // re-bind events after clearing the DOM with .html() on previous line
    return this;
  },

  sort(sortCol) {
    this.collection.sort(sortCol);
    return false;
  },

  previous() {
    this.collection.previousPage();
    return false;
  },

  goto(e) {
    e.preventDefault();
    this.collection.gotoPage($(e.target).attr('rel'));
    return false;
  },

  next() {
    this.collection.nextPage();
    return false;
  },
});
export { PaginatedCollection, PaginatedView };
