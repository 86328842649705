function updateEntriesForSiteUsersTable() {
  const entrySelected = document.querySelector('#site_users-entries .li-check').id.split('-')[2];
  const searchValue = document.getElementById('search-site_user').value;
  window.getSiteUsers(searchValue, 1, entrySelected);
}
window.updateEntriesForSiteUsersTable = updateEntriesForSiteUsersTable;

function updateUrlWithEntries() {
  const entrySelected = document.querySelector('#site_users-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entrySelected, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateUrlWithEntries = updateUrlWithEntries;
