let laborRateRange;
let laborRateInput;

function updateLaborRate(accountId) {
  window.$.ajax({
    url: `/accounts/${accountId}/update_labor_rate`,
    type: 'PUT',
    dataType: 'script',
    data: { account: { labor_rate: laborRateInput.value } },
  });
}
window.updateLaborRate = updateLaborRate;

function getBackgroundSize(element) {
  return `${((element.value - element.min) * 100) / (element.max - element.min)}% 100%`;
}

function toggleLaborRateUpdateButton(action) {
  const updateButtonEnabled = document.querySelector('#labor-rate-update-btn');
  const updateButtonDisabled = document.querySelector('#labor-rate-update-btn-disabled');
  if (action === 'disable') {
    updateButtonEnabled.classList.add('tw-hidden');
    updateButtonDisabled.classList.remove('tw-hidden');
  } else {
    updateButtonEnabled.classList.remove('tw-hidden');
    updateButtonDisabled.classList.add('tw-hidden');
  }
}

function showWarningLabel(value, idSelector) {
  const labelContainer = document.querySelector(`#${idSelector} .warning-label`);
  const label = labelContainer.querySelector('p');
  if (value < 10) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.min_error;
  if (value > 80) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.max_error;
  if (Number.isNaN(value)) label.textContent = window.I18n.insights.labor_savings.cards.labor_rate.not_a_number;
  labelContainer.classList.remove('tw-hidden');
}

function handleLaborRateRangeOnChange(e) {
  e.target.style.backgroundSize = getBackgroundSize(e.target);
  document.getElementById('labor-rate-input').value = parseFloat(e.target.value).toFixed(2);
  document.querySelector('#labor-rate .warning-label').classList.add('tw-hidden');
  toggleLaborRateUpdateButton('enable');
}

function handleLaborRateInputOnChange(e) {
  const range = document.getElementById('labor-rate-range');
  const value = parseFloat(e.target.value);
  if (value < 10 || value > 80 || Number.isNaN(value)) {
    showWarningLabel(value, 'labor-rate');
    toggleLaborRateUpdateButton('disable');
    return;
  }
  document.querySelector('#labor-rate .warning-label').classList.add('tw-hidden');
  toggleLaborRateUpdateButton('enable');
  range.style.backgroundSize = getBackgroundSize(e.target);
  range.value = value;
}

function handleLaborRateInputOnBlur(e) {
  const value = parseFloat(e.target.value);
  if (value >= 10 && value <= 80) e.target.value = value.toFixed(2);
}

function getLaborSavingsInsights() {
  window.$.ajax({
    url: '/insights/labor_savings/labor_savings_insights',
    type: 'GET',
    dataType: 'script',
    success: () => {
      document.querySelectorAll('.btn-dropdown').forEach((button) => button.addEventListener('click', window.dropdownToggle));
      laborRateInput = document.getElementById('labor-rate-input');
      laborRateRange = document.getElementById('labor-rate-range');
      if (laborRateRange) {
        laborRateRange.addEventListener('input', handleLaborRateRangeOnChange);
        laborRateInput.addEventListener('input', handleLaborRateInputOnChange);
        laborRateInput.addEventListener('blur', handleLaborRateInputOnBlur);
        laborRateInput.value = parseFloat(laborRateInput.dataset.value).toFixed(2);
        laborRateRange.value = laborRateInput.dataset.value;
        laborRateRange.style.backgroundSize = getBackgroundSize(laborRateInput);
      }
    },
  });
}

function getYearByYearComparison() {
  window.$.ajax({
    url: '/insights/labor_savings/year_by_year_comparison',
    type: 'GET',
    dataType: 'script',
    success: () => {
      document.querySelectorAll('.btn-dropdown').forEach((button) => button.addEventListener('click', window.dropdownToggle));
    },
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const laborSavingsIndex = document.getElementById('insights-labor_savings-index');

  if (laborSavingsIndex) {
    window.displayLastUpdatedDate('labor_savings-index');
    getLaborSavingsInsights();
    getYearByYearComparison();
    document.getElementById('select_branch')?.addEventListener('change', () => {
      const branchSelector = document.querySelector('#select_branch #branch_id');
      if (branchSelector.value !== branchSelector.dataset.previousValue) {
        branchSelector.dataset.previousValue = branchSelector.value;
        window.updateTimeframeOnLaborSavingsInsightsChart('branch_id');
        window.updatePeriodOnYearByYearChart();
      }
    });
  }
});
