function toggleUpdatePermissionsButton() {
  const updateButton = document.querySelector('#edit-user-permissions-modal .submit');
  updateButton.classList.add('tw-button-primary');
  updateButton.classList.remove('tw-btn-disabled');
}
window.toggleUpdatePermissionsButton = toggleUpdatePermissionsButton;

function updateEditUserPermissionsModal(modal, runCommands, editPrograms, siteAdmin) {
  modal.querySelector('input#run_commands').checked = runCommands === 'true';
  modal.querySelector('input#edit_programs').checked = editPrograms === 'true';
  modal.querySelector('input#site_admin').checked = siteAdmin === 'true';
  modal.querySelector('input#run_commands').disabled = false;
  modal.querySelector('input#edit_programs').disabled = false;
  modal.querySelector('input#site_admin').disabled = false;
  modal.querySelector('.submit').classList.add('tw-btn-disabled');
  modal.querySelector('.submit').classList.remove('tw-button-primary');
}

function getUserPermissions() {
  const permissions = {};
  const checkboxes = document.querySelectorAll('#edit-user-permissions-modal input[type="checkbox"]:not([disabled]):checked');
  checkboxes.forEach((checkbox) => { permissions[checkbox.id] = checkbox.value; });
  return permissions;
}

function updateSiteUserOrInvitePermissions(type, id) {
  window.$.ajax({
    url: `/sites/${window.getSiteIdFromUrl()}/${type}/${id}`,
    data: {
      permissions: getUserPermissions(),
    },
    dataType: 'script',
    type: 'PUT',
  });
}

function destroyCustomPermission(id) {
  window.$.ajax({
    url: `/sites/${window.getSiteIdFromUrl()}/site_custom_users/${id}`,
    dataType: 'script',
    type: 'DELETE',
  });
}

function createCustomPermission(id) {
  window.$.ajax({
    url: `/sites/${window.getSiteIdFromUrl()}/site_custom_users`,
    data: {
      permissions: getUserPermissions(),
      user_id: id,
    },
    dataType: 'script',
    type: 'POST',
  });
}
function showEditUserPermissionsModal(type, id, userInfo, runCommands, editPrograms, siteAdmin) {
  const editUserPermissionsModal = document.getElementById('edit-user-permissions-modal');
  if (type === 'invites') {
    editUserPermissionsModal.querySelector('.description').innerHTML = window.I18n.site_users.index.edit_user_permissions_modal.description.replace('%{email}', userInfo).replace('%{user_name}', '');
    editUserPermissionsModal.querySelector('.description').style.wordWrap = 'anywhere';
  } else {
    editUserPermissionsModal.querySelector('.description').innerHTML = window.I18n.site_users.index.edit_user_permissions_modal.description.replace('%{email}', '').replace('%{user_name}', userInfo);
  }
  document.querySelector('.custom_permission').classList.add('tw-hidden');
  updateEditUserPermissionsModal(editUserPermissionsModal, runCommands, editPrograms, siteAdmin);
  editUserPermissionsModal.querySelector('.submit').onclick = () => updateSiteUserOrInvitePermissions(type, id);
  editUserPermissionsModal.classList.remove('tw-hidden');
}
window.showEditUserPermissionsModal = showEditUserPermissionsModal;

function showEditCustomPermissionsModal(type, id, userInfo, runCommands, editPrograms, siteAdmin) {
  const editUserPermissionsModal = document.getElementById('edit-user-permissions-modal');
  editUserPermissionsModal.querySelector('.description').innerHTML = window.I18n.site_users.index.edit_user_permissions_modal.description.replace('%{email}', '').replace('%{user_name}', userInfo);
  editUserPermissionsModal.querySelector('input#run_commands').checked = runCommands[1];
  editUserPermissionsModal.querySelector('input#edit_programs').checked = editPrograms[1];
  editUserPermissionsModal.querySelector('input#site_admin').checked = siteAdmin[1];
  editUserPermissionsModal.querySelector('input#run_commands').disabled = runCommands[0];
  editUserPermissionsModal.querySelector('input#edit_programs').disabled = editPrograms[0];
  editUserPermissionsModal.querySelector('input#site_admin').disabled = siteAdmin[0];
  editUserPermissionsModal.querySelector('.submit').classList.add('tw-btn-disabled');
  editUserPermissionsModal.querySelector('.submit').classList.remove('tw-button-primary');
  if (type === 'Custom') {
    document.querySelector('.custom_permission').classList.remove('tw-hidden');
    editUserPermissionsModal.querySelector('.submit').onclick = () => updateSiteUserOrInvitePermissions('users', id);
    editUserPermissionsModal.querySelector('.custom_permission').onclick = () => destroyCustomPermission(id);
  } else {
    document.querySelector('.custom_permission').classList.add('tw-hidden');
    editUserPermissionsModal.querySelector('.submit').onclick = () => createCustomPermission(id);
  }
  editUserPermissionsModal.querySelector('.submit').classList.add('tw-btn-disabled');
  editUserPermissionsModal.querySelector('.submit').classList.remove('tw-button-primary');

  editUserPermissionsModal.classList.remove('tw-hidden');
}
window.showEditCustomPermissionsModal = showEditCustomPermissionsModal;
