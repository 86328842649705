import Backbone from 'backbone';
import $ from 'jquery';
import manualRunPopoverTemplate from '@assets/javascripts/backbone/templates/inspections/manual_run_popover.ejs';
import ManualRunModel from '@assets/javascripts/backbone/models/manual_run.js';

const InspectionManualRunView = Backbone.View.extend({
  el: '#inspection-table',
  controllerId: $('#inspection').data('controller-id'),
  mock: Boolean($('#inspection').data('mock')),
  externalProvider: Boolean($('#inspection').data('external-provider')),
  instructions: null,
  events: {
    'click .manual_start': 'manualStart',
    'click .manual_stop': 'manualStop',
  },
  initialize() {
    console.log('Inspection Manual Run Initalized');
    this.popoverRun = manualRunPopoverTemplate;
    const context = this;
    window.addEventListener('load', () => {
      $('.manual_run').each((index, element) => {
        const zoneId = $(element).data('zone-id');
        $(element).find('.trigger').popover({
          trigger: 'click', placement: 'right', title: 'Run Zone', content: context.popoverRun({ zone_id: zoneId }), html: true, sanitize: false,
        });
      });
    });
  },
  processStart(_request, response, e) {
    if (response.meta.success) {
      const inspectionZoneId = $(e.target).closest('tr').attr('inspections_zone_id');
      $('tr.active').removeClass('active');
      $(`tr[inspections_zone_id=${inspectionZoneId}]`).addClass('active');
      const zoneId = $(e.target).parent().data('zone-id');
      $(`.manual_run[data-zone-id=${zoneId}] .trigger`).removeClass('btn-warning').addClass('btn-info');
      $('.trigger').popover('hide');
    } else {
      $('#warning-snackbar').removeClass('tw-hidden');
      window.toggleSnackbar('warning-snackbar', response.result.instruction.exception);
    }
  },
  processError(_request, response) {
    $('#warning-snackbar').removeClass('tw-hidden');
    window.toggleSnackbar('warning-snackbar', response?.meta?.message || 'Something got wrong.');
  },
  manualStart(e) {
    $('#warning-snackbar').addClass('tw-hidden');
    const zoneId = $(e.target).parent().data('zone-id');
    const context = this;
    if (!this.mock || this.externalProvider) {
      const zoneRun = new ManualRunModel(this.controllerId);
      zoneRun.set({ run_action: 'start' });
      zoneRun.set({ run_time: '5' });
      zoneRun.set({ zone: zoneId });
      zoneRun.save(null, { success: ((request, response) => context.processStart(request, response, e)), error: ((request, response) => context.processError(request, response)) });
    }
  },
  processStop(request, response, e) {
    if (response.meta.success) {
      const inspectionZoneId = $(e.target).closest('tr').attr('inspections_zone_id');
      $(`tr[inspections_zone_id=${inspectionZoneId}]`).removeClass('active');
      const zoneId = $(e.target).parent().data('zone-id');
      $(`.manual_run[data-zone-id=${zoneId}] .trigger`).removeClass('btn-info').addClass('btn-warning');
      $('.trigger').popover('hide');
    } else {
      $('#warning-snackbar').removeClass('tw-hidden');
      window.toggleSnackbar('warning-snackbar', response.result.instruction.exception);
    }
  },
  manualStop(e) {
    $('#warning-snackbar').addClass('tw-hidden');
    const zoneId = $(e.target).parent().data('zone-id');
    const context = this;
    if (!this.mock || this.externalProvider) {
      const zoneStop = new ManualRunModel(this.controllerId);
      zoneStop.set({ zone: zoneId });
      zoneStop.save(null, { success: ((request, response) => context.processStop(request, response, e)), error: ((request, response) => context.processError(request, response)) });
    }
  },
});
export default InspectionManualRunView;
