function removeUserFromSite(userId) {
  window.$.ajax({
    url: `/sites/${window.getSiteIdFromUrl()}/users/${userId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}

function showRemoveUserFromSiteModal(userName, userId, myself) {
  const warningModal = document.getElementById('remove-user-modal');
  const description = myself
    ? window.I18n.site_users.index.remove_user_modal.myself_description
    : window.I18n.site_users.index.remove_user_modal.other_user_description.replace('%{user_name}', userName);
  warningModal.querySelector('.description').innerHTML = description;
  warningModal.querySelector('.tw-btn-action-red').onclick = () => removeUserFromSite(userId);
  warningModal.classList.remove('tw-hidden');
}
window.showRemoveUserFromSiteModal = showRemoveUserFromSiteModal;
