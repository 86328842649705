function resetUrlForEachInspector(url, inspectorId) {
  const modal = document.getElementById('inspector_inspections_modal');
  let newUrl = window.changeDataFromUrl(url, { });
  newUrl = window.changeDataFromUrl(newUrl, {
    'ransack[on_or_after]': modal.dataset.beginningOfYear,
    'ransack[on_or_before]': modal.dataset.endOfYear,
    'ransack[status_in]': [modal.dataset.statusCompleted, modal.dataset.statusNoRepairNeeded],
    'ransack[user_id_in]': [inspectorId],
    'ransack[branch_id_in]': [document.querySelector('#select_branch #branch_id').value],
  });
  return newUrl;
}

const loadingDataComponent = `<div id="loading-data" class="tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-6 sm:tw-mt-8 tw-gap-4 tw-h-96">
                                <div class="tw-bg-blue-100 tw-rounded-full tw-flex tw-self-center tw-w-20 tw-h-20">
                                  <div id="loading-state" class="tw-w-8 tw-h-8 tw-contents"></div>
                                </div>
                                <p class="tw-m-0 tw-text-gray-800 tw-font-medium tw-text-base tw-px-10 sm:tw-px-0 sm:tw-font-medium sm:tw-text-base tw-mt-2">${window.I18n?.home.loading_data.title}</p>
                                <p class="tw-m-0 tw-text-gray-600 tw-font-normal sm:tw-text-sm tw-pt-4 tw-px-10 tw-text-xs sm:tw-pt-4 sm:tw-px-0">${window.I18n?.home.loading_data.description}</p>
                              </div>`;

function showInspectorInspectionsModal(inspectorId, inspectorName) {
  const modal = document.getElementById('inspector_inspections_modal');
  modal.classList.remove('tw-hidden');
  modal.querySelector('.title').textContent = window.I18n.insights.inspections_proposals.by_inspector.detail_modal.title.replace('%{inspector_name}', inspectorName);
  modal.querySelector('#inspections_detail').innerHTML = loadingDataComponent;
  const button = modal.querySelector('.go_to_inspections a');
  button.href = resetUrlForEachInspector(button.href, inspectorId);
}

function updateInspectorInspections(inspectorId, inspectorName) {
  showInspectorInspectionsModal(inspectorId, inspectorName);
  window.loadAnimationPending('#inspector_inspections_modal #loading-state');
  window.$.ajax({
    url: `/insights/inspector/${inspectorId}/inspections`,
    data: { per_page: 6, branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'script',
    type: 'GET',
  });
}
window.updateInspectorInspections = updateInspectorInspections;
