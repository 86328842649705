function getSelectedProposals() {
  const proposals = [];
  document.querySelectorAll('.proposal-checkbox input:checked').forEach((checkedCheckbox) => {
    proposals.push(checkedCheckbox.closest('tr').id);
  });
  return proposals;
}

function listOfProposalIds(arrayOfIds) {
  let result = '';
  const length = arrayOfIds.length;
  if (length > 2) {
    arrayOfIds.slice(0, length - 2).forEach((id) => result = `"${id}", `);
  }
  result += `"${arrayOfIds[length - 2]}" ${window.I18n.and} "${arrayOfIds[length - 1]}"`;
  return result;
}

function combineProposals(proposals) {
  const modal = document.getElementById('confirmation-modal');
  window.$.ajax({
    url: '/api/v2/proposals/combine_proposals',
    data: { proposals },
    dataType: 'json',
    type: 'POST',
    success: (response) => {
      if (response.error) {
        modal.classList.add('tw-hidden');
        window.toggleSnackbar('warning-snackbar', `${window.I18n.there_was_a_problem}: ${response.error}`);
      } else {
        window.location.reload();
      }
    },
    error: () => {
      modal.classList.add('tw-hidden');
      window.toggleSnackbar('warning-snackbar', window.I18n.went_wrong);
    },
  });
}

function showCombineProposalsModal() {
  const proposals = getSelectedProposals();
  if (proposals.length < 2) {
    window.toggleSnackbar('warning-snackbar', window.I18n.proposal.dt.combine_warning);
  } else {
    const modal = document.getElementById('confirmation-modal');
    const description = window.I18n.proposal.combine_proposals_modal.description.replace('%{ids}', listOfProposalIds(proposals));
    modal.querySelector('.description').textContent = description;
    modal.querySelector('.tw-btn-action-blue').onclick = () => combineProposals(proposals);
    modal.classList.remove('tw-hidden');
  }
}
window.showCombineProposalsModal = showCombineProposalsModal;
