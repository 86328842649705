const transferOwnershipModal = document.getElementById('transfer-ownership-modal');
transferOwnershipModal?.addEventListener('click', (event) => {
  if (event.target.classList.contains('tw-btn-cancel')) transferOwnershipModal.classList.add('tw-hidden');
});
function showTransferOwnership() {
  const warningModal = document.getElementById('transfer-ownership-modal');
  warningModal.classList.remove('tw-hidden');
}
window.showTransferOwnership = showTransferOwnership;
function enableTransferOwernshipButton(optionElement) {
  const hiddenInput = document.getElementById('hidden-owner-id-input');
  hiddenInput.value = optionElement.id.split('-')[2];
  const button = document.querySelector('#transfer-ownership-modal .btn-action');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-button-primary');
}
window.enableTransferOwernshipButton = enableTransferOwernshipButton;
