function getWaterSavings() {
  window.$.ajax({
    url: '/insights/water_savings/water_savings',
    type: 'GET',
    dataType: 'script',
    success: () => {
      document.querySelectorAll('.btn-dropdown').forEach((button) => button.addEventListener('click', window.dropdownToggle));
    },
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const laborSavingsIndex = document.getElementById('insights-water_savings-index');

  if (laborSavingsIndex) {
    window.displayLastUpdatedDate('water_savings-index');
    getWaterSavings();
  }
});
