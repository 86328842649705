function getJoinRequest() {
  window.$.ajax({
    url: 'home/account_join_requests',
    type: 'GET',
    dataType: 'script',

  });
}
window.getJoinRequest = getJoinRequest;

function getPendingInvitations() {
  window.$.ajax({
    url: 'home/invitations',
    type: 'GET',
    dataType: 'script',

  });
}
window.getPendingInvitations = getPendingInvitations;

function getInactiveUsers() {
  window.$.ajax({
    url: 'home/inactive_users',
    type: 'GET',
    dataType: 'script',

  });
}
window.getInactiveUsers = getInactiveUsers;

function destroyInactiveUser(userId) {
  window.genericGoogleTagManagerEvent('homeManageUsers', { action: 'Remove Inactive User' });
  window.$.ajax({
    url: `home/inactive_users/${userId}`,
    type: 'DELETE',
    dataType: 'script',

  });
}

function showHomeRemoveUserModal(userName, userId) {
  const warningModal = document.getElementById('remove-user-modal');
  warningModal.querySelector('.tw-btn-action-red').onclick = () => destroyInactiveUser(userId);
  warningModal.querySelector('.description').textContent = window.I18n.accounts.users.remove_user.modal_description.replace('%{user_name}', userName);
  warningModal.classList.remove('tw-hidden');
}
window.showHomeRemoveUserModal = showHomeRemoveUserModal;

function changeManageUsersTab(changeManageUserTab) {
  const tabsContent = { joinRequestTab: 'join_request-result', invitationsTab: 'pending_invitations-result', inactiveUsersTab: 'inactive_users-result' };
  const disabled = ['joinRequestTab', 'invitationsTab', 'inactiveUsersTab'].filter((tab) => tab !== changeManageUserTab);
  disabled.forEach((tab) => {
    const tabButton = document.getElementById(tab);
    tabButton.firstElementChild.classList.add('tw-hidden');
    tabButton.parentElement.classList.add('tw-bg-white', 'tw-text-blue-500', 'tw-px-2');
    tabButton.parentElement.classList.remove('tw-text-white', 'tw-bg-blue-500', 'tw-px-3');
    document.getElementById(tabsContent[tab]).classList.add('tw-hidden');
  });
  const newTab = document.getElementById(changeManageUserTab);
  newTab.parentElement.classList.remove('tw-bg-white', 'tw-text-blue-500', 'tw-px-2');
  newTab.parentElement.classList.add('tw-text-white', 'tw-bg-blue-500', 'tw-px-3');
  newTab.firstElementChild.classList.remove('tw-hidden');
  window.genericGoogleTagManagerEvent('homeManageUsers', { action: `Move To ${changeManageUserTab}` });
  document.getElementById(tabsContent[changeManageUserTab]).classList.remove('tw-hidden');
}
window.changeManageUsersTab = changeManageUsersTab;
