function getSiteMenuButtonPosition(page) {
  const buttons = {
    'sites-edit': 1,
    'sites-delete': 1,
  };
  return document.getElementById('scrollableMenu').querySelectorAll('.site-menu-button')[buttons[page]].offsetLeft - 45;
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('#sites-edit, #sites-delete');
  if (body) {
    document.getElementById('scrollableMenu').scrollLeft = getSiteMenuButtonPosition(body.id);
    window.scrollMenuVerification('scrollableMenu', 'menuGoForward', 'menuGoBack');
    document.getElementById('scrollableMenu').scrollLeft = getSiteMenuButtonPosition(body.id);
  }
});

function hideSiteMenuOptions(buttonId, optionsId) {
  document.getElementById(optionsId)?.classList?.add('tw-hidden');
  const button = document.getElementById(buttonId);
  button?.classList?.remove('tw-bg-blue-500', 'tw-text-white');
  button?.classList?.add('tw-text-blue-500');
  button?.querySelector('.chevron-up-icon')?.classList?.add('tw-hidden');
  button?.querySelector('.chevron-down-icon')?.classList?.remove('tw-hidden');
}

window.addEventListener('click', (e) => {
  if (!(e.target.closest('#site-controllers-button'))) {
    hideSiteMenuOptions('site-controllers-button', 'site-controllers-options');
  }
  if (!(e.target.closest('#site-admin-button'))) {
    hideSiteMenuOptions('site-admin-button', 'site-admin-options');
  }
});

function setHorizontalPosition(optionsId) {
  const pageMargin = window.getComputedStyle(document.querySelector('section')).marginLeft;
  if (optionsId === 'site-controllers-options') {
    const button = document.getElementById('site-controllers-button');
    const options = document.getElementById('site-controllers-options');
    options.style.left = button.getBoundingClientRect().x - parseInt(pageMargin, 10);
  } else {
    const button = document.getElementById('site-admin-button');
    const options = document.getElementById('site-admin-options');
    let offset = 0;
    if (window.innerWidth < 1440) offset = window.innerWidth >= 576 ? 80 : 136;
    options.style.left = button.getBoundingClientRect().x - parseInt(pageMargin, 10) - offset;
  }
}

function toggleSiteMenuOptions(button, optionsId) {
  const chevronDown = button.querySelector('.chevron-down-icon');
  const chevronUp = button.querySelector('.chevron-up-icon');
  const options = document.getElementById(optionsId);
  if (options.classList.contains('tw-hidden')) {
    setHorizontalPosition(optionsId);
    button.classList.add('tw-bg-blue-500', 'tw-text-white');
    button.classList.remove('tw-text-blue-500');
    options.classList.remove('tw-hidden');
    chevronUp.classList.remove('tw-hidden');
    chevronDown.classList.add('tw-hidden');
  } else {
    button.classList.remove('tw-bg-blue-500', 'tw-text-white');
    button.classList.add('tw-text-blue-500');
    options.classList.add('tw-hidden');
    chevronUp.classList.add('tw-hidden');
    chevronDown.classList.remove('tw-hidden');
  }
}
window.toggleSiteMenuOptions = toggleSiteMenuOptions;
