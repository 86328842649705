function UpdateEmailDomain(element) {
  if (element.dataset.dirty === 'true') {
    const accountId = document.getElementById('account_information').dataset.accountId;
    element.dataset.dirty = 'false';
    window.$.ajax({
      url: `/dashboard/accounts/${accountId}/email_domains`,
      data: { domain_name: element.value },
      dataType: 'script',
      type: 'POST',
    });
  }
}

const emailDomainInput = document.getElementById('account_email_domain-input');

emailDomainInput?.addEventListener('blur', (event) => {
  UpdateEmailDomain(event.target);
}, false);

emailDomainInput?.addEventListener('keyup', (type) => {
  if (type.code === 'Enter') {
    UpdateEmailDomain(emailDomainInput);
  } else { emailDomainInput.dataset.dirty = 'true'; }
});

let currentAccountType = document.querySelector('#new_account_type .dropdown-options .li-check')?.id?.split('-')[1];
function updateAccountType(element) {
  const newAccountType = element.id.split('-')[1];
  if (currentAccountType !== newAccountType) {
    const accountId = document.getElementById('account_information').dataset.accountId;
    currentAccountType = newAccountType;
    window.$.ajax({
      url: `/dashboard/accounts/${accountId}/update_account_type`,
      data: { account: { account_type: newAccountType } },
      dataType: 'script',
      type: 'PATCH',
    });
  }
}
window.updateAccountType = updateAccountType;

const accountSuggestedSearchInput = document.getElementById('suggested-account-search');

function getAccountSuggested() {
  const accountId = document.getElementById('account_information').dataset.accountId;

  window.$.ajax({
    url: `/dashboard/accounts/${accountId}/suggested`,
    data: { filter: { suggested_accounts: accountSuggestedSearchInput.value } },
    dataType: 'script',
    type: 'GET',
  });
}
window.getAccountSuggested = getAccountSuggested;

accountSuggestedSearchInput?.addEventListener('keyup', window.debounce(() => {
  getAccountSuggested();
  window.AddParamsToTailwindPagination({ 'filter[suggested_accounts]': accountSuggestedSearchInput.value });
}, 500));

function setNetsuiteInputConstraint() {
  const netsuiteInput = document.querySelector('#edit-netsuite input');
  netsuiteInput.addEventListener('blur', (event) => window.setInputConstraints(event.target, parseInt(2147483647, 10), parseInt(1, 10), parseInt(1, 10), ''));
}
window.setNetsuiteInputConstraint = setNetsuiteInputConstraint;

document.addEventListener('DOMContentLoaded', () => {
  const dashboardAccount = document.getElementById('dashboard-accounts-show');

  if (dashboardAccount) {
    window.fileChanged(document.getElementById('import_sites_file'));
    const urlValues = new URL(window.location.href);
    const suggested = urlValues.searchParams.get('filter[suggested_accounts]');
    if (suggested) document.getElementById('suggested-account-search').value = suggested;
    getAccountSuggested();
    setNetsuiteInputConstraint();
    window.getAccountBillingUsers();
  }
});

function accountUsersForModal(mergeAccountId) {
  const accountId = document.getElementById('account_information').dataset.accountId;
  window.$.ajax({
    url: `/dashboard/accounts/${accountId}/merge_accounts/new`,
    data: { merge_account_id: mergeAccountId },
    dataType: 'script',
    type: 'GET',
  });
}
window.accountUsersForModal = accountUsersForModal;

function mergeAccounts(mergeAccountId) {
  const accountId = document.getElementById('account_information').dataset.accountId;
  const userRole = document.getElementById('merge-account-modal').querySelector('.li-check').id.split('-')[1];
  window.$.ajax({
    url: `/dashboard/accounts/${accountId}/merge_accounts`,
    data: { merge_account_id: mergeAccountId, merge_role_id: userRole },
    dataType: 'script',
    type: 'POST',
  });
}
window.mergeAccounts = mergeAccounts;

function verifyInputChange(inputElement, platform) {
  const button = document.querySelector(`#edit-${platform} #generate-${platform}`);
  if (inputElement.value === '') {
    button.classList.remove('tw-btn-action-blue');
    button.classList.add('tw-btn-disabled');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  }
}
window.verifyInputChange = verifyInputChange;

function showEditPlatform(platform) {
  document.querySelector(`#show-${platform}`).classList.add('tw-hidden');
  document.querySelector(`#edit-${platform}`).classList.remove('tw-hidden');
  document.querySelector(`#edit-${platform}`).classList.add('tw-flex');
  verifyInputChange(document.querySelector(`#edit-${platform} input`), platform);
}
window.showEditPlatform = showEditPlatform;

function generatePlatformLink(platformType) {
  const accountId = document.getElementById('account_information').dataset.accountId;
  const platformLink = document.querySelector(`#manage-${platformType} input`).value;
  window.$.ajax({
    url: `/dashboard/accounts/${accountId}/update_platform_links`,
    data: { platform_type: platformType, platform_link: platformLink },
    dataType: 'script',
    type: 'PATCH',
  });
}
window.generatePlatformLink = generatePlatformLink;
