function getSelectedOption(className) {
  return document.querySelector(`${className} .dropdown-options .li-check`).id.split('-')[2];
}

function getSelectedFormat() {
  let selectedFormat;
  const formats = document.querySelectorAll("#insights-export_modal input[type='radio']");
  formats.forEach((format) => {
    if (format.checked) selectedFormat = format;
  });
  return selectedFormat.value;
}

function getSelectedSections() {
  const sections = document.querySelectorAll("#insights-export_modal input[type='checkbox']");
  const selectedSections = [];
  sections.forEach((section) => {
    if (section.checked === true) selectedSections.push(section.value);
  });
  return selectedSections;
}

function getSelectedSectionsForUrl() {
  let path = '';
  const selectedSections = getSelectedSections();
  selectedSections.forEach((section) => path += `selected_sections[]=${section}&`);
  return path;
}

function getPeriodsAndTimeframesFor(section) {
  let url;
  switch (section) {
    case 'labor_savings':
      url = `labor_savings[period]=${getSelectedOption('#labor_savings-period')}&`
            + `labor_savings[timeframe]=${getSelectedOption('#labor_savings-timeframe')}&`
            + `year_by_year_period=${getSelectedOption('#year_by_year-period')}&`
            + 'water_savings[period]=Month&water_savings[timeframe]=L12M';
      break;
    case 'water_savings':
      url = `water_savings[period]=${getSelectedOption('#water_savings-period')}&`
            + `water_savings[timeframe]=${getSelectedOption('#water_savings-timeframe')}&`
            + 'labor_savings[period]=Month&labor_savings[timeframe]=L12M&'
            + 'year_by_year_period=Month';
      break;
    default:
      url = 'labor_savings[period]=Month&labor_savings[timeframe]=L12M&'
            + 'year_by_year_period=Month&'
            + 'water_savings[period]=Month&water_savings[timeframe]=L12M';
      break;
  }
  return url;
}

function exportInsightsFile() {
  let filename;
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });
  let url = `${window.location.origin}/insights/export_files?`
              + `selected_format=${getSelectedFormat()}&`
              + `${getSelectedSectionsForUrl()}`;
  if (document.getElementById('insights-labor_savings-index')) url += getPeriodsAndTimeframesFor('labor_savings');
  else if (document.getElementById('insights-water_savings-index')) url += getPeriodsAndTimeframesFor('water_savings');
  else url += getPeriodsAndTimeframesFor('inspections_proposals');
  fetch(url, {
    method: 'GET',
    headers,
  })
    .then((res) => {
      const disposition = res.headers.get('Content-Disposition');
      filename = disposition.split(';')[2].split('=')[1];
      if (filename.toLowerCase().startsWith("utf-8''")) filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
      else filename = filename.replace(/['"]/g, '');
      return res.blob();
    })
    .then((blob) => {
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      window.cleanSnackbars();
      window.toggleSnackbar('success-snackbar', window.I18n.insights.export.success);
    });
}
window.exportInsightsFile = exportInsightsFile;
