import Backbone from 'backbone';
import CartItemModel from './cart_item.js';

const CartModel = Backbone.Model.extend({
  initialize() {
    this.urlRoot = '/api/v2/cart';
  },
  parse(resp) {
    const items = resp.cart.cart_items.map((item) => new CartItemModel(item));
    resp.cart_items = items;
    return resp;
  },
});
export default CartModel;
