import Backbone from 'backbone';
import $ from 'jquery';

const SiteShowView = Backbone.View.extend({
  el: 'sites-show',
  events: {
    'click #leave-feedback': 'toggleFeedbackBox',
    'click #restriction-reject': 'toggleFeedbackTextBox',
    'click #restriction-accept': 'upvoteRestriction',
    'click #submit-rejection': 'downvoteRestriction',
    'click #monitor-restriction-button': 'ensureRestrictionSelected',
  },
  toggleFeedbackBox(e) {
    e.preventDefault();
    document.getElementById('feedback-box').classList.toggle('hidden');
  },
  toggleFeedbackTextBox(e) {
    e.preventDefault();
    document.getElementById('feedback-text-box').classList.toggle('hidden');
  },
  upvoteRestriction(e) {
    e.preventDefault();
    const boundaryId = document.getElementById('feedback-box').dataset.boundaryId;
    const userId = document.getElementById('feedback-box').dataset.userId;
    const submitData = {
      water_boundary_id: boundaryId,
      is_positive: 1,
      user_id: userId,
    };
    $.ajax({
      url: '/dashboard/feedbacks',
      type: 'POST',
      data: submitData,
      dataType: 'json',
      success: () => {
        alert('Thanks for the feedback!');
        document.getElementById('feedback-box').classList.toggle('hidden');
      },
      error: () => {
        alert('We are sorry but something went wrong.  Please refresh the page and try again.');
      },
    });
  },
  downvoteRestriction(e) {
    e.preventDefault();
    e.target.disabled = true;
    const boundaryId = document.getElementById('feedback-box').dataset.boundaryId;
    const userId = document.getElementById('feedback-box').dataset.userId;
    const comment = document.getElementById('feedback-text').value;
    const submitData = {
      water_boundary_id: boundaryId,
      is_positive: false,
      user_id: userId,
      comment,
    };

    $.ajax({
      url: '/dashboard/feedbacks',
      type: 'POST',
      data: submitData,
      dataType: 'json',
      success: () => {
        alert('Thanks for the feedback! We will review your submission.');
        document.getElementById('feedback-text-box').classList.toggle('hidden');
        document.getElementById('feedback-box').classList.toggle('hidden');
        document.getElementById('feedback-text').value = '';
      },
      error: () => {
        alert('We are sorry but something went wrong. Please refresh the page and try again.');
      },
    });
  },
  ensureRestrictionSelected(e) {
    if (document.querySelectorAll('.btn-radio-group.active').length < 1) {
      e.preventDefault();
      alert('Please select a restriction to monitor.');
    }
  },
});
export default SiteShowView;
