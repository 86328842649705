function checkPlaceHolder() {
  if (window.innerWidth < 576) {
    document.getElementById('user-goals-search').placeholder = window.I18n.accounts.user_goals.table.search_title_mobile;
  } else {
    document.getElementById('user-goals-search').placeholder = window.I18n.accounts.user_goals.table.search_title_desk;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('user_goals-index');
  if (body) {
    checkPlaceHolder();
    const urlValues = new URL(window.location.href);
    const roleSelected = urlValues.searchParams.get('filter[role_id_eq]');
    const nameCont = urlValues.searchParams.get('filter[full_name_or_email_cont]');
    if (nameCont) document.getElementById('user-goals-search').value = nameCont;
    if (roleSelected) {
      const entries = document.getElementById(`user_goals_roles-${roleSelected}`);
      window.selectChosenOption(entries);
    }

    window.addEventListener('resize', () => {
      checkPlaceHolder();
    });
  }
});

function searchUserGoals() {
  const inputSearch = document.getElementById('user-goals-search').value;
  const roleSelect = document.querySelector('.filter_box').querySelector('.li-check').id.split('-')[1];

  window.$.ajax({
    url: 'user_goals',
    type: 'GET',
    dataType: 'script',
    data: { filter: { full_name_or_email_cont: inputSearch, role_id_eq: roleSelect } },
  });
}
window.searchUserGoals = searchUserGoals;
const userGoalSearchInput = document.getElementById('user-goals-search');

userGoalSearchInput?.addEventListener('keyup', window.debounce(() => {
  searchUserGoals();
}, 500));
function addParamsToUserGoalsPagination() {
  const inputSearch = document.getElementById('user-goals-search').value;
  const selectedRole = document.querySelector('.filter_box').querySelector('.li-check').id.split('-')[1];
  window.AddParamsToTailwindPagination({ 'filter[full_name_or_email_cont]': inputSearch, 'filter[role_id_eq]': selectedRole });
}
window.addParamsToUserGoalsPagination = addParamsToUserGoalsPagination;
