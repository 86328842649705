import $ from 'jquery';
import Backbone from 'backbone';
import InstructionCellTemplate from '@assets/javascripts/backbone/templates/history/instruction_cell.ejs';
import GlobalCommandCellTemplate from '@assets/javascripts/backbone/templates/history/global_command_cell.ejs';
import ajaxLoaderGif from '@/images/ajax-loader.gif';

const HistoryWidgetView = Backbone.View.extend({
  el: '#history-widget',
  timeout: null,
  windowOpen: false,
  events: {
    'click a.close-button': 'toggleWidget',
  },
  initialize() {
    this.delegateEvents();
    if (this.windowOpen) { this.reload(); }
    this.instructionCell = InstructionCellTemplate;
    this.globalCommandCell = GlobalCommandCellTemplate;
  },
  toggleWidget(e) {
    e.preventDefault();
    if (this.$el.hasClass('minimized')) {
      this.showWindow();
      this.windowOpen = true;
      this.reload();
    } else {
      if (this.timeout) { clearTimeout(this.timeout); }
      this.windowOpen = false;
      this.$el.animate({ bottom: '-50px' }, 300);
      $(e.currentTarget).find('span.close').addClass('hide');
      this.$el.addClass('minimized');
    }
  },
  showAndReload() {
    this.windowOpen = true;
    this.reload();
    if (this.$el.hasClass('minimized')) {
      this.showWindow();
    }
  },
  showWindow() {
    this.windowOpen = true;
    this.$el.animate({ bottom: '0px' }, 300);
    this.$el.removeClass('minimized');
    this.$el.find('span.close').removeClass('hide');
  },
  reload() {
    const itemsContainer = this.$('.items');

    // Display loading spinner
    itemsContainer.html($('<img>').prop('src', ajaxLoaderGif).css('margin', '1.5em').css('display', 'inline'));

    $.ajax('/api/v2/users/recent_activity', {
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        this.$('.items').empty();
        if (data.activity.length === 0) {
          this.$('.items').append(
            $('<p>').text('No recent communications').css('margin-top', '1.5em'),
          );
        }

        let hasPendingItems = false;
        data.activity.forEach((item) => {
          if (item.pending === true && this.windowOpen) {
            hasPendingItems = true;
          }

          if (item.type === 'instruction') {
            itemsContainer.append(this.instructionCell({ instruction: item }));
          } else if (item.type === 'global_batch') {
            itemsContainer.append(this.globalCommandCell({ batch: item }));
          }
        });

        if (hasPendingItems) {
          this.timeout = setTimeout(() => this.reload(), 2500);
        }
      },
    });
  },
});
export default HistoryWidgetView;
