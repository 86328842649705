function openEditPaymentModal(paymentSourceId, type) {
  const element = document.getElementById(`payment_source-${paymentSourceId}`);
  if (type === 'cards') {
    const formatedMonth = parseInt(element.dataset.expMn, 10).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const fullDate = `${formatedMonth}/${element.dataset.expYr.toString().slice(2, 4)}`;
    document.querySelector('#stripe_card_edit_modal #card-expiry-element').value = fullDate;
    document.querySelector('#stripe_card_edit_modal #card-expiry-element').classList.toggle('form_input-typed', false);
    window.$('#stripe_card_edit_modal #card-expiry-element').mask('00/00');
    document.querySelector('#stripe_card_edit_modal .submit').onclick = () => window.updateStripePaymentSource(paymentSourceId);
  } else {
    document.querySelector('#plaid_account_edit_modal .submit').onclick = () => window.updatePlaidPaymentSource(paymentSourceId);
  }
  const elementId = type === 'cards' ? 'stripe_card_edit_modal' : 'plaid_account_edit_modal';
  const accesor = type === 'cards' ? 'card' : 'plaid';
  document.getElementById(elementId).classList.remove('tw-hidden');
  document.querySelector(`#${elementId} #${accesor}-number-element`).value = `••••${element.dataset.last4}`;
  document.querySelector(`#${elementId} input[name='${accesor}-alias']`).value = element.dataset.alias || '';
  document.querySelector(`#${elementId} input[name='${accesor}-alias']`).classList.toggle('form_input-typed', false);
  document.querySelector(`#${elementId} input[name='${accesor}[edit_primary_payment]']`).checked = element.dataset.primary === 'true';
  document.querySelector(`#${elementId} .submit`).classList.add('tw-btn-disabled');
  document.querySelector(`#${elementId} .submit`).classList.remove('tw-button-primary');
  if (element.dataset.primary === 'true') {
    document.querySelector(`#${elementId} input[name='${accesor}[edit_primary_payment]']`).disabled = true;
    document.querySelector(`#${elementId} .tw-dot`).classList.add('tw-cursor-default');
    document.querySelector(`#${elementId} .toggle-background`).classList.add('tw-cursor-default');
  } else {
    document.querySelector(`#${elementId} input[name='${accesor}[edit_primary_payment]']`).disabled = false;
    document.querySelector(`#${elementId} .tw-dot`).classList.remove('tw-cursor-default');
    document.querySelector(`#${elementId} .toggle-background`).classList.remove('tw-cursor-default');
  }
}
window.openEditPaymentModal = openEditPaymentModal;

function changeEditPlaidButton() {
  document.querySelector('#plaid_account_edit_modal .submit').classList.remove('tw-btn-disabled');
  document.querySelector('#plaid_account_edit_modal .submit').classList.add('tw-button-primary');
}
window.changeEditPlaidButton = changeEditPlaidButton;

function changeEditCardButton() {
  const updateCardButton = document.querySelector('#stripe_card_edit_modal .submit');
  if (document.querySelector('#stripe_card_edit_modal #card-expiry-element.tw-text-red-500')) {
    updateCardButton.classList.add('tw-btn-disabled');
    updateCardButton.classList.remove('tw-button-primary');
  } else {
    updateCardButton.classList.remove('tw-btn-disabled');
    updateCardButton.classList.add('tw-button-primary');
  }
}
window.changeEditCardButton = changeEditCardButton;

function validateDateFormat(splitedDate, element) {
  if (splitedDate.length === 2) {
    const currentDate = new Date(new Date().setDate(1));
    const fullInputYear = parseInt(`${currentDate.getFullYear().toString().slice(0, 2)}${splitedDate[1]}`, 10);
    const inputDate = new Date(fullInputYear, splitedDate[0], 1);
    if (parseInt(splitedDate[0], 10) <= 12 && parseInt(splitedDate[0], 10) > 0 && inputDate > currentDate && fullInputYear < currentDate.getFullYear() + 51) {
      document.querySelector('#stripe_card_edit_modal #card-errors p').classList.add('tw-hidden');
      element.classList.remove('tw-text-red-500');
      changeEditCardButton();
      return;
    }
  }
  document.querySelector('#stripe_card_edit_modal #card-errors p').classList.remove('tw-hidden');
  element.classList.add('tw-text-red-500');
  changeEditCardButton();
}

function validateDate(event) {
  let splitedDate = event.target.value.split('/');
  if (parseInt(splitedDate[0], 10) > 12) {
    event.target.value = `0${event.target.value}`;
    splitedDate = event.target.value;
    window.$('#stripe_card_edit_modal #card-expiry-element').unmask();
    window.$('#stripe_card_edit_modal #card-expiry-element').mask('00/00');
    event.target.setSelectionRange(4, 4);
  }
  event.target.classList.toggle('form_input-typed', true);
  validateDateFormat(splitedDate, event.target);
}
window.validateDate = validateDate;
