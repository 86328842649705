function renderSitesCount(numberOfSites) {
  let description = '';
  if (parseInt(numberOfSites, 10) === 1) {
    description = window.I18n.sites.count.one;
  } else {
    description = window.I18n.sites.count.other.replace('%{count}', numberOfSites);
  }
  return description;
}

function removeUserFromSites(userId) {
  window.$.ajax({
    url: `/manage_users/${userId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}

function showRemoveUserFromSitesModal(userId, userFullName) {
  window.$.ajax({
    url: `/api/v2/users/${userId}/billing_sites`,
    dataType: 'json',
    type: 'GET',
    success(response) {
      const looseSites = response.not_billing_user;
      const notLooseSites = response.billing_user;
      const warningModal = document.getElementById('warning-modal');
      const removeButton = document.querySelector('#warning-modal .tw-btn-cancel').nextElementSibling;
      removeButton.classList.remove('tw-btn-disabled');
      removeButton.classList.add('tw-btn-action-red');
      warningModal.querySelector('.description').textContent = window.I18n.manage_users.index.remove_user_modal.description.replace('%{user_name}', userFullName);
      const looseAccess = window.I18n.manage_users.index.remove_user_modal.sub_description.loose_access.replace('%{loose_sites}', renderSitesCount(looseSites));
      const notLooseAccess = notLooseSites > 0 ? window.I18n.manage_users.index.remove_user_modal.sub_description.not_loose_access.replace('%{not_loose_sites}', renderSitesCount(notLooseSites)) : '';
      warningModal.querySelector('.sub_description').textContent = `${looseAccess} ${notLooseAccess} ${window.I18n.manage_users.index.remove_user_modal.sub_description.cannot_undone}`;
      if (looseSites > 0) {
        removeButton.onclick = () => removeUserFromSites(userId);
      } else {
        removeButton.classList.remove('tw-btn-action-red');
        removeButton.classList.add('tw-btn-disabled');
      }
      warningModal.classList.remove('tw-hidden');
    },
  });
}

window.showRemoveUserFromSitesModal = showRemoveUserFromSitesModal;
