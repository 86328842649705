function billingSetEntriesParamValue() {
  const billingEntrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (billingEntrySelected) {
    const entry = document.getElementById(`billing-entries-${billingEntrySelected}`);
    window.selectChosenOption(entry);
  }
}

function billingSetBillingContactParamValue() {
  const billingContactSelected = (new URLSearchParams(window.location.search)).get('filter[billing_contact_id]');
  if (billingContactSelected) {
    const billingContact = document.getElementById(`billing_contact-filter-${billingContactSelected}`);
    window.selectChosenOption(billingContact);
  }
}

function getSitesForBilling() {
  const searchValue = document.getElementById('search-billing').value;
  const billingContactId = document.querySelector('#billing_contact-filter .li-check').id.split('-')[2];
  const perPage = document.querySelector('#billing-entries .li-check').id.split('-')[2];
  window.$.ajax({
    url: '/users/billing',
    data:
    {
      search: searchValue,
      filter: { billing_contact_id: billingContactId },
      per_page: perPage,
    },
    dataType: 'script',
    type: 'GET',
  });
}
window.getSitesForBilling = getSitesForBilling;

function billingSetSearchInputListenerAndValue() {
  const billingSearchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const billingSearchInput = document.getElementById('search-billing');
  billingSearchInput.value = billingSearchInputParam;

  billingSearchInput?.addEventListener('keyup', window.debounce(() => {
    window.AddParamsToTailwindPagination({ search: billingSearchInput.value, page: 1 });
    getSitesForBilling();
  }, 500));
}

function toggleGeneralCheckboxForBilling() {
  const checkboxes = document.querySelectorAll('#billing-index table tbody input[type=checkbox]').length;
  const generalCheckbox = document.getElementById('selectAllSites');
  if (checkboxes > 0) {
    generalCheckbox.classList.remove('tw-hidden');
  } else {
    generalCheckbox.classList.add('tw-hidden');
  }
}
window.toggleGeneralCheckboxForBilling = toggleGeneralCheckboxForBilling;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('billing-index');
  if (body) {
    billingSetEntriesParamValue();
    billingSetBillingContactParamValue();
    billingSetSearchInputListenerAndValue();
    toggleGeneralCheckboxForBilling();
  }
});

function updateBillingUrlWithEntries() {
  const entries = document.querySelector('#billing-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateBillingUrlWithEntries = updateBillingUrlWithEntries;

function updateBillingUrlWithBillingContact() {
  const billingContactId = document.querySelector('#billing_contact-filter .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { 'filter[billing_contact_id]': billingContactId, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateBillingUrlWithBillingContact = updateBillingUrlWithBillingContact;

function removeFirstAndLastCharacters(string) {
  return string.substring(1, string.length - 1);
}

function controllersListForSite(controllersList) {
  const list = removeFirstAndLastCharacters(controllersList).split(', ');
  if (list.length === 1 && list[0] === '') return '';
  let content = '';
  list.forEach((controller) => {
    content += `<li class="tw-text-base tw-font-normal tw-text-gray-600 tw-break-all">
                  ${removeFirstAndLastCharacters(controller)}
                </li>`;
  });
  return content;
}

function claimBillingResponsibility() {
  const modal = document.getElementById('claim_billing_responsibility_modal');
  window.$.ajax({
    url: '/users/billing/claim_site',
    data: { site_id: modal.dataset.siteId },
    dataType: 'script',
    type: 'PATCH',
  });
}
window.claimBillingResponsibility = claimBillingResponsibility;

function showClaimBillingResponsibilityModal(siteId, hasAutobillSubscription, data) {
  const modal = document.getElementById('claim_billing_responsibility_modal');
  const withAutobillSubscriptionSection = modal.querySelector('#with_autobill_subscription');
  const withoutAutobillSubscriptionSection = modal.querySelector('#without_autobill_subscription');
  if (hasAutobillSubscription) {
    withAutobillSubscriptionSection.querySelector('.description').innerHTML = window.I18n.devise.registrations.claim.cannot_claim.replace('%{billing_user_name}', data);
    withAutobillSubscriptionSection.classList.remove('tw-hidden');
    withoutAutobillSubscriptionSection.classList.add('tw-hidden');
  } else {
    withoutAutobillSubscriptionSection.querySelector('.description').textContent = window.I18n.devise.registrations.claim.responsible_for_billing_on_all_controllers;
    withoutAutobillSubscriptionSection.querySelector('.controllers_list').innerHTML = controllersListForSite(data);
    withoutAutobillSubscriptionSection.querySelector('.tw-btn-action-blue').textContent = window.I18n.devise.registrations.claim.claim_site;
    withoutAutobillSubscriptionSection.querySelector('.tw-btn-action-blue').onclick = () => claimBillingResponsibility();
    withoutAutobillSubscriptionSection.classList.remove('tw-hidden');
    withAutobillSubscriptionSection.classList.add('tw-hidden');
  }
  modal.dataset.siteId = siteId;
  modal.classList.remove('tw-hidden');
}

window.showClaimBillingResponsibilityModal = showClaimBillingResponsibilityModal;

function enableClaimAllButton() {
  const button = document.getElementById('claim-all-button');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-btn-action-blue');
}

function disableClaimAllButton() {
  const button = document.getElementById('claim-all-button');
  button.classList.remove('tw-btn-action-blue');
  button.classList.add('tw-btn-disabled');
}
window.disableClaimAllButton = disableClaimAllButton;

function toggleClaimBillingForAllButton() {
  const checkedCheckboxes = document.querySelectorAll('#billing-index table tbody input[type=checkbox]:checked').length;
  if (checkedCheckboxes > 0) {
    enableClaimAllButton();
  } else {
    disableClaimAllButton();
  }
}

window.toggleClaimBillingForAllButton = toggleClaimBillingForAllButton;

function claimBillingResponsibilityForAll(sitesIds) {
  window.$.ajax({
    url: '/users/billing/claim_sites',
    data: { sites_ids: sitesIds },
    dataType: 'script',
    type: 'PATCH',
  });
}

function getSitesForClaim() {
  const sites = [];
  const checkedCheckboxes = document.querySelectorAll('#billing-index table tbody input[type=checkbox]:checked');
  checkedCheckboxes.forEach((checkbox) => sites.push(checkbox.dataset.siteId));
  return sites;
}

function getControllersCountForClaim() {
  let count = 0;
  const checkedCheckboxes = document.querySelectorAll('#billing-index table tbody input[type=checkbox]:checked');
  checkedCheckboxes.forEach((checkbox) => count += parseInt(checkbox.dataset.controllersCount, 10));
  return count;
}

function showClaimBillingResponsibilityForAllModal() {
  const modal = document.getElementById('claim_billing_responsibility_modal');
  const sites = getSitesForClaim();
  const sitesCount = sites.length;
  const controllersCount = getControllersCountForClaim();
  const requiredSection = modal.querySelector('#without_autobill_subscription');
  const notRequiredSection = modal.querySelector('#with_autobill_subscription');
  requiredSection.classList.remove('tw-hidden');
  notRequiredSection.classList.add('tw-hidden');
  requiredSection.querySelector('.description').textContent = window.I18n.devise.registrations.claim.all.replace('%{billing_sites}', sitesCount === 1 ? window.I18n.devise.registrations.claim.billing_sites.one : window.I18n.devise.registrations.claim.billing_sites.other).replace('%{sites_count}', sitesCount === 1 ? window.I18n.sites.count.one : window.I18n.sites.count.other.replace('%{count}', sitesCount)).replace('%{controllers_count}', controllersCount === 1 ? window.I18n.controller_pluralize.one : window.I18n.controller_pluralize.other.replace('%{count}', controllersCount));
  requiredSection.querySelector('.controllers_list').textContent = '';
  requiredSection.querySelector('.tw-btn-action-blue').textContent = window.I18n.devise.registrations.claim.claim_sites;
  requiredSection.querySelector('.tw-btn-action-blue').onclick = () => claimBillingResponsibilityForAll(sites);
  modal.classList.remove('tw-hidden');
}

window.showClaimBillingResponsibilityForAllModal = showClaimBillingResponsibilityForAllModal;
