import Backbone from 'backbone';
import $ from 'jquery';

const JobCatalogItemsIndexView = Backbone.View.extend({
  el: 'body',
  events: {
    'click #create-new': 'toggleNewForm',
    'click #create_new_tag': 'popCreateTagModal',
  },
  initialize() {
    console.log('Job Catalog Items Index Initialized');
  },
  toggleNewForm() {
    $('#new-form').slideToggle();
  },
  popCreateTagModal(formEvent) {
    formEvent.preventDefault();
    $('.create-new-tag-modal').modal('show');

    $('#submit-create-tag').on('click', (clickEvent) => {
      clickEvent.preventDefault();
      clickEvent.stopImmediatePropagation();
      const name = document.getElementById('tag-name-field').value;
      const description = document.getElementById('tag-description-field').value;
      const accountId = document.getElementById('account-id-field').value;

      if (name !== '' && description !== '') {
        $.ajax({
          url: '/job_catalog_tags',
          type: 'POST',
          data: { job_catalog_tag: { name, description, account_id: accountId } },
          dataType: 'json',
          success: (response) => {
            alert('New Tag Created');
            const option = document.createElement('option');
            option.value = response.id;
            option.text = name;
            document.getElementById('tag-select').appendChild(option);
            document.getElementById('tag-select').value = response.id;
            document.getElementById('tag-name-field').value = '';
            document.getElementById('tag-description-field').value = '';
            $('.create-new-tag-modal').modal('hide');
          },
          error: (response) => {
            alert(response.responseText);
          },
        });
      } else {
        alert('Both name & description are required.');
      }
    });
  },

});
export default JobCatalogItemsIndexView;
