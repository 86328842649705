function getFilterFromChart(chartId, filter) {
  return document.querySelector(`#${chartId}-${filter} .dropdown-options .li-check`).id.split('-')[2];
}
window.getFilterFromChart = getFilterFromChart;

function updateLaborSavingsInsightsCards(data) {
  const moneySavingsCard = document.getElementById('money-savings-card');
  const mileageSavingsCard = document.getElementById('mileage-savings-card');
  const timeSavingsCard = document.getElementById('time-savings-card');
  const controllersUpdatesCard = document.getElementById('controllers-updates-card');
  moneySavingsCard.querySelector('h4').innerText = `$${data.money_saved}`;
  mileageSavingsCard.querySelector('h4').innerText = data.mileage_saved === 1
    ? window.I18n.insights.labor_savings.cards.mileage_savings.amount.one.replace('%{count}', data.mileage_saved)
    : window.I18n.insights.labor_savings.cards.mileage_savings.amount.other.replace('%{count}', data.mileage_saved);
  mileageSavingsCard.querySelector('#equivalent').innerHTML = data.mileage_saved_fact;
  timeSavingsCard.querySelector('h4').innerText = data.mileage_saved === 1
    ? window.I18n.insights.labor_savings.cards.time_savings.amount.one.replace('%{count}', data.time_saved)
    : window.I18n.insights.labor_savings.cards.time_savings.amount.other.replace('%{count}', data.time_saved);
  controllersUpdatesCard.querySelector('h4').innerText = data.mileage_saved === 1
    ? window.I18n.insights.labor_savings.cards.controllers_updates.amount.one.replace('%{count}', data.remote_programming_change)
    : window.I18n.insights.labor_savings.cards.controllers_updates.amount.other.replace('%{count}', data.remote_programming_change);
}

function updateLaborSavingsInsightsChart(data) {
  window.ApexCharts.exec('labor-savings-chart', 'updateOptions', {
    series: [{ data: Object.values(data.chart_data) }],
    xaxis: {
      categories: Object.keys(data.chart_data),
    },
  });
}

function updateTimeframeOnLaborSavingsInsightsChart(changeType) {
  if (changeType === 'period' || changeType === 'timeframe') {
    document.getElementById(`export_modal-labor_savings_${changeType}`).value = getFilterFromChart('labor_savings', changeType);
  }
  window.$.ajax({
    url: '/api/v2/insights/labor_savings_insights_chart',
    data: { timeframe: getFilterFromChart('labor_savings', 'timeframe'), period: getFilterFromChart('labor_savings', 'period'), branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'json',
    type: 'GET',
    success: (response) => {
      updateLaborSavingsInsightsChart(response.data);
      if (changeType !== 'period') {
        updateLaborSavingsInsightsCards(response.data);
      }
    },
  });
}
window.updateTimeframeOnLaborSavingsInsightsChart = updateTimeframeOnLaborSavingsInsightsChart;
