import Backbone from 'backbone';

const SiteUsersView = Backbone.View.extend({
  el: ('manage-users'),
  events: {
    'click input': 'update',
    'click .access-request-permission-checkbox input': 'updateAccessRequestPermissions',
  },
  update(e) {
    e.target.closest('form').submit();
  },
  updateAccessRequestPermissions(e) {
    const isChecked = e.target.checked;
    const addOrSubtract = isChecked ? 1 : -1;

    const permissionsChangeValue = parseInt((e.target.value * addOrSubtract), 10);

    const permissionsInput = e.target.closest('tr').querySelector('input[name="permissions"]');
    const permissionsInputValue = parseInt(permissionsInput.value, 10);
    permissionsInput.value = permissionsInputValue + permissionsChangeValue;
  },

});
export default SiteUsersView;
