function removeSelfFromAccount(userId, accountId) {
  window.$.ajax({
    url: `/accounts/${accountId}/remove_self`,
    data: { user_id: userId },
    dataType: 'script',
    type: 'DELETE',
  });
}

function removeUserFromAccount(userId, accountId) {
  window.$.ajax({
    url: `/accounts/${accountId}/remove_member`,
    data: { user_id: userId },
    dataType: 'script',
    type: 'DELETE',
  });
}
function showLeaveAccountModal(userId) {
  const splittedUrl = window.location.href.split('/');
  const accountId = splittedUrl[splittedUrl.length - 1];
  const warningModal = document.getElementById('remove-self-modal');
  warningModal.querySelector('.tw-btn-action-red').onclick = () => removeSelfFromAccount(userId, accountId);
  warningModal.classList.remove('tw-hidden');
}
window.showLeaveAccountModal = showLeaveAccountModal;

function showRemoveUserModal(userName, userId, siteOwner = false, sitesCount = null) {
  let warningModal;
  siteOwner = siteOwner.toLowerCase() === 'true';
  const accountId = document.getElementById('account-users-index').dataset.accountId;

  if (siteOwner) {
    warningModal = document.getElementById('remove-billing-user-modal');
    const description = warningModal.querySelector('.description');

    description.textContent = window.I18n.accounts.users.remove_billing_user.modal_description.replace('%{sites_count}', sitesCount);
    description.textContent = sitesCount > 1 ? description.textContent.replace('%{site_pluralization}', 'sites') : description.textContent.replace('%{site_pluralization}', 'site');
    warningModal.classList.remove('tw-hidden');
  } else {
    warningModal = document.getElementById('remove-user-modal');
    warningModal.querySelector('.description').textContent = window.I18n.accounts.users.remove_user.modal_description.replace('%{user_name}', userName);
    warningModal.querySelector('.tw-btn-action-red').onclick = () => removeUserFromAccount(userId, accountId);
    warningModal.classList.remove('tw-hidden');
  }
}
window.showRemoveUserModal = showRemoveUserModal;
