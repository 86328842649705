// This is a manifest file that'll be compiled into including all the files
// listed below. Add new JavaScript/Coffee code in separate files in this
// directory and they'll automatically be included in the compiled file
// accessible from http://example.com/assets/application.js It's not advisable
// to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.
//
// // BASICS
import $ from 'jquery';
import { Notifier } from '@airbrake/browser';
import 'bootbox';
import 'jquery-mask-plugin';
import 'jquery-ui-dist/jquery-ui.js';
import 'jquery-ui-dist/jquery-ui.css';
import 'bootstrap';
import '@assets/javascripts/components/snackbars.js';
import '@assets/javascripts/v2/ajax_setup.js';
// import '@assets/javascripts/external/d3.js';
// import '@assets/javascripts/external/topojson.v1.js';
// import '@assets/javascripts/rebates/index.js';
import '@assets/javascripts/v2/include/utils.js.coffee';
import '@assets/javascripts/v2/include/reports.js.coffee';
import '@assets/javascripts/v2/include/account_invites.js';
import '@assets/javascripts/v2/include/activating.js.coffee';
import '@assets/javascripts/v2/include/control.js.coffee';
import '@assets/javascripts/v2/include/navigation.js.coffee';
import '@assets/javascripts/v2/include/renewals.js.coffee';
import '@assets/javascripts/v2/include/sites.js.coffee';
import '@assets/javascripts/v2/include/timestamps.js.coffee';

import '@vendor/javascripts/underscore-1.4.1.js';
import '@vendor/javascripts/underscore.string-1.1.4.js';
import '@vendor/javascripts/bootstrap-datepicker.js';
import '@vendor/javascripts/bootstrap3-datepicker.js';
// DATATABLES
import '@vendor/javascripts/jquery.dataTables-1.10.5.min.js';
import '@vendor/javascripts/dataTables.responsive.js';
import '@assets/javascripts/v2/include/router.js';
import { updateColumnsSelection } from '@assets/javascripts/backbone/lib/include/data_tables.js';

// HOME
import '@assets/javascripts/home/load.js';

// GENERIC STUFF
import '@vendor/javascripts/jquery-plugins/jquery.timeago-1.4.0.js';
import '@assets/javascripts/v2/include/html2canvas.js';
import '@assets/javascripts/snapshot.js.coffee';
// import '@vendor/javascripts/jquery-plugins/jquery.scrollTo.js';
import '@assets/javascripts/jquery.stickytableheaders.js';
import '@vendor/javascripts/datepicker.js';

// DASHBOARD
import '@assets/javascripts/dashboard/admin-users-edit_permissions.js.coffee';
import '@assets/javascripts/dashboard/controls-index.js.coffee';
import '@assets/javascripts/dashboard/dashboard-ui.js.coffee';
import '@assets/javascripts/dashboard/events-index.js.coffee';
import '@vendor/javascripts/jquery-plugins/jquery.placeholder.js';
import '@assets/javascripts/dashboard/account/new_account.js';
import '@assets/javascripts/dashboard/account/index.js';
import '@assets/javascripts/dashboard/account/account.js';
import '@assets/javascripts/dashboard/account/import_sites.js';
import '@assets/javascripts/dashboard/menu.js';
import '@assets/javascripts/dashboard/bulk_renewals.js';
import '@assets/javascripts/dashboard/aircards/aircard_spreadsheets.js';

// GLOBAL COMMANDS
import '@assets/javascripts/global_commands/load.js';
// COMPONENTS
import '@assets/javascripts/components/warning_modal.js';
import '@assets/javascripts/components/confirmation_modal.js';
import '@assets/javascripts/components/date_select.js';
import '@assets/javascripts/components/dropdown.js';
import '@assets/javascripts/components/sortable_table.js';
import '@assets/javascripts/components/edit_site_custom_fields.js';
import '@assets/javascripts/components/send_emails_with_files_modal.js';

// PROPOSALS
import '@assets/javascripts/proposals/export_proposal.js';
import '@assets/javascripts/proposals/combine_proposals.js';
import '@assets/javascripts/proposals/delete_proposal.js';

// SITE
import '@assets/javascripts/site/add_user.js';
import '@assets/javascripts/site/delete.js';
import '@assets/javascripts/site/form.js';
import '@assets/javascripts/site/menu.js';
import '@assets/javascripts/site/new_site.js';
import '@assets/javascripts/site/toggle_inspection_schedule_months.js';
import '@assets/javascripts/site/transfer_ownership.js';
// SITE ALERTS
import '@assets/javascripts/site_alerts/index.js';
// ACCOUNTS
import '@assets/javascripts/accounts/load.js';

// INSIGHTS
import '@assets/javascripts/insights/load.js';

// INSPECTIONS
import '@assets/javascripts/inspections/new.js';
import '@assets/javascripts/inspections/save.js';
import '@assets/javascripts/inspections/index.js';

// SHARED
import '@assets/javascripts/shared/date_formatter.js';
import '@assets/javascripts/shared/header_options.js';
import '@assets/javascripts/shared/click_outside.js';
import '@assets/javascripts/shared/pagination.js';
import '@assets/javascripts/shared/pick_columns.js';

// SCHEDULED INSPECTIONS
import '@assets/javascripts/inspection_schedules/index.js';

// DATA TABLES
import '@assets/javascripts/data_tables/common.js';

// USER GOALS
import '@assets/javascripts/user_goals/update_user_goals.js';
import '@assets/javascripts/user_goals/monthly_goal.js';
import '@assets/javascripts/user_goals/set_user_goal_modal.js';

import '@assets/javascripts/aircards/inventory.js';

// BRANCHES
import '@assets/javascripts/branches/form.js';
import '@assets/javascripts/branches/index.js';
import '@assets/javascripts/branches/users.js';
import '@assets/javascripts/branches/new.js';
import '@assets/javascripts/branches/add_users.js';

// BRANCH_SITES
import '@assets/javascripts/branch_sites/add_sites.js';
import '@assets/javascripts/branch_sites/transfer_sites.js';

// SITE_USERS
import '@assets/javascripts/site_users/cancel_invitation.js';
import '@assets/javascripts/site_users/edit_user_permissions.js';
import '@assets/javascripts/site_users/entries.js';
import '@assets/javascripts/site_users/remove_user.js';
import '@assets/javascripts/site_users/search_site_user.js';

// GOOGLE_TAG_MANAGER
import '@assets/javascripts/google_tag_manager/events.js';

// CONTROLLERS
import '@assets/javascripts/controllers/developer.js';
import '@assets/javascripts/controllers/delete.js';
import '@assets/javascripts/controllers/edit.js';

// SHOPPING CART
import '@assets/javascripts/shopping_cart/checkout.js';
import '@assets/javascripts/shopping_cart/plaid_checkout.js';
import '@assets/javascripts/shopping_cart/review.js';
import '@assets/javascripts/shopping_cart/stripe_checkout.js';
import '@assets/javascripts/shopping_cart/summary.js';

// PAYMENT SOURCES
import '@assets/javascripts/payment_sources/destroy.js';
import '@assets/javascripts/payment_sources/edit.js';
import '@assets/javascripts/payment_sources/plaid.js';
import '@assets/javascripts/payment_sources/stripe.js';

// BILLING USERS
import '@assets/javascripts/billing/index.js';

// EQUIPMENT ORDERS
import '@assets/javascripts/equipment_orders/index.js';
import '@assets/javascripts/equipment_orders/show.js';

// ACCOUNT SETUPS
import '@assets/javascripts/account_setups/coworker_email_validation.js';
import '@assets/javascripts/account_setups/show_coworker_form.js';

// MANAGE USERS
import '@assets/javascripts/manage_users/index.js';
import '@assets/javascripts/manage_users/remove_user.js';
import '@assets/javascripts/manage_users/show.js';

// USERS
import '@assets/javascripts/users/index.js';

// COMMON
import '@assets/javascripts/common/form.js';

import '@assets/javascripts/external/modernizr.js';
import ApexCharts from 'apexcharts';

window.updateColumnsSelection = updateColumnsSelection;

window.$ = $;
window.ApexCharts = ApexCharts;
/* eslint-disable no-undef */
if (window.STRIPE_KEY) {
  window.STRIPE = Stripe(STRIPE_KEY);
  window.STRIPE_ELEMENTS = window.STRIPE.elements();
}
if (SERVER_ENV !== 'development' && SERVER_ENV !== 'test') {
  const airbrake = new Notifier({ projectId: 138170, projectKey: AIRBRAKE_KEY });
  airbrake.addFilter((notice) => {
    notice.context.environment = SERVER_ENV;
    return notice;
  });
}

$('.trigger-tooltip').popover({ html: true, sanitize: false });

if (!('ontouchstart' in window)) {
  $('.tooltipable').tooltip({ html: true });
}
$(document).ready(() => {
  $('.has-datepicker').datepicker().on('changeDate', (e) => { $(e.target).trigger('change'); });

  $('.has-datepicker-yymmdd').datepicker({ format: 'yyyy-mm-dd' }).on('changeDate', (e) => { $(e.target).trigger('change'); });
  if (!Modernizr.input.placeholder) $('input, textarea').placeholder();
});

// this makes safari draw a border around empty table cells
$.each($('td:empty'), () => {
  $(this).html('&nbsp;');
});
/* eslint-enable no-undef */
console.log('INITIALIZED');
