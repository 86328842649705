import Backbone from 'backbone';
import $ from 'jquery';
import ExistingRepairItemsTemplate from '@assets/javascripts/backbone/templates/inspections/existing_repair_items.ejs';
import AddRepairItemTemplate from '@assets/javascripts/backbone/templates/inspections/add_repair_item_row.ejs';
import SystemIssuePhotoThumbnailTemplate from '@assets/javascripts/backbone/templates/inspections/system_issue_photo_thumbnail.ejs';
import SystemIssueTemplate from '@assets/javascripts/backbone/templates/inspections/new_system_issue_rows.ejs';
import bootbox from 'bootbox';
import 'blueimp-file-upload/js/jquery.fileupload.js';
import addPhotoGif from '@/images/inspections/add_photo.gif';

const InspectionSystemIssueView = Backbone.View.extend({
  el: '#controller_inspections-edit, #controller_inspections-show',
  accountId: $('#inspection').data('accountId'),
  assignsPriority: !!$('#inspection').data('assignPriority'),
  createProposal: !!$('#inspection').data('proposal'),
  events: {
    'click .create-system-issue': 'createSystemIssue',
    'click .update-issue': 'updateSystemIssue',
    'click .delete-issue': 'deleteSystemIssue',
    'click .system-issue-photo': 'viewSystemIssuePhoto',
    'click #dismiss-system-photo-modal': 'save_and_close_system_photo',
    'click #delete-system-issue-photo': 'deleteSystemIssuePhoto',
  },
  initialize() {
    this.systemIssue = SystemIssueTemplate;
    this.systemIssuePhotoThumbnail = SystemIssuePhotoThumbnailTemplate;
    this.addRepairItem = AddRepairItemTemplate;
    this.existingRepairItems = ExistingRepairItemsTemplate;
    if (!this.is_ios_device()) {
      $('#system-issue-table').stickyTableHeaders();
      $('#table-inspections-scroll').on('scroll', () => $(window).trigger('resize.stickyTableHeaders'));
    }
  },
  is_ios_device() {
    return /iPhone|iPad/.test(navigator.userAgent);
  },

  createSystemIssue(e) {
    const self = this;
    e.preventDefault();
    const controllerId = $('#inspection').data('controller-id');
    const inspectionId = $('#inspection').data('inspection-id');
    const systemIssue = $('#system-issue').val();
    const description = $('#issue-description').val();
    const estimatedCost = $('#issue-estimated-cost').val();
    const issueText = $('#system-issue option:selected').html();

    $.ajax({
      url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/inspections_system_issues`,
      data: ({ issue: systemIssue, description, estimated_cost: estimatedCost }),
      type: 'POST',
      dataType: 'json',
      success(response) {
        if (response.error) {
          alert(response.error);
        } else {
          $('#issue-description').val('');
          $('#issue-estimated-cost').val('');

          $('#system-issue-table tr:last').after(self.systemIssue({
            issue: response,
            issueText,
            controllerId,
            inspectionId,
            addPhotoGif,
          }));

          // Initialize the new photo upload button
          $('.new-fileupload input').fileupload({
            type: 'POST',
            dataType: 'json',
            disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator && navigator.userAgent),
            imageMaxWidth: 1280,
            imageMaxHeight: 1280,
            imageCrop: false,
            done(uploadEvent, data) {
              $(uploadEvent.target).prop('disabled', false);
              const systemIssueId = response.id;
              const systemShowButton = $(`button[system_issue_id='${systemIssueId}']`);
              if (systemShowButton.html() === 'Show') {
                systemShowButton.html('Hide');
                systemShowButton.removeClass('btn-success');
                systemShowButton.addClass('btn-danger');
              }

              const systemGallery = $(`.system-photos-row[system_issue_id="${systemIssueId}"]`);
              $('.progress').remove();
              systemGallery.find('td').append(self.systemIssuePhotoThumbnail({ result: data.result }));
            },
            fail(event, data) {
              if (data.jqXHR.responseJSON) {
                const title = Object.keys(data.jqXHR.responseJSON)[0].replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
                window.toggleSnackbar('warning-snackbar', `${title}: ${Object.values(data.jqXHR.responseJSON)[0][0]}`);
              } else {
                window.toggleSnackbar('warning-snackbar', "We're sorry something went wrong.");
              }
              $('.progress').remove();
              if (event.target) {
                event.target.removeAttribute('disabled');
              }
            },
          });

          // launch the proposal modal if its a proposal inspection
          if (self.createProposal) {
            $('select.tag-selector').val('');

            $('.inspection-proposal-modal').modal({ backdrop: 'static', keyboard: false });
            $('#launcher').addClass('tw-hidden md:tw-block');
            $('.modal-proposal-body').attr('data-inspection-issue-id', response.id);
            $('.repair-item-title').text(`Add repair items for system issue ${issueText}`);

            // clear/reset added item table contents to start fresh

            document.querySelectorAll('.job-items-table tr').forEach((row) => {
              if (!row.querySelector('th')) {
                row.parentNode.removeChild(row);
              }
            });

            $('.modal-proposal-body').on('keyup', 'input', () => {
              const jobCataglogTagIds = $('.tag-selector').val();
              if ($('#repair-item-search').val().length > 0) {
                const autocompleteOptions = {
                  delay: 500,
                  minLength: 1,
                  source: `/api/v2/job_catalog_items?account_id=${self.accountId}&job_catalog_tag_ids=${jobCataglogTagIds}`,
                  focus(event, ui) {
                    $('#repair-item-search').val(ui.item.name);
                    return false;
                  },
                  select(event, ui) {
                    $('#repair-item-search').val('');

                    // don't allow items to be added that are already in the list
                    if ($(`td[data-job_catalog_item_id=${ui.item.id}]`).length > 0) {
                      bootbox.alert({
                        message: `Looks like the item <span style='color:blue'>${ui.item.name}</span> is already in the added items list.  If you want multiple added, please adjust the quantity field and click update.`,
                      });
                    } else {
                      $.ajax({
                        url: '/api/v2/repair_items',
                        data: ({
                          inspection_id: inspectionId, job_catalog_item_id: ui.item.id, inspections_system_issue_id: response.id, inspection_issue: issueText.trim().toLowerCase().split(' ').join('_'), quantity: 1,
                        }),
                        type: 'POST',
                        dataType: 'json',
                        success(autocompleteResponse) {
                          if (autocompleteResponse.errors) {
                            alert(JSON.stringify(autocompleteResponse.errors));
                          } else {
                            $('.job-items-table tbody').append(self.addRepairItem({ response: autocompleteResponse, ui, assignsPriority: self.assignsPriority }));
                          }
                        },
                        error() {
                          alert('Something went wrong.');
                        },
                      });
                    }

                    $('.ui-helper-hidden-accessible').hide();
                    return false;
                  },
                };
                /* eslint-disable-next-line no-underscore-dangle */
                $('#repair-item-search').autocomplete(autocompleteOptions)
                  .autocomplete('instance')._renderItem = (ul, item) => $('<li>')
                    .append(`<a><tr><td>${item.name} &nbsp &nbsp</td><td><span style='color:blue'>${item.part_number}<span></td></tr></a>`)
                    .appendTo(ul);
              }
            });
          }
        }
      },
      error() {
        return alert("We're sorry something went wrong.");
      },

    });
  },

  updateSystemIssue(e) {
    const self = this;
    e.preventDefault();
    const controllerId = $('#inspection').data('controller-id');
    const inspectionId = $('#inspection').data('inspection-id');
    const systemIssueId = $(e.currentTarget).attr('data-id');
    const issue = $(`#system-issue-${systemIssueId} option:selected`).val();
    const issueText = $(`#system-issue-${systemIssueId}`).html();
    const description = $(`#issue-description-${systemIssueId}`).val();
    const estimatedCost = $(`#issue-estimated-cost-${systemIssueId}`).val();

    return $.ajax({
      url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/inspections_system_issues/${systemIssueId}`,
      type: 'PUT',
      data: ({ issue, description, estimated_cost: estimatedCost }),
      dataType: 'json',
      success(response) {
        $(`#issue-estimated-cost-${systemIssueId}`).val(response.estimated_cost);
        if (self.createProposal) {
          $('select.tag-selector').val('');

          $('.inspection-proposal-modal').modal({ backdrop: 'static', keyboard: false });
          $('#launcher').addClass('tw-hidden md:tw-block');
          $('.repair-item-title').text(`Add repair items for system issue ${issueText}`);

          // clear/reset added item table contents to start fresh
          document.querySelectorAll('.job-items-table tr').forEach((row) => {
            if (!row.querySelector('th')) {
              row.parentNode.removeChild(row);
            }
          });

          $('#repair-item-search').val('');

          // add existing items to table from DB
          $.ajax({
            url: '/api/v2/repair_items',
            data: ({ inspection_id: inspectionId, inspections_system_issue_id: systemIssueId }),
            type: 'GET',
            dataType: 'json',
            success(itemResponse) {
              let i = 0;
              while (i < itemResponse.length) {
                let selectedOption;
                if (itemResponse[i].priority === 2) {
                  selectedOption = 'selected';
                } else {
                  selectedOption = null;
                }

                $('.job-items-table tbody').append(self.existingRepairItems({
                  response: itemResponse, i, selectedOption, assignsPriority: self.assignsPriority,
                }));
                i += 1;
              }
            },
            error() {
              alert('Something went wrong.');
            },
          });

          $('.modal-proposal-body').attr('data-inspection-issue-id', systemIssueId);

          $('.modal-proposal-body').on('keyup', 'input', () => {
            const jobCataglogTagIds = $('.tag-selector').val();
            if ($('#repair-item-search').val().length > 0) {
              const autocompleteOptions = {
                delay: 500,
                minLength: 1,
                source: `/api/v2/job_catalog_items?account_id=${self.accountId}&job_catalog_tag_ids=${jobCataglogTagIds}`,
                focus(event, ui) {
                  $('#repair-item-search').val(ui.item.name);
                  return false;
                },
                select(event, ui) {
                  $('#repair-item-search').val('');

                  // don't allow items to be added that are already in the list
                  if ($(`td[data-job_catalog_item_id=${ui.item.id}]`).length > 0) {
                    bootbox.alert({
                      message: `Looks like the item <span style='color:blue'>${ui.item.name}</span> is already in the added items list.  If you want multiple added, please adjust the quantity field and click update.`,
                    });
                  } else {
                    $.ajax({
                      url: '/api/v2/repair_items',
                      data: ({
                        inspection_id: inspectionId, job_catalog_item_id: ui.item.id, inspections_system_issue_id: response.id, inspection_issue: issueText.trim().toLowerCase().split(' ').join('_'), quantity: 1,
                      }),
                      type: 'POST',
                      dataType: 'json',
                      success(repairResponse) {
                        if (repairResponse.errors) {
                          alert(JSON.stringify(repairResponse.errors));
                        } else {
                          $('.job-items-table tbody').append(self.addRepairItem({ response: repairResponse, ui, assignsPriority: self.assignsPriority }));
                        }
                      },
                      error() {
                        alert('Something went wrong.');
                      },
                    });
                  }

                  $('.ui-helper-hidden-accessible').hide();
                  return false;
                },
              };

              /* eslint-disable-next-line no-underscore-dangle */
              $('#repair-item-search').autocomplete(autocompleteOptions)
                .autocomplete('instance')._renderItem = (ul, item) => $('<li>')
                  .append(`<a><tr><td>${item.name} &nbsp &nbsp</td><td><span style='color:blue'>${item.part_number}<span></td></tr></a>`)
                  .appendTo(ul);
            }
          });
        }
      },
      error() {
        return alert("We're sorry something went wrong");
      },

    });
  },
  deleteSystemIssue(e) {
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm('Are you sure you want to delete this issue?')) {
      e.preventDefault();
      const controllerId = $('#inspection').data('controller-id');
      const inspectionId = $('#inspection').data('inspection-id');
      const systemIssueId = $(e.currentTarget).attr('data-id');
      const dis = $(e.currentTarget);

      $.ajax({
        url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/inspections_system_issues/${systemIssueId}`,
        type: 'DELETE',
        dataType: 'json',
        success(response) {
          if (response.error) {
            alert(response.error);
          } else {
            dis.closest('tr').next('tr').remove();
            dis.closest('tr').remove();
          }
        },
        error() {
          alert("We're sorry something went wrong.");
        },
      });
    }
  },

  viewSystemIssuePhoto(e) {
    e.preventDefault();
    const t = $(e.target);
    const id = t.data('phile-id');
    const systemIssueId = t.data('system-issue-id');
    const medium = t.data('medium');
    const original = t.data('original');
    const caption = t.data('caption');
    $('.modal-system-issue-photo-body').html(`<img src="${medium}"/>`);
    $('#system-issue-photo-caption').val(caption);
    $('#delete-system-issue-photo').attr('data-phile-id', id);
    $('#delete-system-issue-photo').attr('data-system-issue-id', systemIssueId);
    $('#dismiss-system-photo-modal').attr('data-phile-id', id);
    $('#dismiss-system-photo-modal').attr('data-system-issue-id', systemIssueId);
    $('#system-original').attr('href', original);
    $('.system-issue-photo-modal').modal('show');
  },

  save_and_close_system_photo(e) {
    const data = e.target.dataset;
    const caption = $('#system-issue-photo-caption').val();
    const issueId = data.systemIssueId;
    const phileId = data.phileId;
    const controllerId = $('#inspection').data('controller-id');
    const inspectionId = $('#inspection').data('inspection-id');

    $.ajax({
      url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/inspections_system_issues/${issueId}/photos/${phileId}`,
      type: 'PUT',
      dataType: 'json',
      data: ({ description: caption }),
      success(response) {
        $(`.system-issue-photo[data-phile-id='${data.phileId}']`).data('caption', response.description);
      },
      error() {
        alert("We're sorry something went wrong.");
      },
    });
  },

  deleteSystemIssuePhoto(e) {
    /* eslint-disable-next-line no-restricted-globals */
    confirm('Are you sure?');
    const data = e.target.dataset;
    const phileId = data.phileId;
    const issueId = data.systemIssueId;
    const controllerId = $('#inspection').data('controller-id');
    const inspectionId = $('#inspection').data('inspection-id');

    $.ajax({
      url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/inspections_system_issues/${issueId}/photos/${phileId}`,
      type: 'DELETE',
      dataType: 'json',
      success(response) {
        if (response.error) {
          alert(`Sorry there was a problem. Error: ${response.error}`);
        } else {
          $(`.system-issue-photo[data-phile-id='${phileId}']`).remove();
          $('.modal').modal('hide');
        }
      },
      error() {
        alert("We're sorry something went wrong.");
      },
    });
  },
});
export default InspectionSystemIssueView;
