const searchSiteInput = document.getElementById('site-home-search');

function searchSite(searchValue) {
  window.$.ajax({
    url: '/home/sites',
    type: 'GET',
    dataType: 'script',
    data: { site_name: searchValue },

  });
}
window.searchSite = searchSite;
searchSiteInput?.addEventListener('keyup', window.debounce(() => {
  searchSite(searchSiteInput.value);
  window.genericGoogleTagManagerEvent('homeSearchSite');
}, 500));

const searchResult = document.getElementById('search_site-result');

searchSiteInput?.addEventListener('focusout', (event) => {
  if (!event.relatedTarget?.classList?.contains('sites_result')) {
    searchResult.classList.add('tw-hidden');
  }
});

function redirectToSite(siteId) {
  window.location.href = `sites/${siteId}`;
}
window.redirectToSite = redirectToSite;
