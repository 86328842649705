function showMonthlyGoal(element) {
  const proposalsForm = document.querySelector('#set-user-goal-modal #proposals');
  const inspectionsForm = document.querySelector('#set-user-goal-modal #inspections');
  const setGoalButton = document.querySelector('#set-user-goal-modal .set-goal-btn');
  const selector = element.id.split('-')[1];
  setGoalButton.classList.remove('tw-btn-disabled');
  setGoalButton.classList.add('tw-button-primary');
  if (selector === 'proposals') {
    proposalsForm.classList.remove('tw-hidden');
    inspectionsForm.classList.add('tw-hidden');
  } else {
    inspectionsForm.classList.remove('tw-hidden');
    proposalsForm.classList.add('tw-hidden');
  }
}
window.showMonthlyGoal = showMonthlyGoal;

function showDecimalsOnInput(element) {
  element.value = parseFloat(element.value).toFixed(2);
}

const proposalsValueInput = document.querySelector('input[name=proposals-value]');
proposalsValueInput?.addEventListener('blur', (e) => {
  window.setInputConstraints(e.target, 9999999999999.99, 0, 0.01, 0);
  showDecimalsOnInput(e.target);
});

const proposalsAcceptedValueInput = document.querySelector('input[name=proposals-accepted-value]');
proposalsAcceptedValueInput?.addEventListener('blur', (e) => {
  window.setInputConstraints(e.target, 9999999999999.99, 0, 0.01, 0);
  showDecimalsOnInput(e.target);
});

const proposalsAcceptanceRateInput = document.querySelector('input[name=proposals-acceptance-rate]');
proposalsAcceptanceRateInput?.addEventListener('blur', (e) => window.setInputConstraints(e.target, 100, 0, 1, 0));

function toggleInspectionGoalsMinusButton(action) {
  const minusButton = document.querySelector('#set-user-goal-modal #inspections .ctn-stepper .btn-minus');
  if (action === 'disable') {
    minusButton.classList.add('tw-pointer-events-none');
    minusButton.classList.add('tw-text-gray-500');
    minusButton.classList.remove('tw-text-blue-500');
  } else {
    minusButton.classList.remove('tw-pointer-events-none');
    minusButton.classList.remove('tw-text-gray-500');
    minusButton.classList.add('tw-text-blue-500');
  }
}
window.toggleInspectionGoalsMinusButton = toggleInspectionGoalsMinusButton;

const inspectionGoalsInput = document.querySelector('input[name=inspection-goals]');
inspectionGoalsInput?.addEventListener('blur', (e) => {
  let value = e.target.value;
  if (value === '' || value < 0) value = 0;
  e.target.value = Math.round(value);
  if (e.target.value === '0') {
    toggleInspectionGoalsMinusButton('disable');
  } else {
    toggleInspectionGoalsMinusButton('enable');
  }
});

function changeInspectionGoalsInput(button) {
  let value = parseInt(inspectionGoalsInput.value, 10);
  value = button === 'plus' ? value + 1 : value - 1;
  if (value < 1) {
    value = 0;
    toggleInspectionGoalsMinusButton('disable');
  } else {
    toggleInspectionGoalsMinusButton('enable');
  }
  inspectionGoalsInput.value = value;
}
window.changeInspectionGoalsInput = changeInspectionGoalsInput;
