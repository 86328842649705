const findAircardInput = document.querySelector('#aircard-lookup input[type=number]');

findAircardInput?.addEventListener('input', () => {
  const button = document.querySelector('#aircard-lookup form button');
  if (parseInt(findAircardInput.value, 10) > 0) {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-cancel');
  } else {
    button.classList.remove('tw-btn-cancel');
    button.classList.add('tw-btn-disabled');
  }
});

const aircardSpreadsheetFileInputs = document.querySelectorAll('#aircard-spreadsheets input[type="file"]');

function bytesToSize(bytes) {
  let size = '';
  if (bytes < 1000000) {
    size = Math.round(bytes / 1000);
    size = `${size < 1 ? 1 : size}KB`;
  } else {
    size = `${Math.round(bytes / 1000000)}MB`;
  }
  return size;
}
window.bytesToSize = bytesToSize;

aircardSpreadsheetFileInputs.forEach((input) => {
  input.addEventListener('change', () => {
    const fileInfo = input.nextElementSibling;
    const fileSize = bytesToSize(input.files[0].size);
    const fileName = input.files[0].name;

    input.previousElementSibling.classList.add('tw-hidden');
    fileInfo.querySelector('.file-name').textContent = fileName;
    fileInfo.querySelector('.file-size').textContent = `(${fileSize})`;
    fileInfo.classList.remove('tw-hidden');
    fileInfo.nextElementSibling.classList.remove('tw-hidden');
  });
});

function returnToChooseAFile(sectionId) {
  const input = document.querySelector(`#${sectionId} form input[type=file]`);
  input.nextElementSibling.classList.add('tw-hidden');
  input.previousElementSibling.classList.remove('tw-hidden');
  input.nextElementSibling.nextElementSibling.classList.add('tw-hidden');
  document.querySelector(`#${sectionId} form`).reset();
}
window.returnToChooseAFile = returnToChooseAFile;

function toggleStepperMinusButtonAndSubmitButton(sectionId, action) {
  const minusButton = document.querySelector(`#${sectionId} .ctn-stepper .btn-minus`);
  const button = document.querySelector(`#${sectionId} form #submit`);
  if (action === 'disable') {
    minusButton.classList.add('tw-pointer-events-none');
    minusButton.classList.add('tw-text-gray-500');
    minusButton.classList.remove('tw-text-blue-500');
    button.classList.remove('tw-btn-cancel');
    button.classList.add('tw-btn-disabled');
  } else {
    minusButton.classList.remove('tw-pointer-events-none');
    minusButton.classList.remove('tw-text-gray-500');
    minusButton.classList.add('tw-text-blue-500');
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-cancel');
  }
}

const QRCodesStepperInput = document.querySelector('#generate #qr-codes input[name=count]');

QRCodesStepperInput?.addEventListener('input', (e) => {
  let value = e.target.value;
  if (value === '' || value < 0) value = 0;
  e.target.value = Math.round(value);
  if (e.target.value === '0') {
    toggleStepperMinusButtonAndSubmitButton('qr-codes', 'disable');
  } else {
    toggleStepperMinusButtonAndSubmitButton('qr-codes', 'enable');
  }
});

const cardCodesStepperInput = document.querySelector('#generate #plan-cards input[name=count]');

cardCodesStepperInput?.addEventListener('input', (e) => {
  let value = e.target.value;
  if (value === '' || value < 0) value = 0;
  e.target.value = Math.round(value);
  if (e.target.value === '0') {
    toggleStepperMinusButtonAndSubmitButton('plan-cards', 'disable');
  } else {
    toggleStepperMinusButtonAndSubmitButton('plan-cards', 'enable');
  }
});

function changeAircardSpreadsheetStepperInput(sectionId, button) {
  const stepperInput = sectionId === 'qr-codes' ? QRCodesStepperInput : cardCodesStepperInput;
  let value = parseInt(stepperInput.value, 10) || 0;
  value = button === 'plus' ? value + 1 : value - 1;
  if (value < 1) {
    value = 0;
    toggleStepperMinusButtonAndSubmitButton(sectionId, 'disable');
  } else {
    toggleStepperMinusButtonAndSubmitButton(sectionId, 'enable');
  }
  stepperInput.value = value;
}
window.changeAircardSpreadsheetStepperInput = changeAircardSpreadsheetStepperInput;

function updatePlanCardForm(planCardSelected) {
  const formInput = document.querySelector('#generate #plan-cards input[name=plan]');
  formInput.value = planCardSelected.id.split('-entries-')[1];
  const button = document.querySelector('#generate #plan-cards form #submit');
  if (parseInt(cardCodesStepperInput.value, 10) > 0) {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-cancel');
  } else {
    button.classList.remove('tw-btn-cancel');
    button.classList.add('tw-btn-disabled');
  }
}
window.updatePlanCardForm = updatePlanCardForm;

function checkForSelectedPlan() {
  const selectedPlan = document.querySelector('#generate #plan-cards input[name=plan]').value;
  const button = document.querySelector('#generate #plan-cards form #submit');
  if (parseInt(cardCodesStepperInput.value, 10) > 0 && selectedPlan !== '') {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-cancel');
  } else {
    button.classList.remove('tw-btn-cancel');
    button.classList.add('tw-btn-disabled');
  }
}
window.checkForSelectedPlan = checkForSelectedPlan;

function validateQrCodesInput() {
  const button = document.querySelector('#generate #qr-codes form #submit');
  if (parseInt(QRCodesStepperInput.value, 10) > 0) {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-cancel');
  } else {
    button.classList.remove('tw-btn-cancel');
    button.classList.add('tw-btn-disabled');
  }
}
window.validateQrCodesInput = validateQrCodesInput;
