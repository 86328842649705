function reRenderDateSelects(dateSelects) {
  dateSelects.forEach((dateSelect) => {
    const selects = dateSelect.querySelectorAll('select');
    const containerElement = dateSelect.querySelector('div');
    selects.forEach((select) => {
      const div = document.createElement('div');
      div.classList.add(...(select.dataset.divClass.split(' ')));
      div.appendChild(select);
      containerElement.appendChild(div);
    });
  });
}
window.reRenderDateSelects = reRenderDateSelects;
document.addEventListener('DOMContentLoaded', () => {
  const dateSelects = document.querySelectorAll('.sln-date-select');
  if (dateSelects.length > 0) {
    reRenderDateSelects(dateSelects);
  }
});
