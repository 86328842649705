import $ from 'jquery';
import bootbox from 'bootbox';

function getInputValue(element) {
  if (!element) {
    return null;
  }

  if (element.tagName.toLowerCase() === 'select' && element.multiple) {
    // For multiple-select
    return Array.from(element.selectedOptions).map((option) => option.value);
  }
  if (element.type === 'checkbox' || element.type === 'radio') {
    // For checkboxes and radios
    return element.checked ? element.value : null;
  }
  return element.value;
}

function buildUrlParams(data) {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else {
      params.append(key, value);
    }
  });
  return params;
}
function showFilterButtons(direction = true) {
  const createFilter = document.querySelector('#create_filter_report');
  const destroyFilter = document.querySelector('#destroy_filter_report');
  const updateFilter = document.querySelector('#update_filter_report');

  if (direction) {
    createFilter.classList.remove('hidden');
    destroyFilter.classList.add('hidden');
    updateFilter.classList.add('hidden');
  } else {
    createFilter.classList.add('hidden');
    destroyFilter.classList.remove('hidden');
    updateFilter.classList.remove('hidden');
  }
}

function updateRansackFilter() {
  const filters = {};
  document.querySelectorAll('.ransack:not(.no-save)').forEach((input) => {
    filters[input.id] = getInputValue(input);
  });
  localStorage.setItem(`local-${document.getElementById('saved').dataset.filters}`, JSON.stringify(filters));

  const baseUrl = window.location.href.split('?')[0];

  const allFilters = {};
  document.querySelectorAll('.ransack').forEach((input) => {
    if (input.value) {
      allFilters[`ransack[${input.id}]`] = getInputValue(input);
    }
  });
  const filterUrl = `${baseUrl}?${decodeURIComponent(buildUrlParams(allFilters))}`;

  window.history.pushState(null, null, filterUrl);

  window.table.ajax.reload();
}

function showFilterBoxes(withStyle = true) {
  const filters = {};
  document.querySelectorAll('.ransack').forEach((input) => {
    const properName = input.parentElement.querySelector('label').textContent;

    let valueName = '';
    if (input.tagName.toLowerCase() === 'select') {
      const values = input.selectedOptions;
      if (values.length > 0) {
        const vars = Array.from(values).map((e) => e.textContent);
        valueName = vars.join(', ');
      } else {
        valueName = input.selectedOptions[0]?.textContent || '';
      }
    } else if (input.tagName.toLowerCase() === 'input') {
      valueName = getInputValue(input);
    }

    filters[properName] = {
      id: input.id,
      value: getInputValue(input),
      value_name: valueName,
    };
  });
  const dtFilterbar = document.querySelector('div.dt_filterbar');
  dtFilterbar.innerHTML = '';

  if (!withStyle) {
    dtFilterbar.innerHTML = `<p>${window.I18n.data_tables.filter_by}</p>`;
  }

  let hasFilters = false;
  Object.entries(filters).forEach(([name, filter]) => {
    if (filter.value !== '') {
      hasFilters = true;
      dtFilterbar.innerHTML += `
        <div class="btn-group">
          <button type="button" class="${withStyle ? 'btn btn-primary btn-sm' : ''}">
            ${name}${withStyle || filter.id.includes('status_in', 'user_id_in', 'branch_id_in') ? ': ' : ' '}${filter.value_name}
          </button>
          <button type="button" class="${withStyle ? 'btn btn-sm btn-primary remove-filter' : 'remove-filter'}" data-filter-id="${filter.id}">
            ${withStyle ? 'X' : '<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.5L9 1.5M1 1.5L9 9.5" stroke="#0075A9" stroke-width="2"></svg>'}
          </button>
        </div>`;
    }
  });
  if (hasFilters) {
    dtFilterbar.style.display = 'block';
  } else {
    dtFilterbar.style.display = 'none';
  }
}

function removeFilter(e) {
  const filterId = e.currentTarget.dataset.filterId;
  document.getElementById(filterId).value = '';

  updateRansackFilter();
  showFilterBoxes();
}

function clearFilters() {
  document.querySelectorAll('.ransack:not(.no-save)').forEach((input) => {
    input.value = '';
  });
  showFilterButtons();
  window.table.ajax.reload();
}

function changeFilterPreset(e) {
  const id = e.target.value;

  if (id > 0) {
    $.ajax({
      type: 'GET',
      url: `/api/v0/reports/${id}.json`,
      success(data) {
        $.each(JSON.parse(data.json), (key, value) => {
          $(`#${key}`).val(value);
        });
        window.table.ajax.reload();
        showFilterButtons(false);
        // @TODO: save ransack to HTML5 storage
      },
    });
  } else {
    clearFilters();
    showFilterButtons();
  }
}

function createFilterReport() {
  const name = prompt('Name of filter preset');
  if (name.length >= 1) {
    const filters = {};
    document.querySelectorAll('.ransack:not(.no-save)').forEach((input) => {
      filters[input.id] = getInputValue(input);
    });

    $.ajax({
      type: 'POST',
      url: '/api/v0/reports.json',
      data: { json: filters, name, category: $('#saved').data('filters') },
      success(data) {
        $('#filter_presets').append(`<option value="${data.id}">${data.name}</option>`).val(data.id);
        showFilterButtons(false);
        alert('Filter Created');
      },
    });
  }
}

function updateFilterReport() {
  const filters = {};
  document.querySelectorAll('.ransack:not(.no-save)').forEach((input) => {
    filters[input.id] = getInputValue(input);
  });

  $.ajax({
    type: 'PUT',
    url: `/api/v0/reports/${$('#filter_presets').val()}.json`,
    data: { json: filters },
    success() {
      alert('Filter Updated');
    },
  });
}

function destroyFilterReport() {
  const select = document.getElementById('filter_presets');
  const id = select.value;

  $.ajax({
    type: 'DELETE',
    url: `/api/v0/reports/${id}.json`,
    success() {
      clearFilters();
      const optionToRemove = select.querySelector(`option[value="${id}"]`);
      optionToRemove.remove();
    },
  });
}

function updateColumnsSelection() {
  const newCheckedColumns = document.querySelectorAll('[type="checkbox"][id*="col-select-"]');
  newCheckedColumns.forEach((newCheckedColumn) => {
    const field = newCheckedColumn.dataset.columnName;
    window.COLUMNS[field].visible = newCheckedColumn.checked === true;
    window.table.column(`${field}:name`).visible(window.COLUMNS[field].visible);
  });

  const data = {};
  Object.entries(window.COLUMNS).forEach(([colName, colData]) => {
    data[colName] = { visible: colData.visible };
  });

  $.ajax({
    type: 'POST',
    url: '/api/v0/reports/update_by_category',
    data: {
      name: 'DataTables Columns',
      json: { columns: data },
      category: $('#saved').data('columns'),
    },
  });

  document.querySelectorAll('td:empty').forEach((td) => {
    td.innerHTML = '&nbsp;';
  });

  document.getElementById('tailwind-columns').classList.add('tw-hidden');
}

function fillBlankCells() {
  document.querySelectorAll('td:empty').forEach((td) => {
    td.innerHTML = '&nbsp;';
  });
}

// Helpers
function bool(v) {
  return v === 'true';
}

function errorPopup() {
  bootbox.alert('There was an error loading your data! Please try again');
}

// HTML Formatting
function toggleSidebar() {
  document.querySelectorAll('.sidebar-button, .sidebar-content').forEach((element) => {
    element.classList.toggle('hide');
  });
  document.querySelectorAll('.sidebar, .with-sidebar').forEach((element) => {
    element.classList.toggle('closed');
    element.classList.toggle('open');
  });
}

// Searching
function search() {
  window.table.search(document.querySelector('.dataTables_filter input').value).draw();
}

function delaySearch() {
  clearTimeout(window.timer);
  window.timer = setTimeout(search, 500);
}

// Exporting
function exportFile(e) {
  let csv = '';

  // Headers
  let j = 0;
  const columnLength = Object.keys(window.COLUMNS).length;
  while (j < columnLength) {
    if (window.table.column(j).visible()) {
      if (window.table.column(j).header().textContent === 'ID') {
        csv += `${window.table.column(j).header().textContent.toLowerCase()},`;
      } else {
        csv += `${window.table.column(j).header().textContent},`;
      }
    }
    j += 1;
  }
  csv += '%0A';

  // Actual data
  window.table.rows().data().each((row, index) => {
    let i = 0;
    while (i < columnLength) {
      if (window.table.row(index).column(i).visible()) {
        let value = window.table.column(i).data()[index];
        if (value && value.toString().charAt(0) === '<') {
          value = $(value).attr('export') || '';
        } else if (value && value.toString() === '&nbsp;') {
          value = '';
        }
        csv += `"${encodeURIComponent(value.toString().replace(/"/g, '""'))}",`;
      }
      i += 1;
    }
    csv += '%0A';
  });
  $(e.target.closest('a')).attr('href', `data:text/csv;charset=utf8,${csv}`).attr('download', 'report.csv');
}
function formatArray(currentValue, newValue) {
  if (Array.isArray(currentValue)) {
    currentValue.push(newValue);
    return currentValue;
  }
  return [currentValue, newValue];
}

function extractRansackFromUrl() {
  const regex = /[?&]ransack\[([a-z_]+)](?:\[\])?(?:=([^&#]*))?/g;
  const filters = {};
  const href = decodeURIComponent(window.location.href);
  let match = regex.exec(href);
  while (match !== null) {
    if (match[2]) {
      const value = match[2].replace(/\+/g, ' ');
      if (filters[match[1]]) {
        filters[match[1]] = formatArray(filters[match[1]], value);
      } else if (match[0].includes('[]')) {
        filters[match[1]] = [value];
      } else {
        filters[match[1]] = value;
      }
    }
    match = regex.exec(href);
  }

  return filters;
}

function toggleChevronIcon(tr) {
  const chevronDown = tr.querySelector('.heroicon-chevron-down');
  const chevronUp = tr.querySelector('.heroicon-chevron-up');

  if (chevronDown.classList.contains('tw-hidden')) {
    chevronDown.classList.remove('tw-hidden');
    chevronUp.classList.add('tw-hidden');
  } else {
    chevronDown.classList.add('tw-hidden');
    chevronUp.classList.remove('tw-hidden');
  }
}

export {
  updateRansackFilter, removeFilter, showFilterBoxes, changeFilterPreset, createFilterReport, updateFilterReport, destroyFilterReport,
  fillBlankCells, bool, delaySearch, search, errorPopup, toggleSidebar, exportFile, updateColumnsSelection,
  toggleChevronIcon, extractRansackFromUrl, getInputValue,
};
