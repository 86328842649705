import Backbone from 'backbone';
import $ from 'jquery';

const PriceListIndexView = Backbone.View.extend({
  el: 'body',
  events: {
    'click #create-new': 'toggleNewForm',
  },
  initialize() {
    console.log('Price List Index Initialized');
  },
  toggleNewForm() {
    $('#new-form').slideToggle();
  },

});
export default PriceListIndexView;
