import Backbone from 'backbone';
import $ from 'jquery';
import SiteAlertModel from '@assets/javascripts/backbone/models/user_site_alerts.js';

const SiteAlertsView = Backbone.View.extend({
  el: '#site-alerts',
  events: {
    'click .toggle-center': 'clickAlertSetting',
    'click .alert-setting input': 'eventToggle',
    'click .event-group-toggle': 'toggleEventGroupAlertsOnOrOff',
    'click .notification-type-toggle': 'toggleEventGroupAlertsInstantOrDaily',
    'click .toggle-event-group-column': 'toggleEventGroupColumnOnOrOff',
  },
  initialize() {
    $('#instant-tooltip, .instant-tooltip').popover({ html: true });
  },
  // ----------------------
  // TOGGLE EVENT GROUP ALERTS INSTANT OR DAILY
  // Updates all event groups to use notification_type daily or instantly based on click
  // ----------------------
  toggleEventGroupAlertsInstantOrDaily() {
    $('.notification-type-toggle').toggleClass('btn-success btn-default');
    const notificationType = $('.btn-success.notification-type-toggle').data('notification-type');

    $('.toggle-on.btn-success').each((_e, event) => {
      const siteId = $(event).data('site-id');

      const eventIds = $(event).data('event-ids');
      eventIds.forEach((eventId) => this.setAndSave(siteId, eventId, notificationType));
    });
  },

  // ----------------------
  // TOGGLE EVENT GROUP ALERTS ON OR OFF
  // Toggles event group notifications off or on
  // ----------------------
  toggleEventGroupAlertsOnOrOff(e) {
    let notificationType;
    const self = this;
    const target = $(e.target);

    // Adjust CSS and button text first
    target.parents('.event-group-container').find('.event-group-toggle').toggleClass('btn-success').toggleClass('btn-default');

    if (target.hasClass('sites-index')) {
      if (target.hasClass('btn-success')) {
        target.addClass('toggle-on').removeClass('toggle-off');
        target.text(target.data('toggle-on'));
      } else {
        target.addClass('toggle-off').removeClass('toggle-on');
        target.text(target.data('toggle-off'));
      }
    }

    // Send out the API calls
    if (target.hasClass('toggle-off')) {
      notificationType = null;
    } else {
      notificationType = $('.btn-success.notification-type-toggle').data('notification-type');
    }

    const eventIds = target.data('event-ids');
    const siteId = target.data('site-id');

    eventIds.forEach((eventId) => self.setAndSave(siteId, eventId, notificationType));
  },

  // ----------------------
  // TOGGLE EVENT GROUP COLUMN ON OR OFF
  // Toggles event group notifications off or on
  // ----------------------
  toggleEventGroupColumnOnOrOff(e) {
    let notificationType;
    const self = this;
    const target = $(e.target);
    const className = target.attr('event-group');

    // Send out the API calls
    if (target.hasClass('toggle-off')) {
      notificationType = null;
    } else {
      notificationType = $('.btn-success.notification-type-toggle').data('notification-type');
    }

    $.each($(`td.${className}`), (_, td) => {
      const toggleButton = $(td).children('.event-group-toggle');

      if (target.hasClass('toggle-on')) {
        toggleButton.addClass('btn-success').removeClass('btn-default');
        toggleButton.addClass('toggle-off').removeClass('toggle-on');
        toggleButton.text(toggleButton.data('toggle-on'));
      } else {
        toggleButton.removeClass('btn-success').addClass('btn-default');
        toggleButton.addClass('toggle-off').removeClass('toggle-on');
        toggleButton.text(toggleButton.data('toggle-off'));
      }

      const eventIds = toggleButton.data('event-ids');
      const siteId = toggleButton.data('site-id');

      eventIds.forEach((eventId) => self.setAndSave(siteId, eventId, notificationType));
    });
  },

  // ----------------------
  // CLICK ALERT SETTING (v2)
  // Change the setting
  // ----------------------
  clickAlertSetting(e) {
    const target = $(e.target);
    const siteId = target.parent().parent().parent().attr('site_id');
    const eventType = target.parent().attr('event_type');
    const settings = this.nextAlertType(target.text().trim());
    this.changeBox(target, settings);
    this.setAndSave(siteId, eventType, settings.notification_type);
  },

  // ----------------------
  // EVENT TOGGLE
  // Vertical toggle
  // ----------------------
  eventToggle(e) {
    const self = this;
    const target = $(e.target);
    const eventType = target.attr('event_type');
    const collection = $(`.toggle-center[event_type=${eventType}]`);

    collection.each((_e, alert) => {
      const siteId = $(alert).closest('tr').attr('site_id');
      const settings = self.setAlertType($(e.target).val());
      self.changeBox($(alert).find('span.label'), settings);
      self.setAndSave(siteId, eventType, settings.notification_type);
    });
  },

  // ----------------------
  // CHANGE BOX
  // ----------------------
  changeBox(box, settings) {
    box.removeClass().addClass(`label ${settings.class}`).text(settings.html);
  },

  // ----------------------
  // NEXT
  // Next notification_type to pick
  // ----------------------
  nextAlertType(text) {
    let something;
    if (text === 'Instant') {
      something = { notification_type: 1, html: 'Daily', class: 'label-success' };
    } else if (text === 'Daily') {
      something = { notification_type: null, html: 'Off', class: 'label-default' };
    } else {
      something = { notification_type: 0, html: 'Instant', class: 'label-primary' };
    }
    return something;
  },

  setAlertType(text) {
    let something;
    if (text === 'Instant') {
      something = { notification_type: 0, html: 'Instant', class: 'label-primary' };
    } else if (text === 'Daily') {
      something = { notification_type: 1, html: 'Daily', class: 'label-success' };
    } else {
      something = { notification_type: null, html: 'Off', class: 'label-default' };
    }
    return something;
  },

  // ----------------------
  // SET AND SAVE
  // Save the model
  // ----------------------
  setAndSave(siteId, eventType, notificationType) {
    const model = new SiteAlertModel(siteId);
    model.set('event_type', eventType);
    model.set('notification_type', notificationType);
    model.save();
  },
});
export default SiteAlertsView;
