import Reporting from '@assets/javascripts/backbone/lib/include/reporting.js';
import SmartLinkChartTable from '@assets/javascripts/backbone/lib/include/chart_reporting.js';
import Backbone from 'backbone';

class SmartLinkSeries {
  series = [];

  table = null;

  caches = {
    ids: {},
    forecasted: {},
    deficits: {},
  };

  constructor(unitLabel, range) {
    Object.assign(this, Backbone.Events);
    this.bindEvents();
    this.table = new SmartLinkChartTable(this, unitLabel, range);
  }

  asGoogleTable(series = this) {
    return this.table.forGoogleTable(series);
  }

  resetTable() {
    this.table.reset();
  }

  setRange(range) {
    this.range = range;
    this.table.range = this.range;
  }

  hasEventForDate(date) {
    return this.table.hasEventDate(date);
  }

  addEventForDate(date, event) {
    this.table.addEvent(date, event);
  }

  bindEvents() {
    this.on('series_added', () => {
      this.updateCache();
      this.table.updateCache(this);
    });

    this.on('series_removed', () => {
      this.updateCache();
      this.table.updateCache(this);
    });

    this.on('series_reset', () => {
      this.updateCache();
      this.table.updateCache(this);
    });
  }

  forEach(iterator, context) {
    this.series.forEach(iterator, context);
  }

  length() {
    return this.series.length;
  }

  iLength() {
    return this.length() - 1;
  }

  forecasted() {
    return this.series.filter((s) => s.forecasted);
  }

  withoutForecasted() {
    return this.series.filter((s) => !s.forecasted);
  }

  deficits() {
    return this.series.filter((s) => s.deficit);
  }

  withoutDeficits() {
    return this.series.filter((s) => !s.deficit);
  }

  withoutForecastedAndDeficits() {
    return this.series.filter((s) => !s.forecasted && !s.deficit);
  }

  allWithADeficit() {
    return this.series.filter((s) => SmartLinkSeries.onlyForecastedRunTime(s) && !s.deficit);
  }

  static onlyForecastedRunTime(series) {
    return series.type.match(/usage/) && series.key === 'seconds_ran' && series.forecasted;
  }

  cache(storeName, key, value) {
    this.caches[storeName][key] = value;
  }

  getCache(storeName, key) {
    return this.caches[storeName][key];
  }

  clearCache() {
    Object.keys(this.caches).forEach((cacheKey) => {
      Object.keys(this.caches[cacheKey]).forEach((cacheId) => {
        delete this.caches[cacheKey][cacheId];
      });
    });
  }

  updateCache() {
    this.clearCache();
    this.series.forEach((series, index) => {
      this.cache('ids', series.id, index);
      if (series.deficit) {
        this.cache('deficits', series.related_id, index);
      }
      if (series.forecasted && !series.deficit) {
        this.cache('forecasted', series.past_id, index);
      }
    });
  }

  setModelsForSeries(series, models) {
    this.forId(series.id).models = models;
  }

  setSeries(_series) {
    this.series = _series;
    this.trigger('series_reset');
  }

  bulkAdd(_series = [], _trigger = true) {
    _series.forEach((_s) => this.addOne(_s, false));
    if (_trigger) {
      this.trigger('series_added', _series);
    }
  }

  addOne(_series, _trigger = true) {
    _series.id = SmartLinkSeries.generateId();
    this.series.push(_series);
    if (_trigger) {
      this.trigger('series_added', _series);
    }
  }

  bulkRemove(_series, _trigger) {
    _series = _series ?? [];
    _series.forEach((_s) => this.removeOne(_s, false));
    if (_trigger) {
      this.trigger('series_removed');
    }
  }

  seriesWithAssociated(_series) {
    const forecasted = this.getForecasted(_series);
    const deficit = this.getDeficit(_series);
    return [_series, forecasted, deficit].filter(Boolean);
  }

  seriesWithAssociatedIndexes(_series) {
    return this.seriesWithAssociated(_series).map((s) => this.indexFor(s));
  }

  removeOne(_series, _trigger = true) {
    const index = this.indexFor(_series);
    const forecasted = this.indexFor(this.getForecasted(_series));
    let deficit = this.indexFor(this.getDeficit(_series));
    deficit = deficit || this.indexFor(this.getDeficit(this.atIndex(forecasted)));

    if (deficit !== null) {
      this.series.splice(deficit, 1);
    }
    if (forecasted !== null) {
      this.series.splice(forecasted, 1);
    }
    this.series.splice(index, 1);

    if (_trigger) {
      this.trigger('series_removed');
    }
  }

  indexFor(_series) {
    if (!_series) {
      return null;
    }

    let i = null;
    this.series.some((s, _i) => {
      const result = _series.id === s.id;
      if (result) {
        i = _i;
      }
      return result;
    });

    return i;
  }

  atIndex(index) {
    return this.series[index];
  }

  forId(id) {
    return this.series.find((s) => s.id === id);
  }

  getForecasted(_series) {
    return this.series.find((s) => s.past_id === _series.id);
  }

  getDeficit(_series) {
    return this.series.find((s) => _series && s && s.related_id === _series.id);
  }

  addAllDeficits() {
    const deficitSeries = this.allWithADeficit().map((_series) => SmartLinkSeries.newDeficitForSeries(_series));
    this.bulkAdd(deficitSeries);
    return deficitSeries;
  }

  static newDeficitForSeries(_series) {
    const s = { ..._series };
    const id = SmartLinkSeries.generateId();
    const deficitSeries = Object.assign(s, {
      related_id: s.id,
      id,
      color: '#FC5168',
      label: SmartLinkSeries.deficitLabel(_series),
      deficit: true,
    });

    if (deficitSeries.forecasted) {
      deficitSeries.color = Reporting.adjustLuminance(deficitSeries.color, -0.382);
    }

    return deficitSeries;
  }

  static deficitLabel(_series) {
    const type = (() => {
      switch (_series.key) {
        case 'high_temp':
          return 'High Temp';
        case 'low_temp':
          return 'Low Temp';
        case 'gallons':
          return 'Gallons';
        case 'seconds_ran':
        case 'calculated_runtime_seconds':
          return 'Run Time';
        default:
          return '';
      }
    })();

    const typeLabel = `${type} Deficit`;
    return Reporting.labelName(
      { name: 'Deficit' },
      _series.controller_id,
      _series.zone_number,
      _series.zone_name,
      typeLabel,
    );
  }

  // prettyPrintSeries() {
  //   let output = '';
  //   this.series.forEach((_series) => {
  //     let series = `Series\t     | ID: ${_series.id}    | Type: ${_series.type}    | Key: ${_series.key}\n`;
  //     const forecasted = this.getForecasted(_series);
  //     if (forecasted) {
  //       series += `\tForecasted | ID: ${forecasted.id} | Type: ${forecasted.type} | Key: ${forecasted.key}\n`;
  //     }
  //     const deficit = this.getDeficit(_series);
  //     if (deficit) {
  //       series += `\tDeficit    | ID: ${deficit.id}    | Type: ${deficit.type}    | Key: ${deficit.key}\n`;
  //     }
  //     output += '\n';
  //     output += series;
  //   });
  // }

  // protected

  static generateId() {
    return `_${Math.random().toString(36).substr(2, 9)}`;
  }
}
export default SmartLinkSeries;
