function showDeleteBranchModal(accountId, branchId, branchName) {
  const modal = document.getElementById('warning-modal');
  if (branchName) {
    modal.querySelector('.description').textContent = window.I18n.branch.delete.description.replace('%{branch_name}', branchName);
  }
  modal.querySelector('.tw-btn-action-red').onclick = () => {
    window.$.ajax({
      url: `/accounts/${accountId}/branches/${branchId}`,
      dataType: 'script',
      type: 'DELETE',
    });
  };
  modal.classList.remove('tw-hidden');
}
window.showDeleteBranchModal = showDeleteBranchModal;
