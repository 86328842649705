import { ReportWeatherCollection, ReportUsageCollection, ReportDeficitCollection } from '@assets/javascripts/backbone/collections/report_collection.js';
import moment from 'moment';
import $ from 'jquery';

const GOOGLE_CHART_OFFSET = 3;

const Reporting = {
  GOOGLE_CHART_OFFSET,

  select_collection(input) {
    switch (input.type) {
      case 'weather':
        return new ReportWeatherCollection(input.site_id);
      case 'usage':
      case 'forecasted_usage':
        return new ReportUsageCollection(input.site_id);
      case 'deficit':
        return new ReportDeficitCollection(input.site_id);
      default:
        return null;
    }
  },

  resize_table(table, length, offset = GOOGLE_CHART_OFFSET) {
    Object.keys(table).forEach((date) => {
      while (table[date].length < length * offset + offset) {
        table[date].push(null);
      }
    });
    return table;
  },

  rebuild_axes(axes, inputs) {
    axes.subjects = [];
    inputs.forEach((input) => {
      if (axes.subjects.indexOf(input.axis_subject) === -1) {
        axes.subjects.push(input.axis_subject);
      }
    });
    return axes;
  },

  adjustLuminance(hex, lum) {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');

    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    lum = lum || 0;

    let rgb = '#';
    let i = 0;
    let c = null;

    while (i < 3) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += (`00${c}`).substr(c.length);
      i += 1;
    }

    return rgb;
  },

  parse_date(datestring) {
    return this.convertDateToUTC(moment(datestring, 'YYYY-MM-DD HH:mm:ss UTC').toDate());
  },

  convertDateToUTC(date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  },

  parseSeriesDate(datestring) {
    return this.convertSeriesDateToUTC(moment(datestring).toDate());
  },

  convertSeriesDateToUTC(date) {
    if (-date.getTimezoneOffset() / 60 < 1) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1);
  },

  group_date(interval, d) {
    const date = this.parse_date(d).clone();
    switch (interval) {
      case 'w':
        return date.is().sunday() ? date : date.previous().sunday();
      case 'm':
        return date.moveToFirstDayOfMonth();
      case 'y':
        return date.set({ month: 0, day: 1 });
      default:
        return date;
    }
  },

  end_of_group_date(interval, d) {
    let date = d;
    if (typeof d === 'string') {
      date = this.parse_date(d).clone().clearTime();
    }
    switch (interval) {
      case 'w':
        return date.is().sunday() ? date : date.next().sunday();
      case 'm':
        return date.moveToLastDayOfMonth();
      case 'y':
        return date.set({ month: 11, day: 31 });
      default:
        return date;
    }
  },

  nextIntervalDate(interval, date, n) {
    let retdate = null;
    switch (interval) {
      case 'w':
        retdate = this.group_date(interval, date).addWeeks(n);
        break;
      case 'm':
        retdate = this.group_date(interval, date).addMonths(n);
        break;
      case 'y':
        retdate = this.group_date(interval, date).addYears(n);
        break;
      default:
        retdate = date.addDays(n);
    }

    if (retdate.getTimezoneOffset() > -600) {
      return retdate.set({ hour: 0 });
    }
    return retdate;
  },

  number_of_days(dateStart, dateEnd) {
    return Math.floor((dateEnd - dateStart) / 86400000);
  },

  set_unit_labels(unitLabel, tempUnits, volumeMeasure) {
    if (parseInt(volumeMeasure, 10) === 1) {
      unitLabel.integer.usage = 1;
      unitLabel.long.usage = 'Liters';
      unitLabel.short.usage = 'Liters';
      unitLabel.long.deficit = 'Millimeters';
      unitLabel.short.deficit = 'mm';
      unitLabel.integer.gpm = 1;
      unitLabel.long.gpm = 'Liters Per Minute';
      unitLabel.short.gpm = 'LPM';
    } else {
      unitLabel.integer.usage = 2;
      unitLabel.long.usage = 'Gallons';
      unitLabel.short.usage = 'gal';
      unitLabel.long.deficit = 'Inches';
      unitLabel.short.deficit = 'in';
      unitLabel.integer.gpm = 2;
      unitLabel.long.gpm = 'Gallons Per Minute';
      unitLabel.short.gpm = 'GPM';
    }

    if (tempUnits === 'c') {
      unitLabel.integer.weather = 1;
      unitLabel.long.weather = 'Celsius';
      unitLabel.short.weather = '&#8451';
    } else {
      unitLabel.integer.weather = 2;
      unitLabel.long.weather = 'Fahrenheit';
      unitLabel.short.weather = '&#8457;';
    }

    return unitLabel;
  },

  convertValue(key, value, unitLabel) {
    if ((key === 'high_temp' || key === 'low_temp') && unitLabel.integer.weather === 2 && value !== null) {
      value = ((value * 9.0) / 5.0) + 32.0;
    } else if (key === 'inches' && unitLabel.integer.usage === 1 && value > 0) {
      value *= 25.4;
      return value ? parseInt(value, 10) : null;
    } else if (key === 'gallons' && unitLabel.integer.usage === 1 && value > 0) {
      value *= 3.7854;
    } else if (key === 'seconds_ran' || key === 'calculated_runtime_seconds') {
      value /= 60.0;
      return value ? parseInt(value, 10) : null;
    } else if (key === 'realtime_average_flow' && unitLabel.integer.gpm === 1 && value > 0) {
      value *= 3.78541178;
    }

    return value ? parseFloat(value) : null;
  },

  total(keytable, n) {
    let sum = 0;

    Object.keys(keytable).forEach((key) => {
      sum += keytable[key].values[n] ? parseFloat(keytable[key].values[n]) : 0;
    });
    return sum > 0 ? sum.toFixed(0) : null;
  },

  average(keytable, n) {
    let sum = 0;
    let count = 0;
    Object.keys(keytable).forEach((key) => {
      if (keytable[key].values[n]) {
        sum += parseFloat(keytable[key].values[n]);
        count += 1;
      }
    });
    return count > 0 ? parseFloat(sum / count).toFixed(2) : null;
  },

  build_params(range, input) {
    return {
      range_start: this.stringThisDate(range.start),
      range_end: this.stringThisDate(range.end),
      interval: range.interval,
      site_id: input.site_id,
      controller_id: input.controller_id ? [input.controller_id] : null,
      zone_number: input.zone_number ? [input.zone_number] : null,
    };
  },

  find_site(sites, id) {
    return sites[String(id)];
  },

  find_controller(site, id) {
    const result = $.grep(site.controllers, (e) => e.id === id);
    return result.length === 1 ? result[0] : null;
  },

  labelName(site, controllerId = null, zoneNumber = null, zoneName = null, type = '') {
    controllerId = parseInt(controllerId, 10);
    zoneNumber = parseInt(zoneNumber, 10);

    let string = site.name.substring(0, 50);
    if (controllerId && site.controllers) {
      const controller = this.find_controller(site, controllerId);
      string += ` | ${controller.name}`;
    }

    if (zoneName) {
      string += ` | ${zoneName}`;
    } else if (zoneNumber) {
      string += ` | Z${zoneNumber}`;
    }

    if (type) {
      string += ` | ${type}`;
    }

    return string;
  },

  string_number_or_null(number) {
    return number > 0 ? String(number) : null;
  },

  reset_chart_selection() {
    this.selected.col = null;
    this.selected.row = null;
  },

  events_string(found) {
    if (found > 1) {
      return `${String(found)} Events`;
    }
    if (found === 1) {
      return `${String(found)} Event`;
    }
    return null;
  },

  calculation_string(num, string = '') {
    if (num > 0) {
      const commafied = String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      return `${commafied} ${string}`;
    }
    return null;
  },

  chart_position_of_input(i) {
    return i ? (parseInt(i, 10) + 1) * 3 : null;
  },

  input_position_of_chart(i) {
    return i ? Math.floor(parseInt(i, 10) / 3 - 1) : null;
  },

  is_unique_color(color) {
    let unique = true;
    $.each(this.dataInput, (index, input) => {
      if (color === input.color) {
        unique = false;
      }
    });
    return unique;
  },

  is_something_selected() {
    return this.selected.col;
  },

  stringThisDate(date) {
    const now = new Date(date);
    if (-now.getTimezoneOffset() / 60 < 1) {
      return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()).toString('yyyy-MM-dd');
    }
    return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1).toString('yyyy-MM-dd');
  },

  localized_date_for_date_str(dateStr) {
    const timeOffset = new Date().getTimezoneOffset();
    const date = new Date(dateStr);
    date.setMinutes(date.getMinutes() + timeOffset);
    return date;
  },

  print_this_date(date, daily = false) {
    const now = new Date(date);
    const userYimezone = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const mNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = userYimezone.getDate();
    const formattedMonth = mNames[userYimezone.getMonth()];
    const year = userYimezone.getFullYear();

    let result;
    if (daily) {
      result = `${day} ${formattedMonth} ${date}`;
    } else {
      result = `${formattedMonth} ${date}, ${year}`;
    }

    return result;
  },

  replace_underscores_with_spaces(word) {
    return word.replace(/_/g, ' ');
  },

  capitalize_each_word(string) {
    const words = string.split(' ');
    let newString = '';
    words.forEach((word) => {
      newString += `${word.substring(0, 1).toUpperCase()}${word.substring(1, word.length)} `;
    });
    return newString;
  },

  set_input_key(type) {
    switch (type) {
      case 'weather':
        return $(this.ui.input_weather_type).val();
      case 'usage':
        return 'units';
      case 'forecast':
        return 'forecast_water';
      case 'actual':
        return 'actual_water';
      default:
        return null;
    }
  },

  icon_for_type(type) {
    switch (type) {
      case 'weather':
        return 'icon-fire';
      case 'usage':
        return 'icon-tint';
      case 'forecasted_usage':
        return 'icon-eye-open';
      case 'actual':
        return 'icon-leaf';
      default:
        return 'icon-question-sign';
    }
  },

  what_axis_label(labels, subject) {
    switch (subject) {
      case 'time':
        return 'Run Time (Minutes)';
      case 'gpm':
        return labels.long.gpm;
      case 'usage':
        return labels.long.usage;
      case 'gallons':
        return labels.long.usage;
      case 'temp':
        return labels.long.weather;
      default:
        return null;
    }
  },
};

export default Reporting;
