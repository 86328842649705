import Reporting from '@assets/javascripts/backbone/lib/include/reporting.js';

class SmartLinkChartTable {
  entryCellCount = 3;

  range = null;

  annotations = false;

  dateTable = {};

  eventsTable = {};

  constructor(series, unitLabel, range) {
    this.series = series;
    this.unitLabel = unitLabel;
    this.range = range;
    this.buildDatesFromRange();
  }

  reset() {
    this.dateTable = {};
    this.eventsTable = {};
    this.range = null;
  }

  rowSize(table = this.dateTable) {
    return Math.max(...Object.values(table).map((row) => row.length));
  }

  forGoogleTable(series = this.series.series) {
    this.buildFromSeries(series);
    const self = this;
    if (this.dateTablePresent()) {
      const table = Object.entries(self.dateTable).map(([date, column]) => {
        const parsedDate = Reporting.parseSeriesDate(date);
        if (self.annotations && self.eventsTable[date] && self.eventsTable[date].length > 0) {
          const eventText = self.eventsTable[date].map((e) => e.eventText).join(', ');
          return [parsedDate, String(self.eventsTable[date].length), eventText, ...column.flat()];
        }
        return [parsedDate, null, null, ...column.flat()];
      });

      return this.resizeTable(table);
    }
    return [];
  }

  dateTablePresent() {
    return this.dateTable && Object.keys(this.dateTable).length !== 0;
  }

  resizeTable(table) {
    return Reporting.resize_table(
      table,
      (this.rowSize(table) - this.entryCellCount) / this.entryCellCount,
      this.entryCellCount,
    );
  }

  buildFromSeries(s = this.series.series) {
    this.dateTable = {};
    this.buildDatesFromRange();

    Object.entries(this.dateTable).forEach(([date]) => {
      s.forEach((_series) => {
        if (_series?.models?.length) {
          const model = _series.models.find((m) => m.get('date') === date);
          if (model && _series.key) {
            this.dateTable[date] ||= [];
            if (_series.deficit) {
              const val = model.get('scheduled_run_time_seconds') > model.get('seconds_ran')
                ? 0
                : Reporting.convertValue(_series.key, model.get('seconds_ran') - model.get('scheduled_run_time_seconds'), this.unitLabel);

              const entry = [val, null, null];
              this.dateTable[date].push(entry);
            } else {
              this.dateTable[date].push(this.rowEntriesForModel(model, _series.key));
            }
          } else {
            this.dateTable[date] ||= [];
            this.dateTable[date].push([null, null, null]);
          }
        } else {
          this.dateTable[date] ||= [];
          this.dateTable[date].push([null, null, null]);
        }
      });
    });
  }

  seriesIndexWithAssociatedForIndex(index) {
    const series = this.series.atIndex(index);
    const forecasted = this.series.indexFor(this.series.getForecasted(series));
    const deficit = this.series.indexFor(this.series.getDeficit(series));

    return [index, forecasted, deficit].filter((val) => val !== undefined && val !== null);
  }

  buildDatesFromRange() {
    if (this.range) {
      let loopDate = new Date(this.range.start);
      while (loopDate <= this.range.end) {
        this.emptyColumnForDate(loopDate);
        loopDate = Reporting.nextIntervalDate(this.range.interval, loopDate, 1);
      }
    }
  }

  emptyColumnForDate(date) {
    this.dateTable[Reporting.stringThisDate(date)] = [];
  }

  rowEntriesForModel(model, key) {
    const val = Reporting.convertValue(key, model.get(key), this.unitLabel);
    return [val, null, null];
  }

  hasEventDate(date) {
    return this.eventsTable[date];
  }

  setRange(_range) {
    this.range = _range;
    this.reset();
    this.buildDatesFromRange();
  }

  addEvent(date, event) {
    this.eventsTable[date] ||= [];
    this.eventsTable[date].push(event);
  }

  updateCache(_series) {
    this.series = _series;
    this.buildFromSeries();
  }
}
export default SmartLinkChartTable;
