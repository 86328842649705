function setInputConstraints(element, maxValue, minValue, step, defaultValue) {
  if (element.value > maxValue) { element.value = maxValue; return; }
  if (element.value === '') { element.value = defaultValue; return; }
  if (element.value < minValue) { element.value = minValue; return; }
  element.value = Math.round(element.value / step) * step;
}
window.setInputConstraints = setInputConstraints;

function setInputPercentageConstraints(event) {
  setInputConstraints(event.target, parseInt(event.target.max, 10), parseInt(event.target.min, 10), parseInt(event.target.step, 10), '');
}

function setDefaultInputBehavior(event) {
  event.target.classList.toggle('input-typed', event.target.value.length > 0);
  const label = event.target.parentElement.previousElementSibling
                || event.target.parentElement.parentElement.previousElementSibling;

  if (event.target.value.length > 0) {
    label.classList.add('selected-label');
    event.target.nextElementSibling.classList.remove('tw-hidden');
  } else {
    label.classList.remove('selected-label');
    event.target.nextElementSibling.classList.add('tw-hidden');
  }
}

function deleteCalendar(event) {
  const deleteElement = event.target.closest('span');
  const container = deleteElement.parentElement;
  const input = deleteElement.previousElementSibling;
  deleteElement.classList.add('tw-hidden');
  container.classList.add('tw-hidden');
  input.value = '';
  input.classList.remove('input-typed');

  window.datesLabelValidation('.ctn-omit-times .blocked-dates');
}

const inputPercentages = document.querySelectorAll('.input-percentage');
inputPercentages.forEach((input) => input.addEventListener('blur', setInputPercentageConstraints));

const defaultInputs = document.querySelectorAll('.input');
defaultInputs.forEach((input) => input.addEventListener('blur', setDefaultInputBehavior));

const calendarInputsDelete = document.querySelectorAll('.calendar span');
calendarInputsDelete.forEach((calendar) => calendar.addEventListener('click', deleteCalendar));
