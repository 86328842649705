function getConnectivityHealthTrend() {
  const loader = document.querySelector('#portfolio_health_trend #loading');
  document.querySelector('#portfolio_health_trend #empty').classList.add('tw-hidden');
  document.querySelector('#portfolio_health_trend #chart').classList.add('tw-hidden');
  loader.classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/portfolio_health_trend',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'script',
    success: () => {
      loader.classList.add('tw-hidden');
    },
  });
}
window.getConnectivityHealthTrend = getConnectivityHealthTrend;

function convertChartStringDate(input) {
  const monthNames = {
    JAN: window.I18n.date.month_names[1],
    FEB: window.I18n.date.month_names[2],
    MAR: window.I18n.date.month_names[3],
    APR: window.I18n.date.month_names[4],
    MAY: window.I18n.date.month_names[5],
    JUN: window.I18n.date.month_names[6],
    JUL: window.I18n.date.month_names[7],
    AUG: window.I18n.date.month_names[8],
    SEP: window.I18n.date.month_names[9],
    OCT: window.I18n.date.month_names[10],
    NOV: window.I18n.date.month_names[11],
    DEC: window.I18n.date.month_names[12],
  };
  let day; let fullMonthName; let month;

  if (typeof input === 'string') {
    [month, day] = input.split('/');
    fullMonthName = window.I18n.date.month_names[parseInt(month, 10)];
  } else {
    day = input[0];
    month = input[1].toUpperCase();
    fullMonthName = monthNames[month];
  }
  return `${fullMonthName} ${window.addOrdinalSuffix(day)}`;
}

window.convertChartStringDate = convertChartStringDate;
