const siteCards = [];
let totalSites = 0;

let currentIndex = 0;
let currentPage = 1;

function appendSiteCards(sites) {
  sites.forEach((site) => siteCards.push(site));
}

function invalidZipCodeDesign(id) {
  return `<svg class="tw-w-6 tw-h-6 sm:tw-w-8 sm:tw-h-6 tw-mt-4 sm:tw-mt-3" width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2111 1.95929C11.4345 -0.215596 14.5658 -0.215597 15.7892 1.95929L24.7177 17.8322C25.9176 19.9653 24.3761 22.601 21.9286 22.601H4.07166C1.62422 22.601 0.0827338 19.9653 1.28262 17.8322L10.2111 1.95929ZM14.6 17.8012C14.6 18.6848 13.8837 19.4012 13 19.4012C12.1164 19.4012 11.4 18.6848 11.4 17.8012C11.4 16.9175 12.1164 16.2012 13 16.2012C13.8837 16.2012 14.6 16.9175 14.6 17.8012ZM13 5.00117C12.1164 5.00117 11.4 5.71752 11.4 6.60117V11.4012C11.4 12.2848 12.1164 13.0012 13 13.0012C13.8837 13.0012 14.6 12.2848 14.6 11.4012V6.60117C14.6 5.71752 13.8837 5.00117 13 5.00117Z" fill="#A1272E"/>
          </svg>
          <p class="tw-m-0 tw-text-center tw-mt-2 tw-font-normal tw-text-xs sm:tw-text-sm tw-text-gray-800">${window.I18n.home.today_forecast.card.incorrect_zip_code}</p>
          <a href="/sites/${id}/edit" class="hover:tw-no-underline hover:tw-cursor-pointer tw-button tw-button-primary tw-p-1 tw-w-full tw-text-center tw-text-sm sm:tw-text-base" style="margin-top: 20px !important">${window.I18n.edit}</a>`;
}

function chooseWeatherIcon(avgHumidity) {
  if (parseFloat(avgHumidity) >= 80) {
    return `<svg class="tw-w-6 tw-h-6 sm:tw-w-8 sm:tw-h-6 tw-mt-4 sm:tw-mt-3" fill="none" height="32" viewbox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 15.3333C4 18.2789 10 22 12 22C14 22 15 19 16 19C17 19 18 22 20 22C22 22 28 17.6819 28 14C28 10.3181 25.0152 7.33333 21.3333 7.33333C21.2889 7.33333 21.2445 7.33377 21.2002 7.33464C20.5831 4.291 17.8924 2 14.6667 2C10.9848 2 8 4.98477 8 8.66667C8 9.16912 8.05559 9.6586 8.16094 10.1293C5.77942 10.6635 4 12.7906 4 15.3333Z" fill="#0075A9"></path>
              <path d="M17.9769 26.0636C18.4255 24.652 16.9835 22.0002 15.9835 22C14.9835 21.9998 13.6404 24.651 14.089 26.0636C14.54 27.4839 17.5259 27.4839 17.9769 26.0636Z" fill="#0075A9"></path>
              <path d="M11.9769 28.0636C12.4255 26.652 10.9835 24.0002 9.98347 24C8.98346 23.9998 7.6404 26.651 8.08898 28.0636C8.53998 29.4839 11.5259 29.4839 11.9769 28.0636Z" fill="#0075A9"></path>
              <path d="M23.9769 28.0636C24.4255 26.652 22.9835 24.0002 21.9835 24C20.9835 23.9998 19.6404 26.651 20.089 28.0636C20.54 29.4839 23.5259 29.4839 23.9769 28.0636Z" fill="#0075A9"></path>
            </svg>`;
  }
  return `<svg class="tw-w-6 tw-h-6 sm:tw-w-8 sm:tw-h-6 tw-mt-4 sm:tw-mt-3" width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.375 24.332C9.95875 24.332 8 22.2427 8 19.6654C8 17.2542 9.71433 15.2701 11.9138 15.0243C11.8069 14.5905 11.75 14.135 11.75 13.6654C11.75 10.7198 13.9886 8.33203 16.75 8.33203C19.0986 8.33203 21.0689 10.0592 21.6056 12.3877C21.8572 12.351 22.114 12.332 22.375 12.332C25.4816 12.332 28 15.0183 28 18.332C28 21.6457 25.4816 24.332 22.375 24.332H12.375Z" fill="#0075A9"/>
              <path d="M9.63809 0.332031C10.3035 0.332031 10.8429 0.871421 10.8429 1.53679V2.74155C10.8429 3.40693 10.3035 3.94632 9.63809 3.94632C8.97272 3.94632 8.43333 3.40693 8.43333 2.74155V1.53679C8.43333 0.871421 8.97272 0.332031 9.63809 0.332031Z" fill="#0075A9"/>
              <path d="M8.63737 14.6851C9.26083 14.1731 10.024 13.8361 10.8579 13.7455C10.7525 13.3297 10.6964 12.8932 10.6964 12.4431C10.6964 10.261 12.0151 8.39822 13.8712 7.66511C13.0538 6.16726 11.4646 5.15108 9.63809 5.15108C6.97661 5.15108 4.81905 7.30864 4.81905 9.97013C4.81905 12.2885 6.45615 14.2245 8.63737 14.6851Z" fill="#0075A9"/>
              <path d="M16.4532 4.85869L15.6013 5.71059C15.1308 6.18107 14.368 6.18107 13.8975 5.71059C13.427 5.2401 13.427 4.47728 13.8975 4.0068L14.7494 3.1549C15.2199 2.68441 15.9827 2.68441 16.4532 3.1549C16.9237 3.62539 16.9237 4.3882 16.4532 4.85869Z" fill="#0075A9"/>
              <path d="M5.37869 5.71061C4.9082 6.1811 4.14539 6.1811 3.6749 5.71061L2.823 4.85871C2.35251 4.38822 2.35251 3.62541 2.823 3.15492C3.29349 2.68443 4.0563 2.68443 4.52679 3.15492L5.37869 4.00682C5.84918 4.47731 5.84918 5.24012 5.37869 5.71061Z" fill="#0075A9"/>
              <path d="M5.37858 15.9333L4.52668 16.7852C4.05619 17.2557 3.29338 17.2557 2.82289 16.7852C2.3524 16.3147 2.3524 15.5519 2.82289 15.0814L3.67479 14.2295C4.14527 13.759 4.90809 13.759 5.37858 14.2295C5.84906 14.7 5.84906 15.4628 5.37858 15.9333Z" fill="#0075A9"/>
              <path d="M3.61429 9.97013C3.61429 10.6355 3.07489 11.1749 2.40952 11.1749H1.20476C0.53939 11.1749 0 10.6355 0 9.97013C0 9.30475 0.53939 8.76536 1.20476 8.76536H2.40952C3.07489 8.76536 3.61429 9.30475 3.61429 9.97013Z" fill="#0075A9"/>
            </svg>`;
}

function validZipCodeDesign(avgHumidity, maxTempF, minTempF, controllers) {
  return `${chooseWeatherIcon(avgHumidity)}
          <p class="tw-m-0 tw-text-gray-600 tw-font-semibold tw-text-xs sm:tw-text-base tw-mt-2 tw-text-center">${window.I18n.home.today_forecast.card.rain.replace('%{count}', avgHumidity)}</p>
          <div class="tw-flex tw-justify-center tw-items-center tw-font-normal tw-gap-3"  style="margin-top: 20px !important">
            <p class="tw-m-0 tw-text-gray-600 tw-text-xs sm:tw-text-base">${maxTempF}°</p>
            <p class="tw-m-0 tw-text-gray-500 tw-text-xs">|</p>
            <p class="tw-m-0 tw-text-gray-500 tw-text-xs sm:tw-text-base">${minTempF}°</p>
          </div>
          <p class="tw-m-0 tw-text-gray-600 tw-font-normal tw-text-sm sm:tw-text-base tw-text-center" style="margin-top: 20px !important">
            ${controllers === 1 ? window.I18n.home.today_forecast.card.controllers.one
    : window.I18n.home.today_forecast.card.controllers.other.replace('%{count}', controllers)}
          </p>`;
}

function createSiteCardElement(siteName, avgHumidity, maxTempF, minTempF, controllers, id) {
  const temp = document.createElement('template');
  const html = `<div class="forecast-card tw-flex tw-flex-col tw-justify-between tw-basis-full tw-items-center tw-p-3 tw-rounded-xl tw-bg-gray-100">
            <p class="site-name tw-m-0 tw-text-gray-600 tw-font-normal tw-text-sm sm:tw-text-base tw-text-center tw-w-full tw-h-12">${siteName}</p>
            ${avgHumidity === null ? invalidZipCodeDesign(id) : validZipCodeDesign(avgHumidity, maxTempF, minTempF, controllers)}
          </div>`;
  temp.innerHTML = html;
  return temp.content.firstChild;
}

function renderSiteCards(cards) {
  let indexes = [];
  let last = false;
  for (let i = 0; i < cards; i += 1) {
    let siteCardsIndex = currentIndex + i;
    if (siteCardsIndex >= totalSites) {
      siteCardsIndex -= cards;
      last = true;
    }
    indexes.push(siteCardsIndex);
  }
  indexes = indexes.sort((a, b) => a - b);
  if (last) currentIndex = indexes[0];
  const siteCardsContainer = document.getElementById('site-cards-container');
  siteCardsContainer.innerHTML = '';
  indexes.forEach((index) => {
    const siteData = siteCards[index];
    const card = createSiteCardElement(siteData.site_name, siteData.avg_humidity, siteData.max_temp_f, siteData.min_temp_f, siteData.controllers, siteData.id);
    siteCardsContainer.append(card);
  });
}

function showHiddenButtonsFor(className) {
  const buttons = document.querySelectorAll(className);
  buttons.forEach((button) => button.classList.remove('tw-hidden'));
}

function getTodayForecastWidgetData(cards, page = 1, perPage = 12, firstCall = true) {
  console.log('executing request');
  window.$.ajax({
    url: 'api/v2/sites/today_forecast',
    type: 'GET',
    dataType: 'json',
    data: { page, per_page: perPage },
    success: (response) => {
      const loadAnimationContainer = document.querySelector('#today-forecast .loading_data');
      if (loadAnimationContainer) window.removeLoadAnimation('#today-forecast');
      appendSiteCards(response.data.sites_data);
      if (firstCall) {
        totalSites = response.meta.total_sites;
        if (totalSites <= 2) {
          renderSiteCards(totalSites);
        } else if (totalSites <= 4) {
          if (window.innerWidth < 576) {
            renderSiteCards(2);
            showHiddenButtonsFor('.today-forecast-btn-mobile');
          } else {
            renderSiteCards(totalSites);
          }
        } else if (window.innerWidth < 576) {
          renderSiteCards(2);
          showHiddenButtonsFor('.today-forecast-btn-mobile');
        } else {
          renderSiteCards(3);
          showHiddenButtonsFor('.today-forecast-btn-desktop');
        }
      } else {
        renderSiteCards(cards);
      }
    },
  });
}
window.getTodayForecastWidgetData = getTodayForecastWidgetData;

function toggleTodayForecastButtons(cards, conditional, index) {
  if (cards === 2) {
    const mobileButtons = document.querySelectorAll('.today-forecast-btn-mobile');
    if (conditional) {
      mobileButtons[index].classList.add('tw-pointer-events-none');
      mobileButtons[index].querySelector('path').style.fill = '#E0E0E0';
    } else {
      mobileButtons[index].classList.remove('tw-pointer-events-none');
      mobileButtons[index].querySelector('path').style.fill = '#0075A9';
    }
  } else {
    const desktopButtons = document.querySelectorAll('.today-forecast-btn-desktop');
    if (conditional) {
      desktopButtons[index].classList.add('tw-pointer-events-none');
      desktopButtons[index].querySelector('path').style.fill = '#E0E0E0';
    } else {
      desktopButtons[index].classList.remove('tw-pointer-events-none');
      desktopButtons[index].querySelector('path').style.fill = '#0075A9';
    }
  }
}

function togglePreviousButton(cards) {
  toggleTodayForecastButtons(cards, currentIndex === 0, 0);
}

function toggleNextButton(cards) {
  toggleTodayForecastButtons(cards, totalSites - currentIndex <= cards, 1);
}

function seeNextSiteCards(cards) {
  currentIndex += cards;
  togglePreviousButton(cards);
  if (currentIndex + cards >= siteCards.length && currentIndex + cards < totalSites) {
    currentPage += 1;
    getTodayForecastWidgetData(cards, currentPage, 12, false);
  } else {
    renderSiteCards(cards);
  }
  toggleNextButton(cards);
}
window.seeNextSiteCards = seeNextSiteCards;

function seePreviousSiteCards(cards) {
  currentIndex -= cards;
  if (currentIndex < 0) currentIndex = 0;
  togglePreviousButton(cards);
  renderSiteCards(cards);
  toggleNextButton(cards);
}
window.seePreviousSiteCards = seePreviousSiteCards;
