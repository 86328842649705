function removeWarningModal(element) {
  const modal = element.parentElement.parentElement.parentElement;
  modal.classList.add('tw-hidden');
}
window.removeWarningModal = removeWarningModal;

function cleanSnackbars() {
  document.getElementById('warning-snackbar')?.classList.add('tw-hidden');
  document.getElementById('success-snackbar')?.classList.add('tw-hidden');
  document.getElementById('info-snackbar')?.classList.add('tw-hidden');
}
window.cleanSnackbars = cleanSnackbars;
