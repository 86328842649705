function getSiteIdFromUrl() {
  const splittedUrl = window.location.href.split('/');
  const siteId = splittedUrl[splittedUrl.length - 2];
  return siteId;
}
window.getSiteIdFromUrl = getSiteIdFromUrl;

function getParamsForUrl() {
  const search = document.getElementById('search-site_user').value;
  const urlValues = new URL(window.location.href);
  const page = urlValues.searchParams.get('page');
  const perPage = urlValues.searchParams.get('per_page');
  return [search, page, perPage];
}
window.getParamsForUrl = getParamsForUrl;

function cancelInvitationFromSite(inviteId) {
  window.$.ajax({
    url: `/sites/${getSiteIdFromUrl()}/invites/${inviteId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}

function showCancelInvitationModal(email, inviteId) {
  const warningModal = document.getElementById('cancel-invitation-modal');
  warningModal.querySelector('.description').innerHTML = window.I18n.site_users.index.cancel_invitation_modal.description.replace('%{email}', email);
  warningModal.querySelector('.description').style.wordWrap = 'anywhere';
  warningModal.querySelector('.tw-btn-action-red').onclick = () => cancelInvitationFromSite(inviteId);
  warningModal.classList.remove('tw-hidden');
}
window.showCancelInvitationModal = showCancelInvitationModal;
