import Backbone from 'backbone';
import $ from 'jquery';
import SystemIssueThumbnailTemplate from '@assets/javascripts/backbone/templates/inspections/system_issue_photo_thumbnail.ejs';
import thumbnailTemplate from '@assets/javascripts/backbone/templates/inspections/photo_thumbnail.ejs';
import InspectionPhotoModel from '@assets/javascripts/backbone/models/inspections/inspections_photos.js';
import 'blueimp-file-upload/js/jquery.fileupload.js';

const InspectionEditPhotosView = Backbone.View.extend({
  el: '#controller_inspections-edit,#controller_inspections-show',
  controllerId: $('#inspection').data('controller-id'),
  inspectionId: $('#inspection').data('inspection-id'),
  events: {
    'click .expand_photos': 'expandPhotos',
    'click .photo': 'viewVhoto',
    'click #delete': 'deletePhoto',
    'toggle .toggle': 'toggleGallery',
    'fileuploadstart .fileupload input': 'uploadStart',
    'fileuploadprogress .fileupload input': 'uploadProgress',
    'click #dismiss-modal': 'saveAndClosePhoto',
    'click .toggle-gallery': 'toggleGallery',
  },
  initialize() {
    this.thumbnail = thumbnailTemplate;
    this.systemIssueThumbnail = SystemIssueThumbnailTemplate;
    console.log('Inspection Photos Initialized');
    const self = this;
    // Initialize the photo upload button
    $('.fileupload input').fileupload({
      type: 'POST',
      dataType: 'json',
      disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator && navigator.userAgent),
      imageMaxWidth: 1280,
      imageMaxHeight: 1280,
      imageCrop: false,
      done(e, data) {
        $(e.target).prop('disabled', false);

        const zoneTr = $(e.target).parent().parent().parent();
        const zoneId = zoneTr.find('.fileupload').attr('inspections_zone_id');
        const zoneShowButton = $(`button[inspections_zone_id='${zoneId}']`);
        if (zoneShowButton.html() === 'Show') {
          zoneShowButton.html('Hide');
          zoneShowButton.removeClass('btn-success');
          zoneShowButton.addClass('btn-danger');
        }
        const zoneGallery = $(`.inspection_photos[inspections_zone_id="${zoneId}"]`);
        zoneGallery.find('td').append(self.thumbnail({ result: data.result }));

        const systemIssueId = $(e.target).parent().parent().find('.fileupload')
          .attr('system_issue_id');
        const systemShowButton = $(`button[system_issue_id='${systemIssueId}']`);
        if (systemShowButton.html() === 'Show') {
          systemShowButton.html('Hide');
          systemShowButton.removeClass('btn-success');
          systemShowButton.addClass('btn-danger');
        }

        if (systemIssueId) {
          const systemPhotoShowHideCell = $(`.system-photos-show-hide[system_issue_id="${systemIssueId}"]`);
          if (systemPhotoShowHideCell.html().includes('Hide') || $(e.target).parent().parent().html()
            .includes('Show')) {
            console.log('already a show hide button');
          } else {
            systemPhotoShowHideCell.prepend(`<button class='btn btn-sm toggle-gallery btn-danger' system_issue_id='${systemIssueId}'>Hide</button>`);
          }
        }
        if (zoneId) {
          const zonePhotoShowHideCell = $(`.zone-photos-show-hide[inspections_zone_id="${zoneId}"]`);
          if (zonePhotoShowHideCell.html().includes('Hide') || $(e.target).parent().parent().html()
            .includes('Show')) {
            console.log('already a show hide button');
          } else {
            zonePhotoShowHideCell.prepend(`<button class='btn btn-sm toggle-gallery btn-danger' inspections_zone_id='${zoneId}'>Hide</button>`);
          }
        }

        const systemGallery = $(`.system-photos-row[system_issue_id="${systemIssueId}"]`);
        $('.progress').remove();
        systemGallery.find('td').append(self.systemIssueThumbnail({ result: data.result }));
      },
      fail(e, data) {
        if (data.jqXHR.responseJSON) {
          const title = Object.keys(data.jqXHR.responseJSON)[0].replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
          window.toggleSnackbar('warning-snackbar', `${title}: ${Object.values(data.jqXHR.responseJSON)[0][0]}`);
        } else {
          window.toggleSnackbar('warning-snackbar', "We're sorry something went wrong.");
        }
        $('.progress').remove();
        if (e.target) {
          e.target.removeAttribute('disabled');
        }
      },
    });
  },
  // ----------------------
  // START FILE UPLOAD
  // Create the progress bar & and disable form
  // ----------------------
  uploadStart(e) {
    $(e.target).prop('disabled', true);
    const zoneId = $(e.target).attr('inspections_zone_id');
    const zoneGallery = $(`.inspection_photos[inspections_zone_id="${zoneId}"]`);
    zoneGallery.removeClass('hide');
    zoneGallery.find('td:first').append('<div class="progress"><div class="progress-bar progress-bar-success"><p class="percent"></p></div></div>');

    const systemIssueId = $(e.target).attr('system_issue_id');
    const systemGallery = $(`.system-photos-row[system_issue_id="${systemIssueId}"]`);
    systemGallery.removeClass('hide');
    systemGallery.find('td:first').append('<div class="progress"><div class="progress-bar progress-bar-success"><p class="percent"></p></div></div>');
  },
  // ----------------------
  // UPDATE PROGESS BAR
  // Update the progress bar
  // ----------------------
  uploadProgress(e, data) {
    const progress = Math.floor((data.loaded / data.total) * 100);

    const zoneId = $(e.target).attr('inspections_zone_id');
    const zoneGallery = $(`.inspection_photos[inspections_zone_id="${zoneId}"]`);
    zoneGallery.find('.progress-bar').css('width', `${progress}%`);
    zoneGallery.find('.progress-bar .percent').text(`${progress}%`);

    const systemIssueId = $(e.target).parent().parent().find('.fileupload')
      .attr('system_issue_id');
    const systemGallery = $(`.system-photos-row[system_issue_id="${systemIssueId}"]`);
    systemGallery.find('.progress-bar').css('width', `${progress}%`);
    systemGallery.find('.progress-bar .percent').text(`${progress}%`);
  },

  // ----------------------
  // VIEW PHOTO
  // shows the photo in the modal
  // ----------------------
  expandPhotos() {
    $('.toggle').trigger('click');
  },
  // ----------------------
  // TOGGLE GALLERY
  // Show/Hide the gallery below the zone
  // ----------------------
  toggleGallery(e) {
    const target = $(e.currentTarget);
    const zoneGallery = $(`.inspection_photos[inspections_zone_id="${$(e.target).attr('inspections_zone_id')}"]`);
    const systemIssueGallery = $(`.system-photos-row[system_issue_id="${$(e.target).attr('system_issue_id')}"]`);

    if (target.html() === 'Show') {
      target.html('Hide');
      target.removeClass('btn-success');
      target.addClass('btn-danger');
      zoneGallery.removeClass('hide');
      return systemIssueGallery.removeClass('hide');
    }
    target.html('Show');
    target.removeClass('btn-danger');
    target.addClass('btn-success');
    zoneGallery.addClass('hide');
    return systemIssueGallery.addClass('hide');
  },

  // ----------------------
  // SAVE PHOTO CAPTION
  // Close modal and save photo caption
  // ----------------------
  saveAndClosePhoto(e) {
    const photo = new InspectionPhotoModel(
      this.controllerId,
      this.inspectionId,
      $(e.target).data('zone-id'),
    );
    photo.set({ id: $(e.target).data('id') });
    photo.set('note', $('#photo-caption').val());
    photo.set('issue_type', $('#photo-issue-type').val());
    photo.save();

    const photoThumbnail = $(`.photo[data-id='${$(e.target).data('id')}']`);

    photoThumbnail.data('caption', $('#photo-caption').val());
    photoThumbnail.data('issue-type', $('#photo-issue-type').val());
  },

  // ----------------------
  // VIEW PHOTO
  // shows the photo in the modal
  // ----------------------
  viewVhoto(e) {
    e.preventDefault();
    const t = $(e.target);
    const id = t.data('id');
    const zoneId = t.data('zone-id');
    const medium = t.data('medium');
    const original = t.data('original');
    const caption = t.data('caption');
    const issueType = t.data('issue-type');
    $('.modal-photo-body').html(`<img src="${medium}"/>`);
    $('#photo-caption').val(caption);
    $('#photo-issue-type').val(issueType);
    $('#delete').data('id', id);
    $('#delete').data('zone-id', zoneId);
    $('#dismiss-modal').data('id', id);
    $('#dismiss-modal').data('zone-id', zoneId);
    $('#original').attr('href', original);
    $('.inspection-photo-modal').modal('show');
  },

  // ----------------------
  // DELETE PHOTO
  // ----------------------
  deletePhoto(e) {
    const self = this;
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm('Are you sure?')) {
      const photo = new InspectionPhotoModel(
        self.controllerId,
        self.inspectionId,
        $(e.target).data('zone-id'),
      );
      photo.set({ id: $(e.target).data('id') });
      photo.destroy();
      $(`.photo[data-id='${$(e.target).data('id')}']`).remove();
      $('.modal').modal('hide');
    }
  },
});
export default InspectionEditPhotosView;
