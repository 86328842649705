function manageUsersShowSetEntriesParamValue() {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (entrySelected) {
    const entry = document.getElementById(`manage_users_show-entries-${entrySelected}`);
    window.selectChosenOption(entry);
  }
}

function manageUsersShowSetFilterByParamValue() {
  const filterBySelected = (new URLSearchParams(window.location.search)).get('filter[branch_id]');
  if (filterBySelected) {
    const filterBy = document.getElementById(`manage_users_show-filter-${filterBySelected}`);
    window.selectChosenOption(filterBy);
  }
}

const manageUsersShowColumnHeaders = {
  0: 'site',
  1: 'branch',
};

function sortValueForManageUsersShowTable() {
  const headers = document.querySelectorAll('table .sorting');
  const headerSelected = document.querySelector('[class*="sorting_"]');
  const index = Array.from(headers).indexOf(headerSelected);
  const sort = headerSelected?.classList?.contains('sorting_desc') ? 'desc' : 'asc';
  return index === -1 ? '' : `${manageUsersShowColumnHeaders[index]} ${sort}`;
}

function manageUsersShowSetSortParamValue() {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(manageUsersShowColumnHeaders);
    const values = Object.values(manageUsersShowColumnHeaders);
    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}
window.manageUsersShowSetSortParamValue = manageUsersShowSetSortParamValue;
function updateManageUsersShowTable() {
  const searchValue = document.getElementById('search-manage-users-show').value;
  const branchId = document.querySelector('#manage_users_show-filter .li-check').id.split('-')[2];
  const perPage = document.querySelector('#manage_users_show-entries .li-check').id.split('-')[2];
  const userId = document.getElementById('manage-users-show').dataset.userId;
  const sort = sortValueForManageUsersShowTable();
  window.$.ajax({
    url: `/manage_users/${userId}`,
    data:
    {
      search: searchValue,
      filter: { branch_id: branchId },
      per_page: perPage,
      sort,
    },
    type: 'GET',
    dataType: 'script',
  });
}

window.updateManageUsersShowTable = updateManageUsersShowTable;

function updateManageUsersShowUrlWithEntries() {
  const entries = document.querySelector('#manage_users_show-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateManageUsersShowUrlWithEntries = updateManageUsersShowUrlWithEntries;

function updateManageUsersShowUrlWithFilter() {
  const filterBy = document.querySelector('#manage_users_show-filter .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { 'filter[branch_id]': filterBy, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateManageUsersShowUrlWithFilter = updateManageUsersShowUrlWithFilter;

function manageUsersShowSetSearchInputListenerAndValue() {
  const searchInputParam = (new URLSearchParams(window.location.search)).get('search');
  const searchInput = document.getElementById('search-manage-users-show');
  searchInput.value = searchInputParam;
  searchInput?.addEventListener('keyup', window.debounce(() => {
    window.AddParamsToTailwindPagination({ search: searchInput.value, page: 1 });
    updateManageUsersShowTable();
  }, 500));
}

function executeManageUsersShowSort(event) {
  const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
  const classesList = event.target.classList;
  const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
  const sortStr = `${manageUsersShowColumnHeaders[index]} ${sort}`;
  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}`, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  updateManageUsersShowTable();
}
window.executeManageUsersShowSort = executeManageUsersShowSort;
function verifySelectAllUserPermissions() {
  const permissions = ['view-controllers', 'run-commands', 'edit-programs', 'site-admin'];
  permissions.map((permission) => window.verifySelectAllStatus(`select-all-${permission}`, `[id*="select-${permission}-"]`));
}

window.verifySelectAllUserPermissions = verifySelectAllUserPermissions;

function getManageUsersShowData() {
  const data = [];
  const sites = document.querySelectorAll('table tbody tr:not([data-type-of-access="BillingUser"])');
  sites.forEach((site) => {
    const permissions = {};
    site.querySelectorAll('input[type=checkbox]:checked').forEach((input) => {
      permissions[input.name] = parseInt(input.value, 10);
    });
    data.push({ site_id: site.dataset.siteId, type_of_access: site.dataset.typeOfAccess, permissions });
  });
  return data;
}
window.getManageUsersShowData = getManageUsersShowData;
window.manageUsersShowInitialData = getManageUsersShowData();

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('manage-users-show');
  if (body) {
    verifySelectAllUserPermissions();
    manageUsersShowSetEntriesParamValue();
    manageUsersShowSetFilterByParamValue();
    manageUsersShowSetSearchInputListenerAndValue();
    manageUsersShowSetSortParamValue();
    document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', executeManageUsersShowSort);
    });
  }
});

function toggleOtherUserPermissions(element) {
  const permissions = ['run-commands', 'edit-programs', 'site-admin'];
  const container = element.parentElement.parentElement;
  permissions.forEach((permission) => {
    const checkbox = container.querySelector(`.${permission}-col input[type=checkbox]:not(:checked:disabled)`);
    if (checkbox) checkbox.disabled = !element.checked;
    if (checkbox && !element.checked) checkbox.checked = false;
    window.verifySelectAllStatus(`select-all-${permission}`, `[id*='select-${permission}-']:not(:checked:disabled)`);
  });
}

window.toggleOtherUserPermissions = toggleOtherUserPermissions;

function selectAllUserPermissions(permission, element) {
  window.selectAll(`select-all-${permission}`, `[id*='select-${permission}-']:not(:disabled)`);
  const viewControllerCheckboxes = document.querySelectorAll("table tbody [id*='select-view-controllers-']:not(:disabled)");

  if (permission === 'view-controllers') {
    viewControllerCheckboxes.forEach((checkbox) => toggleOtherUserPermissions(checkbox));
  } else {
    const columnCheckboxes = document.querySelectorAll(`table tbody .${permission}-col input[type=checkbox]:not(:checked:disabled)`);
    columnCheckboxes.forEach((checkbox) => {
      checkbox.checked = element.checked;
      if (element.checked) {
        checkbox.disabled = false;
      } else {
        checkbox.disabled = true;
      }
    });
    if (element.checked) {
      viewControllerCheckboxes.forEach((checkbox) => checkbox.checked = true);
      const selectAllCheckbox = document.getElementById('select-all-view-controllers');
      selectAllCheckbox.checked = true;
      selectAllCheckbox.classList.remove('tw-sl-checkbox-partial');
    } else {
      window.verifySelectAllStatus('select-all-view-controllers', "[id*='select-view-controllers-']:not(:checked:disabled)");
    }
    document.querySelectorAll("table tbody [id*='select-view-controllers-']").forEach((checkbox) => toggleOtherUserPermissions(checkbox));
  }
}

window.selectAllUserPermissions = selectAllUserPermissions;

function enableManageUsersShowUpdateButton() {
  const button = document.querySelector('#manage-users-show #update-button');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-button-secondary');
}

window.enableManageUsersShowUpdateButton = enableManageUsersShowUpdateButton;

function disableManageUsersShowUpdateButton() {
  const button = document.querySelector('#manage-users-show #update-button');
  button.classList.add('tw-btn-disabled');
  button.classList.remove('tw-button-secondary');
}

window.disableManageUsersShowUpdateButton = disableManageUsersShowUpdateButton;

function sumOfArray(array) {
  return array.reduce((acc, value) => acc + value, 0);
}

function getChangedManageUsersShowData(finalObject) {
  const changedData = [];
  finalObject.forEach((row, index) => {
    const initialPermissions = sumOfArray(Object.values(window.manageUsersShowInitialData[index].permissions));
    const finalPermissions = sumOfArray(Object.values(row.permissions));
    if (finalPermissions !== initialPermissions) changedData.push(row);
  });
  return changedData;
}

function updateManageUserPermissions() {
  const finalData = getManageUsersShowData();
  const changedPermissions = getChangedManageUsersShowData(finalData);
  const userId = document.getElementById('manage-users-show').dataset.userId;
  document.getElementById('info-snackbar').classList.remove('tw-hidden');
  window.loadAnimationPending('#info-snackbar #loading-state');
  window.$.ajax({
    url: `/manage_users/${userId}/update_permissions`,
    data: { data: changedPermissions },
    type: 'PATCH',
    dataType: 'script',
    success: () => {
      document.getElementById('info-snackbar').classList.add('tw-hidden');
      window.toggleSnackbar('success-snackbar');
      window.updateManageUsersShowTable();
    },
    error: () => {
      document.getElementById('info-snackbar').classList.add('tw-hidden');
      window.toggleSnackbar('warning-snackbar');
    },
  });
}

window.updateManageUserPermissions = updateManageUserPermissions;
