function determineState(event) {
  const toggleSwitch = document.querySelector(".ctn-omit-times .toggle-switch input[type='checkbox']");
  if (!toggleSwitch.checked) event.target.classList.remove('day-clicked');
}

function changeIcon(hiddenIcon, unhiddenIcon) {
  hiddenIcon.classList.remove('hidden');
  unhiddenIcon.classList.add('hidden');
}
window.changeIcon = changeIcon;

function setDaysBehavior(event) {
  const xIcon = document.querySelector('.ctn-omit-times .toggle-switch .icon-x');
  const checkIcon = document.querySelector('.ctn-omit-times .toggle-switch .icon-check');
  const days = document.querySelectorAll('.ctn-days-of-week .omit-days');
  const label = document.querySelector('.ctn-omit-times .blocked-days .label');
  days.forEach((day) => day.classList.remove('day-clicked'));
  if (event.target.checked) {
    changeIcon(checkIcon, xIcon);
    label.classList.add('selected-label');
    xIcon.parentElement.previousElementSibling.style.background = '#006A9D';
  } else {
    changeIcon(xIcon, checkIcon);
    label.classList.remove('selected-label');
    xIcon.parentElement.previousElementSibling.style.background = '#E0E0E0';
  }
}

function changeTimePickerState(event) {
  const xIcon = event.target.parentElement.querySelector('.icon-x');
  const checkIcon = event.target.parentElement.querySelector('.icon-check');
  const container = event.target.nextElementSibling.nextElementSibling;
  const label = event.target.parentElement.parentElement.previousElementSibling.previousElementSibling;
  const button = label.parentElement.nextElementSibling;
  if (event.target.checked) {
    changeIcon(checkIcon, xIcon);
    container.previousElementSibling.style.background = '#006A9D';
    container.style.marginLeft = '15px';
    label.classList.add('selected-label');
    button.style.pointerEvents = 'all';
    button.textContent = window.I18n.off;
  } else {
    const timePicker = event.target.parentElement.parentElement.previousElementSibling.parentElement.nextElementSibling.nextElementSibling;
    window.resetTimePicker(timePicker);
    changeIcon(xIcon, checkIcon);
    container.previousElementSibling.style.background = '#E0E0E0';
    container.style.marginLeft = '0px';
    label.classList.remove('selected-label');
    button.style.pointerEvents = 'none';
    button.textContent = window.I18n.global_commands.new.basic_settings.time_picker.placeholder;
  }
}

const omitDays = document.querySelectorAll('.ctn-days-of-week .omit-days');
omitDays.forEach((day) => day.addEventListener('click', determineState));

const omitTimesToggleSwitch = document.querySelector("#omit-times-days-and-dates .toggle-switch input[type='checkbox']");
omitTimesToggleSwitch?.addEventListener('change', setDaysBehavior);

const wateringTimesToggles = document.querySelectorAll("#watering-times-and-days .toggle-switch input[type='checkbox']");
wateringTimesToggles.forEach((toggle) => toggle.addEventListener('change', changeTimePickerState));
