function addOrdinalSuffix(day) {
  const dayInt = parseInt(day, 10);
  if (dayInt % 10 === 1 && dayInt !== 11) {
    return `${dayInt}st`;
  } if (dayInt % 10 === 2 && dayInt !== 12) {
    return `${dayInt}nd`;
  } if (dayInt % 10 === 3 && dayInt !== 13) {
    return `${dayInt}rd`;
  }
  return `${dayInt}th`;
}
window.addOrdinalSuffix = addOrdinalSuffix;
