const clearUserPermissionsForm = () => {
  const editModal = window.$('#permissions-modal-container');
  editModal.addClass('tw-hidden');
  editModal.find('.tw-sl-modal').remove();
};
window.clearUserPermissionsForm = clearUserPermissionsForm;
const editUserPermissionsExit = () => {
  clearUserPermissionsForm();
  window.$('#edit_user_permissions_modal_exit').addClass('tw-hidden');
};
window.editUserPermissionsExit = editUserPermissionsExit;
const returnToEditPermissions = () => {
  window.$('#permissions-modal-container').removeClass('tw-hidden');
  window.$('#edit_user_permissions_modal_exit').addClass('tw-hidden');
  window.$('#edit_user_permissions_modal_update').addClass('tw-hidden');
};
window.returnToEditPermissions = returnToEditPermissions;
const updateUserPermissions = () => {
  const form = window.$('#permissions-modal-container').find('form');

  window.$.ajax({
    type: 'POST',
    url: form.attr('action'),
    dataType: 'script',
    data: form.serialize(),
    success: () => {
      clearUserPermissionsForm();
      window.$('#edit_user_permissions_modal_update').addClass('tw-hidden');
    },
  });
};
window.updateUserPermissions = updateUserPermissions;
