const exportProposalModal = document.getElementById('export-proposal-modal');
exportProposalModal?.addEventListener('click', (event) => {
  if (event.target === this
      || event.target.classList.contains('btn-x')
      || event.target.classList.contains('tw-btn-cancel')) exportProposalModal.classList.add('tw-hidden');
});

function setFirstAvailableOption(exportModal, arrayOfLinks) {
  const exportButton = exportModal.querySelector('.btn-export');
  const options = exportModal.querySelectorAll('li');
  const firstAvailable = arrayOfLinks.findIndex((link) => link !== 'nil');
  if (firstAvailable === -1) {
    exportButton.classList.add('tw-hidden');
  } else {
    exportButton.classList.remove('tw-hidden');
    options[firstAvailable].click();
  }
}

function restrictExportingFile(exportModal, arrayOfLinks) {
  const notAvailableLabels = exportModal.querySelectorAll('.option-not-available');
  const options = exportModal.querySelectorAll('li');
  arrayOfLinks.forEach((link, index) => {
    if (link === 'nil') {
      options[index].style.pointerEvents = 'none';
      options[index].classList.remove('tw-text-gray-600');
      options[index].classList.add('tw-text-gray-500');
      notAvailableLabels[index].textContent = window.I18n.proposal.export_modal.dropdown.options.not_available;
    } else {
      options[index].style.pointerEvents = 'all';
      options[index].classList.remove('tw-text-gray-500');
      options[index].classList.add('tw-text-gray-600');
      notAvailableLabels[index].textContent = '';
    }
  });
}

function exportProposal(modal, arrayOfLinks) {
  const options = Array.from(document.querySelectorAll('.ctn-dropdown li'));
  const selectedOption = options.find((option) => option.classList.contains('li-check'));
  const hiddenLink = document.createElement('a');
  switch (selectedOption.id.split('-')[1]) {
    case 'pdf':
      if (arrayOfLinks[0] !== 'nil') {
        hiddenLink.href = arrayOfLinks[0];
        hiddenLink.target = '_blank';
        hiddenLink.click();
      }
      break;
    case 'excel':
      if (arrayOfLinks[1] !== 'nil') {
        hiddenLink.href = arrayOfLinks[1];
        hiddenLink.click();
        window.toggleSnackbar('success-snackbar', window.I18n.success_download);
      }
      break;
    case 'quickbooks':
      if (arrayOfLinks[2] !== 'nil') {
        hiddenLink.href = arrayOfLinks[2];
        hiddenLink.click();
        window.toggleSnackbar('success-snackbar', window.I18n.success_download);
      }
      break;
    default:
      break;
  }
  modal.classList.add('tw-hidden');
}
function showExportProposalModal(links) {
  const modal = document.getElementById('export-proposal-modal');
  let arrayOfLinks = links.replace('[', '').replace(']', '').split(', ');
  arrayOfLinks = arrayOfLinks.map((link) => link.replaceAll('"', ''));
  restrictExportingFile(modal, arrayOfLinks);
  setFirstAvailableOption(modal, arrayOfLinks);
  modal.querySelector('.tw-btn-action-blue').onclick = () => exportProposal(modal, arrayOfLinks);
  modal.classList.remove('tw-hidden');
}
window.showExportProposalModal = showExportProposalModal;
