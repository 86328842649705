import Backbone from 'backbone';
import $ from 'jquery';

const ControllerOptionsView = Backbone.View.extend({
  initialize() {
    const billingSiteSearch = $('#billing_site_search');

    if (billingSiteSearch.length > 0) {
      const autocompleteOptions = {
        delay: 500,
        minLength: 1,
        source: '/api/v2/sites',
        focus: (event, ui) => {
          billingSiteSearch.value = ui.item[0];
          return false;
        },
        select: (event, ui) => {
          billingSiteSearch.value = ui.item[0];
          document.getElementById('billing_site_id').value = ui.item[1];
          document.querySelector('.ui-helper-hidden-accessible').style.display = 'none';
          return false;
        },
      };

      /* eslint-disable-next-line no-underscore-dangle */
      $(billingSiteSearch).autocomplete(autocompleteOptions)
        .autocomplete('instance')._renderItem = (ul, item) => $('<li>')
          .append(`<a>${item[0]}</a>`)
          .appendTo(ul);
    }
  },
});
export default ControllerOptionsView;
