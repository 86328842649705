import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTJobCatalogItemsView = Backbone.View.extend({
  el: 'body',
  events: {
    'change .ransack': Shared.updateRansackFilter,
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click .remove-filter': Shared.removeFilter,
    'click #create_filter_report': Shared.createFilterReport,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #export': Shared.exportFile,
  },
  initialize() {
    console.log('Job Catalog Items DT initialized');
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      return Shared.showFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[1, 'asc']],
      bAutoWidth: false,
      responsive: false,
      searching: true,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // place the item id inside each <tr>
      fnRowCallback() {
        // const row = $(nRow);
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/job_catalog_items.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            $(`#${key}`).val(val);
          });
        },
      },

      columns: [
        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        { data: 'name', name: 'name', visible: window.COLUMNS.name.visible },
        { data: 'part_number', name: 'part_number', visible: window.COLUMNS.part_number.visible },
        { data: 'description', name: 'description', visible: window.COLUMNS.description.visible },
        { data: 'group_name', name: 'group_name', visible: window.COLUMNS.group_name.visible },
        { data: 'service_name', name: 'service_name', visible: window.COLUMNS.service_name.visible },
        {
          data: 'tags', name: 'tags', visible: window.COLUMNS.tags.visible, orderable: false,
        },
        { data: 'inventoriable', name: 'inventoriable', visible: window.COLUMNS.inventoriable.visible },
        {
          data: 'actions', name: 'actions', visible: window.COLUMNS.actions.visible, orderable: false,
        },
      ],
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').off();
  },
});
export default DTJobCatalogItemsView;
