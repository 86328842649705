function accountUsersShowSetEntriesParamValue(value = null) {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page') || value;
  if (entrySelected) {
    const entry = document.getElementById(`account_users_index-entries-${entrySelected}`);
    window.selectChosenOption(entry);
  }
}

function accountUsersShowSetFilterByParamValue(value = null) {
  const filterBySelected = (new URLSearchParams(window.location.search)).get('filter') || value;
  if (filterBySelected) {
    const filterBy = document.getElementById(`account_users_index-filter-${filterBySelected}`);
    window.selectChosenOption(filterBy);
  }
}

const accountUsersShowColumnHeaders = {
  0: 'complete_name',
  1: 'email',
  2: 'user_role',
  3: 'last_login',
};

function sortValueForAccountUsersShowTable() {
  const headers = document.querySelectorAll('table .sorting');
  const headerSelected = document.querySelector('[class*="sorting_"]');
  const index = Array.from(headers).indexOf(headerSelected);
  const sort = headerSelected?.classList?.contains('sorting_desc') ? 'desc' : 'asc';
  return index === -1 ? '' : `${accountUsersShowColumnHeaders[index]} ${sort}`;
}

function accountUsersShowSetSortParamValue() {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(accountUsersShowColumnHeaders);
    const values = Object.values(accountUsersShowColumnHeaders);

    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}
window.accountUsersShowSetSortParamValue = accountUsersShowSetSortParamValue;

function updateAccountUsersShowTable() {
  const accountId = document.getElementById('account-users-index').dataset.accountId;
  const searchValue = document.getElementById('search-account-users-index').value;
  const filterBy = document.querySelector('#account_users_index-filter .li-check').id.split('-')[2];
  const perPage = document.querySelector('#account_users_index-entries .li-check').id.split('-')[2];
  const sort = sortValueForAccountUsersShowTable();
  window.$.ajax({
    url: `/accounts/${accountId}/users`,
    data:
    {
      search: searchValue,
      filter: filterBy,
      per_page: perPage,
      sort,
    },
    type: 'GET',
    dataType: 'script',
  });
}

window.updateAccountUsersShowTable = updateAccountUsersShowTable;

function resetFiltersFromAccountUsersShowTable() {
  const pathUrl = window.changeDataFromUrl(window.location.href, { });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  document.getElementById('search-account-users-index').value = '';
  accountUsersShowSetEntriesParamValue(10);
  accountUsersShowSetFilterByParamValue('all');
  document.querySelector('.sorting_asc')?.classList?.remove('sorting_asc');
  document.querySelector('.sorting_desc')?.classList?.remove('sorting_desc');
}

window.resetFiltersFromAccountUsersShowTable = resetFiltersFromAccountUsersShowTable;

function updateAccountUsersShowUrlWithEntries() {
  const entries = document.querySelector('#account_users_index-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateAccountUsersShowUrlWithEntries = updateAccountUsersShowUrlWithEntries;

function updateAccountUsersShowUrlWithFilter() {
  const filterBy = document.querySelector('#account_users_index-filter .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { filter: filterBy, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateAccountUsersShowUrlWithFilter = updateAccountUsersShowUrlWithFilter;

function accountUsersShowSetSearchInputListenerAndValue() {
  const accountUsersShowSearchInputParam = (new URLSearchParams(window.location.search)).get('search');
  const accountUsersShowSearchInput = document.getElementById('search-account-users-index');
  accountUsersShowSearchInput.value = accountUsersShowSearchInputParam;

  accountUsersShowSearchInput?.addEventListener('keyup', window.debounce(() => {
    window.AddParamsToTailwindPagination({ search: accountUsersShowSearchInput.value, page: 1 });
    updateAccountUsersShowTable();
  }, 500));
}

function executeAccountUsersShowSort(event) {
  const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
  const classesList = event.target.classList;
  const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
  const sortStr = `${accountUsersShowColumnHeaders[index]} ${sort}`;
  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}`, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  updateAccountUsersShowTable();
}
window.executeAccountUsersShowSort = executeAccountUsersShowSort;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('account-users-index');
  if (body) {
    accountUsersShowSetEntriesParamValue();
    accountUsersShowSetFilterByParamValue();
    accountUsersShowSetSearchInputListenerAndValue();
    accountUsersShowSetSortParamValue();

    document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', executeAccountUsersShowSort);
    });
  }
});
