function enableTransferButton(enable) {
  const button = document.getElementById('btn-transfer-site');
  if (enable) {
    button.classList.add('tw-button-secondary');
    button.classList.remove('tw-btn-disabled');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-secondary');
  }
}
window.enableTransferButton = enableTransferButton;

function showConfirmTransferOwnershipModal() {
  document.getElementById('transfer_site_ownership').classList.remove('tw-hidden');
}
window.showConfirmTransferOwnershipModal = showConfirmTransferOwnershipModal;

function sendTransferOwnershipRequest() {
  const selectedUserId = document.getElementById('new_site_owner').querySelector('.li-check').id.split('-')[1];
  const siteId = document.getElementById('transfer_site_ownership').dataset.siteId;

  window.$.ajax({
    url: `/sites/${siteId}/billing_transfer`,
    data: { user_id: selectedUserId },
    dataType: 'script',
    type: 'POST',
  });
}
window.sendTransferOwnershipRequest = sendTransferOwnershipRequest;

function closeTransferModal() {
  document.getElementById('transfer_site_ownership').classList.add('tw-hidden');
}

window.closeTransferModal = closeTransferModal;
