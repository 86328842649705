function sendFileEnableSave(customField, enable) {
  const saveButton = document.querySelector(`#save_flow-${customField}`);

  if (enable) {
    saveButton.classList.remove('tw-pointer-events-none');
    saveButton.classList.remove('tw-text-gray-600');
    saveButton.classList.add('tw-text-blue-500');
  } else {
    saveButton.classList.add('tw-pointer-events-none');
    saveButton.classList.remove('tw-text-blue-500');
    saveButton.classList.add('tw-text-gray-600');
  }
}

function sendFileNameInput(customField, customEmail) {
  const nameInput = document.querySelector(`#name_${customField}`);
  const email = document.querySelector(`#email_${customEmail}`).value;
  const selector = customField;

  nameInput.addEventListener('input', window.debounce(() => {
    if (nameInput.value.length !== 0 && window.isValidEmail(email)) {
      sendFileEnableSave(selector, true);
    } else {
      sendFileEnableSave(selector, false);
    }
  }, 500));
}

function sendFileValidateEmail(customField, customEmail) {
  const emailInput = document.querySelector(`#email_${customEmail}`);
  const selector = customField;

  // This is so the user can have enough time to write before validation starts
  emailInput.addEventListener('input', window.debounce(() => {
    const email = emailInput.value;

    if (email.length === 0) {
      emailInput.classList.remove('tw-border-red-500');
      emailInput.classList.remove('tw-text-red-500');
      emailInput.classList.remove('tw-ring-shadow');

      sendFileEnableSave(selector, false);
      return;
    }

    if (window.isValidEmail(email)) {
      emailInput.classList.remove('tw-border-red-500');
      emailInput.classList.remove('tw-text-red-500');
      emailInput.classList.remove('tw-ring-shadow');

      sendFileEnableSave(selector, true);
    } else {
      emailInput.classList.add('tw-border-red-500');
      emailInput.classList.add('tw-text-red-500');
      emailInput.classList.add('tw-ring-shadow');

      sendFileEnableSave(selector, false);
    }
  }, 1000));

  // Here we have another listener due to debounce gives enough time
  // for user to delete a valid email and save an empty string
  // here we are disabling the button to cover such case
  emailInput.addEventListener('input', () => sendFileEnableSave(selector, false));
}

function sendFileCustomEdit(customField, customEmail) {
  document.getElementById(`add_flow-${customField}`).classList.add('tw-hidden');
  document.getElementById(`edit_flow-${customField}`).classList.remove('tw-hidden');
  document.querySelector(`#${customField}_name input`).classList.remove('tw-hidden');
  document.querySelector(`#${customField}_email input`).classList.remove('tw-hidden');
  document.querySelector(`#${customField}_name p`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_email p`).classList.add('tw-hidden');

  sendFileValidateEmail(customField, customEmail);
  sendFileNameInput(customField, customEmail);
}
window.sendFileCustomEdit = sendFileCustomEdit;

function sendFileAfterSave(customField, customEmail) {
  const name = document.getElementById(`name_${customField}`).value;
  const email = document.getElementById(`email_${customEmail}`).value;

  document.querySelector(`#${customField}_name p`).innerText = name;
  document.querySelector(`#${customField}_email p`).innerText = email;

  document.getElementById(`add_flow-${customField}`).classList.remove('tw-hidden');
  document.getElementById(`edit_flow-${customField}`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_name input`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_email input`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_name p`).classList.remove('tw-hidden');
  document.querySelector(`#${customField}_email p`).classList.remove('tw-hidden');

  const addButton = document.getElementById(`add_custom-${customField}`);

  addButton.classList.remove('tw-pointer-events-none');
  addButton.classList.remove('tw-text-gray-600');
  addButton.classList.add('tw-text-blue-500');
}

function sendFileCustomSave(customField, customEmail) {
  const modal = document.getElementById('send-emails-modal');
  const name = document.getElementById(`name_${customField}`);
  const email = document.getElementById(`email_${customEmail}`);
  const data = { site: {} };

  data.site[customField] = name.value;
  data.site[customEmail] = email.value.trim();

  window.$.ajax({
    url: `/api/v2/sites/${modal.dataset.siteId}/update_custom_fields`,
    type: 'PATCH',
    dataType: 'script',
    data,
    success: () => sendFileAfterSave(customField, customEmail),
  });
}
window.sendFileCustomSave = sendFileCustomSave;

function sendFileCustomCancel(customField) {
  document.getElementById(`add_flow-${customField}`).classList.remove('tw-hidden');
  document.getElementById(`edit_flow-${customField}`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_name input`).value = document.querySelector(`#${customField}_name p`).innerText;
  document.querySelector(`#${customField}_email input`).value = document.querySelector(`#${customField}_email p`).innerText;
  document.querySelector(`#${customField}_name input`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_email input`).classList.add('tw-hidden');
  document.querySelector(`#${customField}_name p`).classList.remove('tw-hidden');
  document.querySelector(`#${customField}_email p`).classList.remove('tw-hidden');

  document.querySelector(`#${customField}_email input`).classList.remove('tw-border-red-500');
  document.querySelector(`#${customField}_email input`).classList.remove('tw-text-red-500');
}
window.sendFileCustomCancel = sendFileCustomCancel;

function sendFileCustomAdd(customField) {
  const customEmail = document.querySelector(`#${customField}_email p`).innerText;
  if (!window.sendFileListEmails.includes(customEmail.toLowerCase())) {
    window.sendFileRenderEmails([customEmail]);
    window.sendFileListEmails.push(customEmail.toLowerCase());
    window.validateEnableSendFile();
  }
}
window.sendFileCustomAdd = sendFileCustomAdd;
