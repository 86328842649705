function deleteProposal(id) {
  const url = `${window.location.origin}/inspections_proposals/${id}`;
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });
  fetch(url, {
    method: 'DELETE',
    headers,
  }).then(() => {
    window.location.reload();
  });
}

function showDeleteProposalModal(proposalId) {
  const modal = document.getElementById('warning-modal');
  modal.querySelector('.tw-btn-action-red').onclick = () => deleteProposal(proposalId);
  modal.classList.remove('tw-hidden');
}
window.showDeleteProposalModal = showDeleteProposalModal;
