import Backbone from 'backbone';
import $ from 'jquery';
import '@vendor/javascripts/jquery-plugins/jquery.dropdown-form.js.coffee';

const ControllerFilesView = Backbone.View.extend({
  el: '.content',
  initialize() {
    console.log('Controller Files');
    this.checkDropdownForm();
  },
  checkDropdownForm() {
    if (typeof $.fn.dropdownForm === 'function') {
      this.initializeDropdowns();
    } else {
      setTimeout(this.checkDropdownForm.bind(this), 100);
    }
  },
  initializeDropdowns() {
    $('.dropdown-form').dropdownForm({ success: this.updateDescription });
  },
  updateDescription(data, textStatus, jqXHR, dropdown) {
    const trigger = dropdown.find('.dropdown-trigger');
    const string = data.description;
    if (string) {
      trigger.text(string);
    } else {
      trigger.html('&nbsp;');
    }
    trigger.data('value', string);
  },
});
export default ControllerFilesView;
