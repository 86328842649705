function changeView(wantedView) {
  const views = ['.ctn-basic-settings', '.ctn-smart-settings', '.ctn-advanced-settings', '.global_commands-summary'];
  views.forEach((view) => {
    if (view === wantedView) {
      document.querySelector(view).classList.remove('tw-hidden');
    } else {
      document.querySelector(view).classList.add('tw-hidden');
    }
  });
}
window.changeView = changeView;

function toggleStep(event) {
  const stepId = event.target.id;
  switch (stepId) {
    case '1':
      changeView('.ctn-basic-settings');
      break;
    case '2':
      changeView('.ctn-smart-settings');
      break;
    case '3':
      changeView('.ctn-advanced-settings');
      break;
    case '4':
      window.getSummary();
      break;
    default:
      break;
  }
}
window.toggleStep = toggleStep;
