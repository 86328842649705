import Reporting from '@assets/javascripts/backbone/lib/include/reporting.js';
import $ from 'jquery';

const siteSummaryChart = {
  sites: [],
  dataFinalTable: null,
  dataKeyTable: null,
  dataInput: [],
  dataInputProcessed: 0,
  annotateEvents: false,
  requested: {
    start: null,
    end: null,
    processing: setTimeout,
  },
  range: {
    start: null,
    end: null,
    interval: 'd',
  },
  selected: {
    row: null,
    col: null,
  },
  unitLabel: {
    integer: {
      weather: -1,
      usage: -1,
      gpm: -1,
    },
    long: {
      weather: '',
      usage: '',
      gpm: '',
    },
    short: {
      weather: '',
      usage: '',
      gpm: '',
    },
  },
  axes: {
    subjects: [],
  },
  google_Table: null,
  google_lineChart: null,
  google_lineChartOptions: {
    height: '195',
    legend: 'none',
    hAxis: {
      minValue: null,
      maxValue: null,
    },
    vAxis: {
      gridlines: {
        color: 'transparent',
      },
    },
    vAxes: {
      0: {
        title: '',
        logScale: false,
      },
      1: {
        title: '',
        logScale: false,
      },
    },
    series: {},
    colors: [],
    chartArea: {
      width: '80%',
      height: '78%',
      backgroundColor: '#1f2d38',
    },
    lineWidth: 3,
    annotations: {
      textStyle: {
        color: '#FFFFFF',
        auraColor: '#black',
      },
    },
  },

  initialize() {
    this.unitLabel = Reporting.set_unit_labels(
      this.unitLabel,
      window.temp_units,
      window.volume_measure,
    );

    this.sites[String(window.default_site.id)] = window.default_site;
    this.dataInput.push({
      type: 'weather',
      key: 'high_temp',
      axis_subject: 'temp',
      axis_display: 'line',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: 'High Temp',
      color: '#FF0000',
    });
    this.dataInput.push({
      type: 'weather',
      key: 'low_temp',
      axis_subject: 'temp',
      axis_display: 'line',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: 'Low Temp',
      color: '#99CCFF',
    });
    this.dataInput.push({
      type: 'usage',
      key: 'seconds_ran',
      axis_subject: 'time',
      axis_display: 'bars',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: 'Run Time',
      color: '#0070b8',
    });

    this.axes = Reporting.rebuild_axes(this.axes, this.dataInput);

    const start = Date.today().addDays(-7);
    const end = Date.today();

    this.range.start = start;
    this.range.end = end;
    this.requested.start = start;
    this.requested.end = end;
    this.google_lineChartOptions.hAxis.minValue = start;
    this.google_lineChartOptions.hAxis.maxValue = end;

    this.google_lineChart = new window.google.visualization.ComboChart(
      document.getElementById('backbone-chart'),
    );
    return this;
  },

  render() {
    this.start();
  },

  start() {
    this.dataFinalTable = [];
    this.dataKeyTable = [];
    this.dataInputProcessed = 0;

    this.google_lineChartOptions.vAxes['0'].title = Reporting.what_axis_label(
      this.unitLabel,
      this.axes.subjects[0],
    );
    this.google_lineChartOptions.vAxes['1'].title = Reporting.what_axis_label(
      this.unitLabel,
      this.axes.subjects[1],
    );

    this.google_Table = new window.google.visualization.DataTable();
    this.google_lineChartOptions.series = {};
    this.google_lineChartOptions.colors = [];

    this.prep_key_table();
  },

  prep_key_table() {
    let loopDate = new Date(this.range.start);

    while (loopDate < this.range.end) {
      this.dataKeyTable[Reporting.stringThisDate(loopDate)] = {
        values: [],
        events: [],
      };
      loopDate = Reporting.nextIntervalDate(
        this.range.interval,
        loopDate,
        1,
      );
    }

    this.populate_key_table();
  },

  populate_key_table() {
    this.dataInput.forEach((input, index) => {
      const i = index * 3;
      this.fetch_collection_and_insert(input, index, i);
    });

    this.are_we_ready_to_rock();
  },

  fetch_collection_and_insert(input, index, i) {
    const collection = Reporting.select_collection(input);

    if (collection) {
      collection.fetch({
        data: Reporting.build_params(this.range, input),
        success: () => {
          collection.each((r) => {
            this.insert_value(r, input.key, index, i);
          });
          this.dataInputProcessed += 1;
        },
      });
    } else {
      this.dataInputProcessed += 1;
    }
  },

  insert_value(model, key, index, i) {
    const date = Reporting.stringThisDate(model.get('date'));

    if (Object.hasOwn(this.dataKeyTable, date)) {
      this.dataKeyTable[date].values[i] = Reporting.convertValue(
        key,
        model.get(key),
        this.unitLabel,
      );
      this.dataKeyTable[date].values[i + 1] = null;
      this.dataKeyTable[date].values[i + 2] = null;
    }
  },

  are_we_ready_to_rock() {
    if (this.dataInput.length > this.dataInputProcessed) {
      setTimeout(() => {
        this.are_we_ready_to_rock();
      }, 100);
    } else {
      this.finalize_table(this.dataKeyTable, this.dataFinalTable);
    }
  },

  finalize_table(before, after) {
    Object.entries(before).forEach(([dateString, data]) => {
      const temp = [];
      const offset = new Date().getTimezoneOffset();
      const date = new Date(dateString);
      date.setMinutes(date.getMinutes() + offset);
      temp.push(date.toString('ddd'));
      temp.push(null);
      temp.push(null);
      after.push(temp.concat(data.values));
    });

    after = Reporting.resize_table(after, this.dataInput.length);
    this.draw_google_chart(after);
  },

  draw_google_chart(table) {
    this.prep_google_headers_and_options();
    this.google_Table.addRows(table);
    this.google_lineChart.draw(this.google_Table, this.google_lineChartOptions);
    $('#average_high').html(
      parseInt(Reporting.average(this.dataKeyTable, 0), 10) || 0,
    );
    $('#average_low').html(
      parseInt(Reporting.average(this.dataKeyTable, 3), 10) || 0,
    );
    $('#total_run_time').text(
      parseInt(Reporting.total(this.dataKeyTable, 6), 10) || 0,
    );
  },

  prep_google_headers_and_options() {
    this.google_Table.addColumn({ type: 'string', label: 'Date' });
    this.google_Table.addColumn({ type: 'string', role: 'annotation' });
    this.google_Table.addColumn({ type: 'string', role: 'annotationText' });

    this.dataInput.forEach((input, index) => {
      this.google_Table.addColumn({ type: 'number', label: input.label });
      this.google_Table.addColumn({ type: 'string', role: 'annotation' });
      this.google_Table.addColumn({ type: 'string', role: 'annotationText' });
      this.google_lineChartOptions.colors.push(input.color);
      this.google_lineChartOptions.series[index] = {
        targetAxisIndex: this.axes.subjects.indexOf(input.axis_subject),
        type: input.axis_display,
      };
    });
  },
};

export default siteSummaryChart;
