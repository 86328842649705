import Backbone from 'backbone';
import $ from 'jquery';
import InspectionNoteModel from '@assets/javascripts/backbone/models/inspections/inspections_notes.js';

const InspectionEditNotesView = Backbone.View.extend({
  el: '#inspection-comments',
  events: {
    'click button': 'saveNote',
    'keyup textarea': 'keyup',
    'click .list-group-item': 'deleteNote',
  },
  initialize() {
    this.controllerId = $('#inspection').data('controller-id');
    this.inspectionId = $('#inspection').data('inspection-id');
  },
  // ----------------------
  // KEYUP
  // ----------------------
  keyup(e) {
    if (e.keyCode === 13) {
      $('#inspection-comments button').trigger('click');
    }
  },

  // ----------------------
  // SAVE NOTE
  // ----------------------
  saveNote() {
    const textarea = $('#inspection-comments textarea');
    const self = this;
    const note = new InspectionNoteModel(this.controllerId, this.inspectionId);
    note.save(
      {
        note: textarea.val().trim(),
        inspection_id: this.inspectionId,
      },
      {
        success(model) {
          $('#inspection-comments .list-group').append(`<li class="list-group-item" data-id="${model.get('id')}">${$.trim(textarea.val())}</li>`);
          textarea.val('').trigger('focus');
          self.toggleTextarea();
        },
      },
    );
  },

  // ----------------------
  // DELETE NOTE
  // ----------------------
  deleteNote(e) {
    const self = this;
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm(`Delete comment: ${$(e.target).text().trim().slice(0, 30)}...`)) {
      const note = new InspectionNoteModel(this.controllerId, this.inspectionId);
      note.set({ id: $(e.target).data('id') });
      note.destroy({
        success() {
          $(e.target).remove();
          return self.toggleTextarea();
        },
      });
    }
  },

  // ----------------------
  // TOGGLE MAX TEXTAREA
  // ----------------------
  toggleTextarea() {
    const comments = $('#inspection-comments');
    const count = comments.find('li').length;
    if (count >= 7) {
      comments.find('.panel-footer').addClass('hidden');
      comments.find('.list-group').removeClass('short');
      alert('You may only save a maxium of 7 comments. Please use zone comments.');
    } else {
      comments.find('.panel-footer').removeClass('hidden');
      comments.find('.list-group').addClass('short');
    }
  },
});
export default InspectionEditNotesView;
