// Remove Controller
function removeControllerModal(event) {
  const e = event.target.closest('button');
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  const modalType = commands.ids.length === 1 ? 'remove_controller_last' : 'remove_controller';
  const title = window.I18n.global_commands.summary.modals[modalType].title;
  let description = window.I18n.global_commands.summary.modals[modalType].description;
  description = commands.ids.length === 1 ? description : description.replace('%{controller}', e.dataset.name).replace('%{site}', e.dataset.site);
  const buttonTitle = window.I18n.global_commands.summary.modals[modalType].remove;
  const modal = document.getElementById('remove-controller-modal');
  modal.querySelector('.title').innerHTML = title;
  modal.querySelector('.description').innerHTML = description;
  modal.querySelector('.remove-controller').id = e.id;
  modal.querySelector('.tw-btn-action-red').innerHTML = buttonTitle;
  modal.classList.remove('tw-hidden');
}
window.removeControllerModal = removeControllerModal;

function basicSettingsResetData() {
  window.systemSettingsResetData();
  window.wateringTimesAndDaysResetData();
  window.seasonalAdjustResetData();
  window.omitTimesAndDaysResetData();
}

const removeModal = document.getElementById('remove-controller-modal');
removeModal?.addEventListener('click', (event) => {
  if (event.target === removeModal || event.target.classList.contains('tw-btn-cancel')) removeModal.classList.add('tw-hidden');
});

function removeControllerGlobalCommand(event) {
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  const controllerIds = commands.ids;
  const index = controllerIds.indexOf(event.target.id);
  if (index !== -1) {
    if (controllerIds.length === 1) {
      sessionStorage.removeItem('globalCommand');
      window.location = '/controls';
    } else {
      controllerIds.splice(index, 1);
      commands.ids = controllerIds;
      const controllerSection = document.getElementById(`controller-${event.target.id}`);
      controllerSection.parentNode.removeChild(controllerSection);
      sessionStorage.setItem('globalCommand', JSON.stringify(commands));
      const pillsCounter = document.querySelectorAll('.controllers-counter');

      const description = controllerIds.length === 1 ? ` ${window.I18n.global_commands.new.basic_settings.controllers_selected.one}` : ` ${window.I18n.global_commands.new.basic_settings.controllers_selected.other}`;
      const newText = description.replace('%{count}', controllerIds.length);
      pillsCounter.forEach((counter) => {
        counter.innerHTML = newText;
      });
    }
  }
  const modal = document.getElementById('remove-controller-modal');
  modal.classList.add('tw-hidden');
}
window.removeControllerGlobalCommand = removeControllerGlobalCommand;

// Show All Controllers - Preview
function showAllControllers(element) {
  const controllers = document.querySelectorAll('.controllers .ctn-controller-row');
  controllers.forEach((controller) => { controller.classList.remove('tw-hidden'); });
  element.classList.add('tw-hidden');
}
window.showAllControllers = showAllControllers;

function validateLast(commandName, validateGlobal = true) {
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  if (Object.entries(commands.data).length !== 1 && validateGlobal) {
    return { last: false, commandType: '' };
  }
  let information = { last: false, commandType: '' };
  switch (true) {
    case ['system_settings', 'watering_times_and_days', 'program_seasonal_adjustments', 'omit_times_and_days'].includes(commandName):
      if (Object.entries(commands.data.basic_settings).length === 1) {
        information = { last: true, commandType: 'basic_settings' };
      }
      break;
    case ['weather_station', 'daylight_saving', 'winterized'].includes(commandName):
      if (Object.entries(commands.data.advanced_settings).length === 1) {
        information = { last: true, commandType: 'advanced_settings' };
      }
      break;
    case ['zones_in_programs'].includes(commandName):
      if (Object.entries(commands.data.smart_settings).length === 1) {
        information = { last: true, commandType: 'smart_settings' };
      }
      break;
    default:
      break;
  }
  return information;
}

function removeSection(sectionName, lastOfSection) {
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  const lastGlobal = validateLast(sectionName, true);
  if (lastGlobal.last) {
    sessionStorage.removeItem('globalCommand');
    window.location = '/controls';
    return;
  }
  if (lastOfSection.last) {
    const commandSection = document.getElementById(`${lastOfSection.commandType}-summary`);
    commandSection.parentNode.removeChild(commandSection);
    switch (lastOfSection.commandType) {
      case 'basic_settings':
        delete commands.data.basic_settings;
        basicSettingsResetData();
        break;
      case 'advanced_settings':
        delete commands.data.advanced_settings;
        window.advancedSettingsResetData();
        break;
      case 'smart_settings':
        delete commands.data.smart_settings;
        window.zonesInProgramsResetData();
        break;
      default:
        break;
    }
    sessionStorage.setItem('globalCommand', JSON.stringify(commands));
  }
}

// Revert Command
function revertChangesModal(commandType) {
  const lastGlobal = validateLast(commandType, true);
  const revertType = lastGlobal.last ? 'revert_command_last' : 'revert_command';
  const title = window.I18n.global_commands.summary.modals[revertType].title;
  const commandName = window.I18n.global_commands.summary.commands[commandType].title;
  let description = window.I18n.global_commands.summary.modals[revertType].description;
  description = lastGlobal.last ? description : description.replace('%{command}', commandName);
  const buttonTitle = window.I18n.global_commands.summary.modals[revertType].revert;
  const modal = document.getElementById('revert-command-modal');
  modal.querySelector('.title').innerHTML = title;
  modal.querySelector('.description').innerHTML = description;
  modal.querySelector('.revert-command').id = commandType;
  modal.querySelector('.tw-btn-action-red').innerHTML = buttonTitle;
  modal.classList.remove('tw-hidden');
}
window.revertChangesModal = revertChangesModal;

const revertModal = document.getElementById('revert-command-modal');
revertModal?.addEventListener('click', (event) => {
  if (event.target === revertModal || event.target.classList.contains('tw-btn-cancel')) revertModal.classList.add('tw-hidden');
});

function revertChange(event) {
  const sectionName = event.target.id;
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  const lastOfSection = validateLast(sectionName, false);
  removeSection(sectionName, lastOfSection);
  if (!lastOfSection.last) {
    switch (sectionName) {
      case 'system_settings':
        delete commands.data.basic_settings.system_settings;
        window.systemSettingsResetData();
        break;
      case 'watering_times_and_days':
        delete commands.data.basic_settings.watering_times_and_days;
        window.wateringTimesAndDaysResetData();
        break;
      case 'program_seasonal_adjustments':
        delete commands.data.basic_settings.program_seasonal_adjustments;
        window.seasonalAdjustResetData();
        break;
      case 'omit_times_and_days':
        delete commands.data.basic_settings.omit_times_and_days;
        window.omitTimesAndDaysResetData();
        break;
      case 'weather_station':
        delete commands.data.advanced_settings.weather_station;
        window.weatherStationResetData();
        break;
      case 'daylight_saving':
        delete commands.data.advanced_settings.daylight_saving;
        window.daylightSavingResetData();
        break;
      case 'winterized':
        delete commands.data.advanced_settings.winterized;
        window.winterizedResetData();
        break;
      default:
        break;
    }
    const section = document.querySelector(`.changes-summary .${sectionName}`);
    section.parentNode.removeChild(section);
    sessionStorage.setItem('globalCommand', JSON.stringify(commands));
  }
  const modal = document.getElementById('revert-command-modal');
  modal.classList.add('tw-hidden');
}
window.revertChange = revertChange;

function checkChanged(event) {
  const parentDiv = event.target.parentElement;
  const arrowSvg = parentDiv.querySelector('.heroicon-arrow');
  if (event.target.checked === true) {
    arrowSvg.classList.remove('rotate-arrow-down');
    arrowSvg.classList.add('rotate-arrow-up');
  } else {
    arrowSvg.classList.add('rotate-arrow-down');
    arrowSvg.classList.remove('rotate-arrow-up');
  }
}
window.checkChanged = checkChanged;

const applyModal = document.getElementById('apply_settings-modal');
applyModal?.addEventListener('click', (event) => {
  if (event.target === applyModal || event.target.classList.contains('tw-btn-cancel')) applyModal.classList.add('tw-hidden');
});
