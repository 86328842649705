import bootbox from 'bootbox';
import Backbone from 'backbone';
import $ from 'jquery';

const SiteDeleteView = Backbone.View.extend({
  events: {
    'click .delete-aircard-controller': 'aircardTerminationPrompt',
  },
  aircardTerminationPrompt(e) {
    e.preventDefault();
    const iccid = e.target.dataset.iccid;
    const controlId = e.target.dataset.controlId;
    const tr = $(e.target).closest('tr');

    bootbox.prompt({
      title: `If you are discontinuing use of this aircard <i>${iccid}</i>, please confirm so that we can terminate cellular service for this SIM. If service is terminated, this aircard cannot be reused. If you want to re-use this aircard on a different controller, you will have 90 days before service is terminated.`,
      inputType: 'select',
      inputOptions: [
        {
          text: 'I am NOT re-using this aircard and you can terminate its service.',
          value: '1',
        },
        {
          text: 'I plan to re-use this aircard within 90 days. I understand if I do not re-use it within 90 days, service may be terminated.',
          value: '2',
        },
      ],
      callback: (result) => {
        if (result[0] === '1') {
          $.ajax({
            url: `/controls/${controlId}`,
            type: 'DELETE',
            dataType: 'json',
            success: () => {
              alert('Successfully deleted controller');
              tr.fadeOut(1000, () => {
                tr.remove();
              });
            },
            error: (response) => {
              console.log('error response next line');
              console.log(response);
              alert('We are sorry. Something went wrong.');
            },
          });
        } else {
          $.ajax({
            url: `/controls/${controlId}`,
            type: 'DELETE',
            data: { reuse_aircard: true },
            dataType: 'json',
            success: () => {
              alert('Successfully deleted controller. You have 90 days to reuse the aircard on another controller.');
              tr.fadeOut(1000, () => {
                tr.remove();
              });
            },
            error: (response) => {
              console.log('error response next line');
              console.log(response);
              alert('We are sorry. Something went wrong.');
            },
          });
        }
      },
    });
  },
});
export default SiteDeleteView;
