function continueAircardActivation() {
  document.querySelector('#activate_aircard_modal #activate_aircard').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal #continue').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal #activate').classList.remove('tw-hidden');
  document.querySelector('#activate_aircard_modal .informative p').textContent = window.I18n.insights.healths.activate_aircard.new_controller.footer_note;
  document.querySelector('#activate_aircard_modal .informative').classList.remove('tw-hidden');
  document.querySelector('#activate_aircard_modal #new_controller').classList.remove('tw-hidden');
}
window.continueAircardActivation = continueAircardActivation;

function insightsActivateAircardModal(aircardICCID) {
  document.querySelector('#activate_aircard_modal .iccid').textContent = `ICCID ${aircardICCID}`;
  document.querySelector('#activate_aircard_modal input[name="control[iccid]"]').value = aircardICCID;
  document.querySelector('#activate_aircard_modal').classList.remove('tw-hidden');
}
window.insightsActivateAircardModal = insightsActivateAircardModal;
function searchSiteVirtualControllers(siteId) {
  return window.$.ajax({
    url: '/api/v0/controllers',
    dataType: 'JSON',
    type: 'GET',
    data: { ransack: { site_id_eq: siteId, mock_eq: true } },

  });
}

function resetInsightAircardActivateSection() {
  document.querySelector('#activate_aircard_modal #has_virtual_controllers').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal .controllers').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal #continue').classList.remove('tw-btn-action-blue');
  document.querySelector('#activate_aircard_modal #continue').classList.add('tw-btn-disabled');
  document.querySelector('#activate_aircard_modal .informative').classList.add('tw-hidden');
  document.querySelector("#activate_aircard_modal input[name='control[site_id]']").value = '';
  document.querySelector('#activate_aircard_modal select[name="control_id"]').value = '';
  document.querySelector('#activate_aircard_modal #activate_aircard').dispatchEvent(new Event('change', { bubbles: true }));
  document.querySelector("#activate_aircard_modal select[name='control_id']").removeAttribute('disabled');
  document.querySelector("#activate_aircard_modal select[name='control_id']").parentElement.classList.remove('tw-pointer-events-none');
  document.querySelector("#activate_aircard_modal select[name='control_id']").nextElementSibling.classList.add('tw-hidden');
  document.querySelector("#activate_aircard_modal select[name='control_id']").classList.remove('tw-bg-none');
}

function cancelAircardActivation() {
  document.querySelector('#activate_aircard_modal #activate_aircard').classList.remove('tw-hidden');
  resetInsightAircardActivateSection();
  document.querySelector('#activate_aircard_modal #continue').classList.remove('tw-hidden');
  document.querySelector('#activate_aircard_modal #activate').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal .informative p').textContent = window.I18n.insights.healths.activate_aircard.footer_note;
  document.querySelector('#activate_aircard_modal #new_controller').classList.add('tw-hidden');
  document.querySelector('#activate_aircard_modal #activate_aircard').reset();
  document.querySelector('#activate_aircard_modal #activate_aircard').dispatchEvent(new Event('change', { bubbles: true }));
  document.querySelector('#activate_aircard_modal').classList.add('tw-hidden');
}

window.cancelAircardActivation = cancelAircardActivation;

function buildControllerDropdown(sites) {
  let sitesDropdown = `<option value="" disabled selected class="input_disabled">${window.I18n.insights.healths.activate_aircard.select_virtual_placeholder}</option>`;
  sites.forEach((site) => sitesDropdown += `<option value="${site.id}"> ${site.name}</option>`);
  document.querySelector('#activate_aircard_modal select[name="control_id"]').innerHTML = sitesDropdown;
}

function aircardVirtualControllers(siteId) {
  searchSiteVirtualControllers(siteId).done((data) => {
    buildControllerDropdown(data.data);
    document.querySelector('#activate_aircard_modal #has_virtual_controllers').classList.toggle('tw-hidden', data.recordsTotal === 0);
    document.querySelector('#activate_aircard_modal .or_separator').classList.toggle('tw-hidden', data.recordsTotal === 0);
    document.querySelector('#activate_aircard_modal .virtual').classList.toggle('tw-hidden', data.recordsTotal === 0);
    document.querySelector('#activate_aircard_modal .name').classList.toggle('md:tw-w-1/2', data.recordsTotal > 0);
    if (data.recordsTotal === 0) {
      document.querySelector('#activate_aircard_modal select[name="control_id"]').innerHTML = `<option value="" disabled selected class="input_disabled">${window.I18n.insights.healths.activate_aircard.select_virtual_placeholder}</option>`;
    }
    document.querySelector('.controllers').classList.remove('tw-hidden');
  }).fail(() => { console.log('FAIL'); });
}
function updateSiteAircardSelected(selectedSite) {
  document.querySelector("#activate_aircard_modal input[name='control[site_id]']").value = selectedSite.dataset.siteId;
  document.querySelector('#activate_aircard_modal select[name="control_id"]').value = '';
  document.querySelector('#activate_aircard_modal #activate_aircard').dispatchEvent(new Event('change', { bubbles: true }));
  document.querySelector('#activate_aircard_modal select[name="control_id"]').dispatchEvent(new Event('change', { bubbles: true }));
  document.querySelector('#activate_aircard_modal #site-aircard-search').value = selectedSite.textContent;
  document.querySelector('#activate_aircard_modal #site-aircard-search').classList.add('form_input-typed');
  aircardVirtualControllers(selectedSite.dataset.siteId);
  document.querySelector('#activate_aircard_modal .sites_result').classList.add('tw-hidden');
}
window.updateSiteAircardSelected = updateSiteAircardSelected;

const searchSiteAircardInput = document.querySelector('#activate_aircard_modal #site-aircard-search');
searchSiteAircardInput?.addEventListener('keyup', window.debounce(() => {
  window.searchSite(searchSiteAircardInput.value);
}, 500));

searchSiteAircardInput?.addEventListener('keyup', () => {
  resetInsightAircardActivateSection();
});

searchSiteAircardInput?.addEventListener('focusout', (event) => {
  if (!event.relatedTarget?.classList?.contains('sites_result')) {
    document.getElementById('search_site-result').classList.add('tw-hidden');
  }
});

searchSiteAircardInput?.addEventListener('click', () => {
  document.getElementById('search_site-result').classList.remove('tw-hidden');
});

document.querySelector('#activate_aircard_modal #activate_aircard')?.addEventListener('change', () => {
  if (document.querySelector("#activate_aircard_modal select[name='control_id']").value !== '') {
    document.querySelector('#activate_aircard_modal #continue').classList.add('tw-hidden');
    document.querySelector('#activate_aircard_modal #activate').classList.remove('tw-hidden');
  } else if (document.querySelector("#activate_aircard_modal input[name='control[name]']").value !== '') {
    document.querySelector('#activate_aircard_modal #continue').classList.remove('tw-hidden', 'tw-btn-disabled');
    document.querySelector('#activate_aircard_modal #continue').classList.add('tw-btn-action-blue');
    document.querySelector('#activate_aircard_modal #activate').classList.add('tw-hidden');
  } else {
    document.querySelector('#activate_aircard_modal #continue').classList.remove('tw-hidden');
    document.querySelector('#activate_aircard_modal #activate').classList.add('tw-hidden');
    document.querySelector('#activate_aircard_modal #continue').classList.remove('tw-btn-action-blue');
    document.querySelector('#activate_aircard_modal #continue').classList.add('tw-btn-disabled');
  }
});

document.querySelector("#activate_aircard_modal select[name='control_id']")?.addEventListener('change', (event) => {
  if (event.target.value === '') {
    document.querySelector("#activate_aircard_modal input[name='control[name]']").classList.remove('input_disabled');
    document.querySelector("input[name='control[slw_present]'][type='checkbox']").classList.remove('input_disabled');
    document.querySelector('label[for="control[slw_present]"]').classList.remove('tw-pointer-events-none');
    document.querySelector('#activate_aircard_modal .informative').classList.add('tw-hidden');
  } else {
    document.querySelector("#activate_aircard_modal input[name='control[name]']").classList.add('input_disabled');
    document.querySelector("#activate_aircard_modal input[name='control[name]']").value = '';
    document.querySelector("input[name='control[slw_present]'][type='checkbox']").classList.add('input_disabled');
    document.querySelector('label[for="control[slw_present]"]').classList.add('tw-pointer-events-none');
    document.querySelector("input[name='control[slw_present]']").checked = false;
    document.querySelector('#activate_aircard_modal .informative p').textContent = window.I18n.insights.healths.activate_aircard.footer_note;
    document.querySelector('#activate_aircard_modal .informative').classList.remove('tw-hidden');
    document.querySelector("#activate_aircard_modal select[name='control_id']").nextElementSibling.classList.remove('tw-hidden');
    document.querySelector("#activate_aircard_modal select[name='control_id']").classList.add('tw-bg-none');
  }
});

function activateAircard() {
  const form = document.querySelector('#activate_aircard_modal #activate_aircard');
  if (document.querySelector("#activate_aircard_modal select[name='control_id']").value !== '') {
    form.action = `/controls/${document.querySelector("#activate_aircard_modal select[name='control_id']").value}`;
    document.querySelector("#activate_aircard_modal input[name='_method']").value = 'patch';
    form.submit();
  } else if (document.querySelector("#activate_aircard_modal input[name='control[name]']").value !== '') {
    form.action = '/controls';
    document.querySelector("#activate_aircard_modal input[name='_method']").value = 'post';
    form.submit();
  }
}
window.activateAircard = activateAircard;

document.querySelector("#activate_aircard_modal input[name='control[name]']")?.addEventListener('keyup', (event) => {
  const controlSelect = document.querySelector("#activate_aircard_modal select[name='control_id']");
  if (event.target.value === '') {
    controlSelect.removeAttribute('disabled');
    controlSelect.parentElement.classList.remove('tw-pointer-events-none');
  } else {
    controlSelect.value = '';
    controlSelect.setAttribute('disabled', true);
    controlSelect.parentElement.classList.add('tw-pointer-events-none');
  }
  document.querySelector('#activate_aircard_modal #activate_aircard').dispatchEvent(new Event('change', { bubbles: true }));
});
