function showExitNewBranchModal(accountId) {
  const modal = document.getElementById('exit-new-branch');
  modal.querySelector('.tw-btn-action-red').onclick = () => window.location = `/accounts/${accountId}/branches`;
  modal.classList.remove('tw-hidden');
}
window.showExitNewBranchModal = showExitNewBranchModal;
function createNewBranch() {
  const button = document.querySelector('form #create-new-branch');
  button.click();
}
window.createNewBranch = createNewBranch;
