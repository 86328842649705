function removePaymentSource(paymentSourceId) {
  window.$.ajax({
    type: 'DELETE',
    url: `/payment_sources/${paymentSourceId}`,
    dataType: 'script',
  });
}

function showDestroyPaymentSourceModal(paymentSourceId, sourceType) {
  const element = document.getElementById(`payment_source-${paymentSourceId}`);
  const confirmationModal = document.getElementById('remove_payment_source');
  confirmationModal.classList.remove('tw-hidden');
  if (sourceType === 'cards') {
    confirmationModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_card_modal.title;
    confirmationModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_card_modal.description.replace('%{last4}', element.dataset.last4);
  } else {
    confirmationModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_bank_account_modal.title;
    confirmationModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_bank_account_modal.description.replace('%{last4}', element.dataset.last4);
  }
  confirmationModal.querySelector('.tw-btn-action-red').onclick = () => removePaymentSource(paymentSourceId);
}
window.showDestroyPaymentSourceModal = showDestroyPaymentSourceModal;
