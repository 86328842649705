function updateControllerProvider(externalProvider) {
  const provider = document.querySelector('[name="control[provider_type]"]');
  if (externalProvider.value === '0') {
    provider.value = 'weathermatic';
    provider.classList.add('input_disabled');
  } else {
    provider.value = 'hydrawise';
    provider.classList.remove('input_disabled');
  }
}
window.updateControllerProvider = updateControllerProvider;
