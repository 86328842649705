function resetButtons(containerClass) {
  const buttons = document.querySelectorAll(`${containerClass} .btn-dropdown`);
  const noChangeOption = buttons[0].nextElementSibling.firstElementChild.firstElementChild.textContent.trim();
  buttons.forEach((button) => {
    button.firstChild.data = noChangeOption;
    button.classList.remove('btn-selected');
    button.previousElementSibling.classList.remove('selected-label');
  });

  const options = document.querySelectorAll(`${containerClass} .dropdown-options li`);
  options.forEach((option) => {
    const checkIcon = option.querySelector('svg');
    option.classList.add('li-no-check');
    option.classList.remove('li-check');
    checkIcon.classList.add('tw-hidden');
    if (option.textContent.trim() === noChangeOption) {
      option.classList.add('li-check');
      option.classList.remove('li-no-check');
      checkIcon.classList.remove('tw-hidden');
    }
  });
}

function resetInputs(containerClass) {
  const inputs = document.querySelectorAll(`${containerClass} input`);
  inputs.forEach((input) => {
    input.value = '';
    input.classList.remove('input-typed');
    input.nextElementSibling.classList.add('tw-hidden');
  });
  const labels = document.querySelectorAll(`${containerClass} label`);
  labels.forEach((label) => label.classList.remove('selected-label'));
}

function resetTimePicker(timePicker) {
  timePicker.querySelector('p').textContent = '';
  timePicker.classList.add('tw-hidden');
  timePicker.previousElementSibling.classList.remove('tw-hidden');
  timePicker.previousElementSibling.previousElementSibling.querySelector('p').classList.remove('selected-label');
}
window.resetTimePicker = resetTimePicker;

function resetTimePickers(containerClass) {
  const timePickers = document.querySelectorAll(`${containerClass} .ctn-time-selected`);
  timePickers.forEach((timePicker) => resetTimePicker(timePicker));
}
window.resetTimePickers = resetTimePickers;

function resetToggleSwitchesOfTimePickers(containerClass) {
  const toggleSwitches = document.querySelectorAll(`${containerClass} .toggle-switch input`);
  toggleSwitches.forEach((toggleSwitch) => {
    toggleSwitch.checked = false;
    const xIcon = toggleSwitch.parentElement.querySelector('.icon-x');
    const checkIcon = toggleSwitch.parentElement.querySelector('.icon-check');
    const container = toggleSwitch.nextElementSibling.nextElementSibling;
    const label = toggleSwitch.parentElement.parentElement.previousElementSibling.previousElementSibling;
    const button = label.parentElement.nextElementSibling;
    container.previousElementSibling.style.background = '#E0E0E0';
    window.changeIcon(xIcon, checkIcon);
    container.style.marginLeft = '0px';
    label.classList.remove('selected-label');
    button.style.pointerEvents = 'none';
  });
}

function resetDays(containerClass) {
  const days = document.querySelectorAll(`${containerClass} .day`);
  days.forEach((day) => day.classList.remove('day-clicked'));
}

function systemSettingsReset() {
  const modal = document.getElementById('reset-modal-system-settings');
  modal.classList.remove('tw-hidden');
}
window.systemSettingsReset = systemSettingsReset;

function systemSettingsResetData() {
  resetButtons('.ctn-system-settings');
}
window.systemSettingsResetData = systemSettingsResetData;

function systemSettingsModalReset(event) {
  systemSettingsResetData();
  event.target.parentElement.parentElement.parentElement.classList.add('tw-hidden');
}

function wateringTimesAndDaysReset() {
  const modal = document.getElementById('reset-modal-watering-times-and-days');
  modal.classList.remove('tw-hidden');
}
window.wateringTimesAndDaysReset = wateringTimesAndDaysReset;

function wateringTimesAndDaysResetData() {
  resetInputs('.ctn-watering-times .ctn-description');
  resetButtons('.ctn-watering-times');
  resetDays('.ctn-watering-times');
  const daysOfWeek = document.querySelectorAll('.ctn-watering-times .ctn-days-of-week');
  const wateringSundays = document.querySelectorAll('.ctn-watering-times .ctn-programs .ctn-days-of-week .day-0');
  wateringSundays.forEach((sunday) => sunday.classList.add('day-clicked'));
  const intervals = document.querySelectorAll('.ctn-watering-times .ctn-interval');
  daysOfWeek.forEach((days) => days.classList.add('tw-hidden'));
  intervals.forEach((interval) => {
    interval.classList.add('tw-hidden');
    interval.querySelector('.stepper').value = 1;
    interval.querySelector('.btn-minus').classList.add('btn-blocked');
    interval.querySelector('.btn-plus').classList.remove('tw-hidden');
    interval.querySelector('.btn-plus').classList.remove('btn-blocked');
    interval.querySelector('.day-0').classList.add('day-clicked');
  });
  resetTimePickers('.ctn-watering-times');
  resetToggleSwitchesOfTimePickers('.ctn-watering-times');
}
window.wateringTimesAndDaysResetData = wateringTimesAndDaysResetData;

function wateringTimesAndDaysModalReset(event) {
  wateringTimesAndDaysResetData();
  event.target.parentElement.parentElement.parentElement.classList.add('tw-hidden');
}

function seasonalAdjustReset() {
  const modal = document.getElementById('reset-modal-seasonal-adjust');
  modal.classList.remove('tw-hidden');
}

function seasonalAdjustResetData() {
  resetInputs('.ctn-monthly-percentage');
}
window.seasonalAdjustResetData = seasonalAdjustResetData;

function seasonalAdjustModalReset(event) {
  seasonalAdjustResetData();
  event.target.parentElement.parentElement.parentElement.classList.add('tw-hidden');
}

function omitTimesAndDaysReset() {
  const modal = document.getElementById('reset-modal-omit-times-and-days');
  modal.classList.remove('tw-hidden');
}

function omitTimesAndDaysResetData() {
  resetTimePickers('.ctn-omit-times');
  resetInputs('.ctn-omit-times .blocked-dates');
  resetDays('.ctn-omit-times');
  const toggleSwitch = document.querySelector('.ctn-omit-times .toggle-switch input');
  const days = document.querySelectorAll('.ctn-days-of-week .omit-days');
  const xIcon = document.querySelector('.ctn-omit-times .icon-x');
  const checkIcon = document.querySelector('.ctn-omit-times .icon-check');
  toggleSwitch.checked = false;
  toggleSwitch.nextElementSibling.style.background = '#E0E0E0';
  days.forEach((day) => day.classList.remove('day-clicked'));
  window.changeIcon(xIcon, checkIcon);
  const daysLabel = document.querySelector('.ctn-omit-times .blocked-days .label');
  const datesLabel = document.querySelector('.ctn-omit-times .blocked-dates .label');
  daysLabel.classList.remove('selected-label');
  datesLabel.classList.remove('selected-label');
}
window.omitTimesAndDaysResetData = omitTimesAndDaysResetData;

function omitTimesAndDaysModalReset(event) {
  omitTimesAndDaysResetData();
  event.target.parentElement.parentElement.parentElement.classList.add('tw-hidden');
}

function weatherStationResetData() {
  // Uncomment when Minimun Deficit is available again
  // resetButtons(".ctn-weather-station");
  const stepper = document.querySelector('.ctn-advanced-settings .stepper');
  const stepperLabel = document.querySelector('.ctn-weather-station .ctn-weather-stepper p');
  stepperLabel.classList.remove('selected-label');
  stepper.value = '';
  stepper.classList.remove('btn-stepper-selected');
  stepper.nextElementSibling.classList.remove('btn-blocked');
  stepper.previousElementSibling.classList.remove('btn-blocked');
}
window.weatherStationResetData = weatherStationResetData;

function daylightSavingResetData() {
  resetButtons('.ctn-daylight-saving');
  resetDays('.ctn-daylight-saving');

  const dayStartLabel = document.querySelector('.ctn-daylight-saving .ctn-daylight-saving-start-day p');
  const dayEndLabel = document.querySelector('.ctn-daylight-saving .ctn-daylight-saving-end-day p');
  dayStartLabel.classList.remove('selected-label');
  dayEndLabel.classList.remove('selected-label');
}
window.daylightSavingResetData = daylightSavingResetData;

function winterizedResetData() {
  resetButtons('.ctn-winterized');
}
window.winterizedResetData = winterizedResetData;

function advancedSettingsReset() {
  const modal = document.getElementById('reset-modal-advanced-settings');
  modal.classList.remove('tw-hidden');
}

function advancedSettingsResetData() {
  weatherStationResetData();
  daylightSavingResetData();
  winterizedResetData();
}
window.advancedSettingsResetData = advancedSettingsResetData;

function advancedSettingsModalReset(event) {
  advancedSettingsResetData();
  event.target.parentElement.parentElement.parentElement.classList.add('tw-hidden');
}

const systemSettingsResetButton = document.querySelector('.ctn-system-settings .ctn-reset');
systemSettingsResetButton?.addEventListener('click', systemSettingsReset);

const wateringTimesAndDaysResetButton = document.querySelector('.ctn-watering-times .ctn-reset');
wateringTimesAndDaysResetButton?.addEventListener('click', wateringTimesAndDaysReset);

const seasonalAdjustResetButton = document.querySelector('.ctn-seasonal-adjust .ctn-reset');
seasonalAdjustResetButton?.addEventListener('click', seasonalAdjustReset);

const omitTimesAndDaysResetButton = document.querySelector('.ctn-omit-times .ctn-reset');
omitTimesAndDaysResetButton?.addEventListener('click', omitTimesAndDaysReset);

const advancedSettingsnResetButton = document.querySelector('.ctn-advanced-settings .ctn-reset');
advancedSettingsnResetButton?.addEventListener('click', advancedSettingsReset);

const resetModals = document.querySelectorAll('.reset-modal');
resetModals.forEach((modal) => modal.addEventListener('click', (event) => {
  if (
    event.target === modal
      || event.target.classList.contains('tw-btn-cancel')
  ) { modal.classList.add('tw-hidden'); }
}));

const systemSettingsResetBtn = document.getElementById('btn-reset-system-settings');
systemSettingsResetBtn?.addEventListener('click', systemSettingsModalReset);

const wateringTimesAndDaysResetBtn = document.getElementById('btn-reset-watering-times-and-days');
wateringTimesAndDaysResetBtn?.addEventListener('click', wateringTimesAndDaysModalReset);

const seasonalAdjustResetBtn = document.getElementById('btn-reset-seasonal-adjust');
seasonalAdjustResetBtn?.addEventListener('click', seasonalAdjustModalReset);

const omitTimesAndDaysResetBtn = document.getElementById('btn-reset-omit-times-and-days');
omitTimesAndDaysResetBtn?.addEventListener('click', omitTimesAndDaysModalReset);

const advancedSettingsnResetBtn = document.getElementById('btn-reset-advanced-settings');
advancedSettingsnResetBtn?.addEventListener('click', advancedSettingsModalReset);

// ** Smart Settings ** //

const zonesInProgramsResetButton = document.querySelector(
  '.ctn-smart-settings-title .ctn-reset',
);

zonesInProgramsResetButton?.addEventListener('click', () => {
  const modal = document.getElementById('reset-modal-zones-in-programs');
  modal.classList.remove('tw-hidden');
});

function zonesInProgramsResetData() {
  resetInputs('.ctn-zones-programs');
}
window.zonesInProgramsResetData = zonesInProgramsResetData;
const zonesInProgramsResetBtn = document.getElementById(
  'btn-reset-zones-in-programs',
);

zonesInProgramsResetBtn?.addEventListener('click', () => {
  zonesInProgramsResetData();
  const modal = document.getElementById('reset-modal-zones-in-programs');
  modal.classList.add('tw-hidden');
});
