function updateWaterSavingsCards(data) {
  const waterSavedCard = document.getElementById('water-saved-card');
  const moneySavedCard = document.getElementById('money-saved-card');
  waterSavedCard.querySelector('h4').innerText = `$${data.water_saved}`;
  waterSavedCard.querySelector('h4').innerText = data.water_saved === 1
    ? window.I18n.insights.water_savings.cards.water_saved.amount.one.replace('%{count}', data.water_saved)
    : window.I18n.insights.water_savings.cards.water_saved.amount.other.replace('%{count}', data.water_saved);
  moneySavedCard.querySelector('h4').innerText = `$${data.money_saved}`;
}

function updateWaterSavingsChart(data) {
  window.ApexCharts.exec('water-savings-chart', 'updateOptions', {
    series: [{ data: Object.values(data.chart_data[0].data) }, { data: Object.values(data.chart_data[1].data) }],
    xaxis: {
      categories: Object.keys(data.chart_data[0].data),
    },
  });
}

function updatePeriodOnWaterSavingsChart(periodSelected) {
  const period = periodSelected.id.split('-')[2];
  document.getElementById('export_modal-water_savings_period').value = period;
  window.$.ajax({
    url: '/api/v2/insights/water_savings_chart',
    data: { period, timeframe: window.getFilterFromChart('water_savings', 'timeframe') },
    dataType: 'json',
    type: 'GET',
    success: (response) => {
      updateWaterSavingsChart(response.data);
    },
  });
}
window.updatePeriodOnWaterSavingsChart = updatePeriodOnWaterSavingsChart;

function updateTimeframeOnWaterSavingsChart(timeframeSelected) {
  const timeframe = timeframeSelected.id.split('-')[2];
  document.getElementById('export_modal-water_savings_timeframe').value = timeframe;
  window.$.ajax({
    url: '/api/v2/insights/water_savings_chart',
    data: { timeframe, period: window.getFilterFromChart('water_savings', 'period') },
    dataType: 'json',
    type: 'GET',
    success: (response) => {
      updateWaterSavingsChart(response.data);
      updateWaterSavingsCards(response.data);
    },
  });
}
window.updateTimeframeOnWaterSavingsChart = updateTimeframeOnWaterSavingsChart;
