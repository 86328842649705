const inspectionScheduleMonths = document.querySelectorAll('#inspection-schedule .month');
inspectionScheduleMonths.forEach((month) => month.addEventListener('click', (event) => {
  event.preventDefault();
  const monthName = event.target.htmlFor.split('_')[4];
  const monthCheckbox = document.getElementById(`site_inspection_schedule_attributes_${monthName}`);
  if (monthCheckbox.checked) {
    monthCheckbox.checked = false;
    month.classList.remove('checked-month');
    month.classList.add('unchecked-month');
  } else {
    monthCheckbox.checked = true;
    month.classList.remove('unchecked-month');
    month.classList.add('checked-month');
  }
}));
