function buildExportInspectionUrl(controllerId, inspectionId, inspectionTitle) {
  const selectedOption = document.querySelector('#export_inspection_modal .li-check').id.split('-')[1];
  switch (selectedOption) {
    case 'xlsx':
      return `/controls/${controllerId}/inspections/${inspectionId}/export/${inspectionTitle}.xlsx?photos=${document.querySelector("#export_inspection_modal input[name='inspections[include_photos]']").checked}`;
    case 'xls':
      return `/controls/${controllerId}/inspections/${inspectionId}/export/${inspectionTitle}.xls`;
    case 'aspire':
      return `/controls/${controllerId}/inspections/${inspectionId}/export/aspire.xlsx`;
    default:
      return `/controls/${controllerId}/inspections/${inspectionId}/export/${inspectionTitle}.pdf?photos=${document.querySelector("#export_inspection_modal input[name='inspections[include_photos]']").checked}`;
  }
}

function downloadExportInspectionFile(controllerId, inspectionId, inspectionTitle) {
  const a = document.createElement('a');
  a.href = buildExportInspectionUrl(controllerId, inspectionId, inspectionTitle);
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function validateExportInspectionButton() {
  const button = document.querySelector('#export_inspection_modal .btn-export');
  const selectedOption = document.querySelector('#export_inspection_modal .li-check');
  if (selectedOption === null || selectedOption.classList.contains('tw-hidden')) {
    button.classList.remove('tw-btn-action-blue');
    button.classList.add('tw-btn-disabled');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  }
}
window.validateExportInspectionButton = validateExportInspectionButton;

function showExportInspectionModal(controllerId, inspectionId, inspectionTitle, hasSpire) {
  const modal = document.getElementById('export_inspection_modal');
  modal.classList.remove('tw-hidden');
  const exportButton = modal.querySelector('.btn-export');
  exportButton.classList.remove('tw-btn-action-blue');
  exportButton.classList.add('tw-btn-disabled');
  const selectedOption = document.querySelector('#export_inspection_modal .li-check');
  selectedOption?.classList.remove('li-check');
  selectedOption?.classList.add('li-no-check');
  selectedOption?.children[0].classList.add('tw-hidden');
  document.querySelector('#export_inspection_modal .ctn-dropdown button p').textContent = window.I18n.select;
  document.querySelector('#export_inspection_modal .ctn-dropdown button').classList.remove('form_option-selected');
  document.querySelector("#export_inspection_modal input[name='inspections[include_photos]']").checked = false;
  exportButton.onclick = () => {
    downloadExportInspectionFile(controllerId, inspectionId, inspectionTitle);
  };
  if (hasSpire) {
    document.querySelector('#export_inspection_modal #export_modal-aspire').classList.remove('tw-hidden');
  } else {
    document.querySelector('#export_inspection_modal #export_modal-aspire').classList.add('tw-hidden');
  }
}
window.showExportInspectionModal = showExportInspectionModal;
