const closeWelcomeModal = document.getElementById('welcome-invite-modal');
closeWelcomeModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('close-modal')) closeWelcomeModal.classList.add('tw-hidden');
});

const closeInviteModal = document.getElementById('user-with-account-invite-modal');
closeInviteModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('close-modal')) closeInviteModal.classList.add('tw-hidden');
});

const emailTo = document.getElementById('btn-mail-to');
emailTo?.addEventListener('click', (event) => {
  if (event.target === this) this.attr('href', 'mailto:support@weathermatic.com?subject=Account Owner Transfer Request');
});

const closeWelcomeHomeownerModal = document.getElementById('welcome-homeowner-modal');
closeWelcomeHomeownerModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('close-modal')) closeWelcomeHomeownerModal.classList.add('tw-hidden');
});
