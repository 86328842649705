function loadAnimationPending(selector) {
  // Resetting the innerHTML is necessary to prevent multiple loading spinners
  // from stacking up when multiple AJAX calls are made. This is done only when
  // the data has not been loaded to ensure a single loading spinner is displayed.
  if (document.querySelector(selector)) {
    document.querySelector(selector).innerHTML = '';

    window.bodymovin.loadAnimation({
      container: document.querySelector(selector),
      path: '/loading_state.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMax slice',
      },
      name: 'loading state',
    });
  }
}
window.loadAnimationPending = loadAnimationPending;

function trackHomeGoogleEvents() {
  const homeRenews = document.querySelectorAll('.home-renew_sub');
  homeRenews.forEach((link) => {
    link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeManageSubscriptions'); });
  });
  document.querySelectorAll('.home-manage_users').forEach((link) => { link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeManageUsers', { action: 'View All Users' }); }); });
  document.querySelectorAll('.home-labor_details').forEach((link) => { link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeViewSavings'); }); });
  document.querySelectorAll('.home-rain_delay').forEach((link) => { link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeSetRainDelay'); }); });
  document.querySelectorAll('.home-view_inspections').forEach((link) => { link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeInspectionsWithoutProposals', { action: 'View Inspections' }); }); });
  document.querySelectorAll('.home-manage_proposals').forEach((link) => { link.addEventListener('click', () => { window.genericGoogleTagManagerEvent('homeManageProposals'); }); });
}

document.addEventListener('DOMContentLoaded', () => {
  const homeIndex = document.getElementById('home-index');

  if (homeIndex) {
    loadAnimationPending('#labor-savings #loading-state');
    window.getLaborSavingsData();
    loadAnimationPending('#expiring-subscriptions #loading-state');
    window.getExpiringSubscriptionsData();
    trackHomeGoogleEvents();
    const totalSites = document.getElementById('today-forecast').dataset.totalSites;
    if (totalSites > 0) {
      loadAnimationPending('#today-forecast #loading-state');
      window.getTodayForecastWidgetData();
    }
    const homeBody = document.querySelector('.home-body');
    if (homeBody.dataset.manageUsers === 'true') {
      loadAnimationPending('#join_request-result #loading-state');
      window.getJoinRequest();
      loadAnimationPending('#pending_invitations-result #loading-state');
      window.getPendingInvitations();
      loadAnimationPending('#inactive_users-result #loading-state');
      window.getInactiveUsers();
    }
    if (homeBody.dataset.accessProposals === 'true') {
      loadAnimationPending('#pending-proposals #loading-state');
      window.getPendingProposalsData();
      loadAnimationPending('#inspections_without_proposals-result #loading-state');
      window.getInspectionsWithoutProposals();
    }
  }
});

function removeLoadAnimation(selector) {
  const loadAnimationContainer = document.querySelector(`${selector} .loading_data`);
  loadAnimationContainer.remove();
}
window.removeLoadAnimation = removeLoadAnimation;
