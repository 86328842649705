function isValidZipCode(zipCode) {
  return zipCode !== '00000' && /(^\d{5}$)/.test(zipCode);
}

function updateAddCardButton(sectionId) {
  const addCardButton = document.querySelector(`#${sectionId} .submit`);
  const invalidStripe = document.querySelectorAll(`#${sectionId} p[class*="not-valid-"]:not(.tw-hidden)`).length > 0;
  const invalidZip = !isValidZipCode(document.querySelector(`#${sectionId} #card-zip-element`).value);
  if (invalidStripe || invalidZip) {
    addCardButton.classList.add('tw-btn-disabled');
    addCardButton.classList.remove('tw-button-primary');
  } else {
    addCardButton.classList.remove('tw-btn-disabled');
    addCardButton.classList.add('tw-button-primary');
  }
}

function validateStripeZip(sectionId, zipElement) {
  const displayError = document.querySelector(`#${sectionId} #card-errors .not-valid-zip`);
  if (isValidZipCode(zipElement.value)) {
    zipElement.classList.remove('tw-text-red-500');
    displayError.classList.add('tw-hidden');
  } else {
    zipElement.classList.add('tw-text-red-500');
    displayError.classList.remove('tw-hidden');
  }
  updateAddCardButton(sectionId);
}

function renderStripeCardError(sectionId, error, errorType) {
  const displayError = document.querySelector(`#${sectionId} #card-errors .not-valid-${errorType}`);
  if (error) {
    displayError.classList.remove('tw-hidden');
    displayError.textContent = error.message;
  } else {
    displayError.classList.add('tw-hidden');
    displayError.textContent = '';
  }
  updateAddCardButton(sectionId);
}

function unmountStripeElements(sectionId) {
  const cardElement = window.STRIPE_ELEMENTS.getElement('cardNumber');
  if (cardElement) {
    cardElement.unmount(`#${sectionId} #card-number-element`);
    cardElement.mount(`#${sectionId} #card-number-element`);
    const cardExp = window.STRIPE_ELEMENTS.getElement('cardExpiry');
    cardExp.unmount(`#${sectionId} #card-expiry-element`);
    cardExp.mount(`#${sectionId} #card-expiry-element`);
    const cardCvc = window.STRIPE_ELEMENTS.getElement('cardCvc');
    cardCvc.unmount(`#${sectionId} #card-cvc-element`);
    cardCvc.mount(`#${sectionId} #card-cvc-element`);
  }
}

function resetStripeCardModal(sectionId) {
  document.querySelector(`#${sectionId} input[name='card-alias']`).value = '';
  document.querySelector(`#${sectionId} #card-zip-element`).value = '';
  document.querySelector(`#${sectionId} #card-errors .not-valid-zip`).classList.add('tw-hidden');
  document.querySelector(`#${sectionId} input[name='stripe[primary_payment]']`).checked = false;
  document.querySelector(`#${sectionId} input[name='stripe[owner_type]']`).checked = true;
  unmountStripeElements(sectionId);
  const addCardButton = document.querySelector(`#${sectionId} .submit`);
  addCardButton.classList.add('tw-btn-disabled');
  addCardButton.classList.remove('tw-button-primary');
}
window.resetStripeCardModal = resetStripeCardModal;

function createStripeForm(sectionId) {
  const style = {
    base: {
      color: '#32325d',
      lineHeight: '24px',
      fontFamily: '"Inter", sans-serif',
      fontSmoothing: 'never',
      fontSize: '16px',
      '::placeholder': {
        color: '#757575',
      },
    },
    invalid: {
      color: '#B94C52',
      iconColor: '#B94C52',
    },
  };
  resetStripeCardModal(sectionId);

  const cardElement = window.STRIPE_ELEMENTS.getElement('cardNumber');
  if (!cardElement) {
    const cardNumberElement = window.STRIPE_ELEMENTS.create('cardNumber', {
      style,
      placeholder: '0000 0000 0000 0000',
    });
    cardNumberElement.mount(`#${sectionId} #card-number-element`);

    const cardExpiryElement = window.STRIPE_ELEMENTS.create('cardExpiry', {
      style,
      placeholder: '00/00',
    });
    cardExpiryElement.mount(`#${sectionId} #card-expiry-element`);

    const cardCvcElement = window.STRIPE_ELEMENTS.create('cardCvc', {
      style,
      placeholder: '000',
    });
    cardCvcElement.mount(`#${sectionId} #card-cvc-element`);

    cardNumberElement.on('change', ({ error }) => {
      renderStripeCardError(sectionId, error, 'card');
    });

    cardCvcElement.on('change', ({ error }) => {
      renderStripeCardError(sectionId, error, 'cvc');
    });

    cardExpiryElement.on('change', ({ error }) => {
      renderStripeCardError(sectionId, error, 'exp');
    });

    const zipElement = document.querySelector(`#${sectionId} #card-zip-element`);
    zipElement.onkeyup = () => validateStripeZip(sectionId, zipElement);
  }
}
window.createStripeForm = createStripeForm;

function proccessStripeToken(result) {
  if (result.token) {
    window.$.ajax({
      type: 'POST',
      url: '/payment_sources',
      dataType: 'script',
      data: {
        token: result.token.id,
        alias: document.querySelector("#stripe_card_modal input[name='card-alias']").value,
        primary: document.querySelector("#stripe_card_modal input[name='stripe[primary_payment]']").checked,
        creator_type: document.querySelector("#stripe_card_modal input[name='stripe[owner_type]']:checked").value,
      },
    });
  }
}
window.proccessStripeToken = proccessStripeToken;
function createStripeToken(sectionId, callback) {
  const options = {
    address_zip: document.querySelector(`#${sectionId} #card-zip-element`).value,
  };
  window.STRIPE.createToken(window.STRIPE_ELEMENTS.getElement('cardNumber'), options).then((result) => callback(result));
}
window.createStripeToken = createStripeToken;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('payment_sources-index');
  if (body) {
    document.querySelector('#stripe_card_modal .submit').onclick = () => {
      createStripeToken('stripe_card_modal', proccessStripeToken);
    };
  }
});

function updatePaymentSources() {
  window.$.ajax({
    type: 'GET',
    url: '/payment_sources',
    dataType: 'script',
  });
}
window.updatePaymentSources = updatePaymentSources;

function updateStripePaymentSource(paymentSourceId) {
  const yearStart = new Date().getFullYear().toString().slice(0, 2);
  const date = document.querySelector('#stripe_card_edit_modal #card-expiry-element').value;
  const year = `${yearStart}${date.slice(3, 5)}`;
  const month = date.slice(0, 2);
  window.$.ajax({
    type: 'PUT',
    url: `/payment_sources/${paymentSourceId}`,
    dataType: 'script',
    data: {
      expiration_mn: month,
      expiration_yr: year,
      alias: document.querySelector("#stripe_card_edit_modal input[name='card-alias']").value,
      primary: document.querySelector("#stripe_card_edit_modal input[name='card[edit_primary_payment]']").checked,
    },
  });
}
window.updateStripePaymentSource = updateStripePaymentSource;
