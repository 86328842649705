function inspectionWithIssues() {
  const inspectionId = document.getElementById('inspection').dataset.inspectionId;
  const controllerId = document.getElementById('inspection').dataset.controllerId;
  window.$.ajax({
    type: 'GET',
    dataType: 'script',
    url: `/controls/${controllerId}/inspections/${inspectionId}/presents_issues`,
  });
}
window.inspectionWithIssues = inspectionWithIssues;

function updateInspectionStatus(inspectionStatus) {
  const inspectionId = document.getElementById('inspection').dataset.inspectionId;
  const controllerId = document.getElementById('inspection').dataset.controllerId;
  window.$.ajax({
    type: 'PUT',
    dataType: 'script',
    url: `/controls/${controllerId}/inspections/${inspectionId}`,
    data: { inspection_status: inspectionStatus },
  });
}
window.updateInspectionStatus = updateInspectionStatus;
