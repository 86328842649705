function showCoworkerForm(button) {
  document.getElementById('btn-back').classList.remove('tw-hidden');
  document.getElementById('coworker-form').classList.remove('tw-hidden');
  button.parentElement.classList.add('tw-hidden');
}
window.showCoworkerForm = showCoworkerForm;
function hideCoworkerForm(backButton) {
  backButton.classList.add('tw-hidden');
  document.getElementById('coworker-email-input').value = '';
  document.querySelector('#coworker-form .valid').classList.add('tw-hidden');
  document.querySelector('#coworker-form .not-valid').classList.add('tw-hidden');
  const button = document.getElementById('btn-request-to-join-with-email');
  button.classList.remove('tw-btn-action-blue');
  button.classList.add('tw-btn-disabled');
  const form = document.getElementById('coworker-form');
  form.classList.add('tw-hidden');
  form.previousElementSibling.classList.remove('tw-hidden');
}
window.hideCoworkerForm = hideCoworkerForm;
