function checkSiteUserSearchPlaceholder() {
  if (window.innerWidth < 576) {
    document.getElementById('search-site_user').placeholder = window.I18n.site_users.index.table.search.mobile;
  } else {
    document.getElementById('search-site_user').placeholder = window.I18n.site_users.index.table.search.desktop;
  }
}

function getSiteUsers(searchValue, page, perPage) {
  window.$.ajax({
    url: `/sites/${window.getSiteIdFromUrl()}/users`,
    data: { filter: { search: searchValue }, page, per_page: perPage },
    dataType: 'script',
    type: 'GET',
  });
}
window.getSiteUsers = getSiteUsers;

const siteUserSearchInput = document.getElementById('search-site_user');

siteUserSearchInput?.addEventListener('keyup', window.debounce(() => {
  const entrySelected = document.querySelector('#site_users-entries .li-check').id.split('-')[2];
  window.AddParamsToTailwindPagination({ 'filter[search]': siteUserSearchInput.value, page: 1 });
  getSiteUsers(siteUserSearchInput.value, 1, entrySelected);
}, 500));

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('site_users-index');
  if (body) {
    checkSiteUserSearchPlaceholder();
    const urlValues = new URL(window.location.href);
    const entrySelected = urlValues.searchParams.get('per_page');
    const searchValue = urlValues.searchParams.get('filter[search]');
    if (searchValue) document.getElementById('search-site_user').value = searchValue;
    if (entrySelected) {
      const entries = document.getElementById(`site_users-entries-${entrySelected}`);
      window.selectChosenOption(entries);
    }
    window.addEventListener('resize', () => checkSiteUserSearchPlaceholder());
  }
});
