function addDatePicker(event) {
  const datePickers = event.target.parentElement.querySelectorAll('.calendar');
  const datePickersBlocked = [];
  datePickers.forEach((datePicker) => {
    if (datePicker.classList.contains('tw-hidden')) datePickersBlocked.push(datePicker);
  });
  if (datePickersBlocked.length > 0) {
    datePickersBlocked[0].classList.remove('tw-hidden');
    datePickersBlocked[0].classList.add('ctn-date-picker');
  }
}

const addDateButton = document.querySelector('.blocked-dates button');
addDateButton?.addEventListener('click', addDatePicker);
