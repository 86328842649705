function manageUsersSetEntriesParamValue() {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (entrySelected) {
    const entry = document.getElementById(`manage_users_index-entries-${entrySelected}`);
    window.selectChosenOption(entry);
  }
}

function manageUsersSetFilterByParamValue() {
  const filterBySelected = (new URLSearchParams(window.location.search)).get('filter[account_id]');
  if (filterBySelected) {
    const filterBy = document.getElementById(`manage_users_index-filter-${filterBySelected}`);
    window.selectChosenOption(filterBy);
  }
}

const manageUsersColumnHeaders = {
  0: 'user',
  1: 'email',
  2: 'account',
};

function sortValueForManageUsersTable() {
  const headers = document.querySelectorAll('table .sorting');
  const headerSelected = document.querySelector('[class*="sorting_"]');
  const index = Array.from(headers).indexOf(headerSelected);
  const sort = headerSelected?.classList?.contains('sorting_desc') ? 'desc' : 'asc';
  return index === -1 ? '' : `${manageUsersColumnHeaders[index]} ${sort}`;
}

function manageUsersSetSortParamValue() {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(manageUsersColumnHeaders);
    const values = Object.values(manageUsersColumnHeaders);
    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}
window.manageUsersSetSortParamValue = manageUsersSetSortParamValue;
function updateManageUsersTable() {
  const searchValue = document.getElementById('search-manage-users-index').value;
  const accountId = document.querySelector('#manage_users_index-filter .li-check').id.split('-')[2];
  const perPage = document.querySelector('#manage_users_index-entries .li-check').id.split('-')[2];
  const sort = sortValueForManageUsersTable();
  window.$.ajax({
    url: '/manage_users',
    data:
    {
      search: searchValue,
      filter: { account_id: accountId },
      per_page: perPage,
      sort,
    },
    type: 'GET',
    dataType: 'script',
  });
}

window.updateManageUsersTable = updateManageUsersTable;

function updateManageUsersUrlWithEntries() {
  const entries = document.querySelector('#manage_users_index-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateManageUsersUrlWithEntries = updateManageUsersUrlWithEntries;

function updateManageUsersUrlWithFilter() {
  const filterBy = document.querySelector('#manage_users_index-filter .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { 'filter[account_id]': filterBy, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}

window.updateManageUsersUrlWithFilter = updateManageUsersUrlWithFilter;

function manageUsersSetSearchInputListenerAndValue() {
  const manageUsersSearchInputParam = (new URLSearchParams(window.location.search)).get('search');
  const manageUsersSearchInput = document.getElementById('search-manage-users-index');
  manageUsersSearchInput.value = manageUsersSearchInputParam;
  manageUsersSearchInput?.addEventListener('keyup', window.debounce(() => {
    window.AddParamsToTailwindPagination({ search: manageUsersSearchInput.value, page: 1 });
    updateManageUsersTable();
  }, 500));
}

function executeManageUsersSort(event) {
  const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
  const classesList = event.target.classList;
  const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
  const sortStr = `${manageUsersColumnHeaders[index]} ${sort}`;
  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}`, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  updateManageUsersTable();
}

window.executeManageUsersSort = executeManageUsersSort;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('manage-users-index');
  if (body) {
    manageUsersSetEntriesParamValue();
    manageUsersSetFilterByParamValue();
    manageUsersSetSearchInputListenerAndValue();
    manageUsersSetSortParamValue();
    document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', executeManageUsersSort);
    });
  }
});
