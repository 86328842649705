function displayOption(event) {
  const dropdownElement = event.target.parentElement.parentElement.parentElement;
  const daysElement = dropdownElement.nextElementSibling;

  // Interval program type of Watering Times and Days section was removed
  // const intervalDaysElement = dropdownElement.nextElementSibling.nextElementSibling;

  daysElement.classList.add('tw-hidden');

  // Interval program type of Watering Times and Days section was removed
  // intervalDaysElement.classList.add("tw-hidden");
  // intervalDaysElement.querySelector(".ctn-days-of-week").classList.add("tw-hidden");

  if (event.target.id === '1') {
    daysElement.classList.remove('tw-hidden');
    daysElement.classList.add('tw-flex');
  }

  // Interval program type of Watering Times and Days section was removed
  // if (event.target.id === "3") {
  //   intervalDaysElement.classList.remove("tw-hidden");
  //   intervalDaysElement.querySelector(".ctn-days-of-week").classList.remove("tw-hidden");
  //   intervalDaysElement.querySelector(".ctn-days-of-week").classList.add("tw-flex");
  // };
}

const programTypeOptions = document.querySelectorAll('.ctn-watering-times .ctn-program .dropdown-options li');
programTypeOptions.forEach((programTypeOption) => programTypeOption.addEventListener('click', displayOption));
