function fetchShoppingCart() {
  window.$.ajax({
    url: '/shopping_cart/summary',
    data: { filter: { status: document.querySelector('#cart_item_status .li-check').id.split('-')[1] } },
    dataType: 'script',
    type: 'GET',
  });
}
window.fetchShoppingCart = fetchShoppingCart;

function updateShoppingCart() {
  setInterval((id) => {
    if (document.getElementById('pending_cart_items').value > 0) {
      fetchShoppingCart();
    } else {
      clearInterval(id);
    }
  }, 3000);
}
window.updateShoppingCart = updateShoppingCart;

function retryCart() {
  window.$.ajax({
    url: '/shopping_cart/retry_failed',
    dataType: 'script',
    type: 'POST',
  });
}
window.retryCart = retryCart;

function retryCartItem(cartItemId) {
  window.$.ajax({
    url: `/cart/cart_items/${cartItemId}/retry`,
    dataType: 'script',
    type: 'POST',
  });
}
window.retryCartItem = retryCartItem;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('shopping_cart-summary');
  if (body) {
    updateShoppingCart();
  }
});
