function updateUserGoals() {
  const url = document.getElementById('set-user-goal-modal').dataset.url;
  const proposalsValue = document.querySelector('input[name=proposals-value]').value;
  const proposalsAcceptedValue = document.querySelector('input[name=proposals-accepted-value]').value;
  const proposalsAcceptanceRate = document.querySelector('input[name=proposals-acceptance-rate]').value;
  const inspectionGoals = document.querySelector('input[name=inspection-goals]').value;
  const chosenOption = document.querySelector('#set-user-goal-modal .dropdown-options .li-check').id.split('-')[1];
  const userGoals = {};
  if (chosenOption === 'proposals') {
    userGoals.proposals_value = proposalsValue;
    userGoals.proposals_accepted_value = proposalsAcceptedValue;
    userGoals.proposals_acceptance_rate = proposalsAcceptanceRate;
  } else {
    userGoals.inspection_goals = inspectionGoals;
  }
  window.$.ajax({
    url,
    data: { user_goals: userGoals },
    dataType: 'script',
    type: 'PATCH',
  });
}
window.updateUserGoals = updateUserGoals;
