function getNewBranchUsers(searchValue, page, perPage) {
  const data = document.getElementById('branches-add-users').dataset;
  window.$.ajax({
    url: `/accounts/${data.accountId}/branches/${data.branchId}/branch_users/users`,
    data: {
      filter: { search: searchValue },
      per_page: perPage,
      page,
    },
    dataType: 'script',
    type: 'GET',
  });
}
window.getNewBranchUsers = getNewBranchUsers;
function newBranchUsersSetEntriesParamValue() {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (entrySelected) {
    const entries = document.getElementById(`new_branch_users-entries-${entrySelected}`);
    window.selectChosenOption(entries);
  }
}

function newBranchUsersSetSearchInputListenerAndValue() {
  const searchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const searchInput = document.getElementById('new-branch-users-search');
  searchInput.value = searchInputParam;

  searchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#new_branch_users-entries .li-check').id.split('-')[2];
    window.AddParamsToTailwindPagination({ 'filter[search]': searchInput.value });
    getNewBranchUsers(searchInput.value, 1, entrySelected);
  }, 500));
}

function updateEntriesForNewBranchUsersTable() {
  const entrySelected = document.querySelector('#new_branch_users-entries .li-check').id.split('-')[2];
  const searchValue = document.getElementById('new-branch-users-search').value;
  getNewBranchUsers(searchValue, 1, entrySelected);
}
window.updateEntriesForNewBranchUsersTable = updateEntriesForNewBranchUsersTable;
function updateNewBranchUsersUrlWithEntries() {
  const entries = document.querySelector('#new_branch_users-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateNewBranchUsersUrlWithEntries = updateNewBranchUsersUrlWithEntries;
document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('branch_users-users');
  if (body) {
    newBranchUsersSetEntriesParamValue();
    newBranchUsersSetSearchInputListenerAndValue();
  }
});

function activateAddUsersButton() {
  const button = document.getElementById('add-users-button');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-btn-action-blue');
}
window.activateAddUsersButton = activateAddUsersButton;
function getSelectedBranchUsers() {
  const selectedUsers = document.querySelectorAll("[id*=\"not_branch_user\"][type='checkbox']:checked");
  return Array.from(selectedUsers).map((user) => {
    const selectedPermissions = user.parentElement.parentElement.querySelectorAll(".user_permissions [id*='controller-'][type='checkbox']:checked:not(:disabled)");
    const permissions = [];
    selectedPermissions.forEach((permission) => permissions.push(permission.id.split('-')[1]));
    return { user_id: user.id.split('-')[1], permissions };
  });
}

function addUsersToBranches(newBranch) {
  const data = document.getElementById('branches-add-users').dataset;
  const users = getSelectedBranchUsers();
  const title = users.length === 1 ? window.I18n.branch.manage.users.add.loader.title.slice(0, -1) : window.I18n.branch.manage.users.add.loader.title;
  document.querySelector('#branches-new-users-list .loading_component .title').innerHTML = title;
  document.querySelector('#branches-new-users-list .loading_component').classList.remove('tw-hidden');
  window.loadAnimationPending('#branches-new-users-list #loading-state');

  window.$.ajax({
    url: `/accounts/${data.accountId}/branches/${data.branchId}/branch_users`,
    data: { users, new_branch: newBranch },
    dataType: 'script',
    type: 'POST',
    error: () => {
      document.querySelector('#branches-new-users-list .loading_component').classList.add('tw-hidden');
    },
  });
}
window.addUsersToBranches = addUsersToBranches;
function toggleAddUsersButton() {
  const checked = document.querySelectorAll("[id*=\"not_branch_user\"][type='checkbox']:checked").length;
  const button = document.getElementById('add-users-button');
  if (checked > 0) {
    button.classList.add('tw-button-primary');
    button.classList.remove('tw-btn-disabled');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
  }
}
window.toggleAddUsersButton = toggleAddUsersButton;
function verifyNewBranchSelectAllView() {
  const selectAllCheck = document.getElementById('selectAllview');
  const checked = document.querySelectorAll('[id*="controller-view"][type="checkbox"]:checked').length;
  if (checked === 0) {
    selectAllCheck.checked = false;
  } else {
    selectAllCheck.checked = true;
  }
}
let viewAllWasChecked = false;

function selectAllNewBranch(selectorAll, actionName) {
  const selectAllCheck = document.getElementById(selectorAll);
  const noneSelected = document.querySelectorAll('[id*="not_branch_user-"]:checked').length === 0;
  let viewElement;
  let userElement;
  let parentElement;
  let wasChecked = false;
  let otherPermissions;
  [...document.querySelectorAll(`[id*="controller-${actionName}"]`)].forEach((element) => {
    userElement = document.getElementById(`not_branch_user-${element.id.split('-')[2]}`);
    if (noneSelected || userElement.checked) {
      wasChecked = element.checked;
      element.checked = selectAllCheck.checked;
      viewElement = document.getElementById(`controller-view-${element.id.split('-')[2]}`);
      parentElement = element.parentElement.parentElement;
      if ((wasChecked || !viewElement.checked) && !viewAllWasChecked) {
        element.disabled = !selectAllCheck.checked;
        otherPermissions = parentElement.querySelectorAll('input[type="checkbox"]:not([id*="controller-view"]):checked').length > 0;
        viewElement.checked = otherPermissions;
        userElement.checked = otherPermissions;
        parentElement.querySelectorAll('input[type="checkbox"]:not([id*="controller-view"])').forEach((permission) => { permission.disabled = !otherPermissions; });
      }
    }
  });
  verifyNewBranchSelectAllView();
  window.verifySelectAllStatus('allNotBranchUsers', '[id*="not_branch_user"]');
  selectAllCheck.classList.remove('tw-sl-checkbox-partial');
}

window.selectAllNewBranch = selectAllNewBranch;

function selectNewBranchUser(userId) {
  const checkElement = document.getElementById(`not_branch_user-${userId}`);
  const permissions = checkElement.parentElement.parentElement.childNodes[7];
  if (checkElement.checked) {
    document.getElementById(`controller-view-${userId}`).checked = true;
    permissions.querySelectorAll('input[type="checkbox"]:not([id*="controller-view"])').forEach((element) => { element.disabled = false; });
  } else {
    permissions.querySelectorAll('input[type="checkbox"]').forEach((element) => {
      element.checked = false;
      element.disabled = true;
    });
  }
  window.verifySelectAllStatus('selectAllrun', '[id*="controller-run"]');
  window.verifySelectAllStatus('selectAlledit', '[id*="controller-edit"]');
  window.verifySelectAllStatus('selectAlladmin', '[id*="controller-admin"]');
  window.verifySelectAllStatus('allNotBranchUsers', '[id*="not_branch_user"]');
  verifyNewBranchSelectAllView();
}
window.selectNewBranchUser = selectNewBranchUser;

function selectAllNewBranchView() {
  const selectAllCheck = document.getElementById('selectAllview');
  viewAllWasChecked = selectAllCheck.checked;
  let userElement;
  [...document.querySelectorAll('[id*="controller-view"]')].forEach((element) => {
    element.checked = selectAllCheck.checked;
  });
  if (!selectAllCheck.checked) {
    document.querySelectorAll('#branches-new-users-list input[type="checkbox"][id*="controller-"]').forEach((element) => { element.disabled = true; element.checked = false; });
  } else {
    document.querySelectorAll('input[type="checkbox"][id*="controller-run"], input[type="checkbox"][id*="controller-admin"], input[type="checkbox"][id*="controller-edit"]').forEach((element) => { element.disabled = false; });
  }
  [...document.querySelectorAll('[id*="controller-view"]')].forEach((element) => {
    element.checked = selectAllCheck.checked;
    userElement = document.getElementById(`not_branch_user-${element.id.split('-')[2]}`);
    userElement.checked = selectAllCheck.checked;
  });
  window.verifySelectAllStatus('selectAllrun', '[id*="controller-run"]');
  window.verifySelectAllStatus('selectAlledit', '[id*="controller-edit"]');
  window.verifySelectAllStatus('selectAlladmin', '[id*="controller-admin"]');
  window.verifySelectAllStatus('allNotBranchUsers', '[id*="not_branch_user"]');
}
window.selectAllNewBranchView = selectAllNewBranchView;

function selectAllNewBranchUsers() {
  const selectAllCheck = document.getElementById('allNotBranchUsers');
  viewAllWasChecked = selectAllCheck.checked;
  if (!selectAllCheck.checked) {
    document.querySelectorAll('#branches-new-users-list input[type="checkbox"][id*="controller-"]').forEach((element) => { element.disabled = true; element.checked = false; });
    window.verifySelectAllStatus('selectAllrun', '[id*="controller-run"]');
    window.verifySelectAllStatus('selectAlledit', '[id*="controller-edit"]');
    window.verifySelectAllStatus('selectAlladmin', '[id*="controller-admin"]');
  } else {
    document.querySelectorAll('input[type="checkbox"][id*="controller-run"], input[type="checkbox"][id*="controller-admin"], input[type="checkbox"][id*="controller-edit"]').forEach((element) => { element.disabled = false; });
  }
  [...document.querySelectorAll('[id*="not_branch_user-"]')].forEach((element) => {
    element.checked = selectAllCheck.checked;
    if (selectAllCheck.checked) document.getElementById(`controller-view-${element.id.split('-')[1]}`).checked = true;
  });
  verifyNewBranchSelectAllView();
  selectAllCheck.classList.remove('tw-sl-checkbox-partial');
}
window.selectAllNewBranchUsers = selectAllNewBranchUsers;
