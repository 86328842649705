import Backbone from 'backbone';
import $ from 'jquery';
import bootbox from 'bootbox';

const SubscriptionView = Backbone.View.extend({
  el: '#subscriptions-show',
  events: {
    'click #cancel-subscription': 'cancelSubscription',
    'click #edit-mode': 'toggleEditMode',
    'click .trig-drop-item': 'dropSubscriptionItem',
    'click .trig-add-item': 'addSubscriptionItem',
    'click .trig-drop-controller': 'dropControllerFromSubscription',
    'click #trig-email-settings': 'showEmailSettings',
    'click #save-email-list': 'saveEmailSettings',
    'click #disable-email': 'disableEmail',
    'click #convert-plan-card-to-credit': 'convertPlanCardToCredit',
    'change #variable-extension-reason': 'changeExtensionReason',
    'click #extend-subscription': 'extendSubscription',
    'click #trig-allow-multiple': 'allowMultipleControllers',
    'click .trig-transfer-sub': 'transferSub',
    'click #trig-rename-subscription': 'renameSubscription',
    'click #extend-30': 'showHideExtension30Form',
  },
  initialize() {
    console.log('SUB SHOW INTI');
    this.subscriptionId = $('#subscription-id').val();
    $('#extension30-reason').on('keyup', this.enableDisableExtension30Submit);
  },
  changeExtensionReason(e) {
    const val = $(e.currentTarget).val();
    if (val === 'netsuite') {
      this.$el.find('#netsuite-sales-order-id').removeClass('hidden');
      this.$el.find('#netsuite-extension-date').removeClass('hidden');
    } else {
      this.$el.find('#netsuite-sales-order-id').addClass('hidden');
      this.$el.find('#netsuite-extension-date').addClass('hidden');
    }
  },
  renameSubscription(e) {
    e.preventDefault();
    bootbox.prompt({
      title: 'Enter a name for your Subscription:',
      value: $('#subscription-name').text(),
      callback: (res) => {
        if (res !== null) {
          window.showLoadingScreen();
          $.ajax({
            type: 'PUT',
            url: `/api/v2/subscriptions/${this.subscriptionId}`,
            data: { name: res },
            success: () => {
              window.hideLoadingScreen();
              $('#subscription-name').text(res);
              $('#subscription-name-title').text(res);
            },
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  allowMultipleControllers() {
    bootbox.confirm({
      message: 'Are you sure you want to allow multiple controllers on this subscription?',
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'PUT',
            url: `/api/v2/subscriptions/${this.subscriptionId}`,
            data: {
              allows_multiple_controllers: true,
            },
            success: () => window.location.reload(),
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  saveEmailSettings() {
    window.showLoadingScreen();
    $.ajax({
      type: 'PUT',
      url: `/api/v2/subscriptions/${this.subscriptionId}/email_preferences`,
      data: {
        email_list: $('#email-list').val(),
      },
      success: () => window.location.reload(),
      error: (jqXHR) => this.displayError(jqXHR),
    });
  },
  disableEmail() {
    window.showLoadingScreen();
    $.ajax({
      type: 'PUT',
      url: `/api/v2/subscriptions/${this.subscriptionId}/disable_email`,
      success: () => window.location.reload(),
      error: (jqXHR) => this.displayError(jqXHR),
    });
  },
  dropControllerFromSubscription(e) {
    const ctlId = $(e.currentTarget).closest('tr').data('id');

    bootbox.confirm({
      message: `Are you sure you want to move remove CTL #${ctlId} from this subscription?  It will go into a 'Pending' state and you will have to select a new payment method to maintain access.`,
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'POST',
            url: `/api/v2/subscriptions/${this.subscriptionId}/remove_ctl_from_subscription`,
            data: {
              controller_id: ctlId,
            },
            success: () => {
              window.hideLoadingScreen();
              alert('Controller was removed');
              window.location.reload();
            },
            error: (jqXHR) => {
              if (jqXHR?.responseText.startsWith('{')) {
                this.displayError(jqXHR);
              }
              window.hideLoadingScreen();
              bootbox.alert('There was an error with your request!  Please try again.');
            },
          });
        }
      },

    });
  },
  transferSub(e) {
    const ctlId = $(e.currentTarget).closest('tr').data('id');
    const xferToId = $(`#subscription-for-${ctlId}`).val();
    const xferToName = $(`#subscription-for-${ctlId}`).find('option:selected').text();
    if (!xferToId || (xferToId === '')) {
      bootbox.alert('You must specify a controller to transfer to!');
      return;
    }

    bootbox.confirm({
      message: `Are you sure you want to move controller #${ctlId} to subscription '${xferToName}'?  This may result in that target subscription being charged for the new controller addition.`,
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'POST',
            url: `/api/v2/subscriptions/${xferToId}/transfer`,
            data: {
              controller_id: ctlId,
            },
            success: () => {
              window.hideLoadingScreen();
              alert(`Transfer was successful.  You will be redirected to subscription #${xferToId} now.`);
              window.location = `/subscriptions/${xferToId}`;
            },
            error: (jqXHR) => {
              if (jqXHR?.responseText.startsWith('{')) {
                this.displayError(jqXHR);
              }
              window.hideLoadingScreen();
              bootbox.alert('There was an error with your request!  Please try again.');
            },
          });
        }
      },

    });
  },
  extendSubscription() {
    const reason = this.$el.find('#variable-extension-reason').val();
    const nsSalesOrderId = this.$el.find('#netsuite-sales-order-id').val();
    const includesFlow = this.$el.find('#includes-flow').prop('checked');
    const includesWarranty = this.$el.find('#includes-warranty').prop('checked');
    if (reason === 'none') {
      bootbox.alert('You must select an extension reason');
      return;
    }
    if ((reason === 'netsuite') && (nsSalesOrderId === '')) {
      bootbox.alert('You must enter a netsuite sales order ID');
      return;
    }
    window.showLoadingScreen();
    $.ajax({
      type: 'PUT',
      url: `/api/v2/subscriptions/${this.subscriptionId}`,
      data: {
        end_date: this.$el.find('#extended-date').val(),
        reason,
        includes_flow: includesFlow,
        includes_warranty: includesWarranty,
        netsuite_sales_order_id: nsSalesOrderId,
      },
      success: () => window.location.reload(),
      error: (jqXHR) => this.displayError(jqXHR),
    });
  },
  cancelSubscription(e) {
    e.preventDefault();
    bootbox.confirm({
      message: 'Halting this subscription will disable your feature access, unless you replace it with a new payment method.',
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'DELETE',
            url: `/api/v2/subscriptions/${this.subscriptionId}`,
            success: () => window.location.reload(),
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  convertPlanCardToCredit(e) {
    e.preventDefault();
    bootbox.confirm({
      message: 'This will result in this subscription being halted, and an account credit being issued for the balance on the plan card.  Are you sure you want to do this?',
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'POST',
            url: `/api/v2/subscriptions/${this.subscriptionId}/convert_plan_card_to_stripe_credit`,
            success: () => window.location.reload(),
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  toggleEditMode(e) {
    console.log('EDIT MODE ENTER');
    e.preventDefault();
    const target = $(e.currentTarget);
    if (target.text() === 'Edit') {
      this.intoEditMode();
    } else {
      this.outOfEditMode();
    }
  },
  intoEditMode() {
    console.log('EDIT MODE');
    $('.edit-field').css('display', 'block');
    $('.edit-field-tr').css('display', 'contents');
    $('#edit-mode').text('Done');
    $('.edit-source').removeClass('tw-hidden');
    $('.show-source').addClass('tw-hidden');
  },
  outOfEditMode() {
    $('.edit-field').css('display', 'none');
    $('.edit-field-tr').css('display', 'none');
    $('#edit-mode').text('Edit');
    if ($('#edit_payment_sources_dropdown').length > 0) {
      this.editPaymentMethod();
    }
  },
  editPaymentMethod() {
    const paymentMethodId = $('#edit_payment_sources_dropdown .li-check')[0].id.split('-')[1];
    if ($('.payment_source')[0].getAttribute('data-payment-source-id') !== paymentMethodId) {
      window.showLoadingScreen();
      $.ajax({
        type: 'PUT',
        url: `/subscriptions/${this.subscriptionId}/update_payment_method`,
        data: { payment_source_id: paymentMethodId },
        dataType: 'script',
        success: () => window.hideLoadingScreen(),
        error: (jqXHR) => this.displayError(jqXHR),
      });
    }
    $('.edit-source').addClass('tw-hidden');
    $('.show-source').removeClass('tw-hidden');
  },
  showEmailSettings() {
    $('#email-setting').toggleClass('hidden');
  },
  showHidePaymentMethod() {
    $('#payment-method-setting').toggleClass('hidden');
  },
  showHideExtension30Form(e) {
    e.preventDefault();
    $('#extension30-form').toggleClass('hidden');
  },
  addSubscriptionItem(e) {
    const targ = $(e.currentTarget);
    const planId = targ.closest('tr').data('id');
    bootbox.confirm({
      message: 'Adding this item will increase the cost of your subscription immediately.  Do you wish to continue?',
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'POST',
            url: `/api/v2/subscriptions/${this.subscriptionId}/subscription_items`,
            data: { plan: planId },
            success: () => {
              console.log('added item!');
              window.location.reload();
            },
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  dropSubscriptionItem(e) {
    const targ = $(e.currentTarget);
    const planId = targ.closest('tr').data('id');
    bootbox.confirm({
      message: 'Dropping this item from your subscription will take effect immediately.  Existing charges will be pro-rated toward your next bill.  Do you want to remove this item?',
      callback: (result) => {
        if (result) {
          window.showLoadingScreen();
          $.ajax({
            type: 'DELETE',
            url: `/api/v2/subscriptions/${this.subscriptionId}/subscription_items`,
            data: { plan: planId },
            success: () => window.location.reload(),
            error: (jqXHR) => this.displayError(jqXHR),
          });
        }
      },
    });
  },
  displayError(jqXHR) {
    window.hideLoadingScreen();
    const returnObj = JSON.parse(jqXHR.responseText);
    bootbox.alert(returnObj.subscription.error_message);
  },
  enableDisableExtension30Submit() {
    const charCount = $('#extension30-reason').val().length;
    if (charCount >= 10) {
      $('#extension30-submit').prop('value', 'Submit').prop('disabled', false);
    } else {
      $('#extension30-submit').prop('value', 'Provide a reason to extend').prop('disabled', true);
    }
  },
});
export default SubscriptionView;
