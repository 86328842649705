import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTInstructionsView = Backbone.View.extend({
  el: 'body',
  events: {
    'change .ransack': Shared.updateRansackFilter,
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click .remove-filter': Shared.removeFilter,
    'click #create_filter_report': Shared.createFilterReport,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #export': Shared.exportFile,
  },
  initialize() {
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      return Shared.showFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      bAutoWidth: false,
      order: [[0, 'desc']],
      responsive: false,
      searching: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/instructions.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            $(`#${key}`).val(val);
          });
        },
      },

      columns: [
        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        { data: 'user', name: 'user', visible: window.COLUMNS.user.visible },
        { data: 'type_common_name', name: 'type_common_name', visible: window.COLUMNS.type_common_name.visible },
        {
          data: 'controller_id', name: 'controller_id', visible: window.COLUMNS.controller_id.visible, orderable: false,
        },
        {
          data: 'aircard_id', name: 'aircard_id', visible: window.COLUMNS.aircard_id.visible, orderable: false,
        },
        { data: 'status_common_name', name: 'status_common_name', visible: window.COLUMNS.status_common_name.visible },
        {
          data: 'description', name: 'description', visible: window.COLUMNS.description.visible, orderable: false,
        },
        { data: 'delay_run_time', name: 'delay_run_time', visible: window.COLUMNS.delay_run_time.visible },
        { data: 'started_at', name: 'started_at', visible: window.COLUMNS.started_at.visible },
        { data: 'ended_at', name: 'ended_at', visible: window.COLUMNS.ended_at.visible },
        {
          data: 'is_command', name: 'is_command', visible: window.COLUMNS.is_command.visible, orderable: false,
        },
        {
          data: 'is_overnight', name: 'is_overnight', visible: window.COLUMNS.is_overnight.visible, orderable: false,
        },
        {
          data: 'is_abbreviated_receive', name: 'is_abbreviated_receive', visible: window.COLUMNS.is_abbreviated_receive.visible, orderable: true,
        },
        {
          data: 'controller_name', name: 'controller_name', visible: window.COLUMNS.controller_name.visible, orderable: true,
        },
        {
          data: 'controller_site_name', name: 'controller_site_name', visible: window.COLUMNS.controller_site_name.visible, orderable: true,
        },
        {
          data: 'controller_timezone', name: 'controller_timezone', visible: window.COLUMNS.controller_timezone.visible, orderable: true,
        },
        {
          data: 'controller_modem_version', name: 'controller_modem_version', visible: window.COLUMNS.controller_modem_version.visible, orderable: true,
        },
        {
          data: 'controller_bootloader_version', name: 'controller_bootloader_version', visible: window.COLUMNS.controller_bootloader_version.visible, orderable: true,
        },
        {
          data: 'message', name: 'message', visible: window.COLUMNS.message.visible, orderable: true,
        },
        {
          data: 'controller_version', name: 'controller_version', visible: window.COLUMNS.controller_version.visible, orderable: true,
        },
        {
          data: 'pickup', name: 'pickup', visible: window.COLUMNS.pickup.visible, orderable: false,
        },
        {
          data: 'exec', name: 'exec', visible: window.COLUMNS.exec.visible, orderable: false,
        },
        {
          data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
        },
      ],
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    return $('.dataTables_filter input').off();
  },
});
export default DTInstructionsView;
