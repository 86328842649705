function showSignalDetail(controllerId, button) {
  const health = document.getElementById(`health_detail-${controllerId}`);
  if (health.classList.contains('tw-hidden')) {
    button.querySelector('path').setAttribute('d', 'M3.33268 10L7.99935 5.33333L12.666 10');
    button.querySelector('p').innerText = window.I18n.hide_details;
    document.querySelectorAll('[id*="health_detail-"]:not(.tw-hidden)').forEach((element) => {
      const elementButton = element.previousElementSibling.querySelector('button');
      elementButton.querySelector('path').setAttribute('d', 'M12.6673 6L8.00065 10.6667L3.33398 6');
      elementButton.querySelector('p').innerText = window.I18n.show_details;
      element.classList.add('tw-hidden');
    });
    health.classList.remove('tw-hidden');
  } else {
    button.querySelector('path').setAttribute('d', 'M12.6673 6L8.00065 10.6667L3.33398 6');
    button.querySelector('p').innerText = window.I18n.show_details;
    health.classList.add('tw-hidden');
  }
}
window.showSignalDetail = showSignalDetail;

function getSignalSort() {
  const sortColumn = document.querySelector('#portfolio_signal_health #content h3[class*="sorting_"]');
  if (!sortColumn) {
    return null;
  }

  const sortField = sortColumn.id.split('-col')[0];
  const sortDirection = sortColumn.classList.contains('sorting_desc') ? 'DESC' : 'ASC';

  return `${sortField} ${sortDirection}`;
}

function getInsightsPortfolioSignal(updateAll = false) {
  document.querySelector('#portfolio_signal_health #content').classList.add('tw-hidden');
  document.querySelector('#portfolio_signal_health #empty').classList.add('tw-hidden');
  document.querySelector('#portfolio_signal_health #loading').classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/controllers_signal',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id').value, update_overview: updateAll, sort: getSignalSort() },
    dataType: 'script',
  });
}
window.getInsightsPortfolioSignal = getInsightsPortfolioSignal;
