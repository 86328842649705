function updatePeriodOnYearByYearChart() {
  const period = document.querySelector('#year_by_year-period .dropdown-options .li-check').id.split('-')[2];
  document.getElementById('export_modal-year_by_year_period').value = period;
  window.$.ajax({
    url: '/api/v2/insights/year_by_year_chart',
    data: { year_by_year_period: period, branch_id: document.querySelector('#select_branch #branch_id').value },
    dataType: 'json',
    type: 'GET',
    success: (response) => {
      window.ApexCharts.exec('yoy-chart', 'updateOptions', {
        series: response.data.yoy_data,
        xaxis: {
          categories: response.data.categories,
        },
      });
    },
  });
}
window.updatePeriodOnYearByYearChart = updatePeriodOnYearByYearChart;
