function flashDanger(message) {
  const flashDangerEl = document.querySelector('#flash-danger');
  if (flashDangerEl) {
    flashDangerEl.closest('.row').classList.remove('hidden');
    flashDangerEl.textContent = message;
  }
}

function flashSuccess(message) {
  const flashSuccessEl = document.querySelector('#flash-success');
  if (flashSuccessEl) {
    flashSuccessEl.closest('.row').classList.remove('hidden');
    flashSuccessEl.textContent = message;
  }
}

export { flashDanger, flashSuccess };
