function findBillingUserOptions(searchValue) {
  window.$.ajax({
    url: '/sites/billing_user_options',
    data: { filter: { search: searchValue } },
    dataType: 'script',
    type: 'GET',
  });
}

const selectBillingUser = document.querySelector('#select-billing-user input');

selectBillingUser?.addEventListener('keyup', window.debounce(() => {
  findBillingUserOptions(selectBillingUser.value);
}, 500));

const billingUserDropdownIcon = document.querySelector('#select-billing-user')?.firstElementChild;
billingUserDropdownIcon?.addEventListener('click', () => {
  selectBillingUser.value = '';
  findBillingUserOptions('');
});

function updateBiliingUserOptions(userName, userId) {
  document.getElementById('billing-users-to-select').innerHTML = '';
  document.getElementById('site_billing_user_id').value = userId;
  selectBillingUser.value = userName;
  selectBillingUser.classList.add('form_input-typed');
}
window.updateBiliingUserOptions = updateBiliingUserOptions;

document.addEventListener('DOMContentLoaded', () => {
  const sitesNewPage = document.getElementById('sites-new');

  if (sitesNewPage) {
    document.addEventListener('mouseup', (e) => {
      const billingUserOptions = document.getElementById('billing-users-to-select');
      if (!e.target.closest('#billing-users-to-select')) {
        if (billingUserOptions) {
          billingUserOptions.innerHTML = '';
        }
      }
      if (selectBillingUser?.value === '') selectBillingUser.classList.remove('form_input-typed');
    });
  }

  const sitesEditPage = document.getElementById('sites-edit');

  if (sitesEditPage) {
    if (window.location.hash.split('#')[1] === 'section_customer') {
      document.getElementById('site_custom_fields').classList.remove('tw-hidden');
      document.getElementById('section_customer').scrollIntoView({ behavior: 'smooth' });
    }
  }
});
