function showPendingAircards(button) {
  button.classList.add('tw-hidden');
  button.nextElementSibling.classList.remove('tw-hidden');
  document.getElementById('pending-aircards').classList.remove('tw-hidden');
}
window.showPendingAircards = showPendingAircards;
function hidePendingAircards(button) {
  button.classList.add('tw-hidden');
  button.previousElementSibling.classList.remove('tw-hidden');
  document.getElementById('pending-aircards').classList.add('tw-hidden');
}
window.hidePendingAircards = hidePendingAircards;
function getActiveAircards(searchValue, page, perPage, sort) {
  const tab = document.getElementById('aircard-inventory').dataset.tab;
  window.$.ajax({
    url: `/aircards?tab=${tab}`,
    data:
    {
      filter: { search: searchValue },
      page,
      per_page: perPage,
      sort,
    },
    dataType: 'script',
    type: 'GET',
  });
}

function updateEntriesForActiveAircardsTable() {
  const entries = document.querySelector('#aircard_inventory-entries .li-check').id.split('-')[2];
  const searchValue = document.getElementById('search-aircard-inventory').value;
  const sortSelected = (new URLSearchParams(window.location.search)).get('sort');
  getActiveAircards(searchValue, 1, entries, sortSelected);
}
window.updateEntriesForActiveAircardsTable = updateEntriesForActiveAircardsTable;
function updateActiveAircardsUrlWithEntries() {
  const entries = document.querySelector('#aircard_inventory-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateActiveAircardsUrlWithEntries = updateActiveAircardsUrlWithEntries;
function aircardInventorySetEntriesParamValue() {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (entrySelected) {
    const entry = document.getElementById(`aircard_inventory-entries-${entrySelected}`);
    window.selectChosenOption(entry);
  }
}

function aircardInventorySetSortParamValue(columnHeaders) {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(columnHeaders);
    const values = Object.values(columnHeaders);

    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}
window.aircardInventorySetSortParamValue = aircardInventorySetSortParamValue;
function aircardInventorySetSearchInputListenerAndValue() {
  const activeAircardsSearchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const activeAircardsSearchInput = document.getElementById('search-aircard-inventory');
  activeAircardsSearchInput.value = activeAircardsSearchInputParam;

  activeAircardsSearchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#aircard_inventory-entries .li-check').id.split('-')[2];
    const sortSelected = (new URLSearchParams(window.location.search)).get('sort');
    window.AddParamsToTailwindPagination({ 'filter[search]': activeAircardsSearchInput.value });
    getActiveAircards(activeAircardsSearchInput.value, 1, entrySelected, sortSelected);
  }, 500));
}

function executeActiveAircardsSort(columnHeaders) {
  return (event) => {
    const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
    const classesList = event.target.classList;
    const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
    const sortStr = `${columnHeaders[index]} ${sort}`;

    const entry = document.querySelector('#aircard_inventory-entries .li-check').id.split('-')[2];
    const searchValue = document.getElementById('search-aircard-inventory').value;
    const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}` });
    window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
    getActiveAircards(searchValue, 1, entry, sortStr);
  };
}
window.executeActiveAircardsSort = executeActiveAircardsSort;
document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('aircards-index');
  if (body) {
    aircardInventorySetEntriesParamValue();
    aircardInventorySetSearchInputListenerAndValue();
    let columnHeaders = {};

    const tab = document.getElementById('aircard-inventory').dataset.tab;
    if (tab === 'terminated') {
      columnHeaders = {};
    } else {
      columnHeaders = {
        0: 'iccid',
        1: 'status',
        3: 'controller',
      };
      aircardInventorySetSortParamValue(columnHeaders);
      document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
        sorteableHeader.addEventListener('click', window.changeSort);
        sorteableHeader.addEventListener('click', executeActiveAircardsSort(columnHeaders));
      });
    }
  }
});
