import Backbone from 'backbone';
import $ from 'jquery';

const InspectionsProposalsIndexView = Backbone.View.extend({
  el: 'body',
  events: {
    'click .onsite_auth': 'popOnsiteAuthModal',
    'click #generate_new_proposal': 'validateNoteLength',
  },
  popOnsiteAuthModal(e) {
    const proposalId = $(e.currentTarget).attr('id');

    // reset the modal each time it is launched
    $('#customer_signature').val('');
    $('.accept_onsite').addClass('hidden');

    $('.onsite_auth_modal').modal('show');
    $('.onsite_auth_modal').on('keyup', 'textarea', () => {
      $('.accept_onsite').removeClass('hidden');
      if ($('#customer_signature').val().length === 0) {
        $('.accept_onsite').addClass('hidden');
      }
      if ($('.approval_level').val() === 'approve_all') {
        $('.accept_onsite').html('Approve');
      }
    });

    $('.onsite_auth_modal').on('click', '.accept_onsite', () => {
      const acceptor = $('#customer_signature').val();
      const approvalLevel = $('.approval_level').val();

      if (approvalLevel != null) {
        $.ajax({
          url: `/inspections_proposals/${proposalId}/accept_onsite`,
          data: { acceptor, approval_level: approvalLevel },
          type: 'POST',
          dataType: 'json',
          success: () => {
            alert('Proposal Approved Successfully');
            window.location.reload();
          },
          error: () => {
            alert('Something went wrong.');
          },
        });
      } else {
        alert('Please choose an approval level from the dropdown box');
      }
    });

    $('.onsite_auth_modal').on('change', '.approval_level', () => {
      const buttonText = $(this).find(':selected').text();
      $('.accept_onsite').html(buttonText);
    });
  },
  validateNoteLength(e) {
    const noteLength = $('#inspections_proposal_notes').val().length;
    if (noteLength > 300) {
      e.preventDefault();
      alert(`Notes must be less than 300 characters. Yours is currently --${noteLength}--. Please condense your notes.`);
    }
  },
});
export default InspectionsProposalsIndexView;
