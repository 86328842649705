import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';
import '@assets/javascripts/external/utilities.js';

const DTProposalsView = Backbone.View.extend({
  el: 'body',
  events: {
    'change .ransack': Shared.updateRansackFilter,
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click .remove-filter': Shared.removeFilter,
    'click #create_filter_report': Shared.createFilterReport,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #export': Shared.exportFile,
  },
  initialize() {
    console.log('Proposals DT initialized');
    console.log(`company use priority for proposals is ${window.USE_PROPOSAL_PRIORITY}`);
    window.view = this;

    const proposalColumns = [
      {
        className: 'proposal-checkbox',
        orderable: false,
        data: null,
        name: null,
        defaultContent: '<input type="checkbox" class="form-input" export="" style="display: none;"/>',
      },
      { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      {
        data: 'site_name', name: 'site_name', visible: window.COLUMNS.site_name.visible, orderable: false,
      },
      {
        data: 'controller_names', name: 'controller_names', visible: window.COLUMNS.controller_name.visible, orderable: false,
      },
      {
        data: 'inspection_titles', name: 'inspection_titles', visible: window.COLUMNS.inspection_title.visible, orderable: false,
      },
      { data: 'price_list', name: 'price_list', visible: window.COLUMNS.price_list.visible },
      {
        data: 'emergency', name: 'emergency', visible: window.COLUMNS.price_list.visible, orderable: false,
      },
      {
        data: 'notes', name: 'notes', visible: window.COLUMNS.notes.visible, orderable: false,
      },
      { data: 'status', name: 'status', visible: window.COLUMNS.status.visible },
      { data: 'sent_by', name: 'sent_by', visible: window.COLUMNS.sent_by.visible },
      { data: 'responder', name: 'responder', visible: window.COLUMNS.responder.visible },
      { data: 'response_notes', name: 'response_notes', visible: window.COLUMNS.response_notes.visible },
      { data: 'completed_by', name: 'completed_by', visible: window.COLUMNS.completed_by.visible },
      { data: 'created_at', name: 'created_at', visible: window.COLUMNS.created_at.visible },
      { data: 'last_sent', name: 'last_sent', visible: window.COLUMNS.last_sent.visible },
      { data: 'responded_at', name: 'responded_at', visible: window.COLUMNS.responded_at.visible },
      { data: 'completed_at', name: 'completed_at', visible: window.COLUMNS.completed_at.visible },
      { data: 'grand_total', name: 'grand_total', visible: window.COLUMNS.grand_total.visible },
      { data: 'approved_total', name: 'approved_total', visible: window.COLUMNS.approved_total.visible },
      {
        data: 'actions', name: 'actions', visible: window.COLUMNS.actions.visible, orderable: false,
      },
    ];

    if (window.USE_PROPOSAL_PRIORITY) {
      proposalColumns.splice(
        17,
        0,
        { data: 'noncritical_total', name: 'noncritical_total', visible: window.COLUMNS.noncritical_total.visible },
        { data: 'critical_total', name: 'critical_total', visible: window.COLUMNS.critical_total.visible },
      );
    }

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      return Shared.showFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[1, 'asc']],
      bAutoWidth: false,
      responsive: false,
      searching: true,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // place the proposal id inside each <tr>
      fnRowCallback(nRow, aData, iDisplayIndex) {
        const row = $(nRow);
        if (aData.can_be_combined === true) {
          row.attr('id', aData.id).attr('n', iDisplayIndex);
          row.find('.proposal-checkbox input').show();
        }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/proposals.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          if (window.getParameterByName('archived') === 'false') {
            params.ransack.archived_eq = false;
            $('#archived_eq').val(0);
          } else if (window.getParameterByName('clear') !== 'true') {
            const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
            Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));
          }

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });
          $.each(Shared.extractRansackFromUrl(), (key, val) => {
            params.ransack[key] = val;
            return $(`#${key}`).val(val);
          });
        },
      }, // This return is very important for some reason

      columns: proposalColumns,
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').off();
  },
});
export default DTProposalsView;
