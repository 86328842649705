function changeSort(event, forceOrderBy = null) {
  const header = event.target;
  const className = header.className;
  const parent = header.closest('tr') || header.closest('.headers');

  parent.querySelectorAll('th.sorting_asc, th.sorting_desc, h3.sorting_desc, h3.sorting_asc').forEach((headerSibling) => {
    headerSibling.classList.add('sorting');
    headerSibling.classList.remove('sorting_asc');
    headerSibling.classList.remove('sorting_desc');
  });

  if (className.includes('sorting_asc') || forceOrderBy === 'DESC') {
    header.classList.add('sorting_desc');
  } else {
    header.classList.add('sorting_asc');
  }
}
window.changeSort = changeSort;
