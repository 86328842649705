function showValidLabelForCoworkerEmail(email) {
  const validLabel = document.querySelector('#coworker-form .valid');
  const notValidLabel = document.querySelector('#coworker-form .not-valid');
  validLabel.classList.add('tw-hidden');
  notValidLabel.classList.add('tw-hidden');
  if (window.isValidEmail(email)) {
    validLabel.classList.remove('tw-hidden');
    validLabel.textContent = window.I18n.accounts.users.valid_email;
  } else {
    notValidLabel.classList.remove('tw-hidden');
    notValidLabel.textContent = window.I18n.accounts.users.not_valid_email;
  }
}

function enableRequestToJoinButton() {
  const button = document.getElementById('btn-request-to-join-with-email');
  const validLabel = document.querySelector('#coworker-form .valid');
  if (validLabel.classList.contains('tw-hidden')) {
    button.classList.remove('tw-btn-action-blue');
    button.classList.add('tw-btn-disabled');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  }
}
function coworkerEmailValidation(input) {
  const coworkerEmail = input.value;
  showValidLabelForCoworkerEmail(coworkerEmail);
  enableRequestToJoinButton();
}
window.coworkerEmailValidation = coworkerEmailValidation;
