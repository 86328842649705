function stepperLabelValidation() {
  const stepper = document.querySelector('.ctn-weather-station .ctn-stepper .stepper');
  const label = stepper.parentElement.previousElementSibling;
  if (stepper.value === '') {
    stepper.classList.remove('btn-stepper-selected');
    label.classList.remove('selected-label');
  } else {
    stepper.classList.add('btn-stepper-selected');
    label.classList.add('selected-label');
  }
}

function stepDown(maxValue, minValue) {
  return (event) => {
    // This will allow to click on minus icon that is inside the button and have the same result
    const minusButton = event.target.closest('.btn-minus');

    const stepper = minusButton.parentElement.parentElement.querySelector('.stepper');
    let count = stepper.value === '' ? maxValue + 1 : parseInt(stepper.value, 10);
    count -= 1;
    stepper.value = count < minValue ? minValue : count;

    minusButton.classList.toggle('btn-blocked', stepper.value === String(minValue));
    minusButton.nextElementSibling.nextElementSibling.classList.toggle('btn-blocked', stepper.value === String(maxValue));

    stepperLabelValidation();
  };
}

function stepUp(maxValue, minValue) {
  return (event) => {
    // This will allow to click on plus icon that is inside the button and have the same result
    const plusButton = event.target.closest('.btn-plus');

    const stepper = plusButton.parentElement.parentElement.querySelector('.stepper');
    let count = stepper.value === '' ? minValue - 1 : parseInt(stepper.value, 10);
    count += 1;
    stepper.value = count > maxValue ? maxValue : count;

    plusButton.classList.toggle('btn-blocked', stepper.value === String(maxValue));
    plusButton.previousElementSibling.previousElementSibling.classList.toggle('btn-blocked', stepper.value === String(minValue));

    stepperLabelValidation();
  };
}

// Interval program type of Watering Times and Days section was removed
// function setIntervalInputStepperConstraints(event) {
//   setInputConstraints(event.target, 30, 1, 1, 1);
//   event.target.nextElementSibling.classList.toggle("btn-blocked", event.target.value === "30");
//   event.target.previousElementSibling.classList.toggle("btn-blocked", event.target.value === "1");
// };

function setWeatherStationInputStepperConstraints(event) {
  window.setInputConstraints(event.target, 99, 0, 1, '');
  event.target.nextElementSibling.classList.toggle('btn-blocked', event.target.value === '99');
  event.target.previousElementSibling.classList.toggle('btn-blocked', event.target.value === '1');

  stepperLabelValidation();
}

// Interval program type of Watering Times and Days section was removed
// const intervalStepperMinusButtons = document.querySelectorAll(".ctn-watering-times .ctn-interval .ctn-stepper .btn-minus");
// intervalStepperMinusButtons.forEach((minusButton) => {
//   minusButton.classList.add("btn-blocked");
//   minusButton.addEventListener("click", stepDown(30, 1))
// });

const weatherStationStepperMinusButton = document.querySelector('.ctn-weather-station .ctn-stepper .btn-minus');
weatherStationStepperMinusButton?.addEventListener('click', stepDown(99, 0));

// Interval program type of Watering Times and Days section was removed
// const intervalStepperPlusButtons = document.querySelectorAll(".ctn-watering-times .ctn-interval .ctn-stepper .btn-plus");
// intervalStepperPlusButtons.forEach((plusButton) => plusButton.addEventListener("click", stepUp(30, 1)));

const weatherStationStepperPlusButton = document.querySelector('.ctn-weather-station .ctn-stepper .btn-plus');
weatherStationStepperPlusButton?.addEventListener('click', stepUp(99, 0));

// Interval program type of Watering Times and Days section was removed
// const intervalInputStepper = document.querySelectorAll(".ctn-watering-times .ctn-interval .ctn-stepper .stepper");
// intervalInputStepper.forEach((stepper) => stepper.addEventListener("blur", setIntervalInputStepperConstraints));

const weatherStationInputStepper = document.querySelector('.ctn-weather-station .ctn-stepper .stepper');
weatherStationInputStepper?.addEventListener('blur', setWeatherStationInputStepperConstraints);
