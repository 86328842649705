function dropdownToggle(event) {
  // This will allow to click on select icon that is inside the button and have the same result
  const button = event.target.closest('.btn-dropdown');

  const dropdown = button.parentNode.querySelector('.dropdown-options');
  if (dropdown.classList.contains('tw-hidden')) {
    dropdown.classList.remove('tw-hidden');
  } else {
    dropdown.classList.add('tw-hidden');
  }
}
window.dropdownToggle = dropdownToggle;

function selectOption(event) {
  const button = event.target.parentElement.parentElement.parentElement.querySelector('.btn-dropdown');
  button.firstChild.data = event.target.textContent;
  const label = button.previousElementSibling;
  if (label) {
    if (event.target.id === 'no change' || event.target.id.split('-')[2] === 'no_change') {
      button.classList.remove('btn-selected');
      label.classList.remove('selected-label');
    } else {
      button.classList.add('btn-selected');
      label.classList.add('selected-label');
    }
  }

  const dropdown = button.nextElementSibling;
  dropdown.classList.add('tw-hidden');

  const options = event.target.parentElement.querySelectorAll('li');
  options.forEach((option) => {
    option.firstChild.nextElementSibling.classList.add('tw-hidden');
    option.classList.remove('li-check');
    option.classList.add('li-no-check');
  });

  const checkIcon = event.target.querySelector('svg');
  checkIcon.classList.remove('tw-hidden');
  checkIcon.parentElement.classList.remove('li-no-check');
  checkIcon.parentElement.classList.add('li-check');
}

const buttons = document.querySelectorAll('.btn-dropdown');
buttons.forEach((button) => button.addEventListener('click', dropdownToggle));

const dropdownOptions = document.querySelectorAll('.global-commands .dropdown-options ul li, .quick_commands .dropdown-options ul li');
dropdownOptions.forEach((option) => option.addEventListener('click', selectOption));

document.addEventListener('mouseup', (event) => {
  const dropdowns = document.querySelectorAll('.dropdown-options');
  dropdowns.forEach((dropdown) => {
    if (!dropdown.contains(event.target)) {
      dropdown.classList.add('tw-hidden');
    }
  });
});
