import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTGlobalCommandsView = Backbone.View.extend({
  el: 'body',
  events: {
    'change .ransack': Shared.updateRansackFilter,
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click .remove-filter': Shared.removeFilter,
    'click #create_filter_report': Shared.createFilterReport,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #export': Shared.exportFile,
  },
  initialize() {
    $('#date_gt, #date_lt').datepicker({ format: 'yyyy-mm-dd' }).on('changeDate', (e) => { $(e.target).trigger('change'); });
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      return Shared.showFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[0, 'desc']],
      bAutoWidth: false,
      responsive: false,
      searching: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // Place the controller id inside each <tr>
      fnRowCallback(nRow, aData) {
        if (aData.is_fault) { $(nRow).addClass('alert-danger'); }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/global_batches.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};
          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            $(`#${key}`).val(val);
          });
        },
      }, // This return is very important for some reason

      columns: [

        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        { data: 'user', name: 'user', visible: window.COLUMNS.user.visible },
        { data: 'action', name: 'action', visible: window.COLUMNS.action.visible },
        { data: 'created_at', name: 'created_at', visible: window.COLUMNS.created_at.visible },
        { data: 'started_at', name: 'started_at', visible: window.COLUMNS.started_at.visible },
        //        {data: 'ended_at',    name: 'ended_at',    visible: window.COLUMNS.ended_at.visible},
        {
          data: 'controllers', name: 'controllers', visible: window.COLUMNS.controllers.visible, orderable: false,
        },
        {
          data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
        },
      ],
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    return $('.dataTables_filter input').off();
  },
});
export default DTGlobalCommandsView;
