import { PaginatedCollection } from '@assets/javascripts/backbone/lib/backbone_paginate.js';
import EventModel from '../models/event.js';

const EventsCollection = PaginatedCollection.extend({
  initialize(opts) {
    const options = opts || {};
    options.extraParams = options.extraParams || {};

    if (options.site_id) {
      options.extraParams.site_id = options.site_id;
    } else if (options.controller_id) {
      options.extraParams.controller_id = options.controller_id;
    }

    this.model = EventModel;
    this.modelCollectionName = 'events';
    this.baseUrl = '/api/v2/events';
    this.extraParams = options.extraParams;
    PaginatedCollection.prototype.initialize.call(this);
  },
  search(term) {
    console.log(`Searching for ${term}`);
    this.extraParams = this.extraParams || {};
    this.extraParams.q = term;
    this.fetch();
  },
});
export default EventsCollection;
