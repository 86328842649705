import Reporting from '@assets/javascripts/backbone/lib/include/reporting.js';
import Backbone from 'backbone';
import SmartLinkSeries from '@assets/javascripts/backbone/lib/include/smartlink_series.js';
import ReportShowEventsTemplate from '@assets/javascripts/backbone/templates/reports/report_show_events.ejs';
import ReportShowTemplate from '@assets/javascripts/backbone/templates/reports/report_show.ejs';
import ReportShowInputsTemplate from '@assets/javascripts/backbone/templates/reports/report_show_inputs.ejs';
import $ from 'jquery';
import ReportModel from '@assets/javascripts/backbone/models/report.js';
import EventsCollection from '@assets/javascripts/backbone/collections/events_collection.js';
import 'devbridge-autocomplete';

const ReportView = Backbone.View.extend({
  report: (typeof (window.default_report) !== 'undefined') ? window.default_report : null,
  sites: [],
  chart_table: null, // Triple level array with YYYY-MM-DD dates as keys
  chart_table_value_row_size: 3,
  chart_series: [],
  chart_series_processed: 0, // How many columns have been inserted?
  chart_forecasted_series: [],
  eventTypes: {
    timeline: [1, 2, 3, 4, 5, 8, 12],
    weather: [4, 6, 9, 10, 11],
    usage: [0, 7, 13, 14, 15, 16, 17, 18],
  },
  save_panel: {
    save_button: false,
    save_as_button: false,
    reset_button: false,
  },
  unitLabel: {
    integer: {
      weather: -1,
      usage: -1,
      gpm: -1,
    },
    long: {
      weather: '',
      usage: 'Usage',

      gpm: '',
    },
    short: {
      weather: '',
      usage: '',
      gpm: '',
    },
  },
  eventSearching: 0,
  annotateEvents: false,
  requested: {
    start: null,
    end: null,
    processing: setTimeout,
  },
  range: {
    start: null,
    end: null,
    interval: 'd', // d|w|m|y
  },
  selected: {
    row: null,
    col: null,
  },
  axes: { subjects: [] },
  google_Table: null, // Given as input to Google Charts
  google_lineChart: null, // Google LineChart Object
  google_lineChartOptions: { // Google Chart UI options & config
    height: '400',
    legend: 'none',
    bar: {
      groupWidth: 50,
    },
    hAxis: {
      minValue: null,
      maxValue: null,
      gridlines: {
        color: '#464D54',
      },
    },
    vAxes: {
      0: {
        title: '', // Placeholder
        logScale: false,
      },
      1: {
        title: '', // Placeholder
        logScale: false,
      },
      textStyle: {
        fontName: 'Helvetica Neue',
      },
    },
    vAxis: {
      gridlines: {
        color: '#464D54',
      },
    },
    series: {},
    colors: [],
    isStacked: true,
    //        curveType: 'function'
    chartArea: {
      width: '85%',
      height: '80%',
      backgroundColor: '#1f2d38',
    },
    lineWidth: 3,
    annotations: {
      textStyle: {
        color: '#FFFFFF',
        auraColor: '#black',
      },
    },
    animation: {
      duration: 250,
    },
  },
  defaultColors: ['#ff00ff', '#00cc00', '#ff9900', '#660099', '#ff0099', '#ffff00'],
  el: '#backbone-actions',
  ui: {
    // -- Panels
    event_list: '#event_list',
    event_alert: '#event_selection_alert',
    input_list: '#input_list',
    controller_row: '#controller_row',
    source_row: '#source_row',
    // -- Form Inputs
    input_data_type: '#type',
    input_controller_id: '#controllers',
    input_zone_number: '#zones',
    input_range_start: '#range_start',
    input_range_end: '#range_end',
    input_range_interval: '#interval',
    input_line_color: '#line_color',
    input_site_search: '#site_search',
    input_cancel_site_search: '#cancel_site_search',
    input_site_id: '#sites',
    input_add_button: '#add_button',
    input_weather_type: '#weather_type',
    input_usage_type: '#usage_type',
    input_label: '#input_label',
    input_display: '#axis_display',
    input_advanced_options: '#advanced_options',
    input_annotate_chart: '#annotate_chart',
    input_remove_selection: '#remove_event_selection',
    // -- Action Buttons
    action_save: '#save',
    action_save_as: '#save-as',
    action_reset: '#reset',
    action_export: '#export-report',
  },
  events: {
    'change #range_start': 'event_date_range_change',
    'change #range_end': 'event_date_range_change',
    'change #interval': 'event_date_interval_change',
    'change #sites': 'event_sites_select_change',
    'change #controllers': 'event_controller_change',
    'change #type': 'show_correct_form_inputs',
    'change #zones': 'show_correct_form_inputs',
    'click #input_list li button': 'event_remove_input',
    'click #input_list li': 'event_input_click',
    'click #add_button': 'event_add_button_click',
    'click #range-presets a': 'event_click_range_preset',
    'click #cancel_site_search': 'event_cancel_site_search',
    'click #advanced_options': 'event_show_advanced_options',
    'click #annotate_chart': 'event_annotate_chart_click',
    'click #remove_event_selection': 'event_remove_event_selection',
    'change #weather_type': 'show_correct_form_inputs',
    'change #usage_type': 'show_correct_form_inputs',
    'click #save': 'action_save',
    'click #save-as': 'action_save_as',
    'click #reset': 'action_reset',
    'change #report_id': 'change_report',
  },
  initialize() {
    this.events2_template = ReportShowEventsTemplate;
    this.template = ReportShowTemplate;
    this.input_template = ReportShowInputsTemplate;
    this.set_unit_labels();
    this.store_default_site();
    this.set_default_date_range();
    this.chart_load_series();
    this.rebuild_axes();
    this.google_chart_initialize();
  },
  set_unit_labels() {
    this.unitLabel = Reporting.set_unit_labels(this.unitLabel, window.temp_units, window.volume_measure);
  },

  store_default_site() {
    this.sites[String(window.default_site.id)] = window.default_site;
  },

  google_chart_initialize() {
    this.google_lineChart = new window.google.visualization.ComboChart(document.getElementById('backbone-chart'));
    window.google.visualization.events.addListener(this.google_lineChart, 'select', (event) => this.event_chart_click(event));
  },

  rebuild_axes(_series = this.chart_series.series) {
    this.axes = Reporting.rebuild_axes(this.axes, _series);
  },

  has_report_items() {
    return this.report && Object.hasOwn(this.report, 'report_items');
  },

  load_report_items_series() {
    const reportItems = this.report.report_items.map((item) => ({
      id: item.id,
      type: item.report_type,
      key: item.key,
      axis_subject: item.axis_subject,
      axis_display: item.axis_display,
      site_id: item.site_id,
      controller_id: item.controller_id,
      zone_number: item.zone_id,
      label: item.label,
      color: item.color,
    }));

    return this.chart_series.bulkAdd(reportItems);
  },

  load_defaultchart_series() {
    const defaultSiteHasWeatherStation = window.default_site.controllers.find((c) => c.sw_status > 0);
    if (defaultSiteHasWeatherStation) { this.load_default_weather_station_inputs(); }

    const runRimes = {
      type: 'usage',
      key: 'seconds_ran',
      axis_subject: 'time',
      axis_display: 'bars',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: Reporting.labelName(window.default_site, null, null, null, 'Run Time'),
      color: '#38BEFC',
    };

    return this.chart_series.addOne(runRimes);
  },

  load_default_weather_station_inputs() {
    const highTemp = {
      type: 'weather',
      key: 'high_temp',
      axis_subject: 'temp',
      axis_display: 'line',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: Reporting.labelName(window.default_site, null, null, null, 'High Temp'),
      color: '#FCA338',
    };

    const lowTemp = {
      type: 'weather',
      key: 'low_temp',
      axis_subject: 'temp',
      axis_display: 'line',
      site_id: window.default_site.id,
      controller_id: null,
      zone_number: null,
      label: Reporting.labelName(window.default_site, null, null, null, 'Low Temp'),
      color: '#157FAF',
    };

    this.chart_series.bulkAdd([highTemp, lowTemp]);
  },

  set_default_date_range() {
    let end; let start;
    const anySiteHasForecasting = this.sites.some((site) => site.forecast_et_enabled);

    if (anySiteHasForecasting != null) {
      start = Date.today().addDays(-7);
      end = Date.today().addDays(7);
    } else {
      start = Date.today().addDays(-30);
      end = Date.today();
    }

    this.range.start = start;
    this.range.end = end;
    this.requested.start = start;
    this.requested.end = end;
    this.google_lineChartOptions.hAxis.minValue = start;
    this.google_lineChartOptions.hAxis.maxValue = end;
  },

  render() {
    this.chart_async_load_and_draw();
    this.render_template();
    this.render_controllers_dropdown(window.default_site.id);
    this.chart_related_views_render_series();
    this.load_ui_bindings();
    this.initialize_site_search();
  },

  render_template() {
    this.$el.html(this.template({
      range_start: Reporting.stringThisDate(this.range.start),
      range_end: Reporting.stringThisDate(this.range.end),
      usage_label: this.unitLabel.long.usage,
      save_panel: this.save_panel,
      days_report_history: window.days_report_history,
    }));
  },

  load_ui_bindings() {
    $(this.ui.input_line_color).spectrum(); // Render the color wheel
    if (window.days_report_history === '7') {
      $('#timeline-panel input').datepicker({ format: 'yyyy-mm-dd', startDate: '-7d', endDate: '+10d' }).on('changeDate', (e) => { e.target.value = this.formatDate(e.date); $(e.target).trigger('change'); });
    } else if (window.days_report_history === '30') {
      $('#timeline-panel input').datepicker({ format: 'yyyy-mm-dd', startDate: '-30d', endDate: '+10d' }).on('changeDate', (e) => { e.target.value = this.formatDate(e.date); $(e.target).trigger('change'); });
    } else {
      $('#timeline-panel input').datepicker({ format: 'yyyy-mm-dd', endDate: '+10d' }).on('changeDate', (e) => { e.target.value = this.formatDate(e.date); $(e.target).trigger('change'); });
    }
    $(this.ui.action_export).on('click', (event) => this.event_export_click(event)); // Bind export button ( outside of @$el )
    $('.popover-this').popover({ trigger: 'hover' }); // Bind the Bootstrap Popover's
    $('#report_id_move').html($('#report_id').detach());
  },
  formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  },

  chart_async_load_and_draw() {
    this.chart_reset_drawing_data();
    this.rebuild_axes();
    window.showLoadingScreen();
    this.chart_table_fetch_and_add_column_events()
      .then(() => this.chart_series_fetch_all_data()
        .then(() => {
          window.hideLoadingScreen();
          this.google_chart_draw();
        }).catch(() => window.hideLoadingScreen())).catch(() => window.hideLoadingScreen());
  },

  chart_load_series() {
    this.chart_series = new SmartLinkSeries(this.unitLabel, this.range);
    if (this.has_report_items()) { this.load_report_items_series(); } else { this.load_defaultchart_series(); }
  },

  chart_reset_drawing_data() {
    this.chart_series_processed = 0;
    this.chart_forecasted_series = [];
    this.chart_series.resetTable();
  },

  chart_render_supporting_views() {
    this.chart_related_views_render_series();
    this.ui_render_selected_series();
    this.chart_related_views_render_events();
    this.setup_and_render_tooltips();
    this.display_correct_save_options();
  },

  sites_count() {
    let count = 0;
    this.sites.forEach(() => count += 1);
    return count;
  },

  chart_table_fetch_and_add_column_events() {
    let sitesProcessed = 0;

    const promises = this.sites.map((site) => this.chart_table_fetch_events_for_site(site));
    return Promise.all(promises)
      .then(() => {
        sitesProcessed += 1;
        if (sitesProcessed === this.sites_count()) {
          Promise.resolve();
        }
      });
  },

  chart_table_fetch_events_for_site(site) {
    return new Promise((_resolve, _reject) => {
      const collection = new EventsCollection();

      // Backbone's fetch does not return a Promise, so we create one ourselves
      const fetchPromise = collection.fetch({
        data: {
          start_at: Reporting.stringThisDate(this.range.start),
          end_at: Reporting.stringThisDate(this.range.end),
          site_id: site.id,
        },
        success: () => {
          collection.each((_event) => this.add_event(site, _event));
          _resolve();
        },
        error: (e) => {
          _reject(e);
        },
      });

      // If the fetchPromise fails, reject the outer Promise
      fetchPromise.catch((error) => {
        _reject(error);
      });
    });
  },

  add_event(site, _event) {
    const groupDate = Reporting.group_date(this.range.interval, _event.get('date'));
    const date = Reporting.stringThisDate(groupDate);

    // TODO
    // if (true) { // @chart_series.has_event_for_date(date)
    const dateReformatted = this.format_the_date(_event.get('date_formatted'));
    this.chart_series.addEventForDate(date, {
      id: _event.get('id'),
      date: dateReformatted,
      site_id: site.id,
      controller_id: (_event.get('controller') ? _event.get('controller').id : undefined),
      zone_number: (_event.get('zone') ? _event.get('zone').number : undefined),
      event_type: _event.get('event_type'),
      event_text: _event.get('as_text'),
    });
    // }
  },

  any_range_dates_for_future() {
    return (new Date(this.range.start) >= Date.today()) || (new Date(this.range.end) >= Date.today());
  },

  async chart_series_fetch_all_data() {
    this.chart_forecasted_series = [];
    const baseSeries = this.chart_series.withoutForecastedAndDeficits();
    this.chart_series.setSeries(baseSeries);
    this.chart_series.setRange(this.range);

    const fetches = async () => {
      if (this.chart_series.withoutForecastedAndDeficits().length === 0) {
        return;
      }

      // Create an array of promises
      const promises = this.chart_series.withoutForecastedAndDeficits().map(async (series) => {
        await this.chart_async_fetch_data_for_a_series(series);
      });

      // Use Promise.all to wait for all promises to resolve
      await Promise.all(promises);
    };

    const forecastedFetches = async () => {
      if (this.any_range_dates_for_future) {
        this.chart_series.bulkAdd(this.chart_forecasted_series);
        this.chart_series_processed += this.chart_forecasted_series.length;
      }
    };

    const deficitProcessing = async () => {
      const deficitSeries = this.chart_series.addAllDeficits();
      this.chart_series_processed += deficitSeries.length;
    };

    try {
      await fetches();
      await forecastedFetches();
      await deficitProcessing();
    } catch (err) {
      console.error(err);
    }
  },

  chart_async_fetch_data_for_a_series(series, seriesIndex, rowIndex) {
    return new Promise((resolve, reject) => {
      const collection = Reporting.select_collection(series);
      if (collection) {
        collection.fetch({
          data: Reporting.build_params(this.range, series),
          success: () => {
            const pastModels = collection.models.filter((model) => !model.get('forecasted'));
            this.chart_series.setModelsForSeries(series, pastModels);
            this.chart_series_processed += 1;

            const forecastedModels = collection.models.filter((entity) => entity.get('forecasted'));
            if (forecastedModels) {
              this.chart_fetch_callback_for_forecasted(series, forecastedModels);
            }

            resolve(seriesIndex, rowIndex);
          },
          error: (e) => {
            console.error(e);
            reject(e);
          },
        });
      } else {
        this.chart_series_processed += 1;
        resolve(seriesIndex, rowIndex);
      }
    });
  },

  chart_fetch_callback_for_forecasted(series, forecastedModels) {
    const type = `forecasted_${series.type}`;
    const label = this.chart_series_label(series);
    const exists = this.chart_forecasted_series.find((_series) => _series && _series.type === type && _series.key === series.key);

    if (!exists) {
      const clonedSeries = { ...series };
      const forecastedSeries = {
        ...clonedSeries,
        type,
        past_id: series.id,
        id: series.id + 2000,
        models: forecastedModels,
        color: Reporting.adjustLuminance(clonedSeries.color, -0.382),
        forecasted: true,
        label,
      };

      this.chart_forecasted_series.push(forecastedSeries);
    }
  },

  chart_series_label(series) {
    const type = (() => {
      const keyToLabel = {
        high_temp: 'High Temp',
        low_temp: 'Low Temp',
        gallons: 'Gallons',
        seconds_ran: 'Run Time',
      };

      return keyToLabel[series.key] || series.key; // Use series.key if no match found
    })();

    const typeLabel = `Forecasted ${type}`;
    return Reporting.labelName(window.default_site, series.controller_id, series.zone_number, series.zone_name, typeLabel);
  },

  inputs_and_events_not_ready() {
    return this.data_input_processing() || (this.eventSearching > 0);
  },

  data_input_processing() {
    return (this.chart_series.series.length > this.chart_series_processed);
  },

  setup_and_render_tooltips() {
    this.print_input_events_label();
    this.print_input_usage_label();
  },

  // ** TODO: need to support multiple chart_series object (for selected only display)
  google_chart_draw() {
    this.google_Table = new window.google.visualization.DataTable();
    const table = this.google_chart_build();
    this.google_Table.addRows(table);
    this.google_lineChart.draw(this.google_Table, this.google_lineChartOptions);
    this.chart_render_supporting_views();
  },

  google_chart_set_axes_date_format() {
    this.google_lineChartOptions.hAxis.format = this.range && (this.range.interval === 'd')
      ? 'E, MMM d'
      : 'MMM d yyyy';
  },

  google_chart_selected_or_all_series() {
    this.chart_series.table.annotations = this.annotateEvents;
    if (this.googlechart_series_selected()) {
      return this.google_chart_selected_series_with_associated();
    }

    return this.chart_series.series;
  },

  google_chart_build() {
    this.google_chart_reset_options();
    this.google_chart_add_date_column();

    const series = this.google_chart_selected_or_all_series();
    this.rebuild_axes(series);
    this.google_chart_set_axes_labels();
    this.google_chart_add_all_series(series);
    this.google_chart_set_axes_date_format();

    return this.chart_series.asGoogleTable(series);
  },

  google_chart_selected_series_with_associated() {
    const selectedSeries = this.chart_selected_series();
    return this.chart_series.seriesWithAssociated(selectedSeries);
  },

  google_chart_reset_options() {
    this.google_lineChartOptions.series = {};
    this.google_lineChartOptions.colors = [];
  },

  google_chart_add_date_column() {
    this.add_column_with_annotation('date', 'Date');
  },

  google_chart_set_axes_labels() {
    const lastIndex = this.axes.subjects.length - 1;
    let index = 0;
    while (index <= lastIndex) {
      const title = Reporting.what_axis_label(this.unitLabel, this.axes.subjects[index]);
      this.google_lineChartOptions.vAxes[index].title = title;
      index += 1;
    }
  },

  chart_selected_series_index() {
    return (this.selected.col - this.chart_table_value_row_size) / this.chart_table_value_row_size; // The first three columns is the time range
  },

  chart_selected_series() {
    return this.chart_series.atIndex(this.chart_selected_series_index());
  },

  google_chart_add_all_series(_series = this.chart_series.series) {
    _series.forEach((_s, index) => {
      this.google_chart_add_series_column(_s);
      this.google_chart_add_series_color(_s);
      this.add_series_at_index(_s, index);
    });
  },

  google_chart_add_series_color(input) {
    this.google_lineChartOptions.colors.push(input.color);
  },

  add_column_with_annotation(type, label) {
    this.google_Table.addColumn({ type, label });
    this.google_Table.addColumn({ type: 'string', role: 'annotation' });
    this.google_Table.addColumn({ type: 'string', role: 'annotationText' });
  },

  google_chart_add_series_column(input) {
    this.add_column_with_annotation('number', input.label);
  },

  add_series_at_index(input, index) {
    this.google_lineChartOptions.series[index] = {
      targetAxisIndex: this.axes.subjects.indexOf(input.axis_subject),
      type: input.axis_display,
    };
  },

  // #########################################################################
  //
  //                             EVENT HANDLERS
  //
  // #########################################################################

  // ---------------------
  // UI EVENT: CHANGE TIMELINE RANGE
  // Change the range, and restart
  // ----------------------
  event_date_range_change(e) {
    const self = this;
    clearTimeout(self.requested.processing);
    this.requested.processing = setTimeout(() => {
      const date = new Date($(e.target).val());

      if ($(e.target).attr('id') === 'range_start') {
        this.requested.start = date;
      } else {
        this.requested.end = date;
      }

      this.process_range_change();
    }, 50);
  },

  // ---------------------
  // UI EVENT: CLICK RANGE RESET
  // Set the time preset and then restart
  // ----------------------
  event_click_range_preset(e) {
    e.preventDefault();
    this.requested.start = new Date($(e.target).data('date'));
    this.requested.end = new Date();
    this.process_range_change();
  },

  // ---------------------
  // PROCESS DATE RANGE CHANGE
  // After the requested date range has been set
  // reset the UI, and rebuild chart
  // ----------------------
  process_range_change() {
    this.hide_intervals();
    this.set_appropriate_date_interval();
    this.set_date_range();
    this.chart_reset_selection();
    this.chart_async_load_and_draw();
  },

  // ---------------------
  // UI EVENT: CHANGE TIMELINE INTERVAL
  // Change interval dates between day, week, month, year
  // ----------------------
  event_date_interval_change() {
    this.set_date_interval();
    this.set_date_range();
    this.chart_reset_selection();
    this.chart_async_load_and_draw();
  },

  // ---------------------
  // UI EVENT: CHART CLICK
  // Handle a click event on a chart entity
  // ----------------------
  event_chart_click() {
    const selected = this.google_lineChart.getSelection();
    if (selected.length < 1) {
      this.selected.row = null;
      this.selected.col = null;
    } else {
      this.selected.row = Object.hasOwn(selected[0], 'row') ? selected[0].row : null;
      this.selected.col = Object.hasOwn(selected[0], 'column') ? selected[0].column : null;
    }
    this.chart_related_views_render_events();
  },

  ui_render_selected_series() {
    if (this.selected.col) {
      const seriesIndex = (this.selected.col / this.chart_table_value_row_size) - 1;
      const inputItem = $(`.input_item[number='${seriesIndex}']`);
      inputItem.addClass('selected');
      $('.input_item').not(inputItem).removeClass('selected');
    } else {
      $('.input_item').removeClass('selected');
    }
  },

  // ---------------------
  // UI EVENT: INPUT EVENTS CLICK
  // Handle a click event on an input events click
  // ----------------------
  event_input_click(e) {
    if (!$(e.target).hasClass('btn')) {
      const li = $(e.target).closest('li');

      // If the clicked item has a selection,
      // then remove the selection
      if (li.hasClass('selected')) {
        this.selected.col = null;
        this.selected.row = null;
      } else {
        // If it wasn't selected, then figure out which input item it is
        // highlight it, and remove all other selections
        const inputPosition = li.attr('number');
        const chartPosition = this.chart_position_of_input(inputPosition);
        this.selected.col = chartPosition;
        this.selected.row = null;
      }

      this.google_chart_draw();
      this.chart_related_views_render_events();
    }
  },

  // ---------------------
  // UI EVENT: ADD BUTTON CLICK
  // Add the new input into the list
  // ----------------------
  event_add_button_click() {
    const self = this;
    const {
      ui,
    } = this;

    const siteId = $(this.ui.input_site_id).val() !== 'null' ? parseInt($(this.ui.input_site_id).val(), 10) : null;
    const controllerId = $(this.ui.input_controller_id).val() !== 'null' ? parseInt($(this.ui.input_controller_id).val(), 10) : null;
    const initialZoneNumber = $(this.ui.input_zone_number).val() !== 'null' ? parseInt($(this.ui.input_zone_number).val(), 10) : null;
    const source = $(ui.input_data_type).val();
    const selected = $(`#${source}_type option:selected`);
    const subject = selected.data('subject');
    const key = self.set_input_key(source);
    const display = $(ui.input_display).val();
    let axisSubj = subject;

    if (selected.data('axis_subj')) {
      axisSubj = selected.data('axis_subj');
    }

    if (self.can_add_axis_subject(subject) && (initialZoneNumber === 0)) {
      $('#zones option').each((zoneIndex) => {
        const color = $(ui.input_line_color).val();
        const zoneNumber = parseInt($('#zones option')[zoneIndex].value, 10);

        if (zoneNumber > 0) {
          const zoneName = $('#zones option')[zoneIndex].text;
          const label = Reporting.labelName(Reporting.find_site(self.sites, siteId), controllerId, zoneNumber, zoneName, selected.data('label'));
          self.chart_series.addOne({
            type: subject,
            site_id: siteId,
            controller_id: controllerId,
            zone_number: zoneNumber,
            zone_name: zoneName,
            key,
            label,
            color,
            axis_subject: axisSubj,
            axis_display: display,
          });
          self.set_color();
        }
      }); // Set a new color in the form

      self.set_all_save_panel(true);
      self.chart_async_load_and_draw();
    } else if (self.can_add_axis_subject(subject)) {
      const label = $(this.ui.input_label).val();
      const zoneName = $(this.ui.input_zone_number).text();
      const color = $(this.ui.input_line_color).val();

      self.chart_series.addOne({
        type: subject,
        site_id: siteId,
        controller_id: controllerId,
        zone_number: initialZoneNumber,
        zone_name: zoneName,
        key,
        label,
        color,
        axis_subject: axisSubj,
        axis_display: display,
      });

      self.set_color(); // Set a new color in the form
      self.set_all_save_panel(true);
      self.chart_async_load_and_draw();
    } else {
      // If not, then tell the user why.
      let string = 'You are trying to report on ';
      string += Reporting.what_axis_label(this.unitLabel, subject);
      string += ", and we can't add this to the chart because of dissimilar units being reported.  Please remove either all ";
      string += Reporting.what_axis_label(this.unitLabel, this.axes.subjects[0]); // hardcoded position, because we know there are two subjects already
      string += ' or ';
      string += Reporting.what_axis_label(this.unitLabel, this.axes.subjects[1]); // hardcoded position, because we know there are two subjects already
      string += ' reporting elements to allow adding of this new report item.';
      alert(string);
    }
  },

  // ---------------------
  // UI EVENT: REMOVE INPUT
  // Remove input, and restart
  // ----------------------
  event_remove_input(e) {
    const inputItem = $(e.target).closest('li');
    const number = parseInt(inputItem.attr('number'), 10);

    const series = this.chart_series.atIndex(number);
    this.chart_series.removeOne(series);

    $(e.target).closest('li').remove();

    //        @chart_related_views_render_events()
    //        @chart_related_views_render_series()
    this.set_all_save_panel(true);
    this.google_chart_draw();
    this.chart_render_supporting_views();
  },

  // ---------------------
  // UI EVENT: CHANGE CONTROLLER
  // Build the zone dropdown menu, when
  // the controllers selection change
  // ----------------------
  event_controller_change(e) {
    const target = $('option:selected', e.target);
    if (target.attr('zone_names')) {
      this.print_zones_dropdown(JSON.parse(target.attr('zone_names')));
    }
    return this.show_correct_form_inputs();
  },

  // ---------------------
  // UI EVENT: SITE SEARCH SELECTED
  // Event handler when the user selected a site
  // from the autocomplete dropdown
  // ----------------------
  event_site_search_selected(result) {
    $(this.ui.input_site_search).val('').hide();
    $(this.ui.input_cancel_site_search).hide();
    if (Reporting.find_site(this.sites, result.data.id)) {
      $(this.ui.input_site_id).val(result.data.id).show();
      setTimeout(() => {
        alert('Site previous added');
      }, 1);
    } else {
      this.sites[String(result.data.id)] = (result.data);
      $(this.ui.input_site_id).prepend(`<option value="${result.data.id}">${result.data.name}</option>`).val(result.data.id).show();
    }
    $(this.ui.input_controller_id).show();
    $(this.ui.source_row).show();
    $(this.ui.input_add_button).show();

    this.render_controllers_dropdown(result.data.id);
  },

  // ---------------------
  // UI EVENT: CANCEL SITE SEARCH
  // How to abort from the process of adding
  // another site to the dropdown
  // ----------------------
  event_cancel_site_search(e) {
    e.preventDefault();
    $(this.ui.input_site_search).hide();
    $(this.ui.source_row).show();
    $(this.ui.input_cancel_site_search).hide();
    $(this.ui.input_site_id).val(window.default_site.id).show();
    this.render_controllers_dropdown(window.default_site.id);
    this.show_correct_form_inputs();
    return $(this.ui.input_add_button).show();
  },

  // ---------------------
  // UI EVENT: SITE SELECT CHANGE
  // If 'add site' is selected, hide most of the UI
  // Else rebuild the controller dropdown
  // ----------------------
  event_sites_select_change(e) {
    const value = $(e.target).val();
    if (value === 'add') {
      $(this.ui.input_site_search).show();
      $(this.ui.input_cancel_site_search).show();
      $(this.ui.input_site_id).hide();
      $(this.ui.controller_row).hide();
      $(this.ui.source_row).hide();
      $(this.ui.input_add_button).hide();
      $(this.ui.input_zone_number).hide();
    } else {
      this.render_controllers_dropdown(value);
    }
  },

  // ---------------------
  // UI EVENT: Show Advanced Options
  // ----------------------
  event_show_advanced_options() {
    $('.advanced_option').toggle();
    if (!$(this.ui.input_advanced_options).hasClass('btn-warning')) {
      $(this.ui.input_advanced_options).text('Hide Advanced');
    } else {
      $(this.ui.input_advanced_options).text('Advanced Settings');
    }
    $(this.ui.input_advanced_options).toggleClass('btn-warning');
  },

  // ---------------------
  // UI EVENT: ANNOTATE CHART
  // This is a toggle if the chart should be annotated or not
  // ----------------------
  event_annotate_chart_click() {
    // toggle the class and variable
    if (this.annotateEvents) {
      $(this.ui.input_annotate_chart).removeClass('btn-warning');
      this.annotateEvents = false;
    } else {
      $(this.ui.input_annotate_chart).addClass('btn-warning');
      this.annotateEvents = true;
    }

    // Remove current drill down selection
    $('.input_item').removeClass('selected');

    // Reset the chart selections
    this.chart_reset_selection();

    this.google_chart_draw();

    // Re-print the event list
    this.chart_related_views_render_events();
  },

  // ---------------------
  // UI EVENT: EXPORT
  // Will send the input params to the export path
  // ----------------------
  event_export_click() {
    const series = this.chart_series.series;
    const table = this.chart_series.asGoogleTable(series);
    // Print out the top row (aka the columns)
    let csv = ',,';
    $.each(table, (index, day) => {
      if (day[0] === null) {
        csv += ',';
      } else {
        csv += `${Reporting.stringThisDate(day[0])},`;
      }
    });
    csv += '%0A';

    // Loop through the inputs
    let position = 0;
    series.forEach((input) => {
      let unitLabel;
      const labelVal = encodeURI(input.label).replace(/#/g, '%23');
      csv += `${labelVal},`;

      if (input.type === 'usage' && input.key === 'seconds_ran') {
        unitLabel = 'Minutes';
      } else {
        unitLabel = this.unitLabel.long[input.type];
      }

      csv += `${unitLabel},`;

      position += this.chart_table_value_row_size;

      // Loop through the days, and print the correct column
      table.forEach((day) => {
        if (day[position] === null) {
          csv += ',';
        } else {
          csv += `${day[position]},`;
        }
      });

      csv += '%0A';
    });

    $(this.ui.action_export).attr('href', `data:text/csv;charset=utf8,${csv}`).attr('download', 'report.csv');
    return true;
  },

  // ---------------------
  // REMOVE EVENT SELECTION
  // ----------------------
  event_remove_event_selection() {
    this.google_lineChart.setSelection([]);
    this.selected.row = null;
    this.chart_related_views_render_events();
  },

  // #########################################################################
  //
  //                             SAVE PANEL
  //
  // #########################################################################

  // ---------------------
  // UI: CHANGE REPORT
  // Handling report dropdown menu changes
  // ----------------------
  change_report(e) {
    window.location = `/sites/${window.default_site.id}/reports/${$(e.target).val()}`;
  },

  // ---------------------
  // UI: ACTION SAVE
  // This will save a report
  // ----------------------
  action_save() {
    this.set_all_save_panel(false);
    this.display_correct_save_options();
    if (this.report) {
      const report = new ReportModel();
      report.set('id', this.report.id);
      report.set('report_items', this.chart_series.withoutForecastedAndDeficits());
      return report.save({}, {
        success() {
          return alert('Report has been saved!');
        },
      });
    }
    return alert('Something went wrong.');
  },

  // ---------------------
  // UI: ACTION SAVE
  // This will save the current report as something else
  // ----------------------
  action_save_as() {
    const reportName = window.prompt('Report Name');
    if (reportName) {
      this.set_all_save_panel(false);
      this.display_correct_save_options();
      const report = new ReportModel();
      report.set('name', reportName);
      report.set('parent_id', window.default_site.id);
      report.set('report_items', this.chart_series.withoutForecastedAndDeficits());
      report.save({}, {
        success(data) {
          window.location = `/sites/${window.default_site.id}/reports/${data.id}`;
        },
      });
    }
  },

  // ---------------------
  // UI: ACTION SAVE
  // This will simply reload the report
  // ----------------------
  action_reset() {
    this.set_all_save_panel(false);
    this.display_correct_save_options();
    if (this.report) {
      window.location = `/sites/${window.default_site.id}/reports/${this.report.id}`;
    } else {
      window.location = `/sites/${window.default_site.id}/reports/new`;
    }
  },

  // #########################################################################
  //
  //                          USER INTERFACE
  //
  // #########################################################################

  // ---------------------
  // UI: SET DATE RANGE
  // Get Date range from UI, adjust it (if necessary), and save it
  // ----------------------
  set_date_range() {
    const inputStartRange = $(this.ui.input_range_start);
    const inputEndRange = $(this.ui.input_range_end);
    const adjustedStart = Reporting.group_date(this.range.interval, this.requested.start);
    const adjustedEnd = Reporting.end_of_group_date(this.range.interval, this.requested.end);
    this.range.start = adjustedStart;
    this.range.end = adjustedEnd;
    this.google_lineChartOptions.hAxis.minValue = adjustedStart;
    this.google_lineChartOptions.hAxis.maxValue = Reporting.group_date(this.range.interval, adjustedEnd);
    inputStartRange.val(Reporting.stringThisDate(adjustedStart));
    inputEndRange.val(Reporting.stringThisDate(adjustedEnd));
  },

  // ---------------------
  // UI: SET DATE INTERVAL
  // Get interval from UI and save it
  // ----------------------
  set_date_interval() {
    this.range.interval = $(this.ui.input_range_interval).val();
  },

  // ---------------------
  // UI: SET APPROPRIATE DATE INTERVAL
  // This will directly manipulate the interval dropdown
  // and select one depending on the date range
  // ----------------------
  set_appropriate_date_interval() {
    let interval;
    const inputInterval = $(this.ui.input_range_interval);
    const days = Reporting.number_of_days(this.requested.start, this.requested.end);
    if (days > 500) {
      interval = 'y';
    } else if (days > 120) {
      interval = 'm';
    } else if (days > 45) {
      interval = 'w';
    } else {
      interval = 'd';
    }
    this.range.interval = interval;
    inputInterval.val(interval);
  },

  // ---------------------
  // UI: SELECT COLOR
  // Will select an unused default color.
  // If all are gone, it will generate a random color
  // ----------------------
  set_color() {
    const self = this;
    let color = '';
    if (this.chart_series.series.length >= self.defaultColors.length) {
      color = `#${((Math.random() * 0xFFFFFF) << 0).toString(16)}`; // eslint-disable-line no-bitwise
    } else {
      let colorIsSet = false;
      while (!colorIsSet) {
        const slot = Math.floor(Math.random() * (self.defaultColors.length - 1));
        color = self.defaultColors[slot];
        if (self.is_unique_color(color)) {
          colorIsSet = true;
        }
      }
    }
    $(this.ui.input_line_color).spectrum('set', color);
    return color;
  },

  // ---------------------
  // UI: SETUP AUTOCOMPLETE SEARCH
  // This is from the devbridge-autocomplete jquery plugin
  // ----------------------
  initialize_site_search() {
    const self = this;
    window.addEventListener('load', () => {
      $(this.ui.input_site_search).devbridgeAutocomplete({
        serviceUrl: `/api/v2/sites?perPage=${this.chart_table_value_row_size}`,
        paramName: 'q',
        minChars: 3,
        transformResult(response) {
          return {
            suggestions: $.map(JSON.parse(response).result.sites, (dataItem) => ({
              value: `${dataItem.name}<br /><span style="font-size: 80%;">${dataItem.address1}, ${dataItem.city}, ${dataItem.state}</span>`,
              data: dataItem,
            })),
          };
        },
        formatResult(suggestion) {
          return suggestion.value;
        },
        onSelect(suggestion) {
          // For some reason specifying the function for the callback
          // directly didn't work. To lazy to figure out why.
          self.event_site_search_selected(suggestion);
        },
      });
    });
  },

  format_the_date(date) {
    const parts = date.split(' ');
    const datePart = parts[0];
    const timePart = parts[1].replace(/[apm]/g, '').concat(':00');
    const timezone = parts[2].replace(/[()]/g, '');
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
    const dateReformatted = (Date.parse(`${datePart} ${timePart} ${timezone}`)).toLocaleString('en', options);
    return dateReformatted;
  },

  // ---------------------
  // UI: PRINT EVENT LIST
  // Determine which event list to print
  // ----------------------
  chart_related_views_render_events() {
    let events;
    const row = this.selected.row;
    const col = this.selected.col;
    const dataInputNumber = parseInt(col / this.chart_table_value_row_size, 10) - 1;
    if ((row !== null) && (col !== null)) {
      const date = this.chart_series.asGoogleTable()[row][0];
      events = this.find_events(Reporting.stringThisDate(date), dataInputNumber);
      $(this.ui.event_alert).show();
    } else if (col !== null) {
      events = this.find_events(null, dataInputNumber);
      $(this.ui.event_alert).hide();
    } else {
      events = this.find_events(null, null);
      $(this.ui.event_alert).hide();
    }
    this.chart_related_views_render_events_helper(events);
  },

  // ---------------------
  // UI HELPER: PRINT EVENT LIST HELPER
  // Print events nicely in the box
  // ----------------------
  chart_related_views_render_events_helper(events) {
    const where = $(this.ui.event_list);
    $('#event_list_badge').text(`${events.length} Events Found`);
    if (events.length > 0) {
      where.html('');
      events.forEach((e) => {
        const site = Reporting.find_site(this.sites, e.site_id);
        where.append(this.events2_template({
          label: Reporting.labelName(site, e.controller_id, e.zone_number),
          event: e,
          icon: this.icon_for_input_type(e.input.type),
        }));
      });
    } else {
      where.html('<h4 id="no_events">No Events Found</h4>');
    }
  },

  chart_related_views_render_series() {
    const inputList = $(this.ui.input_list);
    inputList.html('');

    this.chart_series.series.forEach((input, index) => {
      const classes = [];
      if (input.forecasted) { classes.push('forecasted'); }
      if (input.deficit) { classes.push('deficit'); }
      inputList.append(this.input_template({
        input,
        position: index,
        icon: this.icon_for_input_type(input.type, input.key),
        classes,
      }));
    });

    $(this.ui.input_list).children('.forecasted').hide();
    $(this.ui.input_list).children('.deficit').hide();
  },

  // ---------------------
  // UI: PRINT INPUT EVENT LABELS
  // Fill bubble with number of events
  // ----------------------
  print_input_events_label() {
    $(`${this.ui.input_list} li`).each((i) => {
      const events = this.find_events(null, i);
      const string = Reporting.events_string(events.length);
      if (string) {
        $(this).find('.label-events').text(string).show();
      }
    });
  },

  // ---------------------
  // UI: PRINT INPUT USAGE LABELS
  // Fill bubble with water usage
  // ----------------------
  print_input_usage_label() {
    const self = this;
    $(this.ui.input_list).find('li').each((_e, liElement) => {
      let val;
      const subject = $(liElement).attr('subject');
      const number = $(liElement).attr('number');
      let table = self.chart_series.table.dateTable;
      const t = {};
      Object.keys(table).forEach((date) => t[date] = { values: [].concat(...table[date]) });

      table = t;
      let prefix = '';

      if (self.what_type_of_calculation(subject) === 'sum') {
        val = Reporting.total(table, number * self.chart_table_value_row_size);
        prefix = 'Total: ';
      } else {
        val = Reporting.average(table, number * self.chart_table_value_row_size);
        prefix = 'Avg: ';
      }

      const bubbleText = Reporting.calculation_string(val, self.what_bubble_label(subject));
      if (bubbleText !== null) { $(liElement).find('.label-calculate').html(prefix + bubbleText).show(); }
    });
  },

  // ---------------------
  // UI: BUILD CONTROLLERS DROPDOWN
  // Create a dropdown menu of the controllers
  // ----------------------
  render_controllers_dropdown(siteId) {
    const dropdown = $(this.ui.input_controller_id);
    dropdown.html('');
    dropdown.append('<option value="null" zone_count="null">All Controllers...</option>');
    $.each(this.sites[String(siteId)].controllers, (i, c) => {
      const zoneArray = [];
      $.each(c.active_zones, (ignore, z) => zoneArray.push(z.description ? `${z.number}) ${z.description}` : `Zone ${z.number}`));
      dropdown.append(`<option value="${c.id}" zone_count="${zoneArray.length}" zone_names='${JSON.stringify(zoneArray).replace(/'/g, '&#39;')}'>${c.name}</option>`);
    });
    this.show_correct_form_inputs();
  },

  // ---------------------
  // UI: BUILD ZONES DROPDOWN
  // Create a dropdown menu of the controllers
  // ----------------------
  print_zones_dropdown(zoneNames) {
    const that = this;
    this.show_correct_form_inputs();
    const dropdown = $(this.ui.input_zone_number);
    dropdown.html('<option value="null">All Zones (Grouped)</option><option value="0">All Zones (Individually)</option>');
    return $.each(zoneNames, (i, name) => dropdown.append(`<option value="${i + 1}">${that.htmlEntities(name)}</option>`));
  },

  // ---------------------
  // UI HELPER: SHOW CORRECT FORM INPUTS
  // Only display the zone dropdown when relevant
  // ----------------------
  show_correct_form_inputs() {
    const type = $(this.ui.input_data_type).val();
    const weatherType = $(this.ui.input_weather_type);
    const usageType = $(this.ui.input_usage_type);
    const siteId = $(this.ui.input_site_id);
    const controllerId = $(this.ui.input_controller_id);
    const zoneNumber = $(this.ui.input_zone_number);
    const zoneName = $(`${this.ui.input_zone_number} option:selected`).text();
    const display = $(this.ui.input_display);
    let label = '';

    switch (type) {
      case 'weather':
        weatherType.show();
        usageType.hide();
        controllerId.show();
        $(this.ui.controller_row).show();
        zoneNumber.val(null).hide();
        label = weatherType.find(':selected').data('label');
        display.val('line');
        break;

      case 'usage': case 'forecasted_usage':
        weatherType.hide();
        usageType.show();
        controllerId.show();
        $(this.ui.controller_row).show();
        if (controllerId.val() !== 'null') { zoneNumber.show(); } else { zoneNumber.hide(); }
        label = usageType.find(':selected').data('label');
        display.val('bars');
        break;

      default:
        alert('Error displaying the correct form inputs');
    }

    label = Reporting.labelName(
      Reporting.find_site(this.sites, siteId.val()),
      controllerId.val(),
      zoneNumber.val(),
      zoneName,
      label,
    );
    $(this.ui.input_label).val(label);
  },

  // ---------------------
  // UI HELPER: HIDE INTERVALS
  // If the requested range in too small,
  // Hide the larger intervals, which are meaningless
  // ----------------------
  hide_intervals() {
    const days = Reporting.number_of_days(this.requested.start, this.requested.end);
    const dropdown = $(this.ui.input_range_interval);

    if (days < 14) {
      dropdown.find('option[value="y"]').hide();
      dropdown.find('option[value="m"]').hide();
      dropdown.find('option[value="w"]').hide();
      dropdown.find('option[value="d"]').show();
    } else if (days < 60) {
      dropdown.find('option[value="y"]').hide();
      dropdown.find('option[value="m"]').hide();
      dropdown.find('option[value="w"]').show();
      dropdown.find('option[value="d"]').show();
    } else if (days < 400) {
      dropdown.find('option[value="y"]').hide();
      dropdown.find('option[value="m"]').show();
      dropdown.find('option[value="w"]').show();
      dropdown.find('option[value="d"]').show();
    } else {
      dropdown.find('option[value="y"]').show();
      dropdown.find('option[value="m"]').show();
      dropdown.find('option[value="w"]').show();
      dropdown.find('option[value="d"]').show();
    }
  },

  // #########################################################################
  //
  //                        SMARTLINK EVENTS LOGIC
  //
  // #########################################################################

  // ---------------------
  // FIND EVENTS
  // Return an array of events that match the given criteria
  // ----------------------
  find_events(dateString, inputPosition) {
    let winningNumbers;
    const self = this;
    self.eventSearching += 1;
    const events = [];

    let eventsTable = self.chart_series.table.eventsTable;
    const t = {};
    Object.keys(eventsTable).forEach((date) => t[date] = { events: eventsTable[date] });
    eventsTable = t;

    // Specific Date
    if ((inputPosition !== null) && (dateString !== null) && Object.hasOwn(eventsTable, dateString)) {
      winningNumbers = this.winning_numbers([], inputPosition);
      eventsTable[dateString].forEach((event) => {
        if (winningNumbers.numbers.includes(event.event_type) && self.match_criteria(event, winningNumbers.input)) {
          events.push(event);
        }
      });

      // Specific Series
    } else if ((inputPosition !== null) && (dateString === null)) {
      winningNumbers = this.winning_numbers([], inputPosition);
      Object.keys(eventsTable).forEach((eventKey) => {
        eventsTable[eventKey].events.forEach((event) => {
          if (winningNumbers.numbers.includes(event.event_type) && self.match_criteria(event, winningNumbers.input)) {
            events.push(event);
          }
        });
      });

      // Nothing Specific
    } else {
      // Create a master event list
      let masterEventList = [];
      masterEventList = masterEventList.concat(...Object.values(eventsTable));

      const masterEventListLength = masterEventList.length;

      // Loop the inputs
      self.chart_series.series.forEach((input, seriesInputPosition) => {
        winningNumbers = self.winning_numbers([], seriesInputPosition);
        for (let i = 0; i < masterEventListLength; i += 1) {
          if (masterEventList[i]?.events) {
            /* eslint-disable-next-line no-loop-func */
            masterEventList[i].events.forEach((event) => {
              if (winningNumbers.numbers.includes(event.event_type) && self.match_criteria(event, winningNumbers.input)) {
                event.input = input;
                events.push(event);
                const indexToRemove = masterEventList[i].events.indexOf(event);
                masterEventList[i].events.splice(indexToRemove, 1);
              }
            });
          }
        }
      });

      // Append the Timeline Events
      winningNumbers = self.winning_numbers([], -1);
      masterEventList.forEach((eventSet) => {
        eventSet.events.forEach((event) => {
          if (winningNumbers.numbers.includes(event.event_type)) {
            event.input = winningNumbers.input;
            events.push(event);
          }
        });
      });
    }

    // Sort the events by date
    events.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Just Cause
    setTimeout(
      () => self.eventSearching -= 1,
      50,
    );
    return events;
  },

  // ---------------------
  // WINNING NUMBERS
  // What are the event type's that we want?
  // input_position < 0 does the Timeline events
  // ----------------------
  winning_numbers(array, inputPosition) {
    const output = { input: null, numbers: null };
    if (inputPosition < 0) {
      output.input = { type: 'timeline', color: '#000000' };
      output.numbers = array.concat(this.eventTypes.timeline);
    } else {
      output.input = this.chart_series.series[inputPosition];
      output.numbers = array.concat(this.eventTypes[output.input.type]);
    }
    return output;
  },

  // ---------------------
  // MATCH CRITERIA
  // Is the event relevant to the input?
  // ----------------------
  match_criteria(event, input) {
    const s = input.site_id;
    const c = input.controller_id;
    const z = input.zone_number;
    const es = event.site_id;
    const ec = event.controller_id;
    const ez = event.zone_number;

    // No site, controller, or zone specified
    if (!s && !c && !z) {
      return true;
    }
    // Only site specified
    if (s === es && !c && !z) {
      return true;
    }
    // If site and controller specified
    if (s === es && c === ec && !z) {
      return true;
    }
    // If site and controller and zone specified (return true)
    // Doesn't match anything (return false)
    return s === es && c === ec && z === ez;
  },

  // #########################################################################
  //
  //                           UNIVERSAL HELPERS
  //
  // #########################################################################

  // ----------------------
  // HELPER: RESET CHART SELECTION
  // ----------------------
  chart_reset_selection() {
    this.selected.col = null;
    this.selected.row = null;
  },

  // ----------------------
  // HELPER: CHART POSITION OF INPUT
  // Return the column position of the input
  // ----------------------
  chart_position_of_input(i) {
    if (i) { return (parseInt(i, 10) + 1) * this.chart_table_value_row_size; } return null;
  },

  // ----------------------
  // HELPER: INPUT POSITION OF CHART COLUMN
  // The inverse of chart_position_of_input
  // ----------------------
  input_position_of_chart(i) {
    if (i) { return Math.floor((parseInt(i, 10) / this.chart_table_value_row_size) - 1); } return null;
  },

  // ---------------------
  // HELPER: IS UNIQUE COLOR?
  // Simply check if the color has been used already
  // ----------------------
  is_unique_color(color) {
    let unique = true;
    this.chart_series.series.forEach((input) => {
      if (color === input.color) {
        unique = false;
      }
    });
    return unique;
  },

  // ---------------------
  // HELPER: IS SOMETHING SELECTED?
  // Is something on the chart already selected?
  // ----------------------
  googlechart_series_selected() {
    return this.selected.col;
  },

  // ---------------------
  // HELPER: IS DAY SELECTED?
  // Is something on the chart already selected?
  // ----------------------
  is_day_selected() {
    return this.selected.row;
  },

  // ----------------------
  // HELPER: SET INPUT KEY
  // Depending on the type of input,
  // what's the model's value key?
  // ----------------------
  set_input_key(type) {
    const self = this;
    switch (type) {
      case 'weather':
        return $(self.ui.input_weather_type).val();
      case 'forecasted_weather':
        return $(self.ui.input_weather_type).val();
      case 'usage':
        return $(self.ui.input_usage_type).val();
      case 'forecasted_usage':
        return $(self.ui.input_usage_type).val();
      default:
        return null;
    }
  },

  // ----------------------
  // HELPER: WHAT BUBBLE LABEL
  // Will return the customer string of the
  // backend reporting subject type.
  // ----------------------
  what_bubble_label(subject) {
    switch (subject) {
      case 'time':
        return 'min';
      case 'gpm':
        return this.unitLabel.short.gpm;
      case 'gallons':
        return this.unitLabel.short.usage;
      case 'temp':
        return this.unitLabel.short.weather;
      default:
        return null;
    }
  },

  // ----------------------
  // HELPER: WHAT TYPE OF CALCULATION
  // Based on the subject, should the bubble
  // be an average or a sum?
  // ----------------------
  what_type_of_calculation(subject) {
    switch (subject) {
      case 'time':
        return 'sum';
      case 'gpm':
        return 'avg';
      case 'gallons':
        return 'sum';
      case 'usage':
        return 'sum';
      case 'temp':
        return 'avg';
      default:
        return null;
    }
  },

  // ----------------------
  // HELPER: CAN I ADD AN AXIS SUBJECT?
  // Will return a boolean if the reporting subject
  // can be added. Google chart only has two axes.
  // ----------------------
  can_add_axis_subject(subject) {
    if (this.axes.subjects.length < 2) {
      return true;
    }
    return this.axes.subjects.indexOf(subject) > -1;
  },

  // ----------------------
  // HELPER: ICON FOR TYPE
  // What icon should the input display?
  // ----------------------
  icon_for_input_type(type, key) {
    switch (type) {
      case 'weather':
      case 'forecasted_weather':
        return 'fa fa-sun-o fa-2x';
      case 'usage': case 'forecasted_usage':
        switch (key) {
          case 'gallons':
            return 'glyphicon glyphicon-tint';
          case 'seconds_ran':
            return 'glyphicon glyphicon-time';
          default:
            return 'glyphicon glyphicon-dashboard';
        }
      default:
        return 'glyphicon glyphicon-dashboard';
    }
  },

  // ----------------------
  // HELPER: SET ALL SAVE PANEL
  // This will simply set, which save buttons
  // should or shouldn't appear
  // ----------------------
  set_all_save_panel(bool) {
    if (this.report) {
      this.save_panel.save_button = bool;
    }
    this.save_panel.save_as_button = bool;
    this.save_panel.reset_button = bool;
  },

  htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
  },

  // ----------------------
  // HELPER: DISPLAY CORRECT SAVE OPTIONS
  // Based on @save_panel booleans,
  // just show() or hide() the buttons
  // ----------------------
  display_correct_save_options() {
    if (this.save_panel.save_button) {
      $(this.ui.action_save).parent().show();
    } else {
      $(this.ui.action_save).parent().hide();
    }
    if (this.save_panel.save_as_button) {
      $(this.ui.action_save_as).parent().show();
    } else {
      $(this.ui.action_save_as).parent().hide();
    }
    if (this.save_panel.reset_button) {
      return $(this.ui.action_reset).parent().show();
    }
    return $(this.ui.action_reset).parent().hide();
  },
});
export default ReportView;
