function updateSelectedPreferred(element) {
  if (element.checked) {
    const checkeds = document.querySelectorAll(`[id^="plaid_primary_payment_["]:checked:not([id="${element.id}"])`);
    checkeds.forEach((inputChecked) => {
      inputChecked.checked = false;
    });
  }
}
window.updateSelectedPreferred = updateSelectedPreferred;

function createPlaidPaymentSource(token, plaidAccountId, alias, creatorType, primary) {
  return window.$.ajax({
    type: 'POST',
    url: '/api/v2/cart/payment_sources',
    dataType: 'script',
    data: {
      token,
      type: 'plaid',
      plaid_account_id: plaidAccountId,
      alias,
      primary,
      creator_type: creatorType,
    },
  });
}

async function createPlaidPaymentSources(token) {
  const plaidAccounts = document.querySelectorAll('[id^="plaid_account-"]');
  let errorCount = 0;
  const allAJAX = [...plaidAccounts].map(async (plaidAccount) => {
    const index = plaidAccount.id.split('-')[1];
    return createPlaidPaymentSource(token, plaidAccount.querySelector(`[name="plaid[${index}][account_id]"]`).value, plaidAccount.querySelector(`[name="plaid[${index}][alias]"]`).value, plaidAccount.querySelector(`[name="plaid[${index}][owner_type]"]:checked`).value, document.querySelector(`[name="plaid[${index}][primary_payment]"]`).checked).fail((e) => { if (e.status !== 200) { errorCount += 1; } });
  });
  Promise.allSettled(allAJAX).then(() => {
    if (errorCount > 0) {
      const message = plaidAccounts.length === 1 ? window.I18n.payment_sources.bank_account_modal.error_message.one : window.I18n.payment_sources.bank_account_modal.error_message.other;
      window.toggleSnackbar('warning-snackbar', message.replace('%{count}', errorCount));
      document.querySelector('#success-snackbar').classList.add('tw-hidden');
      if (errorCount !== plaidAccounts.length) {
        window.updatePaymentSources();
      }
    } else {
      const message = plaidAccounts.length === 1 ? window.I18n.payment_sources.bank_account_modal.success_message.one : window.I18n.payment_sources.bank_account_modal.success_message.other;
      window.toggleSnackbar('success-snackbar', message.replace('%{count}', plaidAccounts.length));
      document.querySelector('#warning-snackbar').classList.add('tw-hidden');
      window.updatePaymentSources();
      document.querySelector('#plaid_accounts_modal').classList.add('tw-hidden');
    }
  });
}

function getPlaidPaymentSourceAccounts(token) {
  window.$.ajax({
    type: 'POST',
    data: { token },
    url: '/payment_sources/plaid_accounts',
    error: (jqXHR, textStatus) => {
      window.renderPaymentError(textStatus);
    },
    success: () => {
      document.querySelector('#plaid_accounts_modal .submit').onclick = () => { createPlaidPaymentSources(token); };
    },
  });
}

function openPlaidPaymentSourceModal() {
  // TODO: Once the Plaid gem is update to the latest version, we should change the key and use token instead
  // https://plaid.com/docs/link/link-token-migration-guide/
  const handler = window.Plaid.create({
    env: window.SERVER_ENV === 'production' ? 'production' : 'sandbox',
    apiVersion: 'v2',
    clientName: 'SLN Payments',
    key: window.PLAID_KEY,
    product: ['auth'],
    onSuccess: (publickToken) => { getPlaidPaymentSourceAccounts(publickToken); },
    onExit: window.plaidExited,
  });
  handler.open();
}
window.openPlaidPaymentSourceModal = openPlaidPaymentSourceModal;

function movePlaidAccountForm(type, accountsCount) {
  const currentIndex = parseInt(document.querySelector('[id^="plaid_account-"]:not(.tw-hidden)').id.split('-')[1], 10);
  const newIndex = type === 'prev' ? currentIndex - 1 : currentIndex + 1;
  document.querySelector('#plaid_accounts_modal .tw-info').innerHTML = window.I18n.payment_sources.bank_account_modal.account_total_html.replace('%{total}', accountsCount).replace('%{current}', newIndex + 1);
  document.getElementById(`plaid_account_circle-${currentIndex}`).classList.add('tw-text-gray-300');
  document.getElementById(`plaid_account_circle-${currentIndex}`).classList.remove('tw-text-blue-500');
  document.getElementById(`plaid_account_circle-${newIndex}`).classList.remove('tw-text-gray-300');
  document.getElementById(`plaid_account_circle-${newIndex}`).classList.add('tw-text-blue-500');
  document.getElementById(`plaid_account-${currentIndex}`).classList.add('tw-hidden');
  document.getElementById(`plaid_account-${newIndex}`).classList.remove('tw-hidden');
  if (newIndex === 0) {
    document.querySelector('.plaid_prev').classList.add('tw-btn-disabled');
    document.querySelector('.plaid_prev').classList.remove('tw-button-secondary');
    document.querySelector('.plaid_save').classList.add('tw-hidden');
    document.querySelector('.plaid_next').classList.remove('tw-hidden');
  } else if (newIndex === accountsCount - 1) {
    document.querySelector('.plaid_next').classList.add('tw-hidden');
    document.querySelector('.plaid_save').classList.remove('tw-hidden');
    document.querySelector('.plaid_prev').classList.remove('tw-btn-disabled');
    document.querySelector('.plaid_prev').classList.add('tw-button-secondary');
  } else {
    document.querySelector('.plaid_prev').classList.remove('tw-btn-disabled');
    document.querySelector('.plaid_prev').classList.add('tw-button-secondary');
    document.querySelector('.plaid_next').classList.remove('tw-hidden');
    document.querySelector('.plaid_save').classList.add('tw-hidden');
  }
}
window.movePlaidAccountForm = movePlaidAccountForm;

function updatePlaidPaymentSource(paymentSourceId) {
  window.$.ajax({
    type: 'PUT',
    url: `/payment_sources/${paymentSourceId}`,
    dataType: 'script',
    data: {
      alias: document.querySelector("#plaid_account_edit_modal input[name='plaid-alias']").value,
      primary: document.querySelector("#plaid_account_edit_modal input[name='plaid[edit_primary_payment]']").checked,
    },
  });
}
window.updatePlaidPaymentSource = updatePlaidPaymentSource;
