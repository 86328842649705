import Backbone from 'backbone';
import $ from 'jquery';

const PriceListItemsIndexView = Backbone.View.extend({
  el: 'body',
  events: {
    'click .edit-price': 'showPriceEdit',
  },
  initialize() {
    console.log('Price List Items Index Initialized');
    $('.datatable').on('click', '.save-price', (e) => {
      const dis = $(e.currentTarget);
      if (dis.prev('input').val() !== '') {
        const price = dis.prev('input').val();
        const priceListId = $('#price_list_id').data('price-list-id');
        const priceListItemId = dis.data('id');

        $.ajax({
          url: `/price_lists/${priceListId}/price_list_items/${priceListItemId}`,
          data: { price_list_item: { price } },
          type: 'PATCH',
          dataType: 'json',
          success: (response) => {
            $(`#${priceListItemId}`).css('visibility', 'visible');
            dis.css('visibility', 'hidden');
            dis.prev('input').val('');
            dis.prev('input').css('visibility', 'hidden');
            const newPrice = Number(response.price).toFixed(2);
            dis.closest('td').find('.unit-price').html(newPrice);
          },
          error: () => {
            alert('Sorry, something went wrong');
          },
        });
      }
    });
  },
  showPriceEdit(e) {
    e.preventDefault();
    const dis = $(e.currentTarget);
    dis.closest('td').prev('td').find('input').css('visibility', 'visible');
    dis.closest('td').prev('td').find('input').focus();
    dis.closest('td').prev('td').find('.save-price').css('visibility', 'visible');
    dis.css('visibility', 'hidden');
  },
});
export default PriceListItemsIndexView;
