const pagesWithFormAndNewDesigns = ['#sites-new', '#sites-edit', '#branches-new', '#branches-edit', '#accounts-settings', '#dashboard-accounts-new'];

function selectStateForCountry(state) {
  const body = document.querySelector('body');
  const changeFormClasses = pagesWithFormAndNewDesigns.find((page) => page === `#${body.id}`);
  const stateSelect = document.querySelector('.autocomplete-state');
  const countrySelect = document.querySelector('.autocomplete-country');
  const countryIdSelected = countrySelect.value;
  stateSelect.disabled = true;

  window.$.ajax({
    type: 'GET',
    url: `/api/v2/countries/${countryIdSelected}/state_options`,
    dataType: 'json',
    success: (response) => {
      stateSelect.innerHTML = response.data;
      stateSelect.disabled = false;
      if (changeFormClasses) countrySelect.classList.add('form_option-selected');
      if (changeFormClasses) stateSelect.classList.add('form_option-selected');
    },
    error: (xhr, status, err) => {
      console.log(`Error retrieving state options for country: ${countryIdSelected}}`, xhr, status, err);
    },
    complete: () => {
      if (typeof state === 'string') stateSelect.value = state;
    },
  });
}

function autocompleteForm(autocomplete, changeFormClasses) {
  const place = autocomplete.getPlace();
  const cityInput = document.querySelector('.autocomplete-city');
  const postalCodeInput = document.querySelector('.autocomplete-postal-code');
  let streetNumber = '';
  let streetName = '';
  let state = '';
  let i = 0;
  while (i < place.address_components.length) {
    const address = place.address_components[i];
    switch (address.types[0]) {
      case 'street_number':
        streetNumber = address.long_name;
        break;
      case 'route':
        streetName = address.long_name;
        break;
      case 'locality':
        if (changeFormClasses) cityInput.classList.add('form_option-selected');
        cityInput.value = address.long_name;
        break;
      case 'administrative_area_level_1':
        state = address.long_name;
        break;
      case 'postal_code':
        if (changeFormClasses) postalCodeInput.classList.add('form_option-selected');
        postalCodeInput.value = address.long_name;
        break;
      case 'country':
        document.querySelectorAll('.autocomplete-country option').forEach((option) => {
          if (option.dataset.iso === address.short_name.trim().toUpperCase()) option.selected = true;
        });
        selectStateForCountry(state);
        break;
      default:
        break;
    }
    i += 1;
  }

  const street = (streetNumber && streetNumber !== 'undefined') ? `${streetNumber} ${streetName}` : streetName;
  document.querySelector('.autocomplete-address').value = street;
}

document.addEventListener('DOMContentLoaded', async () => {
  const body = document.querySelector(`${pagesWithFormAndNewDesigns.join(', ')}, #registrations-new, #registrations-edit, #registrations-create, #invites_registration-new, #dashboard-restrictions-water_authorities-new, #dashboard-restrictions-water_authorities-edit`);
  if (body) {
    const changeFormClasses = pagesWithFormAndNewDesigns.find((page) => page === `#${body.id}`);
    const addressField = document.querySelector('.autocomplete-address');
    await window.google.maps.importLibrary('places');
    const autocomplete = new window.google.maps.places.Autocomplete(addressField, {});
    window.google.maps.event.addListener(autocomplete, 'place_changed', () => autocompleteForm(autocomplete, changeFormClasses));
    document.querySelector('.autocomplete-country').addEventListener('change', selectStateForCountry);
  }
});
