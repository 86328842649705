import $ from 'jquery';
import bootbox from 'bootbox';
import Backbone from 'backbone';
import CartModel from '@assets/javascripts/backbone/models/shopping_cart/cart.js';
import CartItemModel from '@assets/javascripts/backbone/models/shopping_cart/cart_item.js';

const ShoppingCartWidgetView = Backbone.View.extend({
  initialize() {
    this.delegateEvents();
    this.reload();
  },
  reload() {
    this.cart = new CartModel();
  },
  addToCart(subscriptionId, withSnackbar = false) {
    window.showLoadingScreen();
    const planId = $('#add-to-cart').data('plan-id');
    const item = new CartItemModel({ subscription_id: subscriptionId, plan: planId });

    item.save({}, {
      success: (_, data) => {
        if (withSnackbar) {
          window.toggleSnackbar('success-snackbar');
        }

        this.changeShoppingCartButtonStyle(data);
        const controllerIds = data.cart.controller_ids;
        $('#account_subscriptions-table').data('controller-ids', controllerIds);

        $('[id^="select-from-"]:checked:not(:disabled)').each((index, input) => {
          if (controllerIds.includes(parseInt(input.dataset.controllerId, 10))) {
            input.disabled = true;
          }

          // Remove general input of each subtable if there is no input allowed inside
          const subtable = $(input).closest('.subtable');
          const generalInput = subtable.find('[id^="select-all"]');
          if (subtable.find('[id^="select-from"]:not(:disabled)').length === 0) {
            generalInput.remove();
          } else {
            generalInput.prop('checked', false).removeClass('tw-sl-checkbox-partial');
          }
        });

        $('#add-to-cart').addClass('tw-hidden');
        window.hideLoadingScreen();
        this.reload();
      },
      error: this.displayError,
    });
  },
  changeShoppingCartButtonStyle(data) {
    const count = data.cart.cart_items.length;
    const shoppingCartButton = $('#shopping-cart-btn');
    shoppingCartButton.find('p.count').text(`(${count})`);
    shoppingCartButton.addClass('tw-button-primary').removeClass('tw-btn-disabled');
  },
  displayError(jqXHR, textStatus) {
    window.hideLoadingScreen();
    $('#add-to-cart').removeClass('tw-hidden');

    if (textStatus.status === 401) {
      return;
    }

    if (textStatus?.responseText?.startsWith('{')) {
      const msg = JSON.parse(textStatus.responseText);
      if (msg?.subscription?.error_message) {
        bootbox.alert(msg.subscription.error_message);
      } else {
        bootbox.alert('Could not add item!');
      }
    } else {
      bootbox.alert('There was an error with your request! Please try again.');
    }
  },
});
export default ShoppingCartWidgetView;
