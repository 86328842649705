function emailSectionValidator(event) {
  let arrayOfEmails = event.target.value.split(',');
  arrayOfEmails = arrayOfEmails.map((email) => email.trim());
  window.showValidLabel(arrayOfEmails, event.target.parentElement);
}

function ownerEmailValidor(event) {
  let arrayOfEmails = event.target.value.split(',');
  arrayOfEmails = arrayOfEmails.map((email) => email.trim());
  const parent = event.target.parentElement;
  if (arrayOfEmails.length > 1) {
    parent.querySelector('.owner_email_error').classList.remove('tw-hidden');
    parent.querySelector('.valid').classList.add('tw-hidden');
    parent.querySelector('.not-valid').classList.add('tw-hidden');
  } else {
    parent.querySelector('.owner_email_error').classList.add('tw-hidden');
    window.showValidLabel(arrayOfEmails, parent);
  }
}

function isValidPassword(password) {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,70}$/.test(password);
}

function showValidPassword(event, container) {
  const validLabel = container.querySelector('.valid');
  const notValidLabel = container.querySelector('.not-valid');
  validLabel.classList.add('tw-hidden');
  notValidLabel.classList.add('tw-hidden');
  if (isValidPassword(event.target.value)) {
    validLabel.classList.remove('tw-hidden');
  } else {
    notValidLabel.classList.remove('tw-hidden');
  }
}

function validateNewAccountForm() {
  return ['account_name', 'account_address_1', 'account_city', 'account_postal', 'account_owner_email', 'account_password'].every((input) => document.getElementById(input).value !== '') && document.querySelector('.not-valid:not(.tw-hidden)') == null;
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('dashboard-accounts-new');
  if (body) {
    const accountOwnerEmail = document.getElementById('account_owner_email');
    const accountAdminUsers = document.getElementById('account_admin_users');
    const accountNonAdminUsers = document.getElementById('account_non_admin_users');
    const password = document.getElementById('account_password');
    accountOwnerEmail.addEventListener('input', ownerEmailValidor);
    accountAdminUsers.addEventListener('input', emailSectionValidator);
    accountNonAdminUsers.addEventListener('input', emailSectionValidator);
    password.addEventListener('input', (event) => { showValidPassword(event, password.parentElement); });
    const form = document.querySelector('form');
    form.addEventListener('change', () => {
      const submitButton = form.querySelector('[type="submit"]');
      if (validateNewAccountForm()) {
        submitButton.classList.add('tw-button-primary');
        submitButton.classList.remove('tw-btn-disabled');
      } else {
        submitButton.classList.remove('tw-button-primary');
        submitButton.classList.add('tw-btn-disabled');
      }
    });
  }
});
