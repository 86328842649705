import Backbone from 'backbone';
import $ from 'jquery';

const InspectionZoneCommentsView = Backbone.View.extend({
  el: '#inspection-table',
  events: {
    'click .edit-zone-comment': 'popZoneCommentModal',
  },
  initialize() {
    console.log('Inspection Zone Comments initialized');

    $('.zone-comment-modal').on('click', '.frequent-comment', (e) => {
      const selectedComment = $(e.target).closest('tr').find($('.response-comment')).text();
      $('.zone-comment-modal').find('#modal-zone-comment').val(selectedComment);
    });

    $('.zone-comment-modal').on('click', '#save-zone-comment', (e) => {
      const inspectionZoneId = $(e.target).attr('data-inspection-zone-id');
      const comment = $('.zone-comment-modal').find('#modal-zone-comment').val();
      const controllerId = $('#inspection').data('controller-id');
      const inspectionId = $('#inspection').data('inspection-id');

      $.ajax({
        url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/zones/${inspectionZoneId}`,
        type: 'PUT',
        data: ({ controls_inspections_zone: { note: comment } }),
        dataType: 'json',
        success(resp) {
          console.log(resp);
          $(`.zone-comment-trigger[data-id='${inspectionZoneId}']`).text(resp.note);
        },
        error(resp) {
          alert('Sorry something went wrong');
          console.log(resp);
        },
      });
    });
  },
  popZoneCommentModal(e) {
    const comment = $(e.currentTarget).children($('p')).text();
    const zoneNumber = $(e.target).attr('data-number');
    const controllerId = $('#inspection').data('controller-id');
    const inspectionId = $('#inspection').data('inspection-id');
    const inspectionZoneId = $(e.target).attr('data-id');

    $('.zone-comment-modal').modal('show');

    // add comment to modal input
    $('.zone-comment-modal').find('#modal-zone-comment').val(comment.trim());

    // fill modal header
    $('.zone-comment-title').text(`Zone #${zoneNumber} Comment`);

    $('#save-zone-comment').attr('data-inspection-zone-id', inspectionZoneId);

    // clear frequent comment table contents to start fresh
    document.querySelectorAll('#frequent-comments-table tr').forEach((row) => {
      if (!row.querySelector('th')) {
        row.parentNode.removeChild(row);
      }
    });

    return $.ajax({
      url: `/api/v2/controls/${controllerId}/inspections/${inspectionId}/zones/${zoneNumber}/frequent_zone_notes`,
      type: 'GET',
      dataType: 'json',
      success(response) {
        console.log(response);
        let i = 0;
        while (i < response.length) {
          $('#frequent-comments-table tr:last').after(`<tr><td class='response-comment'>${response[i]}</td><td class='centered'><button class='btn btn-xs btn-info frequent-comment'>Select</button></td></tr>`);
          i += 1;
        }
      },
      error(response) {
        alert('Sorry something went wrong');
        return console.log(response);
      },
    });
  },
});
export default InspectionZoneCommentsView;
