import Backbone from 'backbone';

const SiteNewControllerView = Backbone.View.extend({
  events: {
    'submit #new_controller_form': 'check_numeric_name',
  },
  checkNumericName(e) {
    const nameInput = document.querySelector("input[name='control[name]']");
    if (!Number.isNaN(nameInput.value)) {
      alert("It looks like you may have entered the ICCID in the 'name' field. Please double-check!");
      e.preventDefault();
      return false;
    }
    return true;
  },
});
export default SiteNewControllerView;
