import Backbone from 'backbone';

const WaterRestrictionsEditView = Backbone.View.extend({
  el: '.backbone-view-container',
  initialize() {
    console.log('Initializing Water Restrictions View Edit');
  },
  events: {
    'click #select_all_days': 'selectAllDays',
  },
  selectAllDays() {
    console.log('clicked all days');
    const selectAllDaysCheckbox = document.getElementById('select_all_days');
    const waterRestrictionCheckboxes = document.querySelectorAll('#days #water_restriction_week_');

    if (selectAllDaysCheckbox.checked) {
      waterRestrictionCheckboxes.forEach((checkbox) => checkbox.checked = true);
    } else {
      waterRestrictionCheckboxes.forEach((checkbox) => checkbox.checked = false);
    }
  },
});
export default WaterRestrictionsEditView;
