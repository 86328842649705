function move(showId, hideId) {
  const show = document.querySelector(showId);
  show.classList.remove('tw-hidden');
  const hide = document.querySelector(hideId);
  hide.classList.add('tw-hidden');
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function changeApplyModalInfo(title, description) {
  document.querySelector('#apply_settings-modal .title').innerHTML = title;
  document.querySelector('#apply_settings-modal .description').innerHTML = description;
}

function applyChangesButtonActivate() {
  const button = window.$('#step-4 .disabled');
  button.attr('disabled', false);
  button.removeClass('disabled');
  button.addClass('btn-next');
}
window.applyChangesButtonActivate = applyChangesButtonActivate;

function applyChanges() {
  const commands = JSON.parse(sessionStorage.getItem('globalCommand'));
  window.$.ajax({
    type: 'POST',
    url: '/global_commands.js',
    dataType: 'script',
    data: commands,
  });
}
window.applyChanges = applyChanges;

function showApplyModal(hasNoSLWAndSmartWateringMode, hasWinterized) {
  let modalType = '';
  const modal = document.getElementById('apply_settings-modal');
  if (hasWinterized) {
    modalType = 'apply_winterized';
  } else if (hasNoSLWAndSmartWateringMode) {
    modalType = 'apply_watering_smart_mode';
  } else {
    modalType = 'apply';
  }
  changeApplyModalInfo(
    window.I18n.global_commands.summary.modals[modalType].title,
    window.I18n.global_commands.summary.modals[modalType].description,
  );
  modal.classList.remove('tw-hidden');
  modal.addEventListener('click', (e) => {
    if (e.target === e.currentTarget) applyChangesButtonActivate();
  });
}

function applyChangesButtonDeactivate() {
  const button = window.$('#step-4 .btn-next');
  button.attr('disabled', true);
  button.removeClass('btn-next');
  button.addClass('disabled');
}

function showApplyModalTwice() {
  const modal = document.getElementById('apply_settings-modal');
  changeApplyModalInfo(
    window.I18n.global_commands.summary.modals.apply_watering_smart_mode.title,
    window.I18n.global_commands.summary.modals.apply_watering_smart_mode.description,
  );
  modal.querySelector('.continue').onclick = () => {
    changeApplyModalInfo(
      window.I18n.global_commands.summary.modals.apply_winterized.title,
      window.I18n.global_commands.summary.modals.apply_winterized.description,
    );
    modal.querySelector('.continue').onclick = () => applyChanges();
  };
  modal.classList.remove('tw-hidden');
}

// Apply Changes
function applyChangesModal() {
  const hasNoSLW = document.querySelectorAll('.controllers .ctn-controller-row .no-slw').length > 0;
  const hasSmartWateringMode = window.retrieveSystemSettings()?.mode === '1';
  const hasWinterized = document.querySelectorAll('.controllers .ctn-controller-row .winterized').length > 0;
  if ((hasNoSLW && hasSmartWateringMode) && hasWinterized) {
    showApplyModalTwice();
  } else {
    showApplyModal(hasNoSLW && hasSmartWateringMode, hasWinterized);
  }
}

function goNext(event) {
  const stepId = event.target.id.replace('step-', '');
  switch (stepId) {
    case '1':
      move('.ctn-smart-settings', '.ctn-basic-settings');
      break;
    case '2':
      move('.ctn-advanced-settings', '.ctn-smart-settings');
      break;
    case '3':
      window.getSummary();
      break;
    case '4':
      applyChangesModal();
      applyChangesButtonDeactivate();
      break;
    default:
      move('.ctn-smart-settings', '.ctn-basic-settings');
      break;
  }
}
window.goNext = goNext;

function goBack(event) {
  const stepId = event.target.id.replace('step-', '');
  switch (stepId) {
    case '2':
      move('.ctn-basic-settings', '.ctn-smart-settings');
      break;
    case '3':
      move('.ctn-smart-settings', '.ctn-advanced-settings');
      break;
    case '4':
      move('.ctn-advanced-settings', '.global_commands-summary');
      break;
    default:
      move('.ctn-basic-settings', '.ctn-smart-settings');
      break;
  }
}
window.goBack = goBack;
