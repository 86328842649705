function EmptyCheck(value) {
  return value && Object.keys(value).length === 0 && value.constructor === Object;
}
window.EmptyCheck = EmptyCheck;

function retrieveControllerIds() {
  const controllersIds = document.querySelector('.global-commands');
  let ids = JSON.parse(controllersIds.dataset.ids);
  const commandsStorage = JSON.parse(sessionStorage.getItem('globalCommand'));
  if (commandsStorage?.ids) {
    ids = commandsStorage.ids;
  }
  return ids;
}
window.retrieveControllerIds = retrieveControllerIds;

function retrieveSystemSettings() {
  const settings = {};
  const systemSettingsSelectedOptions = document.querySelectorAll(
    '.ctn-system-settings .ctn-system-options .ctn-dropdown .dropdown-options',
  );
  systemSettingsSelectedOptions.forEach((parentClass) => {
    if (typeof parentClass === 'object') {
      const options = parentClass.querySelectorAll('li');
      options.forEach((option) => {
        if (
          option.classList[0] === 'li-check'
          && option.textContent.trim()
          !== window.I18n.global_commands.new.basic_settings.no_change
        ) {
          settings[parentClass.id] = option.id;
        }
      });
    }
  });
  return settings;
}
window.retrieveSystemSettings = retrieveSystemSettings;

function retrieveWateringTimes() {
  const programsSelection = document.querySelectorAll(
    '.ctn-watering-times .ctn-programs .ctn-program',
  );
  const programs = [];
  programsSelection.forEach((programClass) => {
    let referenceName = 'no change';
    let watering = {};
    watering.description = programClass.querySelector('#prg-description').value;
    const options = programClass.querySelectorAll('li');
    options.forEach((option) => {
      if (option.classList[1] === 'li-check') {
        watering.program_type = option.id;
        referenceName = option.classList[0];
      }
    });

    const daysSelection = programClass.querySelectorAll(
      '.ctn-program-type .ctn-program-options .ctn-days-of-week p',
    );

    const daysOfWeekList = [];

    daysSelection.forEach((day) => {
      if (
        day.classList[3] === 'day-clicked'
        && day.classList[1] === 'days-of-week'
        && watering.program_type === '1'
      ) {
        daysOfWeekList.push(day.id);
        watering.days_of_week = daysOfWeekList.toString();
      } else if (
        day.classList[3] === 'day-clicked'
        && watering.program_type === '3'
      ) {
        watering.interval_start = day.id;
        watering.days_interval = programClass.querySelector('.stepper').value;
        daysOfWeekList.push(day.id);
        watering.days_of_week = daysOfWeekList.toString();
      }
    });

    if (watering.program_type === '1') {
      watering.interval_start = '';
      watering.days_interval = 0;
    }

    if (watering.program_type === '2') {
      watering.days_of_week = '';
    }

    if (
      referenceName === 'odd'
    ) {
      watering.oddeven = 3;
    } else if (
      referenceName === 'even'
    ) {
      watering.oddeven = 2;
    } else {
      delete watering.oddeven;
    }

    if (
      referenceName === 'no change'
      && watering.description === ''
    ) {
      watering = {};
    } else if (
      referenceName === 'no change'
      && watering.description !== ''
    ) {
      watering = {};
      watering.description = programClass.querySelector('#prg-description').value;
    }

    watering.program_start_times = [];
    const timePickers = programClass.querySelectorAll(
      '.ctn-program-start-times .ctn-time-picker',
    );

    timePickers.forEach((timePicker) => {
      const startTimeData = {};
      const toggleSwitch = timePicker.querySelector("input[type='checkbox']");

      if (toggleSwitch.checked) {
        startTimeData.number = timePicker.id;
        if (timePicker.querySelector('.ctn-time-selected').classList.contains('tw-hidden')) {
          startTimeData.start_time = 'OFF';
        } else {
          startTimeData.start_time = timePicker.querySelector(
            '.ctn-time-selected p',
          ).textContent;
        }
        watering.program_start_times.push(startTimeData);
      }
    });
    const emptyValues = watering.program_start_times.filter((programStart) => programStart.start_time !== '');
    if (emptyValues.length === 0) {
      delete watering.program_start_times;
    }

    if (!EmptyCheck(watering)) {
      watering.identifier = programClass.querySelector(
        '#ctn-identifier',
      ).textContent;
    }

    programs.push(watering);
  });
  return programs;
}
window.retrieveWateringTimes = retrieveWateringTimes;

function retrieveSeasonalAdjusts() {
  const seasonalPrograms = document.querySelectorAll(
    '.ctn-seasonal-adjust .ctn-seasonal-programs .ctn-seasonal-program',
  );
  const programs = [];
  const programEmptyCheckList = [];
  seasonalPrograms.forEach((programInput) => {
    const program = {};
    const noChangesMonthCheckList = [];
    program.identifier = programInput.querySelector('.seasonal-title p').id;
    program.adjustments = [];
    const inputsPercentages = programInput.querySelectorAll(
      '.ctn-monthly-percentage .input-percentage',
    );
    inputsPercentages.forEach((percentageInput) => {
      const adjustmentsItem = {};
      adjustmentsItem.month = parseInt(percentageInput.id, 10);
      adjustmentsItem.percentage = percentageInput.value;
      program.adjustments.push(adjustmentsItem);
      if (adjustmentsItem.percentage !== '') {
        noChangesMonthCheckList.push(adjustmentsItem);
      }
      if (noChangesMonthCheckList.length !== 0) {
        programEmptyCheckList.push(program.identifier);
      }
    });
    programs.push(program);
  });
  if (programEmptyCheckList.length !== 0) {
    return programs;
  }
  return null;
}
window.retrieveSeasonalAdjusts = retrieveSeasonalAdjusts;

function retrieveOmitTimesAndDays() {
  const omitTimesAndDays = {};
  omitTimesAndDays.controller_omission_times = {};
  const omitParentClass = document.querySelector('.ctn-omit-times');
  omitTimesAndDays.controller_omission_times.start_time = omitParentClass.querySelector(
    '.initial-time-range .ctn-time-picker .ctn-time-selected p',
  ).textContent.toLowerCase().replace(' ', '');
  omitTimesAndDays.controller_omission_times.end_time = omitParentClass.querySelector(
    '.final-time-range .ctn-time-picker .ctn-time-selected p',
  ).textContent.toLowerCase().replace(' ', '');

  const toggleSwitch = document.querySelector(".ctn-omit-times .toggle-switch input[type='checkbox']");
  if (toggleSwitch.checked) omitTimesAndDays.controller_omission_days_enabled = true;

  const omitDays = omitParentClass.querySelectorAll('.ctn-days-of-week p');
  const omissionDaysList = [];
  omitDays.forEach((day) => {
    if (day.classList[4] === 'day-clicked') {
      omissionDaysList.push(day.id);
    }
  });
  omitTimesAndDays.controller_omission_days = omissionDaysList.toString();

  omitTimesAndDays.controller_omission_dates = [];
  const omitDates = omitParentClass.querySelectorAll('.blocked-dates .ctn-date-picker .input');
  omitDates.forEach((date) => {
    if (date.value !== '') {
      const formatedDate = `2000 ${date.value}`;
      omitTimesAndDays.controller_omission_dates.push(formatedDate);
    }
  });

  if (omitTimesAndDays.controller_omission_dates.length === 0) {
    delete omitTimesAndDays.controller_omission_dates;
  }

  if (omitTimesAndDays.controller_omission_days === '') {
    delete omitTimesAndDays.controller_omission_days;
  }

  if (omitTimesAndDays.controller_omission_times.start_time === ''
    || omitTimesAndDays.controller_omission_times.end_time === '') {
    delete omitTimesAndDays.controller_omission_times;
  }

  if (EmptyCheck(omitTimesAndDays)) {
    return null;
  }
  return omitTimesAndDays;
}
window.retrieveOmitTimesAndDays = retrieveOmitTimesAndDays;
