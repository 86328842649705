import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTEventsView = Backbone.View.extend({

  el: 'body',
  events: {

    'change .ransack': Shared.updateRansackFilter,
    'keydown .dataTables_filter input': Shared.delaySearch,
    'change #filter_presets': Shared.changeFilterPreset,
    'click .remove-filter': Shared.removeFilter,
    'click #create_filter_report': Shared.createFilterReport,
    'click #update_filter_report': Shared.updateFilterReport,
    'click #destroy_filter_report': Shared.destroyFilterReport,
    'click #hide_sidebar': Shared.toggleSidebar,
    'click #sidebar-button': Shared.toggleSidebar,
    'click #export': Shared.exportFile,

  },
  initialize() {
    $('#date_gt, #date_lt').datepicker({ format: 'yyyy-mm-dd' }).on('changeDate', (e) => { $(e.target).trigger('change'); });
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      return Shared.showFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[5, 'desc']],
      bAutoWidth: false,
      responsive: false,
      searching: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // Place the controller id inside each <tr>
      fnRowCallback(nRow, aData) {
        if (aData.is_fault_bool) { $(nRow).addClass('alert-danger'); }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/events.json',
        type: 'POST',
        data(params) {
          params.ransack = {};

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => $(`#${key}`).val(value));

          document.querySelectorAll('.ransack').forEach((element) => {
            if (element.value) {
              params.ransack[element.id] = Shared.getInputValue(element);
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            $(`#${key}`).val(val);
          });
        },
        error: Shared.errorPopup,
      }, // This return is very important for some reason

      columns: [
        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        {
          data: 'controller_name', name: 'controller_name', orderable: true, visible: window.COLUMNS.controller_name.visible,
        },
        {
          data: 'controller_id', name: 'controller_id', orderable: true, visible: window.COLUMNS.controller_id.visible,
        },
        {
          data: 'site_name', name: 'site_name', orderable: true, visible: window.COLUMNS.site_name.visible,
        },
        {
          data: 'site_id', name: 'site_id', orderable: true, visible: window.COLUMNS.site_id.visible,
        },
        { data: 'date', name: 'date', visible: window.COLUMNS.date.visible },
        { data: 'date_with_timezone', name: 'date_with_timezone', visible: window.COLUMNS.date_with_timezone.visible },
        {
          data: 'status', name: 'status', orderable: true, visible: window.COLUMNS.status.visible,
        },
        { data: 'is_fault', name: 'is_fault', visible: window.COLUMNS.is_fault.visible },
        { data: 'event_type_as_string', name: 'event_type_as_string', visible: window.COLUMNS.event_type_as_string.visible },
        {
          data: 'as_text', name: 'as_text', orderable: false, visible: window.COLUMNS.as_text.visible,
        },
        { data: 'site_address', name: 'site_address', visible: window.COLUMNS.site_address.visible },
        { data: 'site_city', name: 'site_city', visible: window.COLUMNS.site_city.visible },
        { data: 'site_state', name: 'site_state', visible: window.COLUMNS.site_state.visible },
        { data: 'site_billing_email', name: 'site_billing_email', visible: window.COLUMNS.site_billing_email.visible },
        {
          data: 'user_email', name: 'user_email', orderable: false, visible: window.COLUMNS.user_email.visible,
        },
      ],
    });
    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    return $('.dataTables_filter input').off();
  },
});
export default DTEventsView;
