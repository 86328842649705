const searchSiteInspectionsInput = document.getElementById('site-inspections-search');

function searchSiteInspections(perPage = 10) {
  const inputSearch = document.getElementById('site-inspections-search').value;
  window.$.ajax({
    url: 'inspection_schedules',
    dataType: 'script',
    type: 'GET',
    data: { filter: { name_cont: inputSearch }, per_page: perPage },

  });
}

function entriesScheduledTable() {
  const selectedEntries = document.querySelector('.li-check').id.split('-')[1];
  searchSiteInspections(selectedEntries);
}
window.entriesScheduledTable = entriesScheduledTable;

searchSiteInspectionsInput?.addEventListener('keyup', window.debounce(() => {
  entriesScheduledTable();
}, 500));

function scrollArrowsVerification(containerId, arrowForward, arrowForwardCol, arrowBack) {
  const scrollSize = document.getElementById(containerId).scrollWidth - document.getElementById(containerId).clientWidth - 24;

  if (scrollSize > 0) {
    document.getElementById(arrowForward).classList.remove('tw-hidden');
    [...document.querySelectorAll('[id=inspectionsGoForwardCol]')].forEach((element) => element.classList.remove('tw-hidden'));
  } else {
    document.getElementById(arrowForward).classList.add('tw-hidden');
    [...document.querySelectorAll('[id=inspectionsGoForwardCol]')].forEach((element) => element.classList.add('tw-hidden'));
  }

  if (document.getElementById(containerId).scrollLeft < scrollSize) {
    document.getElementById(arrowForward).classList.remove('tw-hidden');
    [...document.querySelectorAll('[id=inspectionsGoForwardCol]')].forEach((element) => element.classList.remove('tw-hidden'));
  } else {
    document.getElementById(arrowForward).classList.add('tw-hidden');
    [...document.querySelectorAll('[id=inspectionsGoForwardCol]')].forEach((element) => element.classList.add('tw-hidden'));
  }

  if (document.getElementById(containerId).scrollLeft === 0) {
    document.getElementById(arrowBack).classList.add('tw-hidden');
  } else {
    document.getElementById(arrowBack).classList.remove('tw-hidden');
  }
}
window.scrollArrowsVerification = scrollArrowsVerification;

function tableScroll(containerId, type) {
  const container = document.getElementById(containerId);
  const size = container.getElementsByTagName('td')[1].offsetWidth;
  if (type === 'forward') {
    container.scrollLeft += size;
  } else {
    container.scrollLeft -= size;
  }
  scrollArrowsVerification('scrollableContainer', 'inspectionsGoForward', 'inspectionsGoForwardCol', 'inspectionsGoBack');
}
window.tableScroll = tableScroll;

function selectAll(selectorAll, selectorCheckbox) {
  const selectAllCheck = document.getElementById(selectorAll);
  [...document.querySelectorAll(selectorCheckbox)].forEach((element) => {
    element.dataset.dirty = element.checked !== selectAllCheck.checked && element.dataset.dirty !== 'true';
    element.checked = selectAllCheck.checked;
  });
  selectAllCheck.classList.remove('tw-sl-checkbox-partial');
}
window.selectAll = selectAll;

function verifySelectAllStatus(selectorAll, selectorCheckbox) {
  const selectAllCheck = document.getElementById(selectorAll);
  const checked = document.querySelectorAll(`${selectorCheckbox}[type="checkbox"]:checked:not(:disabled)`).length;
  const total = document.querySelectorAll(`${selectorCheckbox}:not(:disabled)`).length;
  if (checked === 0) {
    selectAllCheck.checked = false;
  } else if (total - checked === 0) {
    selectAllCheck.checked = true;
    selectAllCheck.classList.remove('tw-sl-checkbox-partial');
  } else {
    selectAllCheck.checked = true;
    selectAllCheck.classList.add('tw-sl-checkbox-partial');
  }
}
window.verifySelectAllStatus = verifySelectAllStatus;

function verifySelectAllStatusFirst() {
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  months.forEach((month) => verifySelectAllStatus(`selectAll${month}`, `[name*="[${month}]"]`));
}
window.verifySelectAllStatusFirst = verifySelectAllStatusFirst;

function getDataUrl(nameCont, perPage, url) {
  const newUrl = new URL(url);
  if (nameCont !== '') {
    newUrl.searchParams.set('filter[name_cont]', nameCont);
  } else {
    newUrl.searchParams.delete('filter[name_cont]');
  }
  if (perPage) {
    newUrl.searchParams.set('per_page', perPage);
  } else {
    newUrl.searchParams.delete('per_page');
  }
  return newUrl;
}

function addParamsToPagination() {
  const inputSearch = document.getElementById('site-inspections-search').value;
  const selectedEntries = document.querySelector('.li-check').id.split('-')[1];
  const paginationContainer = document.getElementById('pagination-container');
  if (paginationContainer) {
    [...paginationContainer.children].forEach((listElement) => {
      const tag = listElement.children[0];
      if (tag.href !== '') {
        const newUrl = getDataUrl(inputSearch, selectedEntries, tag.href);
        tag.href = newUrl;
      }
    });
  }
  const pathUrl = getDataUrl(inputSearch, selectedEntries, window.location);
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.addParamsToPagination = addParamsToPagination;

function getData() {
  const data = [];
  [...document.getElementById('InspectionSchedulesBody').children].forEach((sites) => {
    const inspections = {};
    inspections.id = sites.getElementsByTagName('input')[0].value;
    [...sites.querySelectorAll('[type="checkbox"][data-dirty="true"]')].forEach((month) => {
      inspections[month.id.split('schedule_attributes_')[1]] = month.checked;
    });
    if (Object.keys(inspections).length > 1) {
      data.push(inspections);
    }
  });
  return data;
}

function sendData() {
  const data = getData();
  window.$.ajax({
    url: 'inspection_schedules/multiple_update',
    dataType: 'script',
    type: 'PUT',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify({ data }),

  });
}
window.sendData = sendData;

function verifyUpdateButtonStatus() {
  const button = document.getElementById('UpdateData');
  if (document.querySelectorAll('[type="checkbox"][data-dirty="true"]').length > 0) {
    button.classList.add('tw-button-secondary');
    button.classList.remove('tw-btn-disabled');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-secondary');
  }
}
window.verifyUpdateButtonStatus = verifyUpdateButtonStatus;

function updateDirty(checkbox) {
  checkbox.dataset.dirty = checkbox.dataset.dirty === 'true' ? 'false' : 'true';
  verifyUpdateButtonStatus();
}
window.updateDirty = updateDirty;

function setAllSchedulesNoDirty() {
  [...document.querySelectorAll('[type="checkbox"][data-dirty="true"]')].forEach((checkbox) => checkbox.dataset.dirty = 'false');
  verifyUpdateButtonStatus();
}
window.setAllSchedulesNoDirty = setAllSchedulesNoDirty;

function updateInputsByParams() {
  const urlValues = new URL(window.location.href);
  const perPage = urlValues.searchParams.get('per_page');
  const nameCont = urlValues.searchParams.get('filter[name_cont]');
  if (nameCont) {
    searchSiteInspectionsInput.value = nameCont;
  }
  if (perPage) {
    const entries = document.getElementById(`scheduled_table_entries-${perPage}`);
    window.selectChosenOption(entries);
  }
}

// This is useful for set the pagination params on the url
// sometimes we may call the pagination endpoint from another endpoint but just the params should be changed in the URL
// so here it also evaluates the current url vs the endpoint url and reflect only the actual pagination params and not change the complete URL
function setUrlParams(linkTag) {
  const linkUrl = new URL(linkTag.href);
  const newPage = linkUrl.searchParams.get('page');
  const currentUrl = new URL(window.location.href);
  if (linkUrl.pathname !== currentUrl.pathname) {
    currentUrl.searchParams.set('page', newPage);
    window.history.pushState({}, null, currentUrl);
  } else {
    window.history.pushState({}, null, linkTag.href);
  }
}
window.setUrlParams = setUrlParams;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('inspection_schedules-index');
  if (body) {
    scrollArrowsVerification('scrollableContainer', 'inspectionsGoForward', 'inspectionsGoForwardCol', 'inspectionsGoBack');
    verifySelectAllStatusFirst();
    updateInputsByParams();
  }
});
