function enableInspectionEditTime(button) {
  button.classList.add('tw-hidden');
  document.getElementById('edit-inspection_time').classList.remove('tw-hidden');
  document.getElementById('fixed-inspection_time').classList.add('tw-hidden');
}
window.enableInspectionEditTime = enableInspectionEditTime;

function renderNewInspectionModal(controlId) {
  window.$.ajax({
    url: `/controls/${controlId}/inspections/new`,
    dataType: 'script',
    type: 'GET',
  });
}
window.renderNewInspectionModal = renderNewInspectionModal;
