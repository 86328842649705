function accountsSetEntriesParamValue() {
  const accountsEntrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (accountsEntrySelected) {
    const entries = document.getElementById(`accounts_index-entries-${accountsEntrySelected}`);
    window.selectChosenOption(entries);
  }
}

const accountsColumnsHeaders = {
  0: 'id', 1: 'name', 3: 'users_count', 4: 'city', 5: 'province',
};

function accountsSetSortParamValue() {
  const sortParam = (new URLSearchParams(window.location.search)).get('sort');
  const sortedHeaders = document.querySelectorAll('table th[class*="sorting_"]');
  if (sortParam) {
    const sortField = sortParam.split(' ');
    const keys = Object.keys(accountsColumnsHeaders);
    const values = Object.values(accountsColumnsHeaders);

    if (sortedHeaders.length <= 0 && values.includes(sortField[0])) {
      const position = values.indexOf(sortField[0]);
      const index = keys[position];
      document.querySelectorAll('table th')[index].classList.add(`sorting_${sortField[1]}`);
    }
  }
}

function getAccounts(searchValue, page, perPage, sort) {
  window.$.ajax({
    url: '/dashboard/accounts/',
    data:
    {
      filter: { search: searchValue },
      page,
      per_page: perPage,
      sort,
    },
    dataType: 'script',
    type: 'GET',
  });
}

function accountsSetSearchInputListenerAndValue() {
  const accountSerachInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const accountsListSearchInput = document.getElementById('search-accounts_index');
  accountsListSearchInput.value = accountSerachInputParam;

  accountsListSearchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#accounts_index-entries .li-check').id.split('-')[2];
    window.AddParamsToTailwindPagination({ 'filter[search]': accountsListSearchInput.value });
    getAccounts(accountsListSearchInput.value, 1, entrySelected);
  }, 500));
}

function executeAccountSort(event) {
  const index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
  const classesList = event.target.classList;
  const sort = classesList.contains('sorting_desc') ? 'desc' : 'asc';
  const sortStr = `${accountsColumnsHeaders[index]} ${sort}`;
  const entries = document.querySelector('#accounts_index-entries .li-check').id.split('-')[2];
  const searchInput = document.getElementById('search-accounts_index');

  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sortStr}` });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
  getAccounts(searchInput.value, 1, entries, sortStr);
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('dashboard-accounts-index');
  if (body) {
    document.querySelectorAll('table .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', executeAccountSort);
    });
    accountsSetSortParamValue();
    accountsSetEntriesParamValue();
    accountsSetSearchInputListenerAndValue();
  }
});

function updateEntriesForAccountsIndexTable() {
  const entries = document.querySelector('#accounts_index-entries .li-check').id.split('-')[2];
  const accountsListSearchInput = document.getElementById('search-accounts_index');
  const searchValue = accountsListSearchInput.value;
  getAccounts(searchValue, 1, entries);
}
window.updateEntriesForAccountsIndexTable = updateEntriesForAccountsIndexTable;

function updateAccountsIndexUrlWithEntries() {
  const entries = document.querySelector('#accounts_index-entries .li-check').id.split('-')[2];
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: entries, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateAccountsIndexUrlWithEntries = updateAccountsIndexUrlWithEntries;
