function showEnterDiscount(button) {
  button.parentElement.classList.add('tw-hidden');
  document.getElementById('enterDiscount').classList.remove('tw-hidden');
  document.getElementById('btn-remove-code').parentElement.classList.add('tw-hidden');
  document.getElementById('btn-apply-code').parentElement.classList.remove('tw-hidden');
}
window.showEnterDiscount = showEnterDiscount;

function updateDiscountView(response) {
  if (response.cart.coupons.length > 0) {
    const totalSection = document.querySelector('.total').children;
    document.getElementById('shopping_cart-discount').classList.remove('tw-border-red-500');
    document.getElementById('shopping_cart-discount').classList.add('tw-border-gray-300');
    document.querySelector('.discount_code .applied').classList.remove('tw-hidden');
    document.querySelector('.discount_code .error').classList.add('tw-hidden');
    document.getElementById('btn-remove-code').parentElement.classList.remove('tw-hidden');
    document.getElementById('btn-apply-code').parentElement.classList.add('tw-hidden');
    totalSection[1].classList.add('tw-text-gray-600', 'tw-line-through', 'tw-decoration-gray-600');
    const current = totalSection[2].textContent.split('/')[1];
    totalSection[2].textContent = `${response.cart.display_total}/${current}`;
    totalSection[2].classList.remove('tw-hidden');
  } else {
    document.getElementById('shopping_cart-discount').classList.add('tw-border-red-500');
    document.getElementById('shopping_cart-discount').classList.remove('tw-border-gray-300');
    document.querySelector('.discount_code .error').classList.remove('tw-hidden');
  }
}

function updateDiscountCode() {
  window.$.ajax({
    url: '/api/v2/cart',
    data: { coupon: document.getElementById('shopping_cart-discount').value },
    dataType: 'json',
    type: 'PUT',
    success(response) {
      updateDiscountView(response);
    },
    error(response) {
      console.log(response);
    },
  });
}
window.updateDiscountCode = updateDiscountCode;

function updateRemoveDiscountView() {
  document.getElementById('enterDiscount').classList.add('tw-hidden');
  const totalSection = document.querySelector('.total').children;
  document.querySelector('.discount_code .applied').classList.add('tw-hidden');
  document.querySelector('.discount_code .error').classList.add('tw-hidden');
  totalSection[1].classList.remove('tw-text-gray-600', 'tw-line-through', 'tw-decoration-gray-600');
  totalSection[2].classList.add('tw-hidden');
  document.querySelector('.discount_code .without_discount').classList.remove('tw-hidden');
  document.getElementById('shopping_cart-discount').value = '';
}

function removeDiscountCode() {
  window.$.ajax({
    url: '/api/v2/cart/remove_coupon',
    dataType: 'json',
    type: 'DELETE',
    success() {
      updateRemoveDiscountView();
    },
  });
}
window.removeDiscountCode = removeDiscountCode;

function addRider(cartItemId, planId) {
  window.$.ajax({
    url: `/cart/cart_items/${cartItemId}/riders`,
    dataType: 'script',
    data: { id: planId },
    type: 'POST',
  });
}
window.addRider = addRider;

function removeRider(cartItemId, planId) {
  window.$.ajax({
    url: `/cart/cart_items/${cartItemId}/riders/${planId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}
window.removeRider = removeRider;

function removeCartItem(cartItemId) {
  window.$.ajax({
    url: `/cart/cart_items/${cartItemId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}
window.removeCartItem = removeCartItem;

function removeCartSiteItem(siteId) {
  window.$.ajax({
    url: `/cart/site_items/${siteId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}
window.removeCartSiteItem = removeCartSiteItem;

function showApplyDiscountButton(input) {
  const button = document.getElementById('btn-apply-code');
  button.classList.remove('tw-hidden');
  if (input.value === '') {
    button.classList.remove('tw-button-secondary'); button.classList.add('tw-btn-disabled');
  } else { button.classList.add('tw-button-secondary'); button.classList.remove('tw-btn-disabled'); }
}
window.showApplyDiscountButton = showApplyDiscountButton;

function emptyCart(cartId) {
  window.$.ajax({
    url: `/shopping_cart/${cartId}`,
    dataType: 'script',
    type: 'DELETE',
  });
}
window.emptyCart = emptyCart;

function updateCartBilled(cartId) {
  const billingInterval = document.querySelector('#billed_type .li-check').id.split('-')[1];
  window.$.ajax({
    url: `/shopping_cart/${cartId}`,
    dataType: 'script',
    data: { billing_interval: billingInterval },
    type: 'PUT',
  });
}
window.updateCartBilled = updateCartBilled;

function showFlowRiderModal(type, cartItemId, planId) {
  const modal = document.getElementById(`${type}-rider-flow`);
  modal.classList.remove('tw-hidden');
  if (type === 'add') {
    modal.querySelector('.tw-btn-action-blue').onclick = () => { addRider(cartItemId, planId); modal.classList.add('tw-hidden'); };
  } else {
    modal.querySelector('.tw-btn-action-red').onclick = () => { removeRider(cartItemId, planId); modal.classList.add('tw-hidden'); };
  }
}
window.showFlowRiderModal = showFlowRiderModal;
