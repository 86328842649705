function exportEquipmentOrder() {
  const orderId = document.getElementById('equipment_order').dataset.equipmentOrder;
  const orderNumber = document.getElementById('equipment_order').dataset.equipmentOrderNumber;
  const accountId = document.getElementById('equipment_order').dataset.accountId;
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });
  const url = `/accounts/${accountId}/equipment_orders/${orderId}.xlsx`;
  fetch(url, {
    method: 'GET',
    headers,
  })
    .then((res) => res.blob())
    .then((blob) => {
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = `Equipment Order ${orderNumber}.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
}
window.exportEquipmentOrder = exportEquipmentOrder;

function deleteOrderDetails() {
  const data = document.getElementById('equipment_order').dataset;
  window.$.ajax({
    url: `/accounts/${data.accountId}/equipment_orders/${data.equipmentOrder}`,
    dataType: 'script',
    type: 'DELETE',
    error: () => {
      document.getElementById('warning-modal').classList.add('tw-hidden');
      window.toggleSnackbar('warning-snackbar', window.I18n.generic_error);
    },
  });
}

function showDeleteOrderModal() {
  const modal = document.getElementById('warning-modal');
  modal.querySelector('.tw-btn-action-red').onclick = () => deleteOrderDetails();
  modal.classList.remove('tw-hidden');
}

window.showDeleteOrderModal = showDeleteOrderModal;
