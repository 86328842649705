function paymentParentSelected(parentElement) {
  const radioInput = parentElement.querySelector('input');
  radioInput.checked = true;
  radioInput.dispatchEvent(new Event('change', { bubbles: true }));
}
window.paymentParentSelected = paymentParentSelected;

function paymentChecked() {
  document.querySelector('.pay-action button').classList.remove('tw-btn-disabled');
  document.querySelector('.pay-action button').classList.add('tw-bg-green-700', 'tw-text-white');
  document.querySelectorAll('.payments_list li.tw-border-blue-500').forEach((element) => {
    element.classList.remove('tw-border-blue-500', 'tw-bg-blue-100');
    element.classList.add('tw-border-gray-300');
    element.querySelector('hr').classList.add('tw-border-gray-300');
    element.querySelector('hr').classList.remove('tw-border-blue-500');
  });
  const checked = document.querySelector('input[name="list-payment"]:checked').parentElement.parentElement.parentElement;
  checked.classList.add('tw-border-blue-500', 'tw-bg-blue-100');
  checked.classList.remove('tw-border-gray-300');
  checked.querySelector('hr').classList.remove('tw-border-gray-300');
  checked.querySelector('hr').classList.add('tw-border-blue-500');
}
window.paymentChecked = paymentChecked;

function renderPaymentError(textStatus, message = null) {
  let snackbarMessage = '';
  if (message) {
    snackbarMessage = message;
  } else if (textStatus.responseText?.startsWith('{')) {
    const msg = JSON.parse(textStatus.responseText);
    if (msg.subscription?.error_message) {
      snackbarMessage = msg.subscription.error_message;
    }
  } else { snackbarMessage = 'There was an error with your request!  Please try again.'; }

  window.toggleSnackbar('warning-snackbar', snackbarMessage);
}
window.renderPaymentError = renderPaymentError;

function emptyCartModal(cartId) {
  const modal = document.getElementById('exit-checkout');
  modal.classList.remove('tw-hidden');
  modal.querySelector('.tw-btn-action-red').onclick = () => { window.emptyCart(cartId); };
}
window.emptyCartModal = emptyCartModal;

function changeCreditCard(cartId, paymentSourceId) {
  window.$.ajax({
    url: '/api/v2/cart',
    data: { payment_source_id: paymentSourceId },
    dataType: 'script',
    type: 'PUT',
  });
}
window.changeCreditCard = changeCreditCard;
