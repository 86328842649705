function toggleAddToCartButton() {
  const button = document.getElementById('add-to-cart');
  if (document.querySelectorAll('[id^="select-from-"]:checked:not(:disabled)').length > 0) {
    button.classList.remove('tw-hidden');
  } else {
    button.classList.add('tw-hidden');
  }
}
window.toggleAddToCartButton = toggleAddToCartButton;
function resetActivationNumber(modal) {
  const input = modal.querySelector('input');
  input.classList.remove('tw-border-green-700', 'tw-border-red-500');
  input.classList.add('tw-border-gray-300');
  input.value = '';
  modal.querySelector('.not-valid').classList.add('tw-hidden');
  modal.querySelector('.valid').classList.add('tw-hidden');
  modal.querySelector('button.activate').classList.remove('tw-btn-action-blue');
  modal.querySelector('button.activate').classList.add('tw-btn-disabled');
}

function showActivatePlanCardModal(subId) {
  const modal = document.getElementById('activate-plan-card-modal');
  modal.classList.remove('tw-hidden');
  resetActivationNumber(modal);
  modal.querySelector('button.activate').dataset.subId = subId;
}
window.showActivatePlanCardModal = showActivatePlanCardModal;
function removeActivatePlanCardModal() {
  const modal = document.getElementById('activate-plan-card-modal');
  modal.classList.add('tw-hidden');
}
window.removeActivatePlanCardModal = removeActivatePlanCardModal;
function updateAccountSubscriptionsUrlWithEntries(entry) {
  const value = entry.dataset.value;
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: value, page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateAccountSubscriptionsUrlWithEntries = updateAccountSubscriptionsUrlWithEntries;
function updateAccountSubscriptionsUrlWithShowOption(show) {
  const value = show.dataset.value;
  const pathUrl = window.changeDataFromUrl(window.location, { show: value });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateAccountSubscriptionsUrlWithShowOption = updateAccountSubscriptionsUrlWithShowOption;
