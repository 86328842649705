const siteAlertsNotificationTranslation = {
  off: null,
  daily: 1,
  instant: 0,
};

const siteAlertsIndexEnableUpdateButton = () => {
  const updateButton = document.getElementById('update-alerts-button');
  updateButton.classList.remove('tw-btn-disabled');
  updateButton.classList.add('tw-btn-cancel');
  const updateButtonSvgPath = updateButton.getElementsByTagName('path')[0];
  updateButtonSvgPath.classList.add('tw-stroke-blue-500');
};

window.siteAlertsIndexEnableUpdateButton = siteAlertsIndexEnableUpdateButton;

const siteAlertsIndexDisableUpdateButton = () => {
  const updateButton = document.getElementById('update-alerts-button');
  updateButton.classList.add('tw-btn-disabled');
  updateButton.classList.remove('tw-btn-cancel');
  const updateButtonSvgPath = updateButton.getElementsByTagName('path')[0];
  updateButtonSvgPath.classList.remove('tw-stroke-blue-500');
};

window.siteAlertsIndexDisableUpdateButton = siteAlertsIndexDisableUpdateButton;

const siteAlertsIndexAddIconToButton = (element) => {
  const idSplit = element.id.split('-');
  const val = idSplit[idSplit.length - 1];
  const container = element.closest('.ctn-dropdown');
  container.querySelector('.btn-dropdown .icon-container svg')?.remove();
  if (val === 'off') return;

  const parentButton = container.getElementsByClassName('btn-dropdown')[0];
  parentButton.getElementsByClassName('icon-for-option')[0]?.remove();
  const svgIcon = element.getElementsByClassName('icon-for-option')[0].cloneNode(true);
  svgIcon.classList.remove('tw-hidden');
  const textContent = parentButton.getElementsByClassName('icon-container')[0];
  textContent.prepend(svgIcon);
};

window.siteAlertsIndexAddIconToButton = siteAlertsIndexAddIconToButton;

const siteAlertsIndexMarkAsChanged = (element) => {
  Array.from(element.parentNode.children).forEach((li) => {
    li.classList.remove('changed');
  });
  element.classList.add('changed');
  const btn = element.closest('.ctn-dropdown').getElementsByClassName('btn-dropdown')[0];
  btn.classList.add('tw-border', 'tw-border-blue-500', 'tw-bg-blue-100', 'tw-text-gray-900');
  btn.classList.remove('tw-border-gray-300', 'tw-border-gray-600', 'tw-bg-white');
  siteAlertsIndexEnableUpdateButton();
};

window.siteAlertsIndexMarkAsChanged = siteAlertsIndexMarkAsChanged;

const siteAlertsIndexExtractValues = (element, view) => {
  const elementSplit = element.id.split('-');
  const siteId = elementSplit[2];
  const groupContainer = element.closest('.event-group-container');
  const eventType = groupContainer.dataset.event;
  const notificationType = siteAlertsNotificationTranslation[elementSplit[4]];
  const data = {
    site_id: siteId,
    event_type: eventType,
    notification_type: notificationType,
  };
  if (view === 'advanced') data.event_number = groupContainer.dataset.eventNumber;
  return data;
};

const resetSiteAlertsSnackbars = () => {
  document.getElementById('info-snackbar').classList.add('tw-hidden');
  document.getElementById('warning-snackbar').classList.add('tw-hidden');
  document.getElementById('success-snackbar').classList.add('tw-hidden');
};

const siteAlertsIndexSetAndSave = (siteAlertsData, view) => {
  const amountOfSitesToUpdate = (new Set(siteAlertsData.map((elem) => window.parseInt(elem.site_id)))).size;
  const url = '/api/v2/sites/user_site_alerts_bulk_manager';
  const snackbar = document.getElementById('info-snackbar');

  let infoMessage;
  let warningMessage;
  let successMessage;

  if (amountOfSitesToUpdate > 1) {
    infoMessage = window.I18n.site_alerts.index.info_snackbar_description.other.replace('%{sites_count}', amountOfSitesToUpdate);
    successMessage = window.I18n.site_alerts.index.success_snackbar_description.other.replace('%{sites_count}', amountOfSitesToUpdate);
    warningMessage = window.I18n.site_alerts.index.warning_snackbar_description.other.replace('%{sites_count}', amountOfSitesToUpdate);
  } else {
    infoMessage = window.I18n.site_alerts.index.info_snackbar_description.one;
    successMessage = window.I18n.site_alerts.index.success_snackbar_description.one;
    warningMessage = window.I18n.site_alerts.index.warning_snackbar_description.one;
  }

  resetSiteAlertsSnackbars();
  snackbar.querySelector('.description').textContent = infoMessage;
  snackbar.classList.remove('tw-hidden');
  window.loadAnimationPending('#info-snackbar #loading-state');
  window.$.ajax({
    type: 'POST',
    contentType: 'application/json; charset=utf-8',
    url,
    data: JSON.stringify({ site_alerts_data: siteAlertsData, view: `${view === 'advanced' ? 'advanced' : 'basic'}` }),
    success: () => {
      resetSiteAlertsSnackbars();
      window.toggleSnackbar('success-snackbar', successMessage);
      siteAlertsIndexDisableUpdateButton();
    },
    error: () => {
      resetSiteAlertsSnackbars();
      window.toggleSnackbar('warning-snackbar', warningMessage);
    },
  });
};

const siteAlertsIndexBranchId = () => document.querySelector('#site_alerts_branch-filter .li-check').id.split('-')[2];

const siteAlertsIndexSearchValue = () => document.getElementById('search').value;

const siteAlertsIndexEntries = () => document.querySelector('#entries .li-check').id.split('-')[1];

const siteAlertsIndexUpdateUserSiteAlerts = (view) => {
  const siteAlertstoUpdate = Array.from(document.getElementsByClassName('changed'));
  if (siteAlertstoUpdate.length === 0) return;

  const newSiteAlertsData = siteAlertstoUpdate.map((siteAlert) => siteAlertsIndexExtractValues(siteAlert, view));
  siteAlertsIndexSetAndSave(newSiteAlertsData, view);
};

window.siteAlertsIndexUpdateUserSiteAlerts = siteAlertsIndexUpdateUserSiteAlerts;

const siteAlertsIndexSetEntriesParamValue = () => {
  const entrySelected = (new URLSearchParams(window.location.search)).get('per_page');
  if (entrySelected) {
    const entriesElement = document.getElementById(`entries-${entrySelected}`);
    window.selectChosenOption(entriesElement);
  }
};

window.siteAlertsIndexSetEntriesParamValue = siteAlertsIndexSetEntriesParamValue;

const siteAlertsIndexExtraParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return [urlParams.get('view'), urlParams.get('notification_type')];
};

const siteAlertsIndexGetRows = (page) => {
  const [view, notificationType] = siteAlertsIndexExtraParams();
  window.$.ajax({
    url: '/site_alerts',
    data:
    {
      view,
      notification_type: notificationType,
      filter: {
        search: siteAlertsIndexSearchValue(),
        branch_id: siteAlertsIndexBranchId(),
      },
      page,
      per_page: siteAlertsIndexEntries(),
    },
    dataType: 'script',
    type: 'GET',
  });
};

window.siteAlertsIndexGetRows = siteAlertsIndexGetRows;

const siteAlertsIndexSetSearchInputListenerAndValue = () => {
  const searchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const searchInput = document.getElementById('search');
  searchInput.value = searchInputParam;

  searchInput?.addEventListener('keyup', window.debounce(() => {
    window.AddParamsToTailwindPagination({ 'filter[search]': searchInput.value });
    siteAlertsIndexGetRows(1);
  }, 500));
};

window.siteAlertsIndexSetSearchInputListenerAndValue = siteAlertsIndexSetSearchInputListenerAndValue;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('site_alerts-index');
  if (body) {
    siteAlertsIndexSetEntriesParamValue();
    siteAlertsIndexSetSearchInputListenerAndValue();
  }
});

const siteAlertsIndexUpdateTableRows = () => {
  siteAlertsIndexGetRows(1);
};

window.siteAlertsIndexUpdateTableRows = siteAlertsIndexUpdateTableRows;

const siteAlertsIndexUpdateUrlWithEntries = () => {
  const pathUrl = window.changeDataFromUrl(window.location, { per_page: siteAlertsIndexEntries(), page: 1 });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
};

window.siteAlertsIndexUpdateUrlWithEntries = siteAlertsIndexUpdateUrlWithEntries;

function siteAlertsIndexModalDescription(paragraph, events) {
  let eventsList = '';
  events.forEach((event) => eventsList += `<li>${event === '2' ? window.I18n.controller.alert_info[`E${event}_short_title`] : window.I18n.controller.alert_info[`E${event}_title`]}</li>`);
  return `${paragraph}<br><br><strong>${window.I18n.site_alerts.index.included_events}</strong><br><ul>${eventsList}</ul>`;
}

const siteAlertsIndexDisplayInformationModal = (view, events, type = null, centerDescription = null) => {
  let title = '';
  let description;
  events = events.split(' ');

  if (view === 'advanced') {
    title = (window.I18n.controller.alert_info[`E${events[0]}_broken_title`] || window.I18n.controller.alert_info[`E${events[0]}_title`]).split(' - ');
    description = window.I18n.controller.alert_info[`E${events[0]}_description`];
  } else {
    title = window.I18n.site_alerts.index[type].title.split(' - ');
    description = siteAlertsIndexModalDescription(window.I18n.site_alerts.index[type].description, events);
  }

  const modal = document.getElementById('site-alerts-information-modal');
  const statusChangeTitle = modal.querySelector('.status_change');
  const modalTitle = modal.querySelector('.title');
  const modalDescription = modal.querySelector('.description');

  if (title.length === 2) {
    statusChangeTitle.innerHTML = title[0];
    modalTitle.innerHTML = title[1];
  } else {
    statusChangeTitle.innerHTML = '';
    modalTitle.innerHTML = title[0];
  }

  if (centerDescription) {
    modalDescription.classList.remove('tw-text-justify');
    modalDescription.classList.add('tw-text-center');
  } else {
    modalDescription.classList.remove('tw-text-center');
    modalDescription.classList.add('tw-text-justify');
  }
  modalDescription.innerHTML = description;
  modal.classList.remove('tw-hidden');
};

window.siteAlertsIndexDisplayInformationModal = siteAlertsIndexDisplayInformationModal;

const siteAlertsIndexHideInformationModal = () => {
  const modal = document.getElementById('site-alerts-information-modal');
  modal.classList.add('tw-hidden');
};

window.siteAlertsIndexHideInformationModal = siteAlertsIndexHideInformationModal;

const siteAlertsIndexSetColumnValue = (column) => {
  const val = document.querySelector(`#${column} .li-check`).id.split('-')[1];
  if (!val) return;
  const options = document.querySelectorAll(`div[id$=-${column}].dropdown-options li[id$=-${val}]`);
  options.forEach((option) => {
    window.selectChosenOption(option);
    siteAlertsIndexMarkAsChanged(option);
    siteAlertsIndexAddIconToButton(option);
  });
};

window.siteAlertsIndexSetColumnValue = siteAlertsIndexSetColumnValue;
