import Backbone from 'backbone';
import $ from 'jquery';

const InspectionShowView = Backbone.View.extend({
  el: 'body',
  events: {
    'click #settings-button': 'open_settings',
  },
  initialize() {
    if ((parseInt($('#inspection-table').attr('data-total-zones'), 10) > 8) && !this.is_ios_device()) {
      $('#inspection-table').stickyTableHeaders();
      $('#table-scroll').on('scroll', () => $(window).trigger('resize.stickyTableHeaders'));
    }
    if ($('body#controller_inspections-show').length > 0) {
      return $('#show-first-inspection').hide();
    }
    return '';
  },
  is_ios_device() {
    return /iPhone|iPad/.test(navigator.userAgent);
  },
  open_settings() {
    // console.log 'open_settings'
    return $('#inspection-info .active').removeClass('active');
  },
});
export default InspectionShowView;
