function timePickerToggle(className) {
  return (event) => {
    if (event.target.tagName !== 'svg' && event.target.tagName !== 'path') {
      const timeSelector = event.target.closest(className).parentElement.querySelector('.ctn-time-picker-stepper');
      if (timeSelector.classList.contains('tw-hidden')) {
        timeSelector.classList.remove('tw-hidden');
        timeSelector.classList.add('tw-flex');
      } else {
        timeSelector.classList.add('tw-hidden');
      }
    }
  };
}

function displayTimeSelected(element) {
  const timePickerStepper = element.parentElement.parentElement;
  const timeSelected = timePickerStepper.previousElementSibling;
  const label = timeSelected.previousElementSibling.previousElementSibling.querySelector('p');
  label.classList.add('selected-label');
  const hours = timePickerStepper.querySelector('.ctn-hours span').textContent;
  const minutes = timePickerStepper.querySelector('.ctn-minutes span').textContent;
  const amPm = timePickerStepper.querySelector('.ctn-am-pm button').classList[1] === 'btn-clicked' ? 'AM' : 'PM';
  timeSelected.querySelector('p').textContent = `${hours}:${minutes} ${amPm}`;
  timeSelected.classList.remove('tw-hidden');
  timeSelected.classList.add('tw-flex');
  timeSelected.previousElementSibling.classList.add('tw-hidden');
}

function goUp(maxValue, minValue, plusButton, increment) {
  const hoursElement = plusButton.nextElementSibling;
  let hours = parseInt(hoursElement.textContent, 10);
  if (hours === maxValue) {
    hours = minValue;
  } else {
    hours += increment;
  }
  if (hours < 10) hours = `0${hours.toString()}`;
  hoursElement.textContent = hours;
}

function goDown(maxValue, minValue, minusButton, decrement) {
  const hoursElement = minusButton.previousElementSibling;
  let hours = parseInt(hoursElement.textContent, 10);
  if (hours === minValue) {
    hours = maxValue;
  } else {
    hours -= decrement;
  }
  if (hours < 10) hours = `0${hours.toString()}`;
  hoursElement.textContent = hours;
}

function hoursUpWith12Am(event) {
  // This will allow to click on plus icon and have always the same result because of icon composition
  const plusButton = event.target.closest('.heroicon-plus');

  goUp(12, 1, plusButton, 1);
  displayTimeSelected(plusButton);
}

function hoursUpWithout12Am(event) {
  // This will allow to click on plus icon and have always the same result because of icon composition
  const plusButton = event.target.closest('.heroicon-plus');
  const minutes = plusButton.parentElement.nextElementSibling.nextElementSibling.querySelector('span');
  const amPm = plusButton.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.querySelector('.btn-clicked');

  if (minutes.textContent === '00' && amPm.textContent === 'AM') {
    goUp(11, 1, plusButton, 1);
  } else {
    goUp(12, 1, plusButton, 1);
  }

  displayTimeSelected(plusButton);
}

function hoursDownWith12Am(event) {
  // This will allow to click on minus icon and have always the same result because of icon composition
  const minusButton = event.target.closest('.heroicon-minus');

  goDown(12, 1, minusButton, 1);
  displayTimeSelected(minusButton);
}

function hoursDownWithout12Am(event) {
  // This will allow to click on minus icon and have always the same result because of icon composition
  const minusButton = event.target.closest('.heroicon-minus');
  const minutes = minusButton.parentElement.nextElementSibling.nextElementSibling.querySelector('span');
  const amPm = minusButton.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.querySelector('.btn-clicked');

  if (minutes.textContent === '00' && amPm.textContent === 'AM') {
    goDown(11, 1, minusButton, 1);
  } else {
    goDown(12, 1, minusButton, 1);
  }

  displayTimeSelected(minusButton);
}

function stepper10MinutesUpWith12Am(event) {
  // This will allow to click on plus icon and have always the same result because of icon composition
  const plusButton = event.target.closest('.heroicon-plus');

  goUp(50, 0, plusButton, 10);
  displayTimeSelected(plusButton);
}

function stepper10MinutesUpWithout12Am(event) {
  // This will allow to click on plus icon and have always the same result because of icon composition
  const plusButton = event.target.closest('.heroicon-plus');
  const hours = plusButton.parentElement.previousElementSibling.previousElementSibling.querySelector('span');
  const amPm = plusButton.parentElement.nextElementSibling.querySelector('.btn-clicked');

  if (hours.textContent === '12' && amPm.textContent === 'AM') {
    goUp(50, 10, plusButton, 10);
  } else {
    goUp(50, 0, plusButton, 10);
  }

  displayTimeSelected(plusButton);
}

function stepper10MinutesDownWith12Am(event) {
  // This will allow to click on minus icon and have always the same result because of icon composition
  const minusButton = event.target.closest('.heroicon-minus');

  goDown(50, 0, minusButton, 10);
  displayTimeSelected(minusButton);
}

function stepper10MinutesDownWithout12Am(event) {
  // This will allow to click on minus icon and have always the same result because of icon composition
  const minusButton = event.target.closest('.heroicon-minus');
  const hours = minusButton.parentElement.previousElementSibling.previousElementSibling.querySelector('span');
  const amPm = minusButton.parentElement.nextElementSibling.querySelector('.btn-clicked');

  if (hours.textContent === '12' && amPm.textContent === 'AM') {
    goDown(50, 10, minusButton, 10);
  } else {
    goDown(50, 0, minusButton, 10);
  }

  displayTimeSelected(minusButton);
}

function stepper9MinutesUp(event) {
  // This will allow to click on plus icon and have always the same result because of icon composition
  const plusButton = event.target.closest('.heroicon-plus');

  goUp(59, 9, plusButton, 10);
  displayTimeSelected(plusButton);
}

function stepper9MinutesDown(event) {
  // This will allow to click on minus icon and have always the same result because of icon composition
  const minusButton = event.target.closest('.heroicon-minus');

  goDown(59, 9, minusButton, 10);
  displayTimeSelected(minusButton);
}

function buttonStyleToggleWith12Am(event) {
  if (event.target.textContent === 'AM') {
    event.target.classList.add('btn-clicked');
    event.target.nextElementSibling.classList.remove('btn-clicked');
  } else {
    event.target.classList.add('btn-clicked');
    event.target.previousElementSibling.classList.remove('btn-clicked');
  }
  displayTimeSelected(event.target);
}

function buttonStyleToggleWithout12Am(event) {
  const hours = event.target.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.querySelector('span');
  const minutes = event.target.parentElement.previousElementSibling.querySelector('span');

  if (!(hours.textContent === '12' && minutes.textContent === '00')) {
    if (event.target.textContent === 'AM') {
      event.target.classList.add('btn-clicked');
      event.target.nextElementSibling.classList.remove('btn-clicked');
    } else {
      event.target.classList.add('btn-clicked');
      event.target.previousElementSibling.classList.remove('btn-clicked');
    }

    displayTimeSelected(event.target);
  }
}

function unlockTimePicker(event) {
  // This will allow to click on exit icon and don't have any trouble with <svg> and <path> elements that compose it
  const deleteIcon = event.target.closest('.heroicon-exit');
  deleteIcon.previousElementSibling.textContent = '';

  const timeSelected = deleteIcon.parentElement;
  timeSelected.previousElementSibling.previousElementSibling.classList.remove('selected-label');
  timeSelected.classList.add('tw-hidden');
  timeSelected.previousElementSibling.classList.remove('tw-hidden');
}

const timePickers = document.querySelectorAll('.btn-time-picker');
timePickers.forEach((timePicker) => timePicker.addEventListener('click', timePickerToggle('.btn-time-picker')));

const plusHoursButtonsOfWateringTimes = document.querySelectorAll('#watering-times-and-days .ctn-hours .heroicon-plus');
plusHoursButtonsOfWateringTimes.forEach((plusHoursButton) => plusHoursButton.addEventListener('click', hoursUpWithout12Am));

const minusHoursButtonsOfWateringTimes = document.querySelectorAll('#watering-times-and-days .ctn-hours .heroicon-minus');
minusHoursButtonsOfWateringTimes.forEach((minusHoursButton) => minusHoursButton.addEventListener('click', hoursDownWithout12Am));

const plusStepper10MinutesButtonsOfWateringTimes = document.querySelectorAll('#watering-times-and-days .stepper-10 .ctn-minutes .heroicon-plus');
plusStepper10MinutesButtonsOfWateringTimes.forEach((plusMinutesButton) => plusMinutesButton.addEventListener('click', stepper10MinutesUpWithout12Am));

const minusStepper10MinutesButtonsOfWateringTimes = document.querySelectorAll('#watering-times-and-days .stepper-10 .ctn-minutes .heroicon-minus');
minusStepper10MinutesButtonsOfWateringTimes.forEach((minusMinutesButton) => minusMinutesButton.addEventListener('click', stepper10MinutesDownWithout12Am));

const plusHoursButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .ctn-hours .heroicon-plus');
plusHoursButtonsOfOmitTimes.forEach((plusHoursButton) => plusHoursButton.addEventListener('click', hoursUpWith12Am));

const minusHoursButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .ctn-hours .heroicon-minus');
minusHoursButtonsOfOmitTimes.forEach((minusHoursButton) => minusHoursButton.addEventListener('click', hoursDownWith12Am));

const plusStepper10MinutesButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .stepper-10 .ctn-minutes .heroicon-plus');
plusStepper10MinutesButtonsOfOmitTimes.forEach((plusMinutesButton) => plusMinutesButton.addEventListener('click', stepper10MinutesUpWith12Am));

const minusStepper10MinutesButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .stepper-10 .ctn-minutes .heroicon-minus');
minusStepper10MinutesButtonsOfOmitTimes.forEach((minusMinutesButton) => minusMinutesButton.addEventListener('click', stepper10MinutesDownWith12Am));

const plusStepper9MinutesButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .stepper-9 .ctn-minutes .heroicon-plus');
plusStepper9MinutesButtonsOfOmitTimes.forEach((plusMinutesButton) => plusMinutesButton.addEventListener('click', stepper9MinutesUp));

const minusStepper9MinutesButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .stepper-9 .ctn-minutes .heroicon-minus');
minusStepper9MinutesButtonsOfOmitTimes.forEach((minusMinutesButton) => minusMinutesButton.addEventListener('click', stepper9MinutesDown));

const amPmButtonsOfWateringTimes = document.querySelectorAll('#watering-times-and-days .ctn-am-pm button');
amPmButtonsOfWateringTimes.forEach((amPmButton) => amPmButton.addEventListener('click', buttonStyleToggleWithout12Am));

const amPmButtonsOfOmitTimes = document.querySelectorAll('#omit-times-days-and-dates .ctn-am-pm button');
amPmButtonsOfOmitTimes.forEach((amPmButton) => amPmButton.addEventListener('click', buttonStyleToggleWith12Am));

const timeSelectedResponses = document.querySelectorAll('.ctn-time-selected');
timeSelectedResponses.forEach((timeSelected) => timeSelected.addEventListener('click', timePickerToggle('.ctn-time-selected')));

const deleteTimePickerIcons = document.querySelectorAll('.ctn-time-picker .ctn-time-selected .heroicon-exit');
deleteTimePickerIcons.forEach((deleteTimePickerIcon) => deleteTimePickerIcon.addEventListener('click', unlockTimePicker));

document.addEventListener('mouseup', (event) => {
  const timePickerSteppers = document.querySelectorAll('.ctn-time-picker-stepper');
  timePickerSteppers.forEach((timePicker) => {
    if (!timePicker.contains(event.target)) {
      timePicker.classList.add('tw-hidden');
    }
  });
});

function setTimePicker(firstElement, secondElement, className) {
  if (firstElement !== '' && secondElement === '') {
    const hours = firstElement.substr(0, 2);
    let minutes = className === 'final' ? String(parseInt(firstElement.substr(3, 2), 10) + 9)
      : String(parseInt(firstElement.substr(3, 2), 10) - 9);
    if (minutes.length === 1) minutes = `0${minutes}`;
    const amPM = firstElement.substr(6, 2);
    const time = `${hours}:${minutes} ${amPM}`;

    const selectedTime = document.querySelector(`.ctn-omit-times .${className}-time-range .ctn-time-selected`);
    selectedTime.firstElementChild.textContent = time;
    selectedTime.classList.remove('tw-hidden');
    selectedTime.classList.add('tw-flex');
    selectedTime.previousElementSibling.classList.add('tw-hidden');

    const label = document.querySelector('.ctn-omit-times .initial-time-range .ctn-time-picker p');
    label.classList.add('selected-label');

    const timePickerStepper = selectedTime.nextElementSibling;
    timePickerStepper.querySelector('.ctn-hours span').textContent = hours;
    timePickerStepper.querySelector('.ctn-minutes span').textContent = minutes;
    if (amPM === 'PM') {
      timePickerStepper.querySelector('.ctn-am-pm .am').classList.remove('btn-clicked');
      timePickerStepper.querySelector('.ctn-am-pm .pm').classList.add('btn-clicked');
    } else {
      timePickerStepper.querySelector('.ctn-am-pm .am').classList.add('btn-clicked');
      timePickerStepper.querySelector('.ctn-am-pm .pm').classList.remove('btn-clicked');
    }
  }
}

function setFinalTimePicker() {
  const initialTime = document.querySelector('.ctn-omit-times .initial-time-range .ctn-time-selected p').textContent;
  const finalTime = document.querySelector('.ctn-omit-times .final-time-range .ctn-time-selected p').textContent;
  setTimePicker(initialTime, finalTime, 'final');
}

function setInitialTimePicker() {
  const finalTime = document.querySelector('.ctn-omit-times .final-time-range .ctn-time-selected p').textContent;
  const initialTime = document.querySelector('.ctn-omit-times .initial-time-range .ctn-time-selected p').textContent;
  setTimePicker(finalTime, initialTime, 'initial');
}

function unlockOmitTimePicker() {
  window.resetTimePickers('.ctn-omit-times');
}

const omitTimesInitialTimePicker = document.querySelector('.ctn-omit-times .initial-time-range .ctn-time-picker-stepper');
omitTimesInitialTimePicker?.addEventListener('click', setFinalTimePicker);

const omitTimesFinalTimePicker = document.querySelector('.ctn-omit-times .final-time-range .ctn-time-picker-stepper');
omitTimesFinalTimePicker?.addEventListener('click', setInitialTimePicker);

const deleteOmitTimePickerIcons = document.querySelectorAll('.ctn-omit-times .ctn-time-picker .ctn-time-selected .heroicon-exit');
deleteOmitTimePickerIcons.forEach((deleteTimePickerIcon) => deleteTimePickerIcon.addEventListener('click', unlockOmitTimePicker));
