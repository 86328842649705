function removeController(controllerId) {
  window.$.ajax({
    url: `/controls/${controllerId}`,
    dataType: 'script',
    type: 'DELETE',
    statusCode: {
      500() {
        window.toggleSnackbar('warning-snackbar');
      },
    },
    error: () => {
      window.toggleSnackbar('warning-snackbar');
    },
  });
}
function showRemoveControllerModal(id = null) {
  const controllerId = id || document.getElementById('controller_destroy').dataset.controllerId;
  const warningModal = document.getElementById('remove-controller-modal');
  warningModal.querySelector('.tw-btn-action-red').onclick = () => removeController(controllerId);
  warningModal.classList.remove('tw-hidden');
}
window.showRemoveControllerModal = showRemoveControllerModal;
