import Backbone from 'backbone';
import $ from 'jquery';
import moment from 'moment';
import { flashDanger, flashSuccess } from '@assets/javascripts/backbone/lib/include/common.js';

const ControllerShowView = Backbone.View.extend({
  el: '#controls-show',
  attempts: 0,
  controllerTime: null,
  controllerUpdatedTime: null,
  events: {
    'click .edit-button': 'clickEditButton',
    'click .cancel-button': 'clickCancelButton',
    'change .zone-run-time-select': 'showHideUsedBox',
    'change select.valve-size': 'selectValveSize',
    'change .manual-run-zone': 'changeManualRunZone',
    'change #valve-locate': 'changeValveLocate',
    'change .manual-run-program': 'changeManualRunProgram',
    'change .manual-test-time': 'changeManualRunTimeTest',
    'change #sensor-type': 'changeFlowSensorType',
    'change .zone-enabled': 'changeZoneEnabled',
    'change .zone-limit.low': 'changeLowFlowLimit',
    'change .zone-limit.high': 'changeHighFlowLimit',
    'click #applyTolerances': 'applyTolerances',
    'change select#program-type': 'changeProgramType',
    'click a.p-0': 'clearZoneA',
    'click a.p-1': 'clearZoneB',
    'click a.p-2': 'clearZoneC',
    'click a.p-3': 'clearZoneD',
    'click #show-more-info': 'showControllerDetails',
    'click #flow_enable_all': 'flowEnableAllZones',
    'click #flow_disable_all': 'flowDisableAllZones',
    'change #label-updated-timestamp': 'updateLastUpdatedTimeTicker',
  },
  initialize() {
    this.pingChecker();
    $('#signal-tooltip').popover({ html: true });

    // Current controller time ticker
    this.controllerTime = moment($('#non-cached-controller-date').data('time'));
    if (this.controllerTime.isValid()) {
      setInterval(this.controllerTimeTicker.bind(this), 1000);
    }

    // Controller last updated time ticker
    this.controllerUpdatedTime = moment($('#controller-updated-timestamp').data('time'));
    if (this.controllerUpdatedTime.isValid()) {
      this.lastUpdatedTimeTicker(); // show it the first time, then update every 10 seconds
      setInterval(this.lastUpdatedTimeTicker.bind(this), 10000);
    }

    this.$('.valve-size').each((index, elem) => {
      const valveGpm = $(elem).closest('tr').find('.valve-gpm').val();
      if (parseInt(valveGpm, 10) === 0) {
        this.selectValveSize({ target: elem });
      }
    });

    this.pollMqttStatus();
  },
  controllerTimeTicker() {
    this.controllerTime.add(1, 'seconds');
    $('#controller-date').text(this.controllerTime.format('ddd, MMM D h:mm:ss A'));
  },
  lastUpdatedTimeTicker() {
    $('#controller-updated-timestamp').text(`( ${this.controllerUpdatedTime.fromNow()} )`);
  },
  updateLastUpdatedTimeTicker() {
    const postSendControllerUpdatedTime = moment($('#post-send-controller-updated-timestamp').data('time'));
    if (postSendControllerUpdatedTime.isValid()) {
      const postSendLastUpdatedTimeTicker = () => {
        $('#post-send-controller-updated-timestamp').text(`( ${postSendControllerUpdatedTime.fromNow()} )`);
      };
      postSendLastUpdatedTimeTicker();
      setInterval(postSendLastUpdatedTimeTicker, 10000);
    }
  },
  clearZoneA(e) {
    e.preventDefault();
    $('select.p-0').val('0');
  },
  clearZoneB(e) {
    e.preventDefault();
    $('select.p-1').val('0');
  },
  clearZoneC(e) {
    e.preventDefault();
    $('select.p-2').val('0');
  },
  clearZoneD(e) {
    e.preventDefault();
    $('select.p-3').val('0');
  },
  showControllerDetails(e) {
    $(e.currentTarget).closest('.row').toggle();
    $('#more-info').removeClass('hidden');
  },
  clickEditButton(e) {
    e.preventDefault();
    $(e.currentTarget).closest('.edit-scope').find('.edit').removeClass('hidden');
    $(e.currentTarget).closest('.edit-scope').find('.show_btn').addClass('hidden');
    $(e.currentTarget).closest('.edit-scope').find('.show').addClass('hidden');
    $('#change_all_flow').removeClass('hidden');
  },
  clickCancelButton(e) {
    e.preventDefault();
    $('.show_btn').removeClass('hidden');
    $('.show').removeClass('hidden');
    $('.edit').addClass('hidden');
    $('#change_all_flow').addClass('hidden');
  },
  changeManualRunZone(e) {
    if (parseInt($(e.currentTarget).val(), 10) === 0) {
      $('.manual-run-program').prop('disabled', false).removeClass('disabled');
      $('#manual-run-time').prop('disabled', true).addClass('disabled').val('0');
      $('#valve-locate').prop('disabled', false).removeClass('disabled').val('0');
      $('#manual-test-time').prop('disabled', false).removeClass('disabled');
    } else {
      $('.manual-run-program').prop('disabled', true).addClass('disabled');
      $('#valve-locate').prop('disabled', true).addClass('disabled').val('0');
      if ($('#manual-run-time').hasClass('disabled')) {
        $('#manual-run-time option:eq(1)').prop('selected', true);
      }
      $('#manual-run-time').prop('disabled', false).removeClass('disabled');
      $('.manual-run-program').val('0');
      $('#manual-test-time').prop('disabled', true).addClass('disabled');
    }
  },
  changeValveLocate(e) {
    if (parseInt($(e.currentTarget).val(), 10) === 0) {
      $('.manual-run-zone').prop('disabled', false).removeClass('disabled');
      $('.manual-run-program').prop('disabled', false).removeClass('disabled');
      $('#manual-run-time').prop('disabled', true).addClass('disabled').val('0');
      $('.manual-test-time').prop('disabled', false).removeClass('disabled');
    } else {
      $('.manual-run-zone').prop('disabled', true).addClass('disabled').val('0');
      $('.manual-run-program').prop('disabled', true).addClass('disabled').val('0');
      $('.manual-run-time').prop('disabled', true).removeClass('disabled');
      $('#manual-test-time').prop('disabled', true).addClass('disabled');
    }
  },
  changeManualRunTimeTest(e) {
    if (parseInt($(e.currentTarget).val(), 10) === 0) {
      $('.manual-run-zone').prop('disabled', false).removeClass('disabled');
      $('.manual-run-program').prop('disabled', false).removeClass('disabled');
      $('#manual-run-time').prop('disabled', true).addClass('disabled').val('0');
      $('#valve-locate').prop('disabled', false).removeClass('disabled').val('0');
    } else {
      $('.manual-run-zone').prop('disabled', true).addClass('disabled');
      $('.manual-run-program').prop('disabled', true).addClass('disabled');
      $('#manual-run-time').prop('disabled', true).addClass('disabled').val('0');
      $('#valve-locate').prop('disabled', true).addClass('disabled').val('0');
    }
  },
  changeManualRunProgram(e) {
    if (parseInt($(e.currentTarget).val(), 10) === 0) {
      $('.manual-run-zone').prop('disabled', false).removeClass('disabled');
      $('#valve-locate').prop('disabled', false).removeClass('disabled').val('0');
      $('#manual-test-time').prop('disabled', false).removeClass('disabled');
    } else {
      $('.manual-run-zone').prop('disabled', true).addClass('disabled').val('0');
      $('#valve-locate').prop('disabled', true).addClass('disabled').val('0');
      $('#manual-test-time').prop('disabled', true).addClass('disabled');
    }
  },
  changeFlowSensorType(e) {
    if ($(e.target).val() === '1') {
      $('.custom_ppg').removeClass('hidden');
    } else {
      $('.custom_ppg').addClass('hidden');
    }
  },
  changeZoneEnabled(e) {
    const val = $(e.currentTarget).val();
    const limitsWebResponsive = $(e.currentTarget).closest('tr').find('.zone-limit');
    const limitsAppResponsive = $(e.currentTarget).closest('.panel-body').find('.zone-limit');
    if (parseInt(val, 10) === 0) {
      $(limitsWebResponsive).prop('disabled', true);
      $(limitsWebResponsive).val(0);
      $(limitsAppResponsive).prop('disabled', true);
      return $(limitsAppResponsive).val(0);
    }
    $(limitsWebResponsive).attr('disabled', false);
    return $(limitsAppResponsive).attr('disabled', false);
  },
  changeLowFlowLimit(e) {
    let lowVal = $(e.currentTarget).val();
    if (lowVal !== 'off') { lowVal = parseInt(lowVal, 10); }
    const highLimit = $(e.currentTarget).closest('tr').find('.high');
    const highLimitChildren = highLimit.children();
    highLimitChildren.each((_i, element) => {
      // 0 is value off
      if (lowVal === 0) { element.removeAttribute('disabled'); }
      const highVal = parseInt(element.value, 10);
      if (highVal <= lowVal) {
        element.setAttribute('disabled', true);
      } else {
        element.removeAttribute('disabled');
      }
    });
  },
  changeHighFlowLimit(e) {
    let highVal = $(e.currentTarget).val();
    if (highVal !== 'off') { highVal = parseInt(highVal, 10); }
    const lowLimit = $(e.currentTarget).closest('tr').find('.low');
    const lowLimitChildren = lowLimit.children();
    lowLimitChildren.each((_i, element) => {
      // 65535 is Off value
      if (highVal === 65535) { element.removeAttribute('disabled'); }
      const lowVal = parseInt(element.value, 10);
      if (lowVal >= highVal) {
        element.setAttribute('disabled', true);
      } else {
        element.removeAttribute('disabled');
      }
    });
  },
  flowEnableAllZones(e) {
    e.preventDefault();
    document.querySelectorAll('.zone-enabled').forEach((element) => {
      element.value = '1';
      element.dispatchEvent(new Event('change'));
    });
  },
  flowDisableAllZones(e) {
    e.preventDefault();
    document.querySelectorAll('.zone-enabled').forEach((element) => {
      element.value = '0';
      element.dispatchEvent(new Event('change'));
    });
  },
  changeProgramType(e) {
    const selectedIndex = parseInt($(e.currentTarget).val(), 10);
    if (selectedIndex === 1) {
      $('.program-type1').show().removeClass('hide');
      $('.program-type2').hide();
      $('.program-type3').hide();
      $('.program-type4').hide();
    } else if (selectedIndex === 2) {
      $('.program-type1').hide();
      $('.program-type2').show().removeClass('hide');
      $('.program-type3').hide();
      $('.program-type4').hide();
    } else if (selectedIndex === 3) {
      $('.program-type1').hide();
      $('.program-type2').hide();
      $('.program-type3').show().removeClass('hide');
      $('.program-type4').hide();
    } else if (selectedIndex === 4) {
      $('.program-type1').hide();
      $('.program-type2').hide();
      $('.program-type3').hide();
      $('.program-type4').show().removeClass('hide');
    }
  },
  applyTolerances(e) {
    e.preventDefault();
    /* eslint-disable-next-line no-restricted-globals */
    if (confirm('Applying tolerances will override your existing high and low flow limits. Are you sure you want to do this?')) {
      $('#area-flow-realtime table tbody tr').each((_e, element) => {
        const tr = $(element);
        const avg = tr.find('.average').data('average');
        const lowLimit = (((100 - parseInt($('#low-flow-tolerance').val(), 10)) / 100) * avg);
        const highLimit = (((100 + parseInt($('#high-flow-tolerance').val(), 10)) / 100) * avg);
        let lastOption = null;

        if (tr.find('.zone-enabled').val() === '1') {
          tr.find('.low').prop('selected', false);
          tr.find('.high').prop('selected', false);

          tr.find('.low option').each((_eLow, lowElement) => {
            if (!lastOption) {
              lastOption = $(lowElement);
            }

            if ($(lowElement).val() > lowLimit) {
              lastOption.prop('selected', true);
              return; // Break the loop
            }

            lastOption = $(lowElement);
          });

          lastOption = null;

          tr.find('.high option').each((_High, highOption) => {
            if (!lastOption) {
              lastOption = $(highOption);
            }

            if ($(highOption).val() > Math.ceil(highLimit) && parseInt($(highOption).val(), 10) !== 65535) {
              lastOption.prop('selected', true);
              return; // Break the loop
            }

            lastOption = $(highOption);
          });
        }
      });
    }
  },
  waitChecker() {
    $.ajax(`/controls/${$('#controller-id').val()}/wait`, {
      type: 'GET',
      dataType: 'json',
      timeout: 35000,
      success: (data) => { // 20x response code
        if (data.status === 'instruction-ok') {
          this.attempts += 1;
          if (data.reload === true) {
            $(window.location).attr('href', `/controls/${$('#controller-id').val()}?comm=success`);
          } else {
            $(window.location).attr('href', `/controls/${$('#controller-id').val()}?comm=transmit`);
          }
        } else if (data.status === 'instruction-error') {
          this.attempts += 1;
          // response_object = JSON.parse data.responseText if data.responseText
          $('p.send-update').hide();
          $('.pending-updates').show();
          $('.pending-updates p').html(data.message);
          $('.pending-updates').removeClass('hidden');
          $('#receive_button').removeClass('disabled').html('<span class="glyphicon glyphicon-cloud-download"></span>Receive');
          if (data.instruction.instruction_type === 0) { // 0 is Instruction::INSTRUCTION_TYPE_TRANSMIT
            $('#send-to-controller')
              .removeClass('disabled')
              .addClass('disable-on-click')
              .attr('href', `/controls/${$('#controller-id').val()}/transmit`)
              .html('<span class="glyphicon glyphicon-cloud-upload"></span>Send');
            $('#receive_button').removeClass('disabled');
          }
          if (data.instruction.instruction_type === 1) { // 0 is Instruction::INSTRUCTION_TYPE_RECEIVE
            $('#send-to-controller').removeClass('disabled');
          }
          this.showError(data.message);
        } else if (data.status === 'instruction-in-progress') {
          // @TODO rate limit this?
          // SmartLink.Singletons.HistoryView.showAndReload()
          setTimeout(this.waitChecker.bind(this), 5000);
        }
      },
    });
  },
  pingChecker() {
    $.ajax(`/controls/${$('#controller-id').val()}/instruction_status`, {
      type: 'GET',
      dataType: 'json',
      success: (data) => { // 20x response code
        if (data.status === 'instruction-ok') {
          if (data.reload === true) {
            if (this.attempts !== 0) {
              setTimeout(window.location.reload.bind(window.location), 2000);
            }
          } else if (data.instruction) {
            // set timestamp?
            if (data.last_updated) { $('label#last-updated-timestamp').text(data.last_updated); }
            $('#comm-banner').addClass('hidden');
            if ((data.instruction.instruction_type === 0) && (data.has_unsent_changes === false)) { $('#send-to-controller').addClass('hidden'); } // 0 is Instruction::INSTRUCTION_TYPE_TRANSMIT
            if (data.message) { this.showSuccess(data.message); }
          }
        } else if (data.status === 'instruction-error') {
          // response_object = JSON.parse data.responseText
          this.attempts += 1;
          $('.pending-updates').show();
          $('.pending-updates p').html(data.message);
          $('.pending-updates').removeClass('hidden');
          if (data.instruction.instruction_type === 0) { // 0 is Instruction::INSTRUCTION_TYPE_TRANSMIT
            $('#send-to-controller')
              .removeClass('disabled')
              .addClass('disable-on-click')
              .attr('href', `/controls/${$('#controller-id').val()}/transmit`)
              .html('<span class="glyphicon glyphicon-cloud-upload"></span>Send');
          }

          this.showError(data.message);
        } else if (data.status === 'instruction-in-progress') {
          this.attempts += 1;
          $('#comm-banner').removeClass('hidden');
          // SmartLink.Singletons.HistoryView.showAndReload()
          $('p.send-update').show();
          if (data.poll_type === 'ping') {
            setTimeout(this.pingChecker.bind(this), 5000);
          } else if (data.poll_type === 'wait') {
            setTimeout(this.waitChecker.bind(this), 5000);
          }
        }
      },
    });
  },
  showError(err) {
    $('#comm-banner').addClass('hidden');
    flashDanger(err);
  },
  showSuccess(msg) {
    $('#comm-banner').addClass('hidden');
    flashSuccess(msg);
  },
  // If program D is used, A-C cannot be.  Inversely, if one of A-C is used, D is not allowed
  // This is a "soak mode" on the controller.
  showHideUsedBox(e) {
    // panels version 10+ do not have the 'used' constraint, so skip this code
    const hasDripConstraint = $('#controller-id').data('has-drip-zone-constraint');
    if (!hasDripConstraint) { return; }

    // must be a < 10 panel, so carry on
    const target = $(e.currentTarget);
    const zoneContainer = target.closest('tr');

    const programSet = target.data('idx'); // zero-indexed, A=0, B=1, C=2, D=3
    const programValue = target.text(); // either "Off" or something like "01:05"

    if (programSet === 3) { // Program D
      if (programValue !== 'OFF') {
        console.log('set program D, mark A/B/C as used');
        zoneContainer.find('.program').not('.program-3').each((index, notProgramD) => {
          $(notProgramD).find('p').css('display', 'block');
          $(notProgramD).find('.dropdown-form').css('display', 'none');
        });
      }
      zoneContainer.find('.program').not('.program-3').find('p').css('display', 'none');
      zoneContainer.find('.program').not('.program-3').find('.dropdown-form').css('display', 'block');
    }
    let programD;
    if (programValue !== 'OFF') {
      programD = zoneContainer.find('.program-3');
      $(programD).find('p').css('display', 'block');
      $(programD).find('.dropdown-form').css('display', 'none');
    }
    const programAVal = zoneContainer.find('.program-0').find('.dropdown-trigger').text().trim();
    const programBVal = zoneContainer.find('.program-1').find('.dropdown-trigger').text().trim();
    const programCVal = zoneContainer.find('.program-2').find('.dropdown-trigger').text().trim();

    if ((programAVal.toLowerCase() === 'off') && (programBVal.toLowerCase() === 'off') && (programCVal.toLowerCase() === 'off')) {
      console.log('a/b/c are off');
      programD = zoneContainer.find('.program-3');
      $(programD).find('p').css('display', 'none');
      $(programD).find('.dropdown-form').css('display', 'block');
    }
  },
  selectValveSize(e) {
    let baseval;
    const pr = $(e.target).val();

    const units = $('#area-flow').data('user-units'); // 0 = gallons, 1 = liters

    if (pr === '0.0') {
      baseval = 0;
    } else if (pr === '0.75') {
      baseval = 10;
    } else if ((pr === '1.0') || (pr === '1')) {
      baseval = 16;
    } else if (pr === '1.25') {
      baseval = 26;
    } else if (pr === '1.5') {
      baseval = 35;
    } else if (pr === '2.0') {
      baseval = 55;
    } else if (pr === '2.5') {
      baseval = 80;
    } else if (pr === '3.0') {
      baseval = 120;
    } else if (pr === '4.0') {
      baseval = 240;
    }

    if (units === 1) {
      baseval = parseInt(parseFloat(baseval) * 3.78541, 10);
    }

    $(e.target).closest('tr').find('input').val(String(baseval));
  },
  pollMqttStatus() {
    if ($('#mqtt-conn-status').data('uses-mqtt')) {
      this.updateMqttStatus();
      setInterval(this.updateMqttStatus, 10000);
    }
  },
  updateMqttStatus() {
    console.log('updating mqtt status');
    const id = $('#mqtt-conn-status').data('controller-id');
    $.ajax(`/api/v2/controllers/${id}/connection_status`).then((resp) => {
      let lastConn; let lastDisconn;
      const ctrl = resp.result.controller;
      console.log('connection status', resp);
      $('#mqtt-conn-status-loading').hide();
      const fmt = 'ddd, MMM D h:mm:ss A';
      if (ctrl.last_connected_at) {
        lastConn = moment(ctrl.last_connected_at);
        lastConn = `${lastConn.format(fmt)} <br><span class='subtle'>(${lastConn.fromNow()})</span>`;
      } else {
        lastConn = 'Unknown';
      }

      if (ctrl.last_disconnected_at) {
        lastDisconn = moment(ctrl.last_disconnected_at);
        lastDisconn = `${lastDisconn.format(fmt)} <br><span class='subtle'>(${lastDisconn.fromNow()})</span>`;
      } else {
        lastDisconn = 'Unknown';
      }
      $('.mqtt-conn-status-last-connected-at').html(lastConn);
      $('.mqtt-conn-status-last-disconnected-at').html(lastDisconn);
      if (ctrl.connected) {
        $('#mqtt-conn-status-disconnected').hide();
        $('#mqtt-conn-status-connected').show();
      } else {
        $('#mqtt-conn-status-disconnected').show();
        $('#mqtt-conn-status-connected').hide();
      }
    });
  },
});
export default ControllerShowView;
