const siteBranchesSearchInput = document.getElementById('site-branches-search');

function getSiteBranches() {
  const accountId = document.getElementById('branches-sites').dataset.accountId;
  const branchId = document.getElementById('branches-sites').dataset.branchId;
  const perPage = document.querySelector('#branch_sites_entries .li-check').id.split('-')[1];
  window.$.ajax({
    url: `/accounts/${accountId}/branches/${branchId}/branch_sites`,
    data: { filter: { name_cont: siteBranchesSearchInput.value }, per_page: perPage, page: 1 },
    dataType: 'script',
    type: 'GET',
  });
}

function updateSiteBranchesPerPage(element) {
  window.AddParamsToTailwindPagination({ per_page: element.id.split('-')[1] });
  getSiteBranches();
}
window.updateSiteBranchesPerPage = updateSiteBranchesPerPage;
function toggleTransferSitesButton() {
  const checked = document.querySelectorAll('#branch_sites-index [id*="sites-select"][type="checkbox"]:checked').length;
  const button = document.getElementById('transfer-sites-button');
  if (checked > 0) {
    button.classList.add('tw-button-primary');
    button.classList.remove('tw-btn-disabled');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
  }
}
window.toggleTransferSitesButton = toggleTransferSitesButton;
siteBranchesSearchInput?.addEventListener('keyup', window.debounce(() => {
  getSiteBranches();
  window.AddParamsToTailwindPagination({ 'filter[name_cont]': siteBranchesSearchInput.value });
  if (siteBranchesSearchInput.value !== '') {
    siteBranchesSearchInput.classList.add('search_input_filled');
    document.getElementById('clean-site-branch-search').classList.remove('tw-hidden');
  } else {
    siteBranchesSearchInput.classList.remove('search_input_filled');
    document.getElementById('clean-site-branch-search').classList.add('tw-hidden');
  }
}, 500));

function scrollMenuVerification(containerId, arrowForward, arrowBack) {
  const scrollSize = document.getElementById(containerId).scrollWidth - document.getElementById(containerId).clientWidth;

  if (document.getElementById(containerId).scrollLeft + 5 < scrollSize) {
    document.getElementById(arrowForward).classList.remove('tw-hidden');
  } else {
    document.getElementById(arrowForward).classList.add('tw-hidden');
  }

  if (document.getElementById(containerId).scrollLeft === 0) {
    document.getElementById(arrowBack).classList.add('tw-hidden');
  } else {
    document.getElementById(arrowBack).classList.remove('tw-hidden');
  }
}
window.scrollMenuVerification = scrollMenuVerification;
function getMenuButtonPosition(page) {
  const buttons = {
    'users-index': 1,
    'branches-index': 2,
    'account_subscriptions-index': 3,
    'account_subscriptions-invoices': 4,
    'equipment_orders-index': 5,
    'roles-index': 6,
    'user_goals-index': 7,
    'accounts-settings': 8,
  };
  return document.getElementById('scrollableMenu').getElementsByTagName('li')[buttons[page]].offsetLeft - 45;
}

document.addEventListener('DOMContentLoaded', () => {
  const branchSiteIndex = document.getElementById('branch_sites-index');

  if (branchSiteIndex) {
    const urlValues = new URL(window.location.href);
    const suggested = urlValues.searchParams.get('filter[name_cont]');
    const perPage = urlValues.searchParams.get('per_page');
    if (suggested) {
      document.getElementById('site-branches-search').value = suggested;
      document.getElementById('clean-site-branch-search').classList.remove('tw-hidden');
    }
    if (perPage) {
      const entry = document.getElementById(`branch_sites_entries-${perPage}`);
      window.selectChosenOption(entry);
    }
  }
});

function cleanSearchInput(id, clearElement, filter) {
  const search = document.getElementById(id);
  search.value = '';
  clearElement.previousElementSibling.classList.remove('search_input_filled');
  search.dispatchEvent(new Event('keyup', { bubbles: true }));
  clearElement.classList.add('tw-hidden');
  window.AddParamsToTailwindPagination({ [`filter[${filter}]`]: '' });
}
window.cleanSearchInput = cleanSearchInput;
document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('#branches-index, #account_subscriptions-index, #users-index, #account_subscriptions-invoices, #roles-index, #user_goals-index, #accounts-settings, #equipment_orders-index');
  if (body) {
    document.getElementById('scrollableMenu').scrollLeft = getMenuButtonPosition(body.id);
    scrollMenuVerification('scrollableMenu', 'menuGoForward', 'menuGoBack');
    document.getElementById('scrollableMenu').scrollLeft = getMenuButtonPosition(body.id);
  }
});

function scrollContainer(containerId, type) {
  const container = document.getElementById(containerId);
  if (type === 'forward') {
    container.scrollLeft += 80;
  } else {
    container.scrollLeft -= 80;
  }
}
window.scrollContainer = scrollContainer;
function scrollMenu(containerId, type) {
  scrollContainer(containerId, type);
  scrollMenuVerification(containerId, 'menuGoForward', 'menuGoBack');
}
window.scrollMenu = scrollMenu;
