function retrieveDataFromDropdown(classes, data, parentKey, childKey) {
  const options = Array.from(document.querySelectorAll(classes));
  const selectedOption = options.find((option) => option.classList[0] === 'li-check');
  const selectedOptionId = selectedOption.id.split('-')[2];
  if (selectedOptionId === 'no_change') {
    delete data[parentKey][childKey];
  } else {
    data[parentKey][childKey] = parseInt(selectedOptionId, 10);
  }
  return data;
}

function retrieveDataFromDays(classes, data, parentKey, childKey) {
  const days = Array.from(document.querySelectorAll(classes));
  const selectedDay = days.find((day) => day.classList.contains('day-clicked'));
  if (typeof (selectedDay) === 'undefined') {
    delete data[parentKey][childKey];
  } else {
    data[parentKey][childKey] = parseInt(selectedDay.id, 10);
  }
}

function retrievedBasicSettingsData() {
  const basicSettings = {};
  const systemSettings = window.retrieveSystemSettings();
  const wateringPrograms = window.retrieveWateringTimes();
  const seasonalPrograms = window.retrieveSeasonalAdjusts();
  const omitTimesAndDays = window.retrieveOmitTimesAndDays();

  if (!window.EmptyCheck(systemSettings)) {
    basicSettings.system_settings = systemSettings;
  }

  const watering = wateringPrograms.filter((element) => !window.EmptyCheck(element));

  if (!window.EmptyCheck(watering)) {
    basicSettings.watering_times_and_days = { programs: wateringPrograms };
  }

  if (watering.length === 0) {
    delete basicSettings.watering_times_and_days;
  }

  if (seasonalPrograms != null) {
    basicSettings.program_seasonal_adjustments = { programs: seasonalPrograms };
  }

  if (omitTimesAndDays != null) {
    basicSettings.omit_times_and_days = omitTimesAndDays;
  }

  if (window.EmptyCheck(basicSettings)) {
    return null;
  }
  return basicSettings;
}

function retrievedAdvancedSettingsData() {
  const advancedSettings = {
    weather_station: {},
    daylight_saving: {},
    winterized: {},
  };
  // Uncomment when Minimun Deficit is available again
  // retrieveDataFromDropdown(".ctn-advanced-settings .ctn-weather-station .dropdown-options li", advanced_settings, "weather_station", "min_deficit");

  const slwDelay = document.querySelector('.ctn-advanced-settings .ctn-weather-station .stepper').value;
  if (slwDelay !== '') {
    advancedSettings.weather_station.slw_delay = parseInt(slwDelay, 10);
  } else {
    delete advancedSettings.weather_station.slw_delay;
  }

  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-daylight-saving .ctn-daylight-saving-time-enabled li', advancedSettings, 'daylight_saving', 'dst_enabled');
  if (advancedSettings.daylight_saving.dst_enabled !== undefined) {
    switch (advancedSettings.daylight_saving.dst_enabled) {
      case 0:
        advancedSettings.daylight_saving.dst_enabled = false;
        break;
      case 1:
        advancedSettings.daylight_saving.dst_enabled = true;
        break;
      default:
        advancedSettings.daylight_saving.dst_enabled = false;
        break;
    }
  }

  retrieveDataFromDays('.ctn-advanced-settings .ctn-daylight-saving-start-day .day', advancedSettings, 'daylight_saving', 'dst_start_day');
  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-daylight-saving-start-week li', advancedSettings, 'daylight_saving', 'dst_start_week');
  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-daylight-saving-start-month li', advancedSettings, 'daylight_saving', 'dst_start_month');
  retrieveDataFromDays('.ctn-advanced-settings .ctn-daylight-saving-end-day .day', advancedSettings, 'daylight_saving', 'dst_stop_day');
  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-daylight-saving-end-week li', advancedSettings, 'daylight_saving', 'dst_stop_week');
  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-daylight-saving-end-month li', advancedSettings, 'daylight_saving', 'dst_stop_month');

  retrieveDataFromDropdown('.ctn-advanced-settings .ctn-winterized .ctn-winterized-dropdown li', advancedSettings, 'winterized', 'option');
  if (advancedSettings.winterized.option !== undefined) {
    switch (advancedSettings.winterized.option) {
      case 0:
        advancedSettings.winterized = false;
        break;
      case 1:
        advancedSettings.winterized = true;
        break;
      default:
        advancedSettings.winterized = false;
        break;
    }
  }

  Object.keys(advancedSettings).forEach((key) => {
    if (Object.keys(advancedSettings[key]).length === 0 && typeof (advancedSettings[key]) !== 'boolean') {
      delete advancedSettings[key];
    }
  });

  if (Object.keys(advancedSettings).length === 0) {
    return null;
  }
  return advancedSettings;
}

function retrievedSmartSettingsData() {
  const smartSettings = {
    zones_in_programs: {
      programs: [],
    },
  };

  document
    .querySelectorAll('.ctn-zones-in-program-percentage .ctn-input input')
    .forEach((input) => {
      const id = input
        .getAttribute('name')
        .replace('zone_in_program_', '')
        .toUpperCase();
      if (input.value !== '') {
        smartSettings.zones_in_programs.programs.push(
          {
            identifier: id,
            moreless: input.value,
          },
        );
      }
    });

  if (smartSettings.zones_in_programs.programs.length === 0) {
    return null;
  }
  return smartSettings;
}

function retrievedUserData() {
  const data = {
    basic_settings: null,
    advanced_settings: null,
    smart_settings: null,
  };
  data.basic_settings = retrievedBasicSettingsData();
  data.advanced_settings = retrievedAdvancedSettingsData();
  data.smart_settings = retrievedSmartSettingsData();

  Object.keys(data).forEach((key) => { if (data[key] === null) delete data[key]; });

  if (Object.keys(data).length === 0) {
    return null;
  }
  return data;
}

function searchKeyOnObject(obj, searchKey, results = []) {
  const r = results;
  Object.keys(obj).forEach((key) => {
    if (key === searchKey && obj[key] !== null) {
      r.push(key);
    } else if (typeof obj[key] === 'object' && key !== searchKey) {
      searchKeyOnObject(obj[key], searchKey, r);
    }
  });
  return r;
}

function searchGlobalCommandKey(object, keysToSearch, keyTitles) {
  let keys = [];
  keysToSearch.forEach((key) => { keys = keys.concat(searchKeyOnObject(object, key)); });
  if (keys.length === 0) { return keys; }
  keys = Array.from(new Set(keys));
  keys = keys.map((key) => keyTitles[key]);
  return keys.filter((n) => n);
}

function globalCommandSeasonalAdjustGa4(basicSettings) {
  const months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let monthsUsed = [];
  const programs = basicSettings?.program_seasonal_adjustments?.programs;
  if (programs) {
    programs.forEach((program) => {
      program.adjustments.forEach((adjustment) => {
        if (adjustment.percentage !== '') {
          monthsUsed.push(adjustment.month);
        }
      });
    });
    monthsUsed = Array.from(new Set(monthsUsed));
    monthsUsed = monthsUsed.map((month) => months[month]);
  }
  return monthsUsed;
}

function globalCommandGa4Analytics(userInputData) {
  if (userInputData) {
    const basicKeys = {
      run_status: 'Run Status',
      sensor_mode: 'Rain/Freeze',
      mode: 'Watering Mode',
      description: 'Description',
      program_type: 'Program Type',
      start_time: 'Start Time',
      program_seasonal_adjustments: 'Seasonal Adjust',
      controller_omission_times: 'Omit Specific Times',
      controller_omission_days: 'Omit Days of Week',
      controller_omission_dates: 'Omit Event Dates',
    };

    const smartKeys = { zones_in_programs: 'More/Less' };

    const advancedKeys = {
      weather_station: 'SLW Delay (X hours)',
      dst_enabled: 'Daylight Saving Time',
      dst_start_day: 'Daylight Saving Day Start',
      dst_start_month: 'Daylight Saving Month Start',
      dst_start_week: 'Daylight Saving Week Start',
      dst_stop_day: 'Daylight Saving Day End',
      dst_stop_month: 'Daylight Saving Month End',
      dst_stop_week: 'Daylight Saving Week End',
      winterized: 'Winterized',
    };

    const data = window.googleTagProperties();
    delete data.accountPermissions;
    window.dataLayer.push({
      event: 'firstActionGlobalCommand',
      ...data,
      controllers: window.retrieveControllerIds(),
      basicSystemSettings: userInputData.basic_settings ? searchGlobalCommandKey(userInputData.basic_settings, ['run_status', 'sensor_mode', 'mode'], basicKeys) : [],
      basicWateringTimes: userInputData.basic_settings ? searchGlobalCommandKey(userInputData.basic_settings, ['description', 'program_type', 'start_time'], basicKeys) : [],
      basicSeasonalAdjust: globalCommandSeasonalAdjustGa4(userInputData?.basicSettings),
      basicOmitTimes: userInputData.basic_settings ? searchGlobalCommandKey(userInputData.basic_settings, ['controller_omission_times', 'controller_omission_days', 'controller_omission_dates'], basicKeys) : [],
      smartZonesInPrograms: userInputData.smart_settings ? searchGlobalCommandKey(userInputData.smart_settings, ['zones_in_programs'], smartKeys) : [],
      advancedWeatherStation: userInputData.advanced_settings ? searchGlobalCommandKey(userInputData.advanced_settings, ['weather_station'], advancedKeys) : [],
      advancedDaylightSaving: userInputData.advanced_settings ? searchGlobalCommandKey(userInputData.advanced_settings, ['dst_enabled', 'dst_start_day', 'dst_start_month', 'dst_start_week', 'dst_stop_month', 'dst_stop_day', 'dst_stop_week'], advancedKeys) : [],
      advancedWinterized: userInputData.advanced_settings ? searchGlobalCommandKey(userInputData.advanced_settings, ['winterized'], advancedKeys) : [],
    });
  }
}

function getSummary() {
  const globalCommandData = retrievedUserData();
  globalCommandGa4Analytics(globalCommandData);
  const userInputData = { data: globalCommandData, ids: window.retrieveControllerIds() };
  window.$.ajax({
    type: 'POST',
    url: '/global_commands/summary.js',
    dataType: 'script',
    data: userInputData,
    success() {
      window.changeView('.global_commands-summary');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      sessionStorage.setItem('globalCommand', JSON.stringify(userInputData));
      const button = document.querySelector('.ctn-footer-buttons #step-4.btn-next');
      if (!userInputData.data) {
        button.classList.add('disabled');
      } else {
        button.classList.remove('disabled');
      }
    },
  });
}
window.getSummary = getSummary;

const clearOnRefresh = () => {
  sessionStorage.removeItem('globalCommand');
  // This can also be used to prevent the refresh of the page
};

window.addEventListener('beforeunload', clearOnRefresh, { capture: true });
