let overviewAlertsOrder = null;

function getDetailAlertSort() {
  const sortColumn = document.querySelector('#detail_alert_age #content h3[class*="sorting_"]');
  if (!sortColumn) {
    return null;
  }

  const sortMapping = {
    site: 'sites.name',
    controller: 'controllers.name',
    age: 'events_count',
    urgency: 'urgency_level',
  };

  const columnId = sortColumn.id.split('-col')[0];
  const sortField = sortMapping[columnId] || columnId;
  const sortDirection = sortColumn.classList.contains('sorting_desc') ? 'DESC' : 'ASC';

  return `${sortField} ${sortDirection}`;
}

async function getDetailAlertAgeReports() {
  const nameSearch = document.getElementById('search-alert-events').value;
  const sortValue = getDetailAlertSort();
  const urgencyType = document.querySelector('#detail_alert_age #urgency_types .li-check').id.split('-')[1];
  const data = { filter: {} };
  if (nameSearch !== '') { data.filter.search = nameSearch; }
  if (sortValue) { data.sort = sortValue; }
  if (urgencyType !== '') { data.filter.urgency_level_eq = parseInt(urgencyType, 10); }
  data.branch_id = document.querySelector('#select_branch #branch_id')?.value;
  document.querySelector('#detail_alert_age #empty').classList.add('tw-hidden');

  await window.$.ajax({
    url: '/insights/connectivity_health_metrics/detail_alert_age',
    data,
    dataType: 'script',
    type: 'GET',
    success: () => {
      document.querySelector('#detail_alert_age #loading').classList.add('tw-hidden');
    },
  });
}

async function getOverviewAlertAgeReports() {
  await window.$.ajax({
    url: '/insights/connectivity_health_metrics/overview_alert_age',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
    dataType: 'script',
    type: 'GET',
    success: () => {
      document.querySelector('#overview_alert_age #loading').classList.add('tw-hidden');
      window.loadAnimationPending('#loading_overview_table #loading-table #loading-state');
    },
  });
}

async function handleOverviewAlertsOrder() {
  const table = document.getElementById('overview_table');
  const loading = document.getElementById('loading_overview_table');
  loading.classList.remove('tw-hidden');
  table.classList.add('tw-hidden');

  if (overviewAlertsOrder === null) {
    overviewAlertsOrder = 'DESC';
  } else {
    overviewAlertsOrder = overviewAlertsOrder === 'ASC' ? 'DESC' : 'ASC';
  }

  const data = {};
  data.branch_id = document.querySelector('#select_branch #branch_id')?.value;
  data.order_by_alerts = overviewAlertsOrder;

  await window.$.ajax({
    url: '/insights/connectivity_health_metrics/sites_with_highest_alerts',
    data,
    dataType: 'script',
    type: 'GET',
    success: () => {
      const sortingIcon = document.querySelector('#overview_alert_age .sorting');
      window.changeSort({ target: sortingIcon }, overviewAlertsOrder);
      loading.classList.add('tw-hidden');
      table.classList.remove('tw-hidden');
    },
  });
}
window.handleOverviewAlertsOrder = handleOverviewAlertsOrder;

function getFullAlertAgeReport() {
  getOverviewAlertAgeReports();
  getDetailAlertAgeReports();
}
window.getFullAlertAgeReport = getFullAlertAgeReport;

// Use the force in case of global filters like branch
function loadAlertReport(section, force = false) {
  const isEmpty = section === 'overview' ? document.querySelector(`#${section}_alert_age #content`).children.length === 0 : document.querySelector(`#${section}_alert_age #content .body_content_scroll`).children.length === 0 && document.querySelector(`#${section}_alert_age #empty`).classList.contains('tw-hidden');
  document.querySelector(`#${section}_alert_age`).classList.remove('tw-hidden');
  if (isEmpty || force) {
    document.querySelector(`#${section}_alert_age #content`).classList.add('tw-hidden');
    document.querySelector(`#${section}_alert_age #loading`).classList.remove('tw-hidden');
    if (section === 'overview') { getOverviewAlertAgeReports(); } else { getDetailAlertAgeReports(); }
  } else if (section === 'overview') {
    document.querySelector(`#${section}_alert_age #content`).classList.remove('tw-hidden');
  } else if (document.querySelector(`#${section}_alert_age #content tbody`).children.length !== 0) {
    document.querySelector(`#${section}_alert_age #content`).classList.remove('tw-hidden');
  } else {
    document.querySelector(`#${section}_alert_age #empty`).classList.remove('tw-hidden');
  }
}
window.loadAlertReport = loadAlertReport;

function toggleAlertAgeReport(section) {
  const overviewButton = document.getElementById('toggle_overview');
  const detailButton = document.getElementById('toggle_detail');
  if (section === 'overview') {
    overviewButton.classList.remove('tw-text-gray-700', 'hover:tw-text-blue-500');
    detailButton.classList.add('tw-text-gray-700', 'hover:tw-text-blue-500');
    overviewButton.classList.add('tw-bg-blue-100', 'tw-text-blue-500');
    detailButton.classList.remove('tw-bg-blue-100', 'tw-text-blue-500');
    document.querySelector('#detail_alert_age').classList.add('tw-hidden');
  } else {
    overviewButton.classList.add('tw-text-gray-700', 'hover:tw-text-blue-500');
    detailButton.classList.remove('tw-text-gray-700', 'hover:tw-text-blue-500');
    overviewButton.classList.remove('tw-bg-blue-100', 'tw-text-blue-500');
    detailButton.classList.add('tw-bg-blue-100', 'tw-text-blue-500');
    document.querySelector('#overview_alert_age').classList.add('tw-hidden');
  }
  loadAlertReport(section);
}
window.toggleAlertAgeReport = toggleAlertAgeReport;
