function initialRefresh() {
  if (sessionStorage.getItem('filter') !== window.location.search) {
    sessionStorage.setItem('filter', window.location.search);
  }

  if (sessionStorage.getItem('show_all?') === 'true') {
    const instructions = document.querySelectorAll('.ctn-controller-summary .ctn-controller-row');
    const showAllButon = document.querySelector('#confirmation-summary-controller-list .ctn-show-button button');
    instructions.forEach((instruction) => { instruction.classList.remove('tw-hidden'); });
    showAllButon.classList.add('tw-hidden');
  }
}

//* Load loader animation
function loadAnimation() {
  const element = document.getElementById('blue-loader');
  if (element) {
    element.innerHTML = '';
    window.bodymovin?.loadAnimation({
      container: element,
      path: '/loader.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'blue loader',
    });
  }
}

const summaryTable = document.getElementById(
  'confirmation-summary-controller-list',
);
const summaryDropdownBtn = document.getElementById(
  'controller-summary-dropdown-button',
);
const controllerSummaryDropdown = document.getElementById(
  'controller-summary-dropdown-content',
);
const controllerSummaryFilters = document.querySelectorAll(
  '#controller-summary-dropdown-content .filter',
);

const snackbarContainer = document.querySelector('#summary-snackbar-container');
const summaryParams = new URLSearchParams(window.location.search);

summaryDropdownBtn?.addEventListener('click', () => {
  controllerSummaryDropdown.classList.toggle('tw-hidden');
});

controllerSummaryDropdown?.addEventListener('mouseleave', () => {
  controllerSummaryDropdown.classList.add('tw-hidden');
});

const showSnackbar = () => {
  snackbarContainer?.classList.remove('tw-hidden');
  if (snackbarContainer) window.hideSnackbarAfterMount(snackbarContainer);
};

const hideSnackbar = () => {
  snackbarContainer?.classList.add('tw-hidden');
};
window.hideSnackbar = hideSnackbar;

const fetchControllerSummary = async (forceUpdate) => {
  const { total: prevTotal, done: prevDone, status: prevStatus } = JSON.parse(
    window.$('#confirmation-summary-instruction-info').val(),
  );
  if (prevTotal !== prevDone || prevTotal === 0 || forceUpdate === true) {
    window.$.ajax({
      url: '/global_commands/list.js',
      dataType: 'script',
      data: {
        id: window.$('#global-command-id').val(),
        filter: summaryParams.get('filter'),
      },
    }).done(() => {
      const { total, done, status } = JSON.parse(
        window.$('#confirmation-summary-instruction-info').val(),
      );
      if (prevStatus === 'pending' || status === 'pending') hideSnackbar();
      loadAnimation();
      // ! This runs when all controllers are successful, and shows the snackbar
      if (total === done && total !== 0) {
        window.$('#summary-pending .pending-count-info').remove();
        showSnackbar();
        // ! Shows snackbar only if the status changed to error
      } else if (status === 'error' && prevStatus !== 'error') {
        showSnackbar();
      }
      initialRefresh();
    });
  }
};

// * Filter buttons event listeners
controllerSummaryFilters?.forEach((filter) => {
  filter.addEventListener('click', async () => {
    const filterValue = filter.getAttribute('data-filter');
    const currentFilter = summaryParams.get('filter');
    if (currentFilter !== filterValue) {
      if (filterValue === 'all') {
        summaryParams.delete('filter');
      } else {
        summaryParams.set('filter', filterValue);
      }
      window.history.replaceState('', '', `?${summaryParams}`);
    }
    controllerSummaryFilters.forEach((f) => f.classList.remove('selected'));
    filter.classList.add('selected');
    controllerSummaryDropdown.classList.add('tw-hidden');
    sessionStorage.setItem('show_all?', false);
    await fetchControllerSummary(true);
  });
});

function checkSelectedFilter() {
  const filterOptions = document.querySelectorAll(
    '.ctn-controller-summary .ctn-title-filter .ctn-filter ul li',
  );
  const selectedFilter = window.location.search.split('=')[1] || 'all';
  filterOptions.forEach((option) => {
    option.classList.remove('selected');
    if (option.id === selectedFilter) option.classList.add('selected');
  });
}

document.addEventListener('DOMContentLoaded', () => {
  sessionStorage.setItem('filter', window.location.search);
  sessionStorage.setItem('show_all?', false);
  checkSelectedFilter();
  loadAnimation();
});

const disableResetButton = (button) => {
  button.attr('disabled', true);
  const text = button.find('.ctn-reset-text');
  text.removeClass('tw-text-blue-500');
  text.addClass('tw-text-gray-500');
};

const summaryRetryGlobalCommandItem = async (globalCommandItemId) => {
  window.$.ajax({
    url: `/global_command_items/${globalCommandItemId}/retry`,
    dataType: 'script',
    type: 'POST',
  }).done(() => {
    disableResetButton(
      window.$(`#summary-controller-item-${globalCommandItemId} .ctn-reset`),
    );
    fetchControllerSummary(true);
  });
};
window.summaryRetryGlobalCommandItem = summaryRetryGlobalCommandItem;

const summaryRetryGlobalCommand = async (globalCommandId) => {
  window.$.ajax({
    url: `/global_commands/${globalCommandId}/retry`,
    dataType: 'script',
    type: 'POST',
  }).done(() => {
    // * Disable the retry all button
    disableResetButton(window.$('#controller-summary-retry-all-button .ctn-reset'));
    // * Select all retry buttons
    window.$('.ctn-controller-summary [data-role=error]')?.each((index, item) => {
      const button = window.$(item).find('.ctn-reset');
      disableResetButton(button);
    });
    fetchControllerSummary(true);
  });
};
window.summaryRetryGlobalCommand = summaryRetryGlobalCommand;

if (summaryTable) {
  setInterval(async () => {
    await fetchControllerSummary(false);
  }, 3000);
}
// * Snackbar starts hidden then it shows
if (summaryParams.has('filter')) hideSnackbar();
else showSnackbar();

// Show More Controllers - Confirmation Summary
function showMoreControllersSummary(element) {
  const instructions = document.querySelectorAll('.ctn-controller-summary .ctn-controller-row');
  instructions.forEach((instruction) => { instruction.classList.remove('tw-hidden'); });
  sessionStorage.setItem('show_all?', true);
  element.classList.add('tw-hidden');
}
window.showMoreControllersSummary = showMoreControllersSummary;
