import $ from 'jquery';
import HmacSHA256 from 'crypto-js/hmac-sha256.js';
import Base64 from 'crypto-js/enc-base64.js';

$.ajaxSetup({
  data: {
    timestamp: +new Date(),
  },
  statusCode: {
    401: () => {
      alert('ERROR #839.0: Access Denied');
    },
    500: () => {
      $('#500-warning-snackbar').remove();
      $('#warning-snackbar').addClass('tw-hidden');
      $("<div class=\"tw-mx-auto tw-mt-4 tw-py-4 tw-flex tw-justify-between tw-items-center tw-rounded-md tw-bg-red-100 tw-text-red-500 tw-border-red-500 tw-w-[90%] lg:tw-w-[1280px] tw-px-4 sm:tw-px-6.5 tw-shadow-[0px_0px_8px_rgba(0,0,0,0.16)] tw-left-1/2 -tw-translate-x-1/2 tw-fixed tw-z-[1001] sm:tw-flex-row tw-flex-col\" id=\"500-warning-snackbar\"><div class=\"tw-flex tw-items-start tw-justify-between tw-gap-2 tw-mr-auto\"><div><svg class=\"tw-w-4 tw-h-4 tw-mt-1\" fill=\"none\" viewBox=\"0 0 15 14\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.16667 8.33333L7.5 7M7.5 7L8.83333 5.66667M7.5 7L6.16667 5.66667M7.5 7L8.83333 8.33333M13.5 7C13.5 10.3137 10.8137 13 7.5 13C4.18629 13 1.5 10.3137 1.5 7C1.5 3.68629 4.18629 1 7.5 1C10.8137 1 13.5 3.68629 13.5 7Z\" stroke=\"#891B21\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"></path></svg></div><div class=\"tw-flex tw-flex-col\"><div class=\"tw-font-bold tw-text-base tw-text-left\">Error</div><div class=\"description tw-text-base\">Something went wrong</div></div></div><div class=\"tw-flex tw-ml-auto\"><button class=\"tw-button tw-bg-transparent\" onclick=\"const snackbar = this.parentElement.parentElement; snackbar.classList.add('tw-hidden')\">Dismiss</button></div></div>").insertAfter('#js_errors');
      window.hideSnackbarAfterMount($('#500-warning-snackbar')[0]);
    },
  },
  beforeSend: (xhr, settings) => {
    xhr.setRequestHeader('x-api-key', window.SLNAK);
    const locale = (new URLSearchParams(window.location.search)).get('locale');

    if (locale) {
      const newUrl = /[?&][^=#]+=[^&#]+/.test(settings.url) ? `${settings.url}&` : `${settings.url}?`;
      settings.url = `${newUrl}locale=${locale}`;
    }

    if (settings.type === 'GET') {
      const match = settings.url.match(/\?(.*)/);
      if (match) {
        const hmac = HmacSHA256(match[1], window.SLNAS).toString(Base64);
        xhr.setRequestHeader('x-api-hmac', hmac);
      } else {
        alert('ERROR #839.1: Encryption was not able to calculate.');
      }
    } else {
      if (typeof settings.data === 'string') {
        try {
          let json = $.parseJSON(settings.data);
          json = Object.assign(json, { timestamp: +new Date() });
          settings.data = JSON.stringify(json);
        } catch (error) {
          // console.error(error);
        }
      } else if (typeof settings.data === 'object') {
        if (settings.type === 'DELETE') {
          settings.data = $.param(settings.data);
        } else if (settings.data && window.FormData === undefined) {
          settings.data = JSON.stringify(settings.data);
        }
      }

      if (settings.data && settings.data.length > 0) {
        const hmac = HmacSHA256(settings.data, window.SLNAS).toString(Base64);
        xhr.setRequestHeader('x-api-hmac', hmac);
      } else {
        console.log('ERROR #839.2: Encryption was not able to calculate.');
      }
    }
  },
});
