function showCreateRole(accountId) {
  window.$.ajax({
    url: `/accounts/${accountId}/roles/new`,
    type: 'GET',
    dataType: 'script',
  });
}
window.showCreateRole = showCreateRole;
function showEditRole(accountId, roleId) {
  window.$.ajax({
    url: `/accounts/${accountId}/roles/${roleId}/edit`,
    type: 'GET',
    dataType: 'script',
  });
}
window.showEditRole = showEditRole;
function exitRoleManage() {
  const exitModal = document.querySelector('.exit-manage_role #warning-modal');
  const modal = document.getElementById('role-modal');
  exitModal.classList.add('tw-hidden');
  modal.classList.remove('tw-hidden');
  modal.innerHTML = '';
}
function showExitModal(newRecord) {
  const exitModal = document.querySelector('.exit-manage_role > div');
  const modal = document.getElementById('role-modal');
  const description = newRecord ? window.I18n.accounts.roles.modals.cancel_create.description : window.I18n.accounts.roles.modals.cancel_manage.description;
  const title = newRecord ? window.I18n.accounts.roles.modals.cancel_create.title : window.I18n.accounts.roles.modals.cancel_manage.title;
  exitModal.querySelector('.description').textContent = description;
  exitModal.querySelector('.title').textContent = title;
  exitModal.querySelector('.tw-btn-action-red').onclick = () => exitRoleManage();
  exitModal.classList.remove('tw-hidden');
  modal.classList.add('tw-hidden');
}
window.showExitModal = showExitModal;
function isEmpty(string) {
  return !string.trim().length;
}
window.isEmpty = isEmpty;
const exitWarningModal = document.querySelector('.exit-manage_role > div');
exitWarningModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('tw-btn-cancel')) {
    exitWarningModal.classList.add('tw-hidden');
    const modal = document.getElementById('role-modal');
    modal.classList.remove('tw-hidden');
  }
});

function removeRole(accountId, roleId) {
  window.$.ajax({
    url: `/accounts/${accountId}/roles/${roleId}`,
    type: 'DELETE',
    dataType: 'script',
  });
}
function showRemoveRoleModal(accountId, roleName, userCount, roleId) {
  const modal = userCount > 0 ? document.querySelector('.remove-role_modal > #confirmation-modal') : document.querySelector('.remove-role_modal > #warning-modal');
  let description = window.I18n.accounts.roles.modals.delete_role.description_no_user.replace('%{role_name}', roleName);
  if (userCount > 0) {
    description = window.I18n.accounts.roles.modals.delete_role.description_with_user.replace('%{role_name}', roleName);
    if (userCount === 1) {
      description = description.replace('%{users_amount}', `1 ${window.I18n.accounts.roles.modals.delete_role.users_amount.one}`).replace('%{users_description}', window.I18n.accounts.roles.modals.delete_role.users_description.one);
    } else {
      description = description.replace('%{users_amount}', `${userCount} ${window.I18n.accounts.roles.modals.delete_role.users_amount.other}`).replace('%{users_description}', window.I18n.accounts.roles.modals.delete_role.users_description.other);
    }
  }
  const title = window.I18n.accounts.roles.modals.delete_role.title.replace('%{role_name}', roleName);
  modal.querySelector('.description').innerHTML = description;
  modal.querySelector('.title').textContent = title;
  if (userCount === 0) {
    modal.querySelector('.tw-btn-action-red').onclick = () => removeRole(accountId, roleId);
  }
  modal.classList.remove('tw-hidden');
}
window.showRemoveRoleModal = showRemoveRoleModal;

const removeRoleModal = document.querySelector('.remove-role_modal > #confirmation-modal');
removeRoleModal?.addEventListener('click', (event) => {
  if (event.target === this || event.target.classList.contains('tw-btn-action-blue')) removeRoleModal.classList.add('tw-hidden');
});
function selectAllRoles() {
  /* eslint-disable-next-line no-restricted-globals */
  if (confirm('Would you like to give ALL permissions?')) {
    const checkboxs = document.querySelectorAll('.form-checkbox');
    checkboxs.forEach((checkbox) => {
      checkbox.checked = true;
    });
  }
  const allCheckbox = document.getElementById('select-all-roles-checkbox');
  allCheckbox.checked = false;
}
window.selectAllRoles = selectAllRoles;
function validateForm() {
  const button = document.getElementById('commit');
  const name = document.querySelector('.name-input').value;
  if (isEmpty(name)) {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-button-primary');
  }
}
window.validateForm = validateForm;
