function toggleAddSitesButton() {
  const checked = document.querySelectorAll('#branches-add-sites [id*="select-site"][type="checkbox"]:checked').length;
  const button = document.getElementById('add-sites-button');
  if (checked > 0) {
    button.classList.add('tw-button-primary');
    button.classList.remove('tw-btn-disabled');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
  }
}
window.toggleAddSitesButton = toggleAddSitesButton;
function getSiteIdsToAddToBranch() {
  const checkedInputs = document.querySelectorAll('table input[id*="select-site"]:checked');
  const ids = [];
  checkedInputs.forEach((input) => ids.push(input.id.split('-')[2]));
  return ids;
}

function addSitesToBranch(accountId, branchId, newBranch) {
  window.$.ajax({
    url: `/accounts/${accountId}/branches/${branchId}/branch_sites`,
    dataType: 'script',
    data: { site_ids: getSiteIdsToAddToBranch(), new_branch: newBranch },
    type: 'POST',
  });
}
window.addSitesToBranch = addSitesToBranch;
function addSitesSetParamValue(param, id) {
  const optionSelected = (new URLSearchParams(window.location.search)).get(param);
  if (optionSelected) {
    const dropdown = document.getElementById(`add_sites-${id}-${optionSelected}`);
    window.selectChosenOption(dropdown);
  }
}

function disableAddSitesGeneralCheckbox() {
  const checkbox = document.getElementById('select-all-sites');
  checkbox.checked = false;
}
window.disableAddSitesGeneralCheckbox = disableAddSitesGeneralCheckbox;
function getBranchSitesData(searchValue, page, perPage, branch) {
  const dataset = document.getElementById('branches-add-sites').dataset;
  window.$.ajax({
    url: `/accounts/${dataset.accountId}/branches/${dataset.branchId}/branch_sites/sites`,
    data:
    {
      filter: { search: searchValue },
      page,
      per_page: perPage,
      branch,
    },
    dataType: 'script',
    type: 'GET',
    success: () => toggleAddSitesButton(),
  });
}
window.getBranchSitesData = getBranchSitesData;
function addSitesSetSearchInputListenerAndValue() {
  const addSitesSearchInputParam = (new URLSearchParams(window.location.search)).get('filter[search]');
  const addSitesSearchInput = document.getElementById('search-add_sites');
  addSitesSearchInput.value = addSitesSearchInputParam;

  addSitesSearchInput?.addEventListener('keyup', window.debounce(() => {
    const entrySelected = document.querySelector('#add_sites-entries .li-check').id.split('-')[2];
    const branchSelected = document.querySelector('#add_sites-branch .li-check').id.split('-')[2];
    window.AddParamsToTailwindPagination({ 'filter[search]': addSitesSearchInput.value });
    getBranchSitesData(addSitesSearchInput.value, 1, entrySelected, branchSelected);
    disableAddSitesGeneralCheckbox();
  }, 500));
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('branch_sites-sites');
  if (body) {
    addSitesSetParamValue('per_page', 'entries');
    addSitesSetParamValue('branch', 'branch');
    addSitesSetSearchInputListenerAndValue();
  }
});

function updateOptionsForAddSitesTable() {
  const entries = document.querySelector('#add_sites-entries .li-check').id.split('-')[2];
  const branch = document.querySelector('#add_sites-branch .li-check').id.split('-')[2];
  const searchValue = document.getElementById('search-add_sites').value;
  getBranchSitesData(searchValue, 1, entries, branch);
}
window.updateOptionsForAddSitesTable = updateOptionsForAddSitesTable;

function updateUrlForAddSitesTable(id) {
  const optionSelected = document.querySelector(`#add_sites-${id} .li-check`).id.split('-')[2];
  const data = id === 'entries' ? { per_page: optionSelected, page: 1 } : { branch: optionSelected, page: 1 };
  const pathUrl = window.changeDataFromUrl(window.location, data);
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateUrlForAddSitesTable = updateUrlForAddSitesTable;
