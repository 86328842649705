import Backbone from 'backbone';
import $ from 'jquery';
import 'dropzone';

const ZonePhotosView = Backbone.View.extend({
  el: '#zone_photos-index',
  events: { 'click .show-zone-pic': 'viewPhoto' },
  initialize() {
    $('.zone_upload_form').dropzone({
      paramName: 'photo',
      acceptedFiles: 'image/jpeg, image/png, image/gif',
      maxFiles: 1,
      success(file) {
        alert('Successfully uploaded zone photo.');
        this.removeFile(file);
        window.location.reload();
      },
      error() {
        alert('Sorry the was a problem.  Please try again.  Allowed file types are .jpeg and .png');
      },
    });
  },
  viewPhoto(e) {
    e.preventDefault();
    const t = $(e.target);
    const url = t.data('url');
    const zoneDescription = t.data('zone-description');

    $('.modal-header').html(`<h3>${zoneDescription.replace(/</g, '&lt;').replace(/>/g, '&gt;')}</h3>`); // html safe'em
    $('.modal-zone-photo-body').html(`<img src="${url}" />`);
    $('#original').attr({ href: url, target: 'blank' });
    $('.zone-photo-modal').modal('show');
  },
});
export default ZonePhotosView;
