function selectAllColumns(selectorAll, selectorCheckbox) {
  const selectAllCheck = document.getElementById(selectorAll);
  [...document.querySelectorAll(selectorCheckbox)].forEach((element) => {
    element.dataset.dirty = element.checked !== selectAllCheck.checked && element.dataset.dirty !== 'true';
    element.checked = selectAllCheck.checked;
  });
  selectAllCheck.classList.remove('tw-sl-checkbox-partial');
}
window.selectAllColumns = selectAllColumns;

function cancelSelection(selectorAll, selectorCheckbox) {
  [...document.querySelectorAll(selectorCheckbox)].forEach((element) => {
    if (element.dataset.dirty === 'true') {
      element.checked = !element.checked;
      element.dataset.dirty = 'false';
    }
  });
  window.verifySelectAllStatus(selectorAll, selectorCheckbox);
  document.getElementById('tailwind-columns').classList.add('tw-hidden');
}
window.cancelSelection = cancelSelection;

function updateColumnDirty(checkbox) {
  checkbox.dataset.dirty = checkbox.dataset.dirty === 'true' ? 'false' : 'true';
}
window.updateColumnDirty = updateColumnDirty;

function showSelectColumnsModal() {
  document.getElementById('tailwind-columns').classList.remove('tw-hidden');
}
window.showSelectColumnsModal = showSelectColumnsModal;

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('tailwind-columns')) {
    window.verifySelectAllStatus('selectAllColumns', '[id*="col-select"]:not([disabled])');
  }
});

function setAllNoDirty() {
  const columnModal = document.getElementById('tailwind-columns');
  [...columnModal.querySelectorAll('[type="checkbox"][data-dirty="true"]')].forEach((checkbox) => checkbox.dataset.dirty = 'false');
}
window.setAllNoDirty = setAllNoDirty;
