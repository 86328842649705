function removeDisconnectAircardModal() {
  const modal = document.getElementById('disconnect-aircard-modal');
  modal.classList.add('tw-hidden');
}
window.removeDisconnectAircardModal = removeDisconnectAircardModal;

function enableDisconnectButton() {
  const button = document.querySelector('#disconnect-aircard-modal .btn-action');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-btn-action-red');
}
window.enableDisconnectButton = enableDisconnectButton;

function disconnectAircard(controllerId) {
  const disconnectionReason = document.querySelector('#disconnect_aircard_reason .li-check').id.split('-')[1];
  window.$.ajax({
    url: `/controls/${controllerId}/disconnect_aircard`,
    data: { reason: disconnectionReason },
    dataType: 'script',
    type: 'PUT',
  });
}

function showDisconnectAircardModal(controllerId, withRemainingPlanTime, remainingPlanTime, subscriptionPlan) {
  const modal = document.getElementById('disconnect-aircard-modal');
  const button = modal.querySelector('.btn-action');
  if (withRemainingPlanTime === 'true') {
    modal.querySelector('.description').textContent = window.I18n.controls.edit.disconnect_aircard_modal.with_remaining_plan.description.replace('%{remaining_plan_time}', `${remainingPlanTime} ${parseInt(remainingPlanTime, 10) > 1 ? window.I18n.days.toLowerCase() : window.I18n.datetime.prompts.day.toLowerCase()}`).replace('%{subscription_plan}', subscriptionPlan);
  } else {
    modal.querySelector('.description').textContent = window.I18n.controls.edit.disconnect_aircard_modal.without_remaining_plan.description;
  }
  window.resetDropdown('#disconnect_aircard_reason', window.I18n.controls.edit.disconnect_aircard_modal.why_disconnect_it.placeholder);
  button.classList.add('tw-btn-disabled');
  button.classList.remove('tw-btn-action-red');
  button.onclick = () => disconnectAircard(controllerId);
  modal.classList.remove('tw-hidden');
}
window.showDisconnectAircardModal = showDisconnectAircardModal;
