const accountBillingUsersSearch = document.getElementById('billing-user-search');

function getAccountBillingUsers() {
  const accountId = document.getElementById('account_information').dataset.accountId;

  window.$.ajax({
    url: `/dashboard/accounts/${accountId}/account_users/billing_users`,
    data: { filter: { full_name_or_email_cont: accountBillingUsersSearch.value } },
    dataType: 'script',
    type: 'GET',
  });
}
window.getAccountBillingUsers = getAccountBillingUsers;

function fileChanged(input) {
  input.addEventListener('change', () => {
    const fileInfo = input.nextElementSibling;
    const fileSize = window.bytesToSize(input.files[0].size);
    const fileName = input.files[0].name;

    input.previousElementSibling.classList.add('tw-hidden');
    fileInfo.querySelector('.file-name').textContent = fileName;
    fileInfo.querySelector('.file-size').textContent = `(${fileSize})`;
    fileInfo.classList.remove('tw-hidden');
  });
}
window.fileChanged = fileChanged;

function returnToModalChooseAFile(sectionId) {
  const input = document.querySelector(`#${sectionId} form input[type=file]`);
  input.nextElementSibling.classList.add('tw-hidden');
  input.previousElementSibling.classList.remove('tw-hidden');
  input.value = '';
  document.querySelector(`#${sectionId} form`).dispatchEvent(new Event('change', { bubbles: true }));
}
window.returnToModalChooseAFile = returnToModalChooseAFile;

function openImportSiteModal() {
  accountBillingUsersSearch.value = '';
  getAccountBillingUsers();
  returnToModalChooseAFile('import_sites_modal');
  document.getElementById('import_sites_modal').classList.remove('tw-hidden');
}
window.openImportSiteModal = openImportSiteModal;

function uploadImportSites() {
  document.getElementById('info-snackbar').classList.remove('tw-hidden');
  window.loadAnimationPending('#info-snackbar #loading-state');
  document.querySelector('#import_sites_modal form').submit();
  document.querySelector('#import_sites_modal').classList.add('tw-hidden');
  document.querySelector('#import_sites_modal form').reset();
}
window.uploadImportSites = uploadImportSites;

accountBillingUsersSearch?.addEventListener('keyup', window.debounce(() => {
  getAccountBillingUsers();
}, 500));

const importSitesForm = document.querySelector('#import_sites_modal form');
importSitesForm?.addEventListener('change', () => {
  const inputFile = document.querySelector('#import_sites_modal form input[type=file]');
  const submitButton = document.querySelector('#import_sites_modal #submit');
  if (inputFile.files.length === 1 && document.querySelector('input[name="billing_user"]:checked')?.checked) {
    submitButton.classList.remove('tw-btn-disabled');
    submitButton.classList.add('tw-button-primary');
  } else {
    submitButton.classList.add('tw-btn-disabled');
    submitButton.classList.remove('tw-button-primary');
  }
});
