const addSiteUserModal = document.getElementById('add-site-user-modal');
addSiteUserModal?.addEventListener('click', (event) => {
  if (event.target.classList.contains('tw-btn-cancel')) addSiteUserModal.classList.add('tw-hidden');
});

function addUsersToSite() {
  const emails = addSiteUserModal.querySelector('textarea').value;
  let arrayOfEmails = emails.split(',');
  arrayOfEmails = arrayOfEmails.map((email) => email.trim());
  const siteId = document.getElementById('site-users').dataset.siteId;
  const permissionInputs = document.querySelectorAll('#add-site-user-modal input[type="checkbox"]:not([value=""]):checked');
  const permissions = [...permissionInputs].map((permission) => permission.value);
  window.$.ajax({
    url: `/sites/${siteId}/users`,
    data: { emails: arrayOfEmails, permissions },
    dataType: 'script',
    type: 'POST',
  });
}

if (addSiteUserModal) {
  addSiteUserModal.querySelector('textarea').rows = window.innerWidth < 960 ? 2 : 1;
  const commaSeparatedEmailsInput = addSiteUserModal.querySelector('textarea');
  commaSeparatedEmailsInput?.addEventListener('input', (event) => { window.emailValidation(event, addSiteUserModal); });
  addSiteUserModal.querySelector('.btn-action').addEventListener('click', addUsersToSite);
}

function enableSiteAddUser() {
  const button = addSiteUserModal.querySelector('.btn-action');
  const validLabel = addSiteUserModal.querySelector('.valid');
  if (validLabel.classList.contains('tw-hidden')) {
    button.classList.remove('tw-btn-action-blue');
    button.classList.add('tw-btn-disabled');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  }
}
window.enableSiteAddUser = enableSiteAddUser;
