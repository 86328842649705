function getInspectionsWithoutProposals() {
  window.$.ajax({
    url: 'home/inspections',
    type: 'GET',
    dataType: 'script',
    data: { filter: { proposal_id_not_null: 0, inspection_status_eq: 2 } },

  });
}
window.getInspectionsWithoutProposals = getInspectionsWithoutProposals;
