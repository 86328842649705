function toggleState(event) {
  event.target.classList.toggle('day-clicked');
}

function oneDaySelected(event) {
  const allContainerDays = event.target.parentElement.querySelectorAll('.starting-days');
  let counter = 0;
  allContainerDays.forEach((day) => {
    if (day !== event.target || day.classList.contains('day-clicked')) {
      day.classList.remove('day-clicked');
    } else {
      day.classList.add('day-clicked');
      counter = 1;
    }
  });

  const label = event.target.parentElement.previousElementSibling;
  if (counter === 1) {
    label.classList.add('selected-label');
  } else {
    label.classList.remove('selected-label');
  }
}

// Interval program type of Watering Times and Days section was removed
// const sundayDays = document.querySelectorAll(".ctn-watering-times .ctn-interval .day-0");
// sundayDays.forEach((sunday) => sunday.classList.add("day-clicked"));

const days = document.querySelectorAll('.days-of-week');
days.forEach((day) => day.addEventListener('click', toggleState));

const startingDays = document.querySelectorAll('.starting-days');
startingDays.forEach((day) => day.addEventListener('click', oneDaySelected));
