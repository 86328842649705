import Backbone from 'backbone';

const ZoneModel = Backbone.Model.extend({
  idAttribute: 'id',
  initialize(controllerId, zoneId) {
    this.id = zoneId;
    this.urlRoot = `/api/v2/controllers/${controllerId}/zones/`;
  },
});
export default ZoneModel;
