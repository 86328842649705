import Backbone from 'backbone';
import $ from 'jquery';
import ExistingRepairItemsTemplate from '@assets/javascripts/backbone/templates/inspections/existing_repair_items.ejs';
import AddRepairItemTemplate from '@assets/javascripts/backbone/templates/inspections/add_repair_item_row.ejs';
import SuggestedRepairItemTemplate from '@assets/javascripts/backbone/templates/inspections/suggested_repair_item_row.ejs';
import bootbox from 'bootbox';

const InspectionProposalsView = Backbone.View.extend({
  el: '#controller_inspections-edit',
  assignsPriority: !!$('#inspection').data('assignPriority'),
  inspectionId: $('#inspection').data('inspectionId'),
  accountId: $('#inspection').data('accountId'),
  createProposal: !!$('#inspection').data('proposal'),
  initialize() {
    this.addRepairItem = AddRepairItemTemplate;
    this.existingRepairItems = ExistingRepairItemsTemplate;
    this.suggestedItemRow = SuggestedRepairItemTemplate;

    // #########################
    //
    // valve status modal stuff
    //
    // #########################

    $('.title_valve_status.boolean').on('click', (e) => {
      if (this.createProposal) {
        // only fire modal when changing valve status to fail
        if ($(e.currentTarget).text().indexOf('Pass') !== -1) {
          const inspectionZoneId = $(e.currentTarget).closest('tr').attr('inspections_zone_id');
          const problemIssue = 'valve_status';
          const zoneNumber = $(e.currentTarget).closest('tr').find('.manual_run').text();

          $('.inspection-proposal-modal').modal({ backdrop: 'static', keyboard: false });
          $('#launcher').addClass('tw-hidden md:tw-block');
          $('.modal-proposal-body').attr('data-inspection-zone-id', inspectionZoneId);
          $('.repair-item-title').text(`Add repair items for zone #${zoneNumber} ${problemIssue.split('_').join(' ')}`);

          this.addExistingItemsToTable(this.inspectionId, inspectionZoneId, problemIssue);
          this.displaySuggestedItems(problemIssue);

          $('.modal-proposal-body').on('keyup', 'input', () => this.typeAheadSearch(this.accountId, this.inspectionId, inspectionZoneId, problemIssue));
        }
      }
    });

    // #########################
    //
    // interger selection modal stuff
    //
    // #########################
    // all this is not very dry and similar to above on click, but a few attrs changed and my jsfoo is weak...sorry team

    $('.proposable').on('click', '.dropdown-submit', (e) => {
      const domDataStore = $(e.currentTarget).closest('.dropdown-form').find('.dropdown-trigger');
      let inspectionZoneId = domDataStore.data('id');
      let problemIssue = domDataStore.data('value');
      const issueCount = $(e.currentTarget).data('value');

      if (this.createProposal) {
        let zoneNumber;
        $('select.tag-selector').val('');

        // for valve status
        if ($(e.currentTarget).attr('data-value') === 'false') {
          inspectionZoneId = $(e.currentTarget).closest('tr').attr('inspections_zone_id');
          problemIssue = 'valve_status';
          zoneNumber = $(e.currentTarget).closest('tr').find('.manual_run').text();

          $('.inspection-proposal-modal').modal({ backdrop: 'static', keyboard: false });
          $('#launcher').addClass('tw-hidden md:tw-block');
          $('.modal-proposal-body').attr('data-inspection-zone-id', inspectionZoneId);
          $('.modal-proposal-body').attr('data-inspection-issue', problemIssue);
          $('.modal-proposal-body').attr('data-issue-count', 1);
          $('.repair-item-title').text(`Add repair items for zone #${zoneNumber} ${problemIssue.split('_').join(' ')}`);

          this.addExistingItemsToTable(this.inspectionId, inspectionZoneId, problemIssue);
          this.displaySuggestedItems(problemIssue);

          $('.modal-proposal-body').on('keyup', 'input', () => this.typeAheadSearch(this.accountId, this.inspectionId, inspectionZoneId, problemIssue));
        } else if ($(e.currentTarget).attr('data-value') === 'true') {
          // do nothing!, i.e. block the condition below

          // for non-valve-status
        } else if ($(e.currentTarget).attr('data-value') !== 0) {
          $('.inspection-proposal-modal').modal({ backdrop: 'static', keyboard: false });
          $('#launcher').addClass('tw-hidden md:tw-block');
          $('.modal-proposal-body').attr('data-inspection-zone-id', inspectionZoneId);
          $('.modal-proposal-body').attr('data-inspection-issue', problemIssue);
          $('.modal-proposal-body').attr('data-issue-count', issueCount);
          zoneNumber = $(e.currentTarget).closest('tr').find('.manual_run').text();
          $('.repair-item-title').text(`Add repair items for zone #${zoneNumber} ${problemIssue.split('_').join(' ')}`);

          this.addExistingItemsToTable(this.inspectionId, inspectionZoneId, problemIssue);
          this.displaySuggestedItems(problemIssue);
        }
      }

      $('.modal-proposal-body').on('keyup', 'input', () => this.typeAheadSearch(this.accountId, this.inspectionId, inspectionZoneId, problemIssue));
    });

    // #############  End of modal monstrosities

    $('.modal-proposal-body').on('click', '.delete-row', (e) => {
      const repairItemId = $(e.currentTarget).closest('tr').find('.repair-item-number').data('repair_item_id');
      $.ajax({
        url: `/api/v2/repair_items/${repairItemId}`,
        type: 'DELETE',
        dataType: 'json',
        success() {
          $(e.currentTarget).closest('tr').remove();
        },
        error(response) {
          alert(`Something went wrong: ${response}`);
        },
      });
    });

    // ##############
    $('.inspection-proposal-modal').on('click', '.close-modal', () => {
      const itemLength = $('.inspection-proposal-modal').find('.job-items-table tbody').children().length;
      let hasUnsavedQuantityChange = false;

      $('.submit-update-quantity').each((index, element) => {
        if ($(element).css('visibility') === 'visible') {
          hasUnsavedQuantityChange = true;
        }
      });

      if (hasUnsavedQuantityChange) {
        bootbox.confirm({
          message: "You have changed a quantity and have not saved it. You must hit the 'Update' button to save any quantity changes.",
          buttons: {
            confirm: {
              label: 'Back to update quantity',
              className: 'btn-success',
            },
            cancel: {
              label: 'Leave without saving',
              className: 'btn-danger',
            },
          },
          callback(result) {
            if (result === false) {
              $('#launcher').removeClass('tw-hidden md:tw-block');
              $('.inspection-proposal-modal').modal('hide');
            }
          },
        });
      } else {
        $('#launcher').removeClass('tw-hidden md:tw-block');
        $('.inspection-proposal-modal').modal('hide');
      }

      // we're trying to dynamically set/hide the green earmark if these are items present
      // because the DOM is differnet for system vs. zone issues, we do both... not ideal, but works

      // # for per-zone ones, flag the little green earmark
      const inspectionZoneId = $('.modal-proposal-body').attr('data-inspection-zone-id');
      const inspectionIssue = $('.modal-proposal-body').attr('data-inspection-issue');

      if (inspectionZoneId && inspectionIssue) {
        const inspectionElement = $(`.dropdown-trigger[data-id=${inspectionZoneId}][data-value=${inspectionIssue}]`);

        if (itemLength > 0) {
          inspectionElement.closest('.editable').find('.catalog_item_flag').removeClass('hidden');
        } else {
          inspectionElement.closest('.editable').find('.catalog_item_flag').addClass('hidden');
        }
      }

      // # for system issues, flag the little green earmark
      const issueId = $('.modal-proposal-body').attr('data-inspection-issue-id');
      if (issueId) {
        const actionsElement = $(`.update-issue[data-id=${issueId}]`);
        if (itemLength > 0) {
          actionsElement.closest('td').find('.catalog_item_flag').removeClass('hidden');
        } else {
          actionsElement.closest('td').find('.catalog_item_flag').addClass('hidden');
        }
      }

      // clear out data
      $('.modal-proposal-body').attr('data-inspection-issue', '');
      $('.modal-proposal-body').attr('data-inspection-zone-id', '');
      $('.modal-proposal-body').attr('data-inspection-issue-id', '');
      return $('.suggested-items-row').addClass('hidden');
    });

    // ##############
    $('.inspection-proposal-modal').on('change keypress', '.item-quantity', (e) => $(e.currentTarget).closest('tr').find('.submit-update-quantity').css('visibility', 'visible'));

    // ##############
    $('.inspection-proposal-modal').on('click', '.submit-update-quantity', (e) => {
      const $dis = $(e.currentTarget);
      const repairItemId = $dis.closest('tr').find('.repair-item-number').data('repair_item_id');
      const itemQuantity = $dis.closest('tr').find('.item-quantity').val();
      return $.ajax({
        url: `/api/v2/repair_items/${repairItemId}`,
        type: 'PUT',
        data: ({ quantity: itemQuantity || 1 }),
        dataType: 'json',
        success(response) {
          if (response.errors) {
            return alert(JSON.stringify(response.errors));
          }
          $dis.closest('tr').find('.submit-update-quantity').css('visibility', 'hidden');
          return $dis.closest('tr').find('.item-quantity').val(`${response.quantity}`);
        },
        error(response) {
          return alert(`Something went wrong: ${response}`);
        },
      });
    });

    // ##############
    $('.inspection-proposal-modal').on('click', '.increment-quantity', (event) => {
      const inputElement = event.target.previousElementSibling;
      const currentValue = inputElement.value;

      if (Number.isNaN(parseFloat(currentValue))) {
        inputElement.value = 1;
      } else {
        inputElement.value = parseFloat(currentValue) + 1;
      }
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
    });

    // ##############
    $('.inspection-proposal-modal').on('click', '.decrement-quantity', (event) => {
      const inputElement = event.target.nextElementSibling;
      const currentValue = inputElement.value;

      if (Number.isNaN(parseFloat(currentValue))) {
        inputElement.value = '';
      } else if ((parseFloat(currentValue) - 1) <= 0) {
        alert('Only postive values allowed');
      } else {
        inputElement.value = parseFloat(currentValue) - 1;
        inputElement.dispatchEvent(new Event('change', { bubbles: true }));
      }
    });

    // ##############
    $('.inspection-proposal-modal').on('change', '.priority-select', (e) => {
      const $dis = $(e.currentTarget);
      const repairItemId = $dis.closest('tr').find('.repair-item-number').data('repair_item_id');
      const priority = $dis.find(':selected').val();
      $.ajax({
        url: `/api/v2/repair_items/${repairItemId}`,
        type: 'PUT',
        data: ({ priority }),
        dataType: 'json',
        success() {
          console.log('Updated Priority');
        },
        error(response) {
          alert(`Something went wrong: ${response}`);
        },
      });
    });

    // ##############
    $('.inspection-proposal-modal').on('click', '.select-suggested', (e) => {
      const itemObject = {
        item: $(e.currentTarget).data('item'),
      };
      const inspectionZoneId = $('.modal-proposal-body').attr('data-inspection-zone-id');
      const quantity = $('.modal-proposal-body').attr('data-issue-count') || 1;
      const problemIssue = $('.modal-proposal-body').attr('data-inspection-issue');
      this.selectCatalogItem(itemObject, this.inspectionId, inspectionZoneId, problemIssue, quantity);
    });
  },
  addExistingItemsToTable(inspectionId, inspectionZoneId, problemIssue) {
    const context = this;
    // clear the search box
    $('#repair-item-search').val('');

    // clear added item table contents to start fresh
    document.querySelectorAll('.job-items-table tr').forEach((row) => {
      if (!row.querySelector('th')) {
        row.parentNode.removeChild(row);
      }
    });
    $.ajax({
      url: '/api/v2/repair_items',
      data: ({ inspection_id: inspectionId, inspection_zone_id: inspectionZoneId, inspection_issue: problemIssue }),
      type: 'GET',
      dataType: 'json',
      success(response) {
        let i = 0;
        while (i < response.length) {
          let selectedOption;
          if (response[i].priority === 2) {
            selectedOption = 'selected';
          } else {
            selectedOption = null;
          }

          $('.job-items-table tbody').append(context.existingRepairItems({
            response, i, selectedOption, assignsPriority: context.assignsPriority,
          }));
          i += 1;
        }
      },
      error(response) {
        alert(`Something went wrong:  ${response}`);
      },
    });
  },

  // ##############
  displaySuggestedItems(problemIssue) {
    const context = this;
    // clear the suggested items table
    document.querySelectorAll('.suggested-items-table tr').forEach((row) => {
      if (!row.querySelector('th')) {
        row.parentNode.removeChild(row);
      }
    });

    $.ajax({
      url: '/api/v2/job_catalog_items/common_by_issue',
      data: ({ inspection_issue: problemIssue }),
      type: 'GET',
      dataType: 'json',
      success(resp) {
        let i = 0;
        if (resp.length > 0) {
          $('.suggested-items-row').removeClass('hidden');
        }
        while (i < resp.length) {
          $('.suggested-items-table tr:last').after(context.suggestedItemRow({ response: resp, index: i }));
          $(`#${resp[i].id}`).data('item', resp[i]);
          i += 1;
        }
      },
      error(resp) {
        return alert(`We're sorry something went wrong: ${resp}`);
      },
    });
  },

  // ##############
  typeAheadSearch(accountId, inspectionId, inspectionZoneId, problemIssue) {
    const jobCataglogTagIds = $('.tag-selector').val();
    const context = this;
    const autocompleteOptions = {
      delay: 750,
      minLength: 2,
      source: `/api/v2/job_catalog_items?account_id=${accountId}&job_catalog_tag_ids=${jobCataglogTagIds}`,
      appendTo: '#search-group',
      messages: {
        noResults() {
          alert(`Sorry no results were found for ${$('#repair-item-search').val()}`);
        },
        results: () => { },
      },
      focus(event, ui) {
        $('#repair-item-search').val(ui.item.name);
        return false;
      },
      select(event, ui) {
        $('#repair-item-search').val('');
        const quantity = $('.modal-proposal-body').attr('data-issue-count') || 1;
        context.selectCatalogItem(ui, inspectionId, inspectionZoneId, problemIssue, quantity);
        $('.ui-helper-hidden-accessible').hide();
        return false;
      },
    };
    /* eslint-disable-next-line no-underscore-dangle */
    $('#repair-item-search').autocomplete(autocompleteOptions)
      .autocomplete('instance')._renderItem = (ul, item) => $('<li>')
        .append(`<a><tr><td>${item.name} &nbsp &nbsp</td><td><span style='color:blue'>${item.part_number}<span></td></tr></a>`)
        .appendTo(ul);
  },
  selectCatalogItem(itemObject, inspectionId, inspectionZoneId, problemIssue, quantity) {
    const context = this;
    if ($(`td[data-job_catalog_item_id=${itemObject.item.id}]`).length > 0) {
      bootbox.alert({
        message: `Looks like the item <span style='color:blue'>${itemObject.item.name}</span> is already in the added items list.  If you want multiple added, please adjust the quantity field and click update.`,
      });
    } else {
      $.ajax({
        url: '/api/v2/repair_items',
        data: ({
          inspection_id: inspectionId, job_catalog_item_id: itemObject.item.id, inspections_zone_id: inspectionZoneId, inspection_issue: problemIssue, quantity,
        }),
        type: 'POST',
        dataType: 'json',
        success(response) {
          if (response.errors) {
            alert(JSON.stringify(response.errors));
          } else {
            $('.job-items-table tbody').append(context.addRepairItem({ response, ui: itemObject, assignsPriority: context.assignsPriority }));
          }
        },
        error(response) {
          alert(`We're sorry something went wrong: ${response}`);
        },
      });
    }
  },
});
export default InspectionProposalsView;
